import * as React from 'react';
import { TitleRow } from './Summary.styles';
import { SummaryProps } from '../../types';

export const Summary: React.FC<SummaryProps> = ({
  totalRespondents,
}: SummaryProps) => {
  return (
    <div>
      <TitleRow>
        <span>{totalRespondents}</span>&nbsp; Respondents
      </TitleRow>
    </div>
  );
};
