import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  margin: 0 2rem;
  section {
    width: 50%;
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};
    border-radius: 0.35rem;
    box-shadow: 0px 0.25rem 0.65rem rgb(0 0 0 / 10%);
  }
  section:nth-child(1) {
    margin-right: 5%;
  }
`;
