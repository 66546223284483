import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { CommentCard } from 'Client/components/CommentCard';
import { isAnswered } from 'Atoms/Card/CommentCardOld/utils';
import { DateDistanceLocalised } from 'Atoms';
import { AgreementCounter, AnswerRenderer } from 'Client/components/organisms';
import {
  ArrowRightSmallIcon,
  ProposalsIcon,
  ShareIcon,
  LikeIcon,
} from 'Atoms/Icons';
import { useProject, useMap } from 'Client/utils/hooks';
import { BlockContentStages } from 'Client/pages/proposals/types';
import { RootState } from 'Client/redux-store';
import { selectProposalBySlug } from 'Client/utils/reduxReducers/pages/selectors';
import { getContributionColors } from 'Client/pages/contributions/utils/getContributionColors';
import { ContributionModerationStatus } from 'Shared/types/contributionModeration';
import { CommentReplySection } from 'Client/pages/dashboard/components/DetailsPages/ContributionDetailsCard/components';
import { ANSWERS_LIMIT } from 'Client/pages/dashboard/components/DetailsPages/ContributionDetailsCard/constants/limitOfAnswers';
import { ToolTip } from 'Client/components/molecules';
import { getQuestionLabel, getSentiment } from '../utils';
import { ContributionCardProps } from '../types';
import { ContributionResponse } from './ContributionResponse';
import { ContributionResponseIndicator } from './ContributionResponseIndicator';
import PendingModeration from './PendingModeration/PendingModeration';
import { ReplyButton } from './ContributionReply';
import { getSentimentLabel } from '../utils/getSentiment';

export const ContributionCard: React.FC<ContributionCardProps> = ({
  answers,
  backUrl,
  userAgreementId,
  isOwnComment,
  contributionId,
  contributionDate,
  isMapComment,
  refetchUserAgreements,
  contrProposalSlug,
  response: fetchedResponse,
  pageId,
  isSurvey,
  canReply,
  card = true, // card is false when displaying the contribution in the map slider panel
  canSeeUnderModerationComments,
  moderation,
  voiceAnswers,
  detailsPage,
}: ContributionCardProps) => {
  const router = useRouter();
  const contributionProposal = useSelector((state: RootState) =>
    selectProposalBySlug(contrProposalSlug, isMapComment, state)
  );
  const {
    state: { proposal },
  } = useMap();
  const proposalContributionLayer =
    proposal?.geolytixWorkspace?.locales?.UK?.layers?.Contributions;
  const svgs = proposalContributionLayer?.svgs;
  const pivotQuestion = proposalContributionLayer?.pivotQuestion;
  const hasPivot = !!pivotQuestion;
  const pivotAnswer: string | null = hasPivot
    ? (answers.filter((q) => q.questionId === pivotQuestion)?.[0]
        ?.value as string)
    : null;
  const { responses } = useSelector(
    (state: RootState) => state.contributionResponses
  );
  const sentimentAnswer =
    answers?.length > 0 &&
    (answers?.find((an) => an?.questionContent?.name === 'feeling')
      ?.value as string);

  const sentimentDescription = getSentimentLabel(sentimentAnswer);
  const { sentiment, commentSentiment } = getSentiment(answers);

  const answered = answers?.filter(isAnswered);
  const [updateDate, setUpdateDate] = React.useState(0);
  const [readMore, setReadMore] = React.useState(false);
  const [contributionColors, setContributionColors] = React.useState({});
  const [isReplyOpen, setIsReplyOpen] = React.useState(false);
  const project = useProject();
  const showAgreements = project?.features?.agreeComments;
  const [agreeEnabled, setAgreeEnabled] = React.useState(showAgreements);
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const response = fetchedResponse || responses[contributionId];
  const canSeeReply = canReply && !response;

  const viewResponse =
    response && (response.visibility === 'public' || isOwnComment);

  React.useEffect(() => {
    if (contributionProposal) {
      const inactiveProposal =
        BlockContentStages.includes(contributionProposal.stage) ||
        BlockContentStages.includes(project.stage);

      setAgreeEnabled(showAgreements && !inactiveProposal);
    }
  }, [showAgreements, contributionProposal, project]);

  const pendingModeration =
    moderation?.status === ContributionModerationStatus.PENDING;
  const canShowAnswers = !pendingModeration || canSeeUnderModerationComments;

  const agreed = !!userAgreementId;
  const isMapContribution = backUrl?.startsWith('/map/');
  if (isMapContribution) {
    backUrl = backUrl + `?cid=${contributionId}`;
  }
  const backTitle = isMapContribution ? t('View on map') : t('View proposal');

  const refreshData = () => {
    setUpdateDate(Date.now());
    refetchUserAgreements();
  };

  const responseToRender = viewResponse ? response : undefined;

  React.useLayoutEffect(() => {
    setContributionColors(getContributionColors(proposal));
  }, []);

  return (
    <CommentCard
      color={
        contributionColors[
          hasPivot ? pivotAnswer : (commentSentiment as number)
        ]
      }
      data-testid="CommentCard"
      data-contribution-id={contributionId}
      card={card}
    >
      <CommentCard.SectionRow>
        <CommentCard.SectionColumn collapse>
          {hasPivot ? (
            <CommentCard.PivotContainer>
              <img
                src={`data:image/svg+xml,${encodeURIComponent(
                  svgs[pivotAnswer]
                )}`}
              />
              <strong style={{ marginRight: '0.5rem' }}>{pivotAnswer}</strong>
            </CommentCard.PivotContainer>
          ) : (
            <>
              <div>
                {sentiment && (
                  <CommentCard.SentimentDot sentiment={sentiment} />
                )}
                <strong style={{ marginRight: '0.5rem' }}>
                  {sentimentDescription}
                </strong>
              </div>
              <DateDistanceLocalised date={contributionDate} />
            </>
          )}
        </CommentCard.SectionColumn>
        {showAgreements && (
          <CommentCard.SectionColumn>
            {isSurvey && (
              <CommentCard.SurveyBadge
                title={t(
                  'This response was added via a paper form or a street interview'
                )}
              >
                {t('Survey')}
              </CommentCard.SurveyBadge>
            )}
            <LikeIcon bold />
            <b style={{ marginLeft: '0.5rem' }}>
              <AgreementCounter.Pure
                commentId={contributionId}
                updateDate={updateDate}
              />
            </b>
          </CommentCard.SectionColumn>
        )}
      </CommentCard.SectionRow>
      <CommentCard.Section>
        {/*
         * We wanna show pending moderation message on both cases. But if the user does not
         * have permission, we don't show any of the answers
         */}
        {pendingModeration && (
          <PendingModeration
            contributionId={contributionId}
            moderation={moderation}
            canSeeUnderModerationComments={canSeeUnderModerationComments}
          />
        )}
        {canShowAnswers &&
          answered?.map((answer, index) => {
            if (index >= 2 && !readMore && detailsPage) {
              return;
            }

            return (
              <AnswerRenderer
                key={`${answer.questionId} - ${answer.questionVersionId}`}
                questionLabel={getQuestionLabel(answer, i18n.language)}
                questionType={answer.questionContent?.type}
                answer={answer}
                contributionId={contributionId}
                lang={router.locale}
                voiceAnswers={voiceAnswers}
                isDetailsPage={detailsPage}
              />
            );
          })}
        {detailsPage && answered?.length >= ANSWERS_LIMIT && (
          <CommentCard.ReadMoreText
            isOpen={readMore}
            onClick={() => setReadMore((previous) => !previous)}
          >
            {readMore ? t('Read less') : t('Read more')}
          </CommentCard.ReadMoreText>
        )}
        {response && <ContributionResponseIndicator response={response} />}
      </CommentCard.Section>
      <ContributionResponse response={responseToRender} />
      <CommentCard.SectionRow stickToBottom={!card}>
        <CommentCard.SectionColumn>
          {backUrl && (
            <CommentCard.MenuButton href={backUrl} title={backTitle}>
              <ProposalsIcon bold />
              {!isMobile && <p>{backTitle}</p>}
              <ArrowRightSmallIcon bold />
            </CommentCard.MenuButton>
          )}
        </CommentCard.SectionColumn>
        <CommentCard.SectionColumn>
          <CommentCard.MenuShareButton
            path={router.asPath}
            commentId={contributionId}
            isMap={isMapComment}
          >
            <ShareIcon bold />
            <span>{t('Share')}</span>
          </CommentCard.MenuShareButton>
          {agreeEnabled && !isOwnComment && !canSeeReply && (
            <ToolTip
              minHorizontalPosition={30}
              startPositionHorizontalMutation={-100}
              hoverableElement={
                <CommentCard.MenuAgreeButton
                  contrast={agreed}
                  userAgreementId={userAgreementId}
                  updateDate={refreshData}
                  commentSentiment={commentSentiment}
                  commentId={contributionId}
                  isMapComment={isMapComment}
                  contributionProposalSlug={contrProposalSlug}
                  pageId={pageId}
                >
                  <LikeIcon bold />
                </CommentCard.MenuAgreeButton>
              }
            >
              <CommentCard.TooltipContent>
                <p>
                  {t('Add your like! More reaction types are coming soon.')}
                </p>
              </CommentCard.TooltipContent>
            </ToolTip>
          )}
          {canSeeReply && (
            <ReplyButton setIsOpen={setIsReplyOpen} isOpen={isReplyOpen} />
          )}
        </CommentCard.SectionColumn>
      </CommentCard.SectionRow>
      {canSeeReply && isReplyOpen && (
        <CommentReplySection id={contributionId} />
      )}
    </CommentCard>
  );
};
