import { ProjectGaudi } from 'Shared/types';

export const specialValues = ['select all', 'unselect all', 'account level'];

interface getProjectListAsOptionsParams {
  projects: Array<ProjectGaudi>;
}

export function getProjectListAsOptions({
  projects,
}: getProjectListAsOptionsParams) {
  const accountLevelOption = { value: 'account level', label: 'Account Level' };
  const projectsAsOptions = projects
    .map((project) => {
      return { value: project._id, label: project.name };
    })
    .sort((a, b) =>
      a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
    );
  return [accountLevelOption, ...projectsAsOptions];
}
