import * as React from 'react';
import { useSelector } from 'react-redux';
import { theme } from 'Client/components/theme';
import { ProjectGaudi } from 'Shared/types';
import { UsersMoreIcon } from 'Atoms/Icons';
import { RootState } from 'Client/redux-store';
import { SimpleHoverModal } from 'Atoms/SimpleHoverModal/SimpleHoverModal.styles';
import {
  checkLengthAndSetModal,
  HTMLElementEvent,
  MouseEventCustom,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import { Permissions } from 'Client/constants/permissions';
import { userHasProjectCentrePermission } from 'Client/utils/user';
import { useUser } from 'Client/utils/hooks';
import {
  Name,
  NameWrapper,
  StatusCell,
  StatusDot,
  Wrapper,
  ArrowIcon,
  ArrowIconWrapper,
  PeopleCounter,
  Container,
  TableContainer,
  Table,
} from './TeamProjectRow.styles';
import { TeamTableHeader } from '../TeamTableHeader';
import { TeamUserRow } from '../TeamUserRow';
import { AddMembersShortcut } from '../AddMembersShortcut';
import {
  getAdminCount,
  getTeamMembersFromProject,
} from './utils/getTeamMembersFromProject';
import { getPeopleCounter } from './utils/getPeopleCounter';

export const TeamProjectRow: React.FC<ProjectGaudi> = (props: ProjectGaudi) => {
  const { name, stage, brandingOptions, _id } = props;
  const color =
    brandingOptions?.navColour || theme.colorMappings.brand || '#000000';
  const { teamMembers, memberToEdit } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const team = getTeamMembersFromProject(teamMembers, _id, memberToEdit);
  const adminCount = getAdminCount(teamMembers, _id);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const { user } = useUser();

  return (
    <Container>
      <TableContainer color={color}>
        <Wrapper
          color={color}
          data-testid="TeamProjectRow-Wrapper"
          onClick={() => setIsOpen((previous) => !previous)}
        >
          <NameWrapper>
            <Name
              onMouseOver={(
                e:
                  | React.MouseEvent<HTMLDivElement, MouseEvent>
                  | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
              ) => {
                setOpenModal(() =>
                  checkLengthAndSetModal(
                    e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
                  )
                );
              }}
              onMouseOut={() => setOpenModal(false)}
            >
              <p>{name}</p>
              {openModal && <SimpleHoverModal>{name}</SimpleHoverModal>}
            </Name>
            <UsersMoreIcon />
            <PeopleCounter>{getPeopleCounter(team.length)}</PeopleCounter>
          </NameWrapper>
          <StatusCell>
            <StatusDot status={`OnboardingProjectStatus${stage}`} /> {stage}
          </StatusCell>
          <ArrowIconWrapper color={color}>
            <ArrowIcon isOpen={isOpen} />
          </ArrowIconWrapper>
        </Wrapper>
        {isOpen && (
          <Table>
            <TeamTableHeader />
            {team.map((user, index) => {
              return (
                <TeamUserRow
                  key={user.email}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  email={user.email}
                  role={user.currentProject.role}
                  dateAdded={user.dateAdded}
                  //last seen date still doesnt exist in the teamMembersobject
                  lastSeen={new Date().toDateString()}
                  color={color}
                  isLastChild={index === team.length - 1}
                  projectId={_id}
                  invite={user.invite}
                  status={user.status}
                  _id={user._id}
                  adminCount={adminCount}
                />
              );
            })}
          </Table>
        )}
      </TableContainer>
      {isOpen &&
        userHasProjectCentrePermission(
          user,
          Permissions.INVITE_TEAM_MEMBERS
        ) && <AddMembersShortcut color={color} projectId={_id} />}
    </Container>
  );
};
