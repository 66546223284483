import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/node';
import Clock from 'Atoms/Icons/Clock/Clock';
import { ContributionModeration } from 'Shared/types/contributionModeration';
import { useUser } from 'Client/utils/hooks';
import { ModerationStatusTypes } from 'Client/pages/proposals/types';
import { CheckIcon, CloseRoundIcon } from 'Atoms/Icons';
import {
  IconContainer,
  ModeratedQuestions,
  ModerationApproveButton,
  ModerationRejectButton,
  ModerationButtonSection,
  ModerationRightSide,
  PendingModerationWrapper,
  Subtitle,
  TextContainer,
  Title,
  ButtonWrapper,
} from './PendingModeration.styles';
import { APPROVE_DECLINE_COMMENTS } from './approveDeclineComment.gql';

interface PendingModerationProps {
  canSeeUnderModerationComments: boolean;
  moderation: ContributionModeration;
  contributionId: string;
}

const PendingModeration = ({
  contributionId,
  canSeeUnderModerationComments,
  moderation,
}: PendingModerationProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [approveDeclineComment] = useMutation(APPROVE_DECLINE_COMMENTS);
  const [actioned, setActioned] = React.useState(false);
  const [success, setSuccess] =
    React.useState<{ [key in ModerationStatusTypes]: boolean }>();

  const handleModerateComment = async (status: ModerationStatusTypes) => {
    setActioned(true);
    try {
      approveDeclineComment({
        variables: {
          mutateCommentInput: {
            contributionId,
            status,
            userId: user.id,
          },
        },
      });
      setSuccess({ ...success, [status]: true });
    } catch (err) {
      captureException(err);
      setActioned(false);
    }
  };

  const subtitleMessage = canSeeUnderModerationComments ? (
    <>
      <ModeratedQuestions>
        {(moderation.moderatedAnswers || []).map(({ questionId, reasons }) => (
          <li key={questionId}>
            <strong>{questionId}</strong> - {reasons.join(', ')}
          </li>
        ))}
      </ModeratedQuestions>
    </>
  ) : (
    <Subtitle>
      {t('This comment is under moderation and will be reviewed shortly.')}
    </Subtitle>
  );
  return (
    <PendingModerationWrapper
      canSeeUnderModerationComments={canSeeUnderModerationComments}
    >
      <ModerationRightSide>
        <IconContainer>
          <Clock />
        </IconContainer>
        <TextContainer>
          <Title>Pending moderation</Title>

          {subtitleMessage}
        </TextContainer>
      </ModerationRightSide>
      {canSeeUnderModerationComments && (
        <ModerationButtonSection>
          <ButtonWrapper>
            {success?.[ModerationStatusTypes.APPROVED] && (
              <CheckIcon color="#5296e6" />
            )}
            <ModerationApproveButton
              disabled={actioned}
              onClick={() => {
                handleModerateComment(ModerationStatusTypes.APPROVED);
              }}
            >
              <CheckIcon />
              <p>{t('Approve')}</p>
            </ModerationApproveButton>
          </ButtonWrapper>
          <ButtonWrapper>
            {success?.[ModerationStatusTypes.REJECTED] && (
              <CheckIcon color="#5296e6" />
            )}

            <ModerationRejectButton
              disabled={actioned}
              onClick={() => {
                handleModerateComment(ModerationStatusTypes.REJECTED);
              }}
            >
              <CloseRoundIcon />
              <p>{t('Mark as deleted')}</p>
            </ModerationRejectButton>
          </ButtonWrapper>
        </ModerationButtonSection>
      )}
    </PendingModerationWrapper>
  );
};

export default PendingModeration;
