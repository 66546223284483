import styled from 'styled-components';
import { ChevronIcon } from 'Icons';

export const Wrapper = styled.div`
  padding: 1.25rem 3.75rem 2rem 3.75rem;
  background: white;
  box-shadow: 0px 0px 1.25rem ${({ theme }) => theme.colors.purple[700]};
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  margin: 0 0 0.75rem;
  color: black;
`;

export const BackButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.green[500]};
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const Arrow = styled(ChevronIcon)`
  transform: scale(1);
  margin-right: 0.4rem;
`;
