import { gql } from '@apollo/client';

export const GET_ALL_DEMOGRAPHICS_QUESTIONS_QUERY = gql`
  query GetAllDemographicsQuestions(
    $demographicsQuestionsParams: DemographicsQuestionsParams
  ) {
    getAllDemographicsQuestions(
      DemographicsQuestionsParams: $demographicsQuestionsParams
    ) {
      questions {
        _id
        content
        questionContent
      }
    }
  }
`;
