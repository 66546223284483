import * as React from 'react';
import { Wrapper, TextWrapper, TitleWrapper } from './FormModal.styles';
import { FormModalProps } from './types';

export const FormModal: React.FC<FormModalProps> = ({
  title,
  text,
  position,
}: FormModalProps) => {
  return (
    <Wrapper position={position}>
      <TitleWrapper>{title}</TitleWrapper>
      <TextWrapper>{text}</TextWrapper>
    </Wrapper>
  );
};
