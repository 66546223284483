import { gql } from '@apollo/client';

export const GET_REFERRALS_TAB_QUERY = gql`
  query GetReferrals($referralsInput: VisitorsParams) {
    getReferrals(ReferralsInput: $referralsInput) {
      referral
      responded
      visitors
      subscribed
    }
  }
`;
