export const rolesOptions = [
  { value: 'Lead admin', label: 'Lead admin' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Communications manager', label: 'Communications manager' },
  { value: 'Tester', label: 'Tester' },
  { value: 'Surveyor', label: 'Surveyor' },
  { value: 'Data analyst', label: 'Data analyst' },
];

export const rolesOptionsStakeholder = [
  ...rolesOptions,
  { value: 'Stakeholder', label: 'Stakeholder' },
];

export const getRolesOptions = (
  currentRole: string,
  adminCount: number,
  inviteStakeholder: boolean
) => {
  //if only admin in the project.
  if (
    ['Lead admin', 'admin', 'Admin', 'lead admin'].includes(currentRole) &&
    adminCount === 1
  ) {
    if (inviteStakeholder) {
      return [
        { value: 'Lead admin', label: 'Lead admin' },
        { value: 'Admin', label: 'Admin' },
        { value: 'Stakeholder', label: 'Stakeholder' },
      ];
    }

    return [
      { value: 'Lead admin', label: 'Lead admin' },
      { value: 'Admin', label: 'Admin' },
    ];
  }
  return inviteStakeholder ? rolesOptionsStakeholder : rolesOptions;
};
