import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: auto;
`;

export const TableRow = styled.div`
  position: relative;
`;

export const TableContainer = styled.div`
  border-spacing: 0;
  &.sticky {
    .header {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .footer {
      bottom: 0;
      box-shadow: 0rem -0.1rem 0.1rem #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [role='rowgroup'] {
      [role='row'] {
        &:hover {
          div {
            background: ${({ theme }) =>
              theme.colorMappings.dashboardTableRowsHover};
          }
        }
      }
    }

    [data-sticky-td] {
      position: sticky !important;
    }

    [data-sticky-last-left-td] {
      box-shadow: 0.1rem 0rem 0.1rem #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -0.1rem 0rem 0.1rem #ccc;
    }
  }
`;

export const TableHeaders = styled.div`
  tr {
    :last-child {
      margin: 0;
      th,
      td {
        border-bottom: 0.1rem solid;
        border-bottom-color: ${({ theme }) => theme.colors.grey[700]};
      }
    }
  }
`;
