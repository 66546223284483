import { gql } from '@apollo/client';

export const GET_RESPONDENTS_STATUS_PILLS_QUERY = gql`
  query GetRespondentsPills($getRespondentsPillsInput: RespondentsPillsInput) {
    getRespondentsPills(GetRespondentsPillsInput: $getRespondentsPillsInput) {
      confirmed
      pending
      surveyed
      anonymous
    }
  }
`;
