import * as React from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { OnboardingPagesTemplate as PageTemplate } from 'Templates';
import { useProject, useUser } from 'Client/utils/hooks';
import { useReturnIfAllCustomers } from 'Client/utils/hooks/useReturnIfAllCustomers';
import {
  setCurrentPage,
  setCurrentTab,
  setIsAddMemberModalOpen,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { LoadRing } from 'Client/components/molecules';
import { RootState } from 'Client/redux-store';
import { userHasProjectCentrePermission } from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';
import { InfoRow, TopBar } from '../../components';
import {
  Content,
  Wrapper,
  ActionsRow,
  AddTeamMemberButton,
} from './TeamManagementPage.styles';
import { TeamProjectTable } from './components/TeamProjectTable';
import { TeamManagementProps } from './types';
import { INVITE_MEMBER } from './TeamManagementPage.gql';
import { AddMembersModal, RemoveMemberModal } from './components';

export const TeamManagementPage: React.FC<TeamManagementProps> = () => {
  const project = useProject();
  const { user } = useUser();
  const router = useRouter();
  const dispatch = useDispatch();
  useReturnIfAllCustomers();
  const { loadingCustomers, membersToAdd, selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const [inviteMember] = useMutation(INVITE_MEMBER);

  const handleModalSubmit = async (modalSubmitData, selectedCustomer) => {
    setIsAddMemberModalOpen(false);
    const { firstName, lastName, email, role, selectedProjects } =
      modalSubmitData;

    const accountLevel = (() => {
      if (selectedProjects.includes('account level')) {
        const index = selectedProjects.findIndex((e) => e === 'account level');
        selectedProjects.splice(index, 1);
        return true;
      }
      return false;
    })();

    const allResponses = await Promise.all(
      email.map(async (_e, index) => {
        return await inviteMember({
          variables: {
            inviteMemberInput: {
              firstName: firstName[index],
              lastName: lastName[index],
              email: email[index],
              role: role[index],
              projects: selectedProjects,
              customerId: selectedCustomer.hubspot_id,
              projectId: String(project._id),
              accountLevel,
            },
          },
        });
      })
    );

    if (allResponses && allResponses.length > 0) {
      router.reload();
    }
  };

  React.useEffect(() => {
    dispatch(setCurrentTab('People'));
    dispatch(setCurrentPage('Team management'));
  }, []);

  React.useEffect(() => {
    if (membersToAdd && membersToAdd.email.length > 0) {
      handleModalSubmit(membersToAdd, selectedCustomer);
    }
  }, [membersToAdd]);

  return (
    <PageTemplate>
      <Wrapper data-testid="TeamManagementPage-Wrapper">
        <TopBar
          title="Manage your team"
          text="Invite colleagues to manage this Commonplace. Different roles define what individuals can access or edit."
        />
        <InfoRow />
        {loadingCustomers ? (
          <LoadRing loadingMessage="loading" />
        ) : (
          <Content>
            {userHasProjectCentrePermission(
              user,
              Permissions.INVITE_TEAM_MEMBERS
            ) && (
              <ActionsRow>
                <AddTeamMemberButton
                  colorMapping="CommonplaceBrand"
                  onClick={() => {
                    dispatch(setIsAddMemberModalOpen(true));
                  }}
                >
                  + Add team member
                </AddTeamMemberButton>
              </ActionsRow>
            )}
            <TeamProjectTable />
          </Content>
        )}
      </Wrapper>

      <AddMembersModal onSubmit={handleModalSubmit} />

      <RemoveMemberModal />
    </PageTemplate>
  );
};
