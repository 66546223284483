import * as React from 'react';
import orderBy from 'lodash.orderby';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { captureException } from '@sentry/node';
import { useApolloClient } from '@apollo/client';
import { transform } from 'ol/proj';
import { useRouter } from 'next/router';
import { AlertBellIcon, CloseIcon, FilledCircleIcon, FilterIcon } from 'Icons';
import { getLocations } from 'Client/services/locations';
import { SelectField, TextField } from 'Client/components/molecules';
import { LabelValueOptions } from 'Client/pages/proposals';
import { TabbedMultiselectAutocomplete } from 'Client/components/molecules/TabbedMultiselectAutocomplete/TabbedMultiselectAutocomplete';
import { DateRangeFilter } from 'Client/components/molecules/DateSelector/DateRangeFilter';
import {
  PlanningAppPageAndContent,
  PlanningAppStage,
} from 'Shared/types/planningapp';
import { InputLabel } from 'Atoms';
import {
  clearReduxFilters,
  setDateFilters,
  setFilterValues,
  setGeneralFilter,
  setOptions,
  setQuickFilter,
  setUrlFilters,
} from 'Client/utils/reduxReducers/filters/filtersReducer';
import { fetchCommentsCountOfPlanApps } from 'Client/services/planningapp/fetchCommentsCountOfPlanApps';
import { RootState } from 'Client/redux-store';
import { OptionItem } from 'Client/types';
import { getMapFeatures } from 'Client/services/map';
import { MapProjection, Xyz, CustomLayerMapEntry } from 'Shared/types/map';
import centerOnFeature from 'Client/utils/map/centerOnFeature';
import { getPlanningAppsCategoryIcon } from 'Atoms/Icons/PlanningApps';
import { ExpandableMultiselect } from 'Client/components/molecules/ExpandableMultiselect/ExpandableMultiselect';
import { Filters } from 'Client/utils/reduxReducers/filters/FilterState';
import { resetZoomLevel } from 'Client/utils/map/resetZoomLevel';
import { useMap } from 'Client/utils/hooks';
import { TabbedInputSelection } from 'Client/components/molecules/TabbedMultiselectAutocomplete/types';
import { MultiselectOption } from 'Shared/types/question';
import { GLWorkspaceLayer } from 'Shared/types/proposals';
import { MapPagePropsWithContent } from 'Client/pages/map/types';
import { GET_FILTERED_PLANNING_APPS } from '../../PlanningApps.gql';
import {
  Wrapper,
  FiltersSectionContainer,
  PlanAppFilterButtonBase,
  MoreFiltersButton,
  GeneralFilter,
  FiltersDraw,
  DrawHeader,
  CloseButton,
  DrawFilters,
  DrawFilterItem,
  BottomSection,
  ApplyFiltersButton,
  CheckboxField,
  CheckboxOptions,
  CheckboxItem,
  ClearFiltersButton,
  PlanAppBellButton,
} from './styles';
import {
  FiltersSectionProps,
  HandleFilterChangeProps,
  PlanningAppFilter,
  PlanningAppFilters,
  PLANNING_APPS_FILTER_TYPES,
} from './types';
import {
  glaPlanningAppsCategories,
  glaPlanningAppsStages,
  glaPlanningAppsStatuses,
} from '../../constants/glaConstants';
import { CreateAlertDrawer } from '../CreateAlertDrawer/CreateAlertDrawer';
import {
  getFiltersFromUrl,
  removeUrlFilters,
  setFiltersOnUrl,
} from './utils/urlFilters';

export const FiltersSection: React.FC<FiltersSectionProps> = ({
  setFilteredPlanningApps,
  setMapResults,
  setListResults,
  project,
  initialFilters,
  clearQueryByArea,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { dispatch, state } = useMap();
  const xyz = state.xyz as Xyz;
  const proposal = React.useMemo(
    () =>
      state.proposal && {
        ...state.proposal,
        ...(state.proposal as MapPagePropsWithContent).content,
      },
    [state.proposal]
  ) as MapPagePropsWithContent;
  const { breakpoints } = useTheme();

  const {
    filters: reduxFilters = initialFilters,
    queryByArea,
    filterValues,
    options,
  } = useSelector((state: RootState) => state.filters);

  const dispatchFiltersRdx = useDispatch();
  const router = useRouter();

  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const [generalSearch, setGeneralSearch] = React.useState<string>('');
  const [debouncedGeneralSearch] = useDebounce(generalSearch, 2000);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [drawOpen, setDrawOpen] = React.useState(false);
  const [alertDrawerOpen, setAlertDrawerOpen] = React.useState(false);
  const [hasFilters, setHasFilters] = React.useState(false);
  const [hasUrlFilters, setHasUrlFilters] = React.useState(false);

  const mapPointResults = React.useRef<CustomLayerMapEntry[]>();

  const isGla = project?.features?.glaPlanningApps;
  const planningAppsStages = isGla ? glaPlanningAppsStages : [];
  const planningAppsStatuses = isGla ? glaPlanningAppsStatuses : [];
  const planningAppsCategories = isGla ? glaPlanningAppsCategories : [];

  const planAppsStages = orderBy(
    planningAppsStages.map((item: string) => ({ label: item, value: item })),
    [(item: LabelValueOptions) => item.label.toLowerCase()],
    ['asc'] // not asc!
  );

  const planAppsStatuses = planningAppsStatuses.map((item) => ({
    ...item,
    value: item.label,
  }));

  const planAppsCategories = planningAppsCategories.map((item) => ({
    ...item,
    value: item.label,
  }));

  const categoryFilterOptions = React.useMemo(
    () =>
      planAppsCategories.map((cat) => ({
        label: cat.label,
        value: cat.value,
        checked: false,
        icon: getPlanningAppsCategoryIcon({
          icon: cat.label,
          width: isMobile ? 35 : 25,
          height: isMobile ? 35 : 25,
        }),
      })),
    [planAppsCategories, isMobile]
  );

  const statusFilterOptions = React.useMemo(
    () =>
      planAppsStatuses.map((status) => ({
        label: status.label,
        value: status.value,
        checked: false,
        icon: <FilledCircleIcon color={status.color} width={10} height={10} />,
      })),
    [planAppsStatuses]
  );

  const filters: PlanningAppFilter[] = [
    {
      type: PLANNING_APPS_FILTER_TYPES.DATE,
      label: 'Date',
      name: 'date',
      tabs: [
        {
          label: 'Validated',
          value: 'validatedDate',
        },
        {
          label: 'Decided',
          value: 'decisionDate',
        },
      ],
      dateOptions: [
        {
          type: 'fixed',
          label: 'Last 7 days',
          value: 7,
        },
        {
          type: 'fixed',
          label: 'Last 30 days',
          value: 30,
        },
        {
          type: 'fixed',
          label: 'Last 6 months',
          value: 180,
        },
        {
          type: 'fixed',
          label: 'Last year',
          value: 365,
        },
        {
          type: 'custom',
          label: 'Specify dates',
        },
      ],
    },
    {
      type: PLANNING_APPS_FILTER_TYPES.TABBED,
      label: 'Location',
      placeholder: 'Postal district, ward or borough',
      name: 'location',
      tabSections: [
        {
          name: 'postcode',
          label: 'Postal district',
          options:
            options?.location?.postal_district?.map((postal) => ({
              label: `${postal.value} ${postal.label}`,
              value: postal.value,
            })) || [],
          isSimilarFilter: true,
        },
        {
          name: 'borough',
          label: 'Borough',
          options: options?.location?.borough || [],
        },
        {
          name: 'ward',
          label: 'Ward',
          options: options?.location?.ward || [],
        },
      ],
    },
    {
      type: PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX,
      label: 'All categories',
      name: 'category',
      multiSelectOptions: categoryFilterOptions,
      mobileOnly: true,
    },
    {
      type: PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX,
      label: 'All statuses',
      name: 'status',
      multiSelectOptions: statusFilterOptions,
      mobileOnly: true,
    },
    {
      type: PLANNING_APPS_FILTER_TYPES.SELECT,
      label: 'Stage',
      placeholder: 'All stages',
      showLabel: true,
      options: planAppsStages,
      name: 'stage',
    },
  ];

  const lazyLoadingInitial = {
    limit: 5,
    offset: 0,
  };

  const closeDraw = () => setDrawOpen(false);

  const toggleDraw = () => {
    setDrawOpen(!drawOpen);
  };

  const handleGeneralFilterChange = (value: string) => setGeneralSearch(value);

  const filterListViewPlanningApps = async (filters: PlanningAppFilters[]) => {
    try {
      const listViewFilters = [
        ...filters,
        ...(queryByArea?.references
          ? [
              {
                reference: {
                  in: `${queryByArea?.references
                    .map((ref) => `'${ref}'`)
                    .join(',')}`,
                },
              },
            ]
          : []),
      ];
      const { data } = await client.query({
        query: GET_FILTERED_PLANNING_APPS,
        variables: {
          filters: listViewFilters,
          limit: lazyLoadingInitial.limit,
          offset: lazyLoadingInitial.offset,
        },
      });
      const {
        getFilteredPlanningApps: { planningAppContent, count },
      } = data;
      setListResults(count);

      const planningAppsData = planningAppContent.map((item) => {
        return {
          content: item,
          slug: item.reference?.split('/').join('-'),
        };
      });

      if (
        planningAppsData?.some(
          (item) => item?.content?.stage === PlanningAppStage.stage3
        )
      ) {
        const numberPerSlug = await fetchCommentsCountOfPlanApps({
          planApps: planningAppsData?.map((item) => ({
            slug: item?.slug,
          })),
          projectId: project?._id,
        });

        const withComments = planningAppsData.map((item) => {
          const contributionsNumber = numberPerSlug?.find(
            (number) => number.slug === item?.slug
          )?.contributionsNumber;

          return {
            ...item,
            ...((contributionsNumber && { contributionsNumber }) || {}),
          };
        });
        setFilteredPlanningApps(withComments);
        return withComments;
      }

      setFilteredPlanningApps(planningAppsData);
      return planningAppsData;
    } catch (error) {
      captureException(error);
    }
  };

  const queryMapPoints = async () => {
    const mapFilters = initialFilters[0];
    reduxFilters.forEach((item) => {
      const key = Object.keys(item)[0];
      const toQuery = {};
      if (key === 'project_id') return;

      /* If key is OR, we want to send it as an array to XYZ */
      if (key === 'or') {
        (item[key] as Filters[]).map((orItem) => {
          return Object.assign(toQuery, orItem);
        });
        const result = Object.keys(toQuery).map((key) => {
          return { [`metadata->>'${key}'`]: toQuery[key] };
        });
        return Object.assign(mapFilters, { or: result });
      }
      Object.keys(item[key]).forEach((filterKey) => {
        if (filterKey === 'in') {
          if (key === 'reference') {
            /* We should ignore this field now as data is not 100% synched with planning_apps table */
            return;
          }
          return Object.assign(toQuery, {
            in: item[key][filterKey]
              .split(',')
              .map((i) => i.replace(/'/gm, '')),
          });
        }

        return Object.assign(toQuery, item[key]);
      });

      /* if obj prop already exists on filter object */
      if (mapFilters[`metadata->>'${key}'`]) {
        return Object.assign(mapFilters[`metadata->>'${key}'`], {
          ...toQuery,
          ...mapFilters[`metadata->>'${key}'`],
        });
      }
      return Object.assign(mapFilters, {
        [`metadata->>'${key}'`]: toQuery,
      });
    });

    if (queryByArea?.ids?.length) {
      Object.assign(mapFilters, {
        id: { in: queryByArea.ids },
      });
    }

    dispatch({
      type: 'SET_MAP_FILTERS',
      payload: { filters: [mapFilters], table: 'Custom' },
    });
  };

  const filterPlanningApps = async (): Promise<PlanningAppPageAndContent[]> => {
    try {
      setLoading(true);

      const listViewFilter = (
        reduxFilters?.length
          ? reduxFilters
          : [
              {
                project_id: {
                  eq: project._id.toString(),
                },
              },
            ]
      )
        .map((query) => {
          delete query['id'];
          return query;
        })
        .filter((query) => !!Object.keys(query).length);
      /* When filters change, clear lazyLoading offset */
      const res = await filterListViewPlanningApps(listViewFilter);
      queryMapPoints();
      setFilteredPlanningApps(res);
      setHasFilters(!!reduxFilters?.length);
      return res;
    } catch (error) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    dispatchFiltersRdx(clearReduxFilters());
    dispatchFiltersRdx(setFilterValues({ filters: {} }));
    dispatch({
      type: 'SET_MAP_FILTERS',
      payload: { filters: initialFilters, table: 'Custom' },
    });
    removeUrlFilters();
    setHasUrlFilters(false);
    filterListViewPlanningApps([]);
    clearQueryByArea();
  };

  const handleFilterChange = ({
    value,
    type,
    filter,
  }: HandleFilterChangeProps) => {
    /* Text inputs need to debounce before search */
    if (
      type !== PLANNING_APPS_FILTER_TYPES.TEXT &&
      type !== PLANNING_APPS_FILTER_TYPES.DATE
    ) {
      dispatchFiltersRdx(
        setFilterValues({
          filters: {
            ...filterValues,
            [filter]: value,
          },
        })
      );
    }

    switch (type) {
      case PLANNING_APPS_FILTER_TYPES.TEXT:
        if (filter === 'general') {
          handleGeneralFilterChange(value);
        }
        break;
      case PLANNING_APPS_FILTER_TYPES.SELECT:
        dispatchFiltersRdx(
          setQuickFilter({
            quickFilter: {
              [filter]: value ? ([value] as LabelValueOptions[]) : [],
            },
          })
        );
        break;
      case PLANNING_APPS_FILTER_TYPES.TABBED: {
        const sectionName = Object.keys(value)[0];
        const tabbedFilter = filters?.find((item) => item.name === filter);
        const section = tabbedFilter?.tabSections?.find(
          (item) => item.name === sectionName
        );

        dispatchFiltersRdx(
          setQuickFilter({
            quickFilter: value,
            isSimilarFilter: section?.isSimilarFilter,
          })
        );
        break;
      }
      case PLANNING_APPS_FILTER_TYPES.DATE:
        dispatchFiltersRdx(
          setDateFilters({
            dateFilters: value,
            name: filter,
          })
        );
        break;
      case PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX:
        {
          dispatchFiltersRdx(
            setFilterValues({
              filters: {
                ...filterValues,
                [filter]: value,
              },
            })
          );

          dispatchFiltersRdx(
            setQuickFilter({ quickFilter: { [filter]: value } })
          );
        }
        break;
      case PLANNING_APPS_FILTER_TYPES.CHECKBOX:
        break;
    }
  };

  React.useEffect(() => {
    dispatchFiltersRdx(
      setFilterValues({
        filters: {
          ...filterValues,
          ['general']: debouncedGeneralSearch,
        },
      })
    );
    dispatchFiltersRdx(
      setGeneralFilter({
        search: debouncedGeneralSearch,
        toMatchProps: [
          { prop: 'name', operator: 'iRgx' },
          { prop: 'glaReference', operator: 'iRgx' },
          { prop: 'reference', operator: 'iRgx' },
          { prop: 'postcode', operator: 'iRgx' },
        ],
        projectId: project._id.toString(),
      })
    );
  }, [debouncedGeneralSearch]);

  React.useEffect(() => {
    if (reduxFilters) {
      setFiltersOnUrl(reduxFilters, filterValues);
      setHasUrlFilters(!!reduxFilters.length);
    }
    if (!reduxFilters.length && !hasUrlFilters) {
      removeUrlFilters();
    }
    if (
      getFiltersFromUrl(router)?.urlFilters?.length &&
      !reduxFilters?.length
    ) {
      return; // do not run filterPlanningApps if filters are already set
    }

    const search = async () => {
      await filterPlanningApps();
    };
    search();
  }, [reduxFilters, queryByArea]);

  React.useEffect(() => {
    /*
     * Xyz object does not exists when out of map view
     * so to each time we switch to it's tab, apply filters
     */
    if (xyz) {
      queryMapPoints();
    }
  }, [xyz?.layers?.list?.Custom]);

  React.useEffect(() => {
    if (proposal) {
      const layers = Object.entries(
        proposal.geolytixWorkspace.locales[xyz.locale.key].layers
      ).map(([key, value]: [string, GLWorkspaceLayer]) => {
        return { ...value, title: key };
      });
      const customLayers = layers.filter((layer) =>
        ['Custom'].includes(layer.title)
      );

      if (typeof xyz?.layers?.list?.Custom?.filter?.current !== 'string')
        return;
      getMapFeatures(
        customLayers,
        proposal,
        project,
        xyz,
        xyz.layers.list.Custom.filter.current
      ).then((features) => {
        const customFeatures: CustomLayerMapEntry[] = features?.['Custom'];
        setMapResults(customFeatures?.length || 0);
        if (customFeatures?.length) {
          mapPointResults.current = customFeatures;
          const singleResult = customFeatures.length === 1;

          const singleResultPoint: GeoJSON.Point = {
            type: 'Point',
            coordinates: transform(
              customFeatures[0].geometry.coordinates,
              MapProjection.WEB_MERCATOR,
              MapProjection.WORLD_GEODETIC_GPS
            ),
          };
          const polygonCoords = customFeatures
            .map((item) => {
              /* Query by area polygon does not have coordinates prop */
              if (!item?.geometry?.coordinates) return null;
              return transform(
                item.geometry.coordinates,
                MapProjection.WEB_MERCATOR,
                MapProjection.WORLD_GEODETIC_GPS
              );
            })
            .filter(Boolean);
          const multipleResultPolygon: GeoJSON.Polygon = {
            type: 'Polygon',
            coordinates: [polygonCoords],
          };
          const feature = singleResult
            ? singleResultPoint
            : multipleResultPolygon;

          if (!reduxFilters.length && !queryByArea) {
            resetZoomLevel(proposal, xyz);
          } else {
            centerOnFeature(xyz.map, feature);
          }
        }
      });
    }
  }, [reduxFilters, proposal, xyz?.layers?.list?.Custom?.filter?.current]);

  React.useEffect(() => {
    const getFilterOptions = async () => {
      const location = await getLocations('london');
      dispatchFiltersRdx(
        setOptions({
          options: {
            location,
          },
        })
      );
    };

    const { urlFilters, values } = getFiltersFromUrl(router);
    urlFilters?.length &&
      dispatchFiltersRdx(
        setUrlFilters({ filters: urlFilters, values, components: filters })
      );
    setHasUrlFilters(!!urlFilters.length);
    getFilterOptions().then(() => {
      if (!urlFilters?.length) {
        dispatchFiltersRdx(
          setUrlFilters({ filters: urlFilters, values, components: filters })
        );
      }
    });
    if (!urlFilters?.length) {
      dispatchFiltersRdx(
        setUrlFilters({ filters: urlFilters, values, components: filters })
      );
      dispatchFiltersRdx(
        setFilterValues({
          filters: {
            ['status']: statusFilterOptions,
            ['category']: categoryFilterOptions,
          },
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (drawOpen) {
      const activeTab = document.querySelector('.activeTab');

      (activeTab as HTMLElement).focus();
    }
  }, [drawOpen]);

  return (
    <Wrapper>
      <FiltersSectionContainer isMobile={isMobile}>
        <div id="filter-section-right-side">
          <GeneralFilter
            label="Search"
            aria-label="Search"
            placeholder={t('Stage ref number, postcode, keyword...')}
            showLabel={true}
            handleChange={(e) => {
              handleFilterChange({
                value: e.target.value as string,
                type: PLANNING_APPS_FILTER_TYPES.TEXT,
                filter: 'general',
              });
            }}
            isMobile={isMobile}
            loading={loading}
          />
          {!isMobile && (
            <>
              <ExpandableMultiselect
                title={t('All categories')}
                options={categoryFilterOptions}
                value={filterValues['category'] as MultiselectOption[]}
                name="category"
                data-testid="expandable-category-filter"
                onChange={(value) => {
                  const filtered = value.filter((v) => v.checked);

                  handleFilterChange({
                    value: filtered,
                    type: PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX,
                    filter: 'category',
                  });
                }}
              />
              <ExpandableMultiselect
                title={t('All statuses')}
                data-testid="expandable-status-filter"
                options={statusFilterOptions}
                value={filterValues['status'] as MultiselectOption[]}
                name="status"
                onChange={(value) => {
                  const filtered = value.filter((v) => v.checked);

                  handleFilterChange({
                    value: filtered,
                    type: PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX,
                    filter: 'status',
                  });
                }}
              />
            </>
          )}
          <MoreFiltersButton
            aria-pressed={drawOpen}
            aria-expanded={drawOpen}
            onClick={() => toggleDraw()}
            data-testid="more-filters-button"
          >
            <FilterIcon width={15} height={11.6} />
            {isMobile ? t('Filters') : t('More filters')}
          </MoreFiltersButton>

          {!isMobile && (
            <ClearFiltersButton
              onClick={() => clearFilters()}
              hasFilters={hasFilters}
              data-testid="clear-filters-button"
            >
              {t('X Clear filters')}
            </ClearFiltersButton>
          )}
        </div>
        {isMobile ? (
          <PlanAppBellButton onClick={() => setAlertDrawerOpen(true)}>
            <AlertBellIcon color="#000" />
          </PlanAppBellButton>
        ) : (
          <div id="filter-section-left-side">
            <PlanAppFilterButtonBase onClick={() => setAlertDrawerOpen(true)}>
              <AlertBellIcon color="#F5B044" />
              {t('Create alert')}
            </PlanAppFilterButtonBase>
          </div>
        )}
      </FiltersSectionContainer>
      <CreateAlertDrawer
        active={alertDrawerOpen}
        onClose={() => setAlertDrawerOpen(false)}
      />
      {drawOpen && (
        <FiltersDraw
          isMobile={isMobile}
          onKeyDown={(e) => {
            if (e.code === 'Escape') closeDraw();
          }}
        >
          <DrawHeader isMobile={isMobile}>
            {isMobile && (
              <div>
                <h1>{t('Filter by')}</h1>
                <ClearFiltersButton
                  onClick={() => clearFilters()}
                  hasFilters={hasFilters}
                >
                  {t('X Clear filters')}
                </ClearFiltersButton>
              </div>
            )}
            <CloseButton
              isMobile={isMobile}
              onClick={closeDraw}
              aria-label="Close"
              title="Close"
            >
              <CloseIcon width={16} height={16} color="#000" />
            </CloseButton>
          </DrawHeader>
          <DrawFilters isMobile={isMobile} filtersQtd={filters.length}>
            {filters.map((filter) => {
              if (filter.mobileOnly && !isMobile) {
                return;
              }
              switch (filter.type) {
                case PLANNING_APPS_FILTER_TYPES.SELECT:
                  return (
                    <DrawFilterItem key={filter.name}>
                      <SelectField
                        tabIndex="0"
                        className="MoreFiltersSelectField"
                        options={filter.options}
                        label={filter.label}
                        placeholder={filter.placeholder}
                        showLabel={filter.showLabel}
                        value={filterValues?.[filter.name] as OptionItem}
                        isSearchable={!isMobile}
                        handleChange={(value) =>
                          handleFilterChange({
                            value,
                            type: PLANNING_APPS_FILTER_TYPES.SELECT,
                            filter: filter.name,
                          })
                        }
                      />
                    </DrawFilterItem>
                  );
                case PLANNING_APPS_FILTER_TYPES.TEXT:
                  return (
                    <DrawFilterItem key={filter.name}>
                      <TextField
                        label={filter.label}
                        placeholder={filter.placeholder}
                        showLabel={filter.showLabel}
                        value={filterValues?.[filter.name] as string}
                        handleChange={(e) =>
                          handleFilterChange({
                            value: e.target.value as string,
                            type: PLANNING_APPS_FILTER_TYPES.TEXT,
                            filter: filter.name,
                          })
                        }
                      />
                    </DrawFilterItem>
                  );
                case PLANNING_APPS_FILTER_TYPES.CHECKBOX:
                  return (
                    <DrawFilterItem key={filter.name}>
                      <CheckboxField>
                        <InputLabel>{filter.label}</InputLabel>
                        <CheckboxOptions>
                          {filter.checkboxOptions.map((option) => (
                            <div key={option.value}>
                              <CheckboxItem
                                checked={option.checked}
                                label={option.label}
                                onChange={(e) =>
                                  handleFilterChange({
                                    value: e.target,
                                    type: PLANNING_APPS_FILTER_TYPES.CHECKBOX,
                                    filter: filter.name,
                                  })
                                }
                              />
                            </div>
                          ))}
                        </CheckboxOptions>
                      </CheckboxField>
                    </DrawFilterItem>
                  );
                case PLANNING_APPS_FILTER_TYPES.TABBED:
                  return (
                    <DrawFilterItem key={filter.name}>
                      <TabbedMultiselectAutocomplete
                        onChange={(value) =>
                          handleFilterChange({
                            value,
                            type: PLANNING_APPS_FILTER_TYPES.TABBED,
                            filter: filter.name,
                          })
                        }
                        label={filter.label}
                        name={filter.name}
                        value={
                          filterValues?.[filter.name] as TabbedInputSelection
                        }
                        /* showIcons={false} @TODO */
                        placeholder={filter.placeholder}
                        sections={filter.tabSections}
                      />
                    </DrawFilterItem>
                  );
                case PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX:
                  return (
                    <DrawFilterItem key={filter.name}>
                      <ExpandableMultiselect
                        title={filter.label}
                        options={filter.multiSelectOptions}
                        value={filterValues[filter.name] as MultiselectOption[]}
                        name={filter.name}
                        onChange={(value) => {
                          const filtered = value.filter((v) => v.checked);

                          handleFilterChange({
                            value: filtered,
                            type: PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX,
                            filter: filter.name,
                          });
                        }}
                      />
                    </DrawFilterItem>
                  );
                case PLANNING_APPS_FILTER_TYPES.DATE:
                  return (
                    <DrawFilterItem key={filter.name}>
                      <InputLabel>{filter.label}</InputLabel>
                      <DateRangeFilter
                        name={filter.name}
                        tabs={filter.tabs}
                        options={filter.dateOptions}
                        onChange={(value) =>
                          handleFilterChange({
                            value,
                            type: PLANNING_APPS_FILTER_TYPES.DATE,
                            filter: filter.name,
                          })
                        }
                      />
                    </DrawFilterItem>
                  );
              }
            })}
          </DrawFilters>
          <BottomSection>
            <ApplyFiltersButton
              tabIndex={0}
              isMobile={isMobile}
              onClick={() => closeDraw()}
            >
              Apply filters
            </ApplyFiltersButton>
          </BottomSection>
        </FiltersDraw>
      )}
    </Wrapper>
  );
};
