import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';
import { MixpanelEventTypes, useAnalytics } from 'Client/utils/hooks';
import { Alert } from 'Atoms';
import { EyeOpenBoldIcon } from 'Atoms/Icons/EyeBold/EyeOpenBold';
import { EyeClosedBoldIcon } from 'Atoms/Icons/EyeBold/EyeClosedBold';
import {
  Image,
  Title,
  Form,
  Label,
  Buttons,
  SignInButton,
  TextField,
  AlertTitle,
} from '../Shared.styles';
import {
  Container,
  Wrapper,
  Header,
  ResetPasswordButton,
  VerificationStepContainer,
  StyledInput,
  Dot,
  DotDiv,
  DotLabel,
} from './ResetPassword.styles';
import { verifyAndSend } from '../utils/verifyAndSendForm';

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [email, setEmail] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<'form' | 'confirmation'>(
    'confirmation'
  );
  const [isConfirmationButtonDisabled, setIsConfirmationButtonDisabled] =
    React.useState<boolean>(true);

  const sendRequest = (params) => {
    if (verifyAndSend(params)) {
      setPage('confirmation');
    }
  };

  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const [isPasswordLengthCorrect, setIsPasswordLengthCorrect] =
    React.useState<boolean>(false);
  const [hasUpperCase, setHasUpperCase] = React.useState<boolean>(false);
  const [hasNumber, setHasNumber] = React.useState<boolean>(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] =
    React.useState<boolean>(false);

  const checkPassword = (password: string) => {
    const hasUpperCaseRegex = /[A-Z]/;
    const hasNumberRegex = /\d/;
    const hasSpecialCharsRegex = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;

    setIsPasswordLengthCorrect(password.length >= 8);

    setHasUpperCase(hasUpperCaseRegex.test(password));

    setHasNumber(hasNumberRegex.test(password));

    setHasSpecialCharacter(hasSpecialCharsRegex.test(password));
  };

  React.useEffect(() => {
    checkPassword(newPassword);
  }, [newPassword]);

  React.useEffect(() => {
    if (newPassword !== '' && confirmPassword !== '') {
      const isPasswordPolicyCorrect =
        isPasswordLengthCorrect &&
        hasUpperCase &&
        hasNumber &&
        hasSpecialCharacter;
      const isValid =
        newPassword === confirmPassword && isPasswordPolicyCorrect;

      setIsConfirmationButtonDisabled(!isValid);
    }
  }, [
    newPassword,
    confirmPassword,
    isPasswordLengthCorrect,
    hasUpperCase,
    hasNumber,
    hasSpecialCharacter,
  ]);

  const handleResetPassword = () => {};

  const resetForm = (
    <Wrapper data-testid="ResetPassword-Wrapper">
      <Container>
        <Header>
          <Image>
            <img src="/static/illustrations/reset_password.png" />
          </Image>
          <Title>{t('Reset your password')}</Title>
        </Header>
        <Form page="reset-password">
          <Label>{t('Email')}</Label>
          <TextField
            placeholder={t('Email')}
            label={'Email'}
            value={email}
            handleChange={(e) => setEmail(e.target.value)}
            type="email"
            status={
              emailError && {
                show: true,
                type: 'error',
                message: t('This does not appear to be a valid email address.'),
              }
            }
          />
        </Form>

        <Buttons>
          <SignInButton
            data-ghostinspectorid="ResetPassword-Button"
            onClick={() => {
              sendRequest({
                emailParams: { email, setEmailError },
                trackEvent: () =>
                  trackEvent(MixpanelEventTypes.RESET_PASSWORD_REQUEST, {
                    email,
                  }),
              });
            }}
          >
            {t('Send password reset instructions')}
          </SignInButton>
        </Buttons>
      </Container>
    </Wrapper>
  );

  const resetConfirmation = (
    <Wrapper data-testid="ResetPassword-Wrapper">
      <Container>
        <Header>
          <Image>
            <img src="/static/illustrations/reset_password.png" />
          </Image>
        </Header>

        <Alert fullWidth type="success">
          <AlertTitle>{t('Email sent successfully!')}</AlertTitle>
          {t('Please, check your email inbox and enter the code below: ')}
        </Alert>

        <VerificationStepContainer>
          <Label>{t('Verification Code')}</Label>
          <StyledInput placeholder={t('Verification Code')} label={t('Code')} />

          <div>
            <div>
              <Label>{t('New Password')}</Label>
              <StyledInput
                placeholder={t('New Password')}
                label={t('New Password')}
                type={showNewPassword ? 'text' : 'password'}
                handleChange={(event) => {
                  const password = event.target.value;

                  setNewPassword(password);
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowNewPassword((current) => !current)
                        }
                      >
                        {showNewPassword ? (
                          <EyeOpenBoldIcon />
                        ) : (
                          <EyeClosedBoldIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <i className="bi bi-eye-slash" id="togglePassword"></i>
            </div>

            <div>
              <Label>{t('Confirm New Password')}</Label>
              <StyledInput
                placeholder={t('Confirm New Password')}
                label={t('Confirm New Password')}
                type={showConfirmPassword ? 'text' : 'password'}
                handleChange={(event) => {
                  const password = event.target.value;

                  setConfirmPassword(password);
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword((current) => !current)
                        }
                      >
                        {showConfirmPassword ? (
                          <EyeOpenBoldIcon />
                        ) : (
                          <EyeClosedBoldIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <Alert fullWidth type="tip" size="big">
                <AlertTitle>{t('Your password must contain:')}</AlertTitle>
                <DotDiv>
                  {isPasswordLengthCorrect ? (
                    <Dot status="granted" />
                  ) : (
                    <Dot status="refused" />
                  )}
                  <DotLabel>{t('Minimum of 8 characters')}</DotLabel>
                </DotDiv>
                <DotDiv>
                  {hasUpperCase ? (
                    <Dot status="granted" />
                  ) : (
                    <Dot status="refused" />
                  )}
                  <DotLabel>{t('1 upper case letter')}</DotLabel>
                </DotDiv>
                <DotDiv>
                  {hasNumber ? (
                    <Dot status="granted" />
                  ) : (
                    <Dot status="refused" />
                  )}
                  <DotLabel>{t('1 or more number')}</DotLabel>
                </DotDiv>
                <DotDiv>
                  {hasSpecialCharacter ? (
                    <Dot status="granted" />
                  ) : (
                    <Dot status="refused" />
                  )}
                  <DotLabel>
                    {t('1 or more special characters, eg. @ # $')}
                  </DotLabel>
                </DotDiv>
              </Alert>
            </div>
          </div>
        </VerificationStepContainer>

        <Buttons>
          <ResetPasswordButton
            type="button"
            aria-disabled={isConfirmationButtonDisabled}
            disabled={isConfirmationButtonDisabled}
            onClick={() => handleResetPassword()}
            data-ghostinspectorid="ResetPasswordBackLogin-Button"
          >
            {t('Reset password and back to login')}
          </ResetPasswordButton>
        </Buttons>
      </Container>
    </Wrapper>
  );

  return page === 'form' ? resetForm : resetConfirmation;
};
