import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 50%;
  padding-right: 2rem;
  padding-bottom: 6.25rem;
  overflow-y: auto;
`;

export const ImageWrapper = styled.div<{ imageUrl: string }>`
  width: 50%;
  position: relative;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 5rem - 3.75rem - 2.25rem);
  padding-left: 3.9375rem;
  overflow: auto;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white[500]};
`;
