import * as React from 'react';
import { MultiselectOption } from 'Shared/types/question';
import {
  Wrapper,
  InputLabel,
  OptionsContainer,
  InlineOption,
} from './InlineMultiselect.styles';
interface InlineMultiselectProps {
  options: MultiselectOption[];
  value?: MultiselectOption[];
  onChange: (option: MultiselectOption[]) => void;
  roundOptions?: boolean;
  label?: string;
}
export const InlineMultiselect = ({
  options,
  label,
  roundOptions = true,
  value = [],
  onChange,
}: InlineMultiselectProps) => {
  const initialOpts = value.length
    ? options.map((opt) => ({
        ...opt,
        checked: value.find((v) => v.value === opt.value)?.checked || false,
      }))
    : options;

  const handleChange = (value: string) => {
    const newOptions = initialOpts.map((opt) => {
      if (opt.value === value) {
        return { ...opt, checked: !opt.checked };
      }
      return opt;
    });

    return onChange(newOptions);
  };

  return (
    <Wrapper>
      {label && <InputLabel>{label}</InputLabel>}

      <OptionsContainer>
        {initialOpts.map((option) => (
          <InlineOption
            onClick={() => handleChange(option.value)}
            key={option.value}
            selected={option.checked}
            rounded={roundOptions}
          >
            {option.label}
          </InlineOption>
        ))}
      </OptionsContainer>
    </Wrapper>
  );
};
