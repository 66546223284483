import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/browser';
import { useQuery } from '@apollo/client';
import { DashboardPagesTemplate as Template } from 'Templates';
import { useProject } from 'Client/utils/hooks';
import { SidePanel } from 'Molecules';
import {
  Header,
  GroupsSelect,
  EmailForm,
  EmailVisualizer,
  Footer,
  FeedbackMessage,
} from './components';
import { Content } from './GroupEmail.styles';
import { EmailFeedbackMessage, GroupEmailProps } from './types';
import { GET_RESPONDENT_GROUP_QUERY } from './GroupEmail.gql';

export const GroupEmail: React.FC<GroupEmailProps> = ({
  query,
}: GroupEmailProps) => {
  const project = useProject();
  const [groupsIds, setGroupsIds] = React.useState<Array<string>>([]);
  const [totalRespondents, setTotalRespondents] = React.useState<number>(null);
  const [repliableRespondents, setRepliableRespondents] =
    React.useState<number>(null);
  const [subjectText, setSubjectText] = React.useState<string>('');
  const [headerText, setHeaderText] = React.useState<string>('');
  const [emailMessage, setEmailMessage] = React.useState<string>('');
  const [buttonText, setButtonText] = React.useState<string>('');
  const [buttonLink, setButtonLink] = React.useState<string>('');
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [loadingGroupInfo, setLoadingGroupInfo] = React.useState<boolean>(false);
  const [emailFeedbackMessage, setEmailFeedbackMessage] =
    React.useState<EmailFeedbackMessage>({
      messageType: 'error',
      emailTitle: headerText,
      repliableRespondents: repliableRespondents,
      isOpen: false,
    });

  const sendEmails = async () => {
    setIsFetching(true);
    const body = {
      groupsIds,
      project,
      emailData: {
        subject: subjectText,
        header: headerText,
        buttonText,
        buttonLink,
        emailMessage,
      },
    };
    try {
      await fetch('/api/email/groupEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      setEmailFeedbackMessage({
        messageType: 'success',
        emailTitle: headerText,
        repliableRespondents: repliableRespondents,
        isOpen: true,
      });
    } catch (error) {
      setEmailFeedbackMessage({
        messageType: 'error',
        emailTitle: headerText,
        repliableRespondents: repliableRespondents,
        isOpen: true,
      });
      captureException(`error in sendEmail @ GroupEmail.tsx : ${error}`);
    } finally {
      setIsFetching(false);
    }
  };

  const onCancel = () => {
    if (query.ref) {
      window.location.href = `/v5/dashboard/${query.ref}s`;
    } else {
      window.location.href = `/v5/dashboard/contributions`;
    }
  };

  const { data } = useQuery(GET_RESPONDENT_GROUP_QUERY, {
    variables: {
      getGroupInput: {
        groupIds: groupsIds,
        projectName: project.id,
      },
    },
  });

  React.useEffect(() => {
    if (query.ref === 'respondent' && data) {
      return;
    }
    const getGroupInfo = async () => {
      setLoadingGroupInfo(true);
      try {
        const result = await fetch('/api/external/group/groupsInfo', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            groupsIds,
          }),
        });
        const json = await result.json();
        const { data } = json;
        const { totalInGroup, totalRepliable } = data;
        setTotalRespondents(totalInGroup);
        setRepliableRespondents(totalRepliable);
      } catch (error) {
        captureException(`error in getGroupInfo @ GroupEmail.tsx : ${error}`);
      }
      setLoadingGroupInfo(false)
    };
    getGroupInfo();
  }, [groupsIds, data, query.ref]);

  React.useEffect(() => {
    if (query.ref === 'respondent' && data) {
      const { getGroup } = data;
      const totalDocumentIds = [];
      getGroup?.map((g) => {
        g.documentsIds.forEach((d) => {
          totalDocumentIds.push(d);
        });
      });
      setTotalRespondents(totalDocumentIds.length);
      setRepliableRespondents(totalDocumentIds.length);
      return;
    }
  }, [groupsIds, data, query.ref]);

  return (
    <Template>
      <Header
        totalRespondents={totalRespondents}
        repliableRespondents={repliableRespondents}
        loadingGroupInfo={loadingGroupInfo}
      >
        <GroupsSelect setGroupsIds={setGroupsIds} />
      </Header>
      <Content>
        <EmailForm
          setSubjectText={setSubjectText}
          setHeaderText={setHeaderText}
          setEmailMessage={setEmailMessage}
          setButtonText={setButtonText}
          setButtonLink={setButtonLink}
        />
        <EmailVisualizer
          subjectText={subjectText}
          headerText={headerText}
          emailMessage={emailMessage}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />
      </Content>
      <Footer
        leftButtonText="Cancel"
        leftButtonAction={() => {
          !isFetching && onCancel();
        }}
        rightButtonText="Send email"
        rightButtonAction={() => {
          !isFetching && sendEmails();
        }}
      />
      <SidePanel isOpen={emailFeedbackMessage.isOpen}>
        <FeedbackMessage
          closeSidePanel={() =>
            setEmailFeedbackMessage((previous) => {
              return { ...previous, isOpen: false };
            })
          }
          {...emailFeedbackMessage}
        />
      </SidePanel>
    </Template>
  );
};
