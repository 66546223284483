import { gql } from '@apollo/client';

export const GET_RESPONDENT_GROUP_QUERY = gql`
  query Query($getGroupInput: GetGroupInput) {
    getGroup(getGroupInput: $getGroupInput) {
      _id
      documentsIds
      projectId
    }
  }
`;
