import styled, { css } from 'styled-components';
import { TabBar } from 'Client/components/molecules/TabBar';
import { Typography } from 'Atoms';

export const StyledBar = styled(TabBar)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    background-color: ${theme.colors.white[600]};
    border: 0px;
    #tab-bar-tabs-container {
      & > button {
        flex-direction: column;
        padding: 15px 2rem;
        color: black;
        &:not(:last-child) {
          margin-right: 0;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
        }
        svg {
          margin: 0;
        }
      }
    }
  `}
`;

export const NoResultsMessage = styled.div<{ isMap?: boolean }>`
  ${({ theme, isMap }) => css`
    padding: 1rem 0.5rem;
    background-color: #dee3f1;
    border-left: 1rem solid #7985a8;
    padding-left: 5rem;
    text-align: left;
    ${isMap &&
    `
      margin-top: 1rem;
    `}
    white-space: break-spaces;
    ${theme.breakpoints.down('sm')} {
      border-left: 0.25rem solid #7985a8;
      padding-left: 1.25rem;
    }
  `}
`;

export const NoResultsTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
`;
export const NoResultsContent = styled(Typography)`
  font-size: 0.9rem;
`;
