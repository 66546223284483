import { sub } from 'date-fns';

export const urlValidation = (url: string) => {
  if (url.match(/([%\s]|[^a-z0-9-_]|^[-_\s]|[-_\s]$)/g)) {
    return true;
  }

  return false;
};

export const latitudeValidation = (latitude) => {
  if (latitude === '' || !isFinite(latitude) || Math.abs(latitude) > 90) {
    return true;
  }

  return false;
};

export const longitudeValidation = (longitude) => {
  if (longitude === '' || !isFinite(longitude) || Math.abs(longitude) > 180) {
    return true;
  }
  return false;
};

const currentDate = new Date();
const yesterday = sub(currentDate, { days: 1 });

export const launchDateValidation = (launchDate): string => {
  if (launchDate === '' || !launchDate) {
    return 'This field is compulsory';
  }

  if (launchDate < yesterday) {
    return 'Launch date must be a future date';
  }
};

export const closeDateValidation = (launchDate, closeDate): string => {
  if (closeDate === '' || !closeDate) {
    return 'This field is compulsory';
  }

  if (closeDate < yesterday) {
    return 'Close date must be a future date';
  }

  if (closeDate <= launchDate) {
    return 'Close date must be after launch date';
  }
};
