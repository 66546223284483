import { gql } from '@apollo/client';

export const DELETE_COMMENT_MUTATION = gql`
  mutation Mutation($deleteContributionInput: DeleteContribution) {
    deleteContribution(deleteContributionInput: $deleteContributionInput) {
      message
      status
    }
  }
`;
