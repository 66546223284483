import { isFuture } from 'date-fns';
import { Status } from 'Atoms/TimelineStatusDot';

export const getStatus = (actual: Date, previous: Date): Status => {
  const actualDate = new Date(actual);
  const previousDate = new Date(previous);

  if (!isFuture(actualDate)) return 'done';

  if (!isFuture(previousDate) && isFuture(actualDate)) return 'active';

  return 'planned';
};
