import { gql } from '@apollo/client';

export const GET_PROJECT_OVERVIEW_CHART_QUERY = gql`
  query GetProjectOverviewChart(
    $getProjectsOverviewChartInput: GetProjectsOverviewChartInput
  ) {
    getProjectOverviewChart(
      GetProjectsOverviewChartInput: $getProjectsOverviewChartInput
    ) {
      series {
        name
        data {
          y
          name
          marker {
            enabled
          }
        }
      }
      categories
    }
  }
`;
