import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { PlanningAppStage } from 'Shared/types/planningapp';
import { AppDetails, CommentsTab, RelatedDocuments } from './components';
import { Wrapper, StyledBar, Content, NavBarWrapper } from './NavBar.styles';
import { NavBarProps } from './types';
import { commentsTabTitle } from './utils/commentsTabTitle';

export const NavBar: React.FC<NavBarProps> = ({
  commentsCount,
  pageContent,
  initialComments,
  slug,
  typedFiles,
  selectedTab,
  setSelectedTab,
}) => {
  const project = useProject();
  const { t } = useTranslation();
  const hideCount =
    project?.features?.glaPlanningApps &&
    pageContent?.stage != PlanningAppStage.stage3;

  const tabs = [
    {
      label: t('Application details'),
      value: 1,
    },
    {
      label: t('Related documents'),
      value: 2,
    },
    {
      label: hideCount ? t('Comments') : commentsTabTitle(commentsCount),
      value: 3,
    },
  ];

  // handlers
  const handleSwitchTab = (tab: number) => {
    setSelectedTab(tab);
  };

  return (
    <Wrapper id="tabs-bar" data-testid="planningAppPage-tabs">
      <NavBarWrapper>
        <StyledBar
          selectedTab={selectedTab}
          tabs={tabs}
          onTabChange={handleSwitchTab}
        />
      </NavBarWrapper>

      <Content id="tabs-section">
        {selectedTab === 1 && (
          <AppDetails pageContent={pageContent} map={pageContent.map} />
        )}

        {selectedTab === 2 && (
          <RelatedDocuments
            documents={pageContent?.documents}
            typedFiles={typedFiles}
            reference={pageContent.reference}
          />
        )}

        {selectedTab === 3 && (
          <CommentsTab
            initialComments={initialComments}
            commentsCount={commentsCount}
            slug={slug}
            isConsultationOpen={pageContent.status === 'Consultation open'}
            stage={pageContent?.stage}
          />
        )}
      </Content>
    </Wrapper>
  );
};
