import * as React from 'react';
import {
  handleDates,
  handleDotColor,
} from 'Pages/dashboard/utils/respondentsDetails';
import { InfoPanelProps } from './types';
import {
  Wrapper,
  ItemWrapper,
  ItemLabel,
  ItemValueWrapper,
  ItemValue,
  TimeValue,
} from './InfoPanel.styles';

export const InfoPanel: React.FC<InfoPanelProps> = ({
  info,
}: InfoPanelProps) => {
  const itemsWithDots = ['status', 'average sentiment', 'sentiment'];
  const dateItems = ['sign up date', 'date'];

  return (
    <Wrapper>
      {info.map(({ label, value }) => {
        const hasDot = itemsWithDots.includes(label);
        const isDate = dateItems.includes(label);
        const dotColor = (() => {
          if (!hasDot) return '';
          return handleDotColor(label, value as string);
        })();
        const dates = (() => {
          if (!isDate) return null;
          return handleDates(value as Date);
        })();
        return (
          <ItemWrapper key={label}>
            <ItemLabel>{label}</ItemLabel>
            <ItemValueWrapper>
              {isDate ? (
                <>
                  <ItemValue>{dates?.date}</ItemValue>
                  <TimeValue>{dates?.time}</TimeValue>
                </>
              ) : (
                <>
                  <ItemValue hasDot={dotColor && hasDot} dotColor={dotColor}>
                    {value}
                  </ItemValue>
                </>
              )}
            </ItemValueWrapper>
          </ItemWrapper>
        );
      })}
    </Wrapper>
  );
};
