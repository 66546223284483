import * as React from 'react';
import { subDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { CalendarIcon } from 'Atoms/Icons';
import { DateRangePicker } from 'Atoms/DateRangePicker/DateRangePicker';
import {
  Content,
  CustomOption,
  FixedOption,
  Header,
  Tab,
  Wrapper,
  TabContent,
} from './DateRangeFilter.styles';
import {
  DateFields,
  DateFilterOptions,
  DateRangeProps,
  DateSelectedOptions,
} from './types';

export const DateRangeFilter: React.FC<DateRangeProps> = ({
  name,
  tabs,
  options,
  onChange,
}) => {
  const todayWithoutTime = new Date(new Date().setHours(0, 0, 0, 0));

  const { filterValues } = useSelector((state: RootState) => state.filters);
  const reduxOptions = filterValues?.[name] as DateSelectedOptions[];

  const [animation, setAnimation] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState<string>(tabs[0].value);
  const [selectedOption, setSelectedOption] = React.useState(
    tabs.reduce((acc, tab) => {
      acc[tab.value] = reduxOptions?.find(
        (option) => option.field === tab.value
      );
      return acc;
    }, {})
  );
  const [dateProps, setDateProps] = React.useState(
    tabs.reduce((acc, tab) => {
      const option = reduxOptions?.find((option) => option.field === tab.value);
      acc[tab.value] =
        option?.type === 'custom'
          ? {
              dateFrom: option?.value?.from,
              dateTo: option?.value?.to,
              currentMonth: option?.value?.from,
            }
          : {
              dateFrom: subDays(todayWithoutTime, 2),
              dateTo: todayWithoutTime,
              currentMonth: todayWithoutTime,
            };
      return acc;
    }, {})
  );
  const [position, setPosition] = React.useState(0);

  const handleSelectFixedOption = (option) => {
    if (
      selectedOption?.[selectedTab]?.value == option.value &&
      option.type == 'fixed'
    ) {
      return setSelectedOption((prev) => ({
        ...prev,
        [selectedTab]: null,
      }));
    }

    setSelectedOption((prev) => ({
      ...prev,
      [selectedTab]: option,
    }));
  };

  const setDateFrom = (date: Date) => {
    setDateProps((prev) => ({
      ...prev,
      [selectedTab]: {
        ...prev[selectedTab],
        dateFrom: date,
      },
    }));
  };

  const setDateTo = (date: Date) => {
    setDateProps((prev) => ({
      ...prev,
      [selectedTab]: {
        ...prev[selectedTab],
        dateTo: date,
      },
    }));
  };

  const setCurrentMonth = (date: Date) => {
    setDateProps((prev) => ({
      ...prev,
      [selectedTab]: {
        ...prev[selectedTab],
        currentMonth: date,
      },
    }));
  };

  React.useEffect(() => {
    if (selectedOption?.[selectedTab]?.type == 'custom') {
      return onChange({
        type: 'custom',
        field: selectedTab,
        value: {
          from: dateProps[selectedTab].dateFrom,
          to: dateProps[selectedTab].dateTo,
        },
      });
    }

    onChange({
      type: 'fixed',
      field: selectedTab,
      value: selectedOption?.[selectedTab]?.value,
    });
  }, [selectedOption, dateProps]);

  const handleTabChange = (tab: DateFields, index: number) => {
    setAnimation(
      index > tabs.findIndex((t) => t.value === selectedTab) ? 'left' : 'right'
    );

    setPosition(0);

    setSelectedTab(tab.value);
  };

  const handleCustomOption = (option: DateFilterOptions) => {
    setSelectedOption((previous) =>
      previous?.[selectedTab]?.type == 'custom'
        ? {
            ...previous,
            [selectedTab]: null,
          }
        : {
            ...previous,
            [selectedTab]: option,
          }
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'ArrowUp' && position > 0) {
      e.preventDefault();

      setPosition(position - 1);
    }

    if (e.code === 'ArrowDown' && position < options.length - 1) {
      e.preventDefault();

      setPosition(position + 1);
    }
  };

  const focusOnActiveOption = React.useCallback(() => {
    const activeOption = document.querySelector('.activeOption');

    (activeOption as HTMLElement).focus();
  }, []);

  React.useEffect(() => {
    focusOnActiveOption();
  }, [position, focusOnActiveOption]);

  return (
    <Wrapper onKeyDown={handleKeyDown}>
      <Header>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.value}
            tabIndex={0}
            isSelected={selectedTab === tab.value}
            className={selectedTab === tab.value ? 'activeTab' : null}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
                (e.target as HTMLElement).click();
              }
            }}
            onClick={() => {
              handleTabChange(tab, index);
            }}
          >
            {tab.label}
          </Tab>
        ))}
      </Header>
      <Content>
        <TabContent animation={animation}>
          {options.map((option, index) => {
            if (option.type === 'fixed') {
              return (
                <FixedOption
                  tabIndex={index}
                  onClick={() => handleSelectFixedOption(option)}
                  onKeyDown={(e) => {
                    if (e.code === 'Space') {
                      e.preventDefault();
                      handleSelectFixedOption(option);
                    }
                  }}
                  className={position === index ? 'activeOption' : null}
                  isSelected={
                    selectedOption?.[selectedTab]?.type == option?.type &&
                    selectedOption?.[selectedTab]?.value == option?.value
                  }
                >
                  {option.label}
                </FixedOption>
              );
            }
            if (option.type === 'custom') {
              return (
                <CustomOption
                  tabIndex={index}
                  className={position === index ? 'activeOption' : null}
                  onKeyDown={(e) => {
                    if (e.code === 'Space') {
                      e.preventDefault();
                      handleCustomOption(option);
                    }
                  }}
                  onClick={() => handleCustomOption(option)}
                  isSelected={
                    selectedOption?.[selectedTab]?.type == option?.type
                  }
                >
                  <CalendarIcon />
                  {option.label}
                </CustomOption>
              );
            }
          })}
        </TabContent>
      </Content>
      {selectedOption?.[selectedTab]?.type == 'custom' && (
        <DateRangePicker
          dateFrom={dateProps?.[selectedTab]?.dateFrom}
          dateTo={dateProps?.[selectedTab]?.dateTo}
          currentMonth={dateProps?.[selectedTab]?.currentMonth}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setCurrentMonth={setCurrentMonth}
        />
      )}
    </Wrapper>
  );
};
