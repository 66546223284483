export const currentBasicColumns = [
  {
    Header: 'Group name',
    accessor: 'name',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Date created',
    accessor: 'createdAt',
  },
  {
    Header: 'Respondents',
    accessor: 'respondentsCount',
  },
];

export const currentTrafficColumns = [];
