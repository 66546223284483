import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';
import { AvatarIcon } from 'Atoms/Icons';
import { TabBar } from 'Client/components/molecules/TabBar';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 2rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0 1rem 2rem;
    }

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`;

export const Text = styled((props) => <Typography {...props} />)`
  margin: 0;
  font-size: 1rem;
`;

export const EmailText = styled((props) => <Typography {...props} />)`
  margin: 0;
  font-size: 1rem;
  line-break: anywhere;
  @media screen and (max-width: 360px) {
    font-size: 0.8125rem;
  }
`;

export const Hr = styled.hr`
  margin: 2rem 0;
  height: 0.07rem;
  width: 30%;
  border: 0;
  background-color: ${({ theme }) => theme.colors.grey[600]};
  opacity: 0.46;
`;

export const Title = styled((props) => <Typography {...props} />)`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  width: 80%;
  margin-bottom: 1rem;

  & label {
    font-weight: bold;
    min-width: 50%;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `}
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 1.5rem;
  }
`;

export const Avatar = styled((props) => (
  <AvatarIcon width={40} height={40} {...props} />
))``;

export const MapTab = styled(TabBar).attrs({
  color: '#6e6d74',
  fontWeight: 400,
  navBorderWidth: '0',
  borderBottomHeight: 0.15,
  borderSpacing: 0.5,
  showResults: false,
})``;

export const InfoWrapper = styled.div`
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      flex-wrap: wrap;
    }
  `}
`;

export const MapWrapper = styled.div`
  width: 55%;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      margin-top: 2rem;
    }
  `}
`;

export const MapTabWrapper = styled.div`
  margin-bottom: 5rem;
  margin-top: 2rem;
`;
