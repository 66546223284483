import { gql } from '@apollo/client';

export const GET_MAP_QUESTIONS = gql`
  query Query($getMapPageQuestionsInput: GetMapPageQuestionsInput) {
    getMapPageQuestions(GetMapPageQuestionsInput: $getMapPageQuestionsInput) {
      questions
      sentimentQuestion
    }
  }
`;
