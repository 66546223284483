import * as React from 'react';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { countSentimentStats } from 'Client/services/map';
import { getContributionUserId } from 'Client/services/contributions';
import { useMap, useProject, useUser } from 'Client/utils/hooks';
import { MapLayerNames, Xyz } from 'Shared/types/map';
import {
  CONTRIBUTION_SESSION_ITEM,
  getLocalItem,
} from 'Client/utils/localstorage';
import { LabelValueOptions, Question } from 'Client/pages';
import { StyledAutocomplete } from 'Client/components/organisms/AddressField/AddressField.styles';
import { getFilterOptions } from 'Server/services/filters/getFilterOptions';
import { ExpandableMultiselect } from 'Client/components/molecules/ExpandableMultiselect/ExpandableMultiselect';
import { InlineMultiselect } from 'Client/components/molecules/InlineMultiselect/InlineMultiselect';
import { CheckboxFilter } from 'Client/components/molecules/CheckboxFilter/CheckboxFilter';
import { resetZoomLevel } from 'Client/utils/map/resetZoomLevel';
import {
  FilterOptions,
  FILTERS_VIEW_COMPONENTS_TYPE,
  FILTERS_VIEW_MULTISELECT_VARIANTS,
} from 'Client/pages/map/types';
import { MultiselectOption } from 'Shared/types/question';
import { GET_MAP_QUESTIONS } from 'Client/services/map/getMapQuestions.gql';
import {
  Loader,
  Wrapper,
  FilterContainer,
  FilterSection,
  FilterItem,
  ViewMapButton,
  ClearFilterContainer,
  ClearFiltersButton,
  TextContentWrapper,
  TextTitle,
  TextContent,
} from './FiltersView.styles';
import { SentimentFilter } from './SentimentFilter';
import { CustomPinFilter } from './CustomPinFilter';
import { WhiteChevron } from '../ToMapButton.styles';

const FiltersView = (): JSX.Element => {
  const {
    dispatch,
    state: { proposal, filterData, ...state },
  } = useMap();
  const xyz = state.xyz as Xyz;
  if (!proposal)
    return (
      <Wrapper data-testId="no-proposal-loading-ring">
        <Loader />
      </Wrapper>
    );
  const { queryFilters, filterValues } = filterData;
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const project = useProject();
  const [getMapQuestions] = useLazyQuery(GET_MAP_QUESTIONS);
  const [filters, setFilters] = React.useState([]);
  const [pivotQuestion, setPivotQuestion] = React.useState<Question>();
  const [loading, setLoading] = React.useState(true);
  const [inputs, setInputs] = React.useState(['']);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [sentiments, setSentiments] = React.useState({
    positiveChecked: true,
    mostlyPositiveChecked: true,
    neutralChecked: true,
    mostlyNegativeChecked: true,
    negativeChecked: true,
  });
  const [sentimentCount, setSentimentCount] = React.useState<
    Record<string, number>
  >({
    '0': 0,
    '25': 0,
    '50': 0,
    '75': 0,
    '100': 0,
  });

  const uniqueId = getContributionUserId({
    user,
    storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
  });

  const getSentimentCounts = () => {
    countSentimentStats({
      sentimentQuestion: proposal?.sentimentQuestion?.question,
      pageId: proposal?.pageId,
    })
      .then((res) => {
        setSentimentCount(res);
      })
      .catch((err) => err);
  };

  React.useEffect(() => {
    const sentimentsState = JSON.parse(
      xyz?.layers.list.Contributions?.filter?.current
    );

    // This object is empty of contributions in the first render, but we display all contributions by default
    if (!sentimentsState.some((el) => el.sentiment)) return;
    const sentiments = sentimentsState.find((el) => el.sentiment).sentiment.in;

    setSentiments({
      positiveChecked: sentiments.some((el) => el === 100),
      mostlyPositiveChecked: sentiments.some((el) => el === 75),
      neutralChecked: sentiments.some((el) => el === 50),
      mostlyNegativeChecked: sentiments.some((el) => el === 25),
      negativeChecked: sentiments.some((el) => el === 0),
    });
  }, [xyz, setSentiments]);

  React.useEffect(() => {
    const pivotId =
      proposal?.geolytixWorkspace?.locales.UK.layers[
        MapLayerNames.CONTRIBUTIONS
      ]?.pivotQuestion;

    const getFiltersContent = async () => {
      try {
        setLoading(true);

        if (pivotId) {
          const { data } = await getMapQuestions({
            variables: {
              getMapPageQuestionsInput: {
                questionIds: proposal.questions,
                sentimentQuestionId: proposal.sentimentQuestion,
                lang: i18n.language,
              },
            },
          });
          const res = data.getMapPageQuestions;
          setPivotQuestion(res.questions[0]);
        }
        const filterOptions = await getFilterOptions({
          proposal,
          lang: i18n.language,
          projectId: project._id,
        });

        if (filterOptions?.length) {
          setFilters(filterOptions);
          setFilterOptions(
            filterOptions.map((filter) => {
              if (filter.type !== FILTERS_VIEW_COMPONENTS_TYPE.TEXT_CONTENT) {
                return filter.options;
              }
            })
          );
          setInputs(filterOptions.map(() => ''));
          const initialValues = filterOptions.map((filter, idx) => {
            if (filter.type === FILTERS_VIEW_COMPONENTS_TYPE.MULTISELECT) {
              /* If values for this index exists on redux, use them as initial value,
               * otherwise gather from question options + checked false
               */
              const toReturn = filterValues?.[idx]?.length
                ? filterValues[idx]
                : filter.options.map((option: LabelValueOptions) => ({
                    ...option,
                    checked: false,
                  }));
              return toReturn;
            }
            return filterValues?.[idx] || [''];
          });
          dispatch({
            type: 'SET_CONTRIBUTION_FILTERS_DATA',
            payload: {
              filterData: {
                ...filterData,
                filterValues: initialValues,
              },
            },
          });
          return;
        }

        if (!pivotId) {
          getSentimentCounts();
        }
      } catch (error) {
        getSentimentCounts();
      } finally {
        setLoading(false);
      }
    };
    getFiltersContent();
  }, []);

  const setSentiment = (sentiment) => {
    setSentiments({
      ...sentiments,
      ...sentiment,
    });
  };

  const setContribution = (contribution) => {
    dispatch({
      type: 'FILTER_COMMENTS',
      payload: {
        uniqueId,
        project,
        ...sentiments,
        ...contribution,
      },
    });
  };

  const setPivotFilter = (pivot: string[], isContributionId?: boolean) => {
    dispatch({
      type: 'FILTER_COMMENTS',
      payload: {
        uniqueId,
        project,
        pivot,
        isContributionId,
        ...sentiments,
      },
    });
  };
  const handleFilterChange = (
    idx,
    questionId,
    parentQuestionId,
    value,
    isMultiple
  ) => {
    resetZoomLevel(proposal, xyz);
    /*  _selected = 'raw' format to filterValues
     *  _currentFilters = values on query format to queryFilters
     */
    const _currentFilters = [...queryFilters];
    const _selected = [...filterValues];
    if (!value || (Array.isArray(value) && !value?.length)) {
      _currentFilters.splice(idx, 1);
      _selected.splice(idx, 1);
    } else {
      let multipleValue = '';
      if (isMultiple) {
        const _value = [...value];
        _selected[idx] = _value;

        const selectedItems: MultiselectOption[] = value.filter(
          (item: MultiselectOption) => item.checked
        );

        /*
         * Different from not multiple, we want this value parsed properly in order to query on xyz
         */
        multipleValue = `%(${selectedItems
          .map((item) => item.value)
          .join('|')})%`;
      } else {
        _selected[idx] = value;
      }
      const includesAll = (value?.value || '').match(' - All');
      _currentFilters[idx] = {
        value: encodeURIComponent(
          isMultiple
            ? multipleValue
            : includesAll
            ? value.value.split(' -')[0]
            : value.value
        ),
        questionId: includesAll ? parentQuestionId : questionId,
        isMultiple,
        queryOrder: filters[idx].queryOrder,
      };
    }
    dispatch({
      type: 'SET_CONTRIBUTION_FILTERS_DATA',
      payload: {
        filterData: {
          ...filterData,
          queryFilters: _currentFilters,
          filterValues: _selected,
        },
      },
    });

    /*
     * To-do: when 'Other' selected, show all answers not on the selector
     * (Currently not implemented)
     *  if (value.value === 'Other') {
     *    value.value = {
     *      ni: filterOptions[idx]
     *        .map((el) => el.value)
     *        .filter((el) => el !== 'Other'),
     *    };
     *  }
     */
  };

  const createPostgresQueryFromArray = ({ metadataFilters }) => {
    return [
      {
        answersPopulated: metadataFilters.map((filter) =>
          Object.keys(filter).map((field) => {
            return {
              [field]: filter[field],
            };
          })
        ),
      },
    ];
  };

  React.useEffect(() => {
    if (!filters?.length) {
      return;
    }

    const metadataFilters = createPostgresQueryFromArray({
      metadataFilters: queryFilters.filter((el) => el),
    });

    dispatch({
      type: 'FILTER_COMMENTS',
      payload: {
        uniqueId,
        project,
        ...sentiments,
        metadataFilters,
      },
    });
  }, [queryFilters]);

  const resetOptions = (idx?: number) => {
    resetZoomLevel(proposal, xyz);
    /* Clearing all options */
    if (!idx && idx !== 0) {
      setFilterOptions(filters.map((r) => r.options));
      setInputs(filters.map(() => ''));
      dispatch({
        type: 'SET_CONTRIBUTION_FILTERS_DATA',
        payload: {
          filterData: {
            ...filterData,
            queryFilters: [],
            filterValues: filters.map<FilterOptions['options']>((f) => {
              if (f.type === FILTERS_VIEW_COMPONENTS_TYPE.MULTISELECT) {
                return f.options.map((o) => ({ ...o, checked: false }));
              }
              return [''];
            }),
          },
        },
      });
      return;
    }
    const _inputs = [...inputs];
    _inputs[idx] = '';
    setInputs(_inputs);
    const _filterOptions = [...filterOptions];
    _filterOptions[idx] = filters[idx].options;
    setFilterOptions(_filterOptions);
    return _filterOptions;
  };

  const handleAutocompleteInputChange = (val: string, idx: number) => {
    if (!val) {
      resetOptions(idx);
    }
    const filteredOptions = filters.map((filter, filterIdx) => {
      if (filterIdx !== idx) return filter.options;
      const filtered = filter.options.filter((option) => {
        const regex = new RegExp(val, 'gi');
        return option.label.match(regex);
      });
      return filtered;
    });
    setFilterOptions(filteredOptions);
  };
  const handleMultiselectChange = (
    options: MultiselectOption[],
    idx: number,
    filter: FilterOptions
  ) => {
    /*
     * Gathers initial options as options on onChange
     * only returns checked ones
     */
    const newOptions = filterValues[idx].map((option: MultiselectOption) => {
      const foundOption = options.find((opt) => opt.value === option.value);
      return {
        ...option,
        checked: foundOption?.checked || false,
      };
    });

    const areOptionsEqual = isEqual(newOptions, filterValues[idx]);
    if (areOptionsEqual) return;

    handleFilterChange(
      idx,
      filter.id,
      filter.parentQuestionId,
      newOptions,
      filter.type === FILTERS_VIEW_COMPONENTS_TYPE.MULTISELECT
    );
  };

  const handleViewMap = () => {
    resetZoomLevel(proposal, xyz);
    dispatch({
      type: 'OPEN_INFO_PANEL',
      payload: {
        infoPanelOpen: false,
      },
    });
  };

  return (
    <div data-testid="ContributionsTabContent">
      <Wrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {filters?.length ? (
              <FilterContainer>
                <ClearFilterContainer>
                  <ClearFiltersButton
                    onClick={() => resetOptions()}
                    hasFilters={!!queryFilters?.length}
                  >
                    {t('Clear filters')}
                  </ClearFiltersButton>
                </ClearFilterContainer>
                <FilterSection>
                  {filters.map((filter, idx) => {
                    switch (filter.type) {
                      case FILTERS_VIEW_COMPONENTS_TYPE.TEXT_CONTENT:
                        return (
                          <TextContentWrapper isFirstItem={idx === 0}>
                            {filter.title && (
                              <TextTitle>{filter.title}</TextTitle>
                            )}
                            <TextContent>{filter.content}</TextContent>
                          </TextContentWrapper>
                        );
                      case FILTERS_VIEW_COMPONENTS_TYPE.TEXTPOLL:
                        return (
                          <FilterItem key={filter.id}>
                            <p>
                              <strong>{filter.label}</strong>
                            </p>
                            <StyledAutocomplete
                              key={`autocomplete_filter_input__${filterValues[idx]}_${idx}`}
                              value={filterValues[idx] || ''}
                              placeholder={filter.placeholder}
                              filterOption={(opt) => opt && true}
                              options={filterOptions?.[idx] || []}
                              isLoading={false}
                              isClearable={true}
                              onBlur={() => {
                                if (!queryFilters[idx]) {
                                  resetOptions(idx);
                                }
                              }}
                              /* @TODO: if question.id is the pivot question, show icon on options */
                              handleChange={(value) => {
                                handleFilterChange(
                                  idx,
                                  filter.id,
                                  filter.parentQuestionId,
                                  value,
                                  filter.type ===
                                    FILTERS_VIEW_COMPONENTS_TYPE.MULTISELECT
                                );
                              }}
                              handleInputChange={(val: string) => {
                                const _inputs = [...inputs];
                                _inputs[idx] = String(val);
                                setInputs(_inputs);
                                handleAutocompleteInputChange(val, idx);
                              }}
                            />
                          </FilterItem>
                        );
                      case FILTERS_VIEW_COMPONENTS_TYPE.MULTISELECT:
                        switch (filter.variant) {
                          case FILTERS_VIEW_MULTISELECT_VARIANTS.EXPANDABLE:
                            return (
                              <ExpandableMultiselect
                                showFullWidth={false}
                                rounded={false}
                                title={filter.label}
                                /* Question options + checked false (initial value) */
                                options={filter.options.map(
                                  (opt: LabelValueOptions) => ({
                                    ...opt,
                                    checked: false,
                                  })
                                )}
                                value={
                                  (filterValues[idx] as MultiselectOption[]) ||
                                  []
                                }
                                onChange={(options) => {
                                  handleMultiselectChange(options, idx, filter);
                                }}
                              />
                            );

                          case FILTERS_VIEW_MULTISELECT_VARIANTS.INLINE:
                            return (
                              <InlineMultiselect
                                label={filter.label}
                                onChange={(options) => {
                                  handleMultiselectChange(options, idx, filter);
                                }}
                                value={
                                  (filterValues[idx] as MultiselectOption[]) ||
                                  []
                                }
                                options={filter.options.map(
                                  (opt: LabelValueOptions) => ({
                                    ...opt,
                                    checked: false,
                                  })
                                )}
                              />
                            );
                          case FILTERS_VIEW_MULTISELECT_VARIANTS.CHECKBOX:
                            if (!pivotQuestion) return;
                            return (
                              <>
                                <CheckboxFilter
                                  label={filter.label}
                                  onChange={(options) => {
                                    handleMultiselectChange(
                                      options,
                                      idx,
                                      filter
                                    );
                                  }}
                                  value={
                                    (filterValues[
                                      idx
                                    ] as MultiselectOption[]) || []
                                  }
                                  options={filter.options.map(
                                    (opt: LabelValueOptions) => ({
                                      ...opt,
                                      checked: false,
                                    })
                                  )}
                                />
                              </>
                            );
                        }
                    }
                  })}
                </FilterSection>
              </FilterContainer>
            ) : pivotQuestion ? (
              <CustomPinFilter
                proposal={proposal}
                setPivotFilter={setPivotFilter}
                pivotQuestion={pivotQuestion}
              />
            ) : (
              <SentimentFilter
                sentimentCount={sentimentCount}
                proposal={proposal}
                setContribution={setContribution}
                setSentiment={setSentiment}
                sentiments={sentiments}
              />
            )}
            <ViewMapButton onClick={handleViewMap}>
              <p>{t('View results')}</p>
              <WhiteChevron />
            </ViewMapButton>
          </>
        )}
      </Wrapper>
    </div>
  );
};

export { FiltersView };
