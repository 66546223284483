import { MOST_RECENT } from '../components/Editor/DynamicProposalsEditor/constants';
import { AddSectionBlockTypes } from '../components/SectionPanel/constants';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const createNewProposalsSectionBlock = (type: AddSectionBlockTypes) => {
  switch (type) {
    case AddSectionBlockTypes.TITLE:
      return {
        type: EDITABLE_CONTENT_BLOCK_TYPES.TITLE,
        content: {
          title: 'Title',
        },
      };
    case AddSectionBlockTypes.DESCRIPTION:
      return {
        type: EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION,
        content: {
          html: '<p>Description</p>',
        },
      };
    case AddSectionBlockTypes.DYNAMIC_PROPOSALS:
      return {
        type: EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS,
        content: {
          order: MOST_RECENT,
          label: 'Tiles',
          rules: [],
          unselectedProposals: [],
        },
      };
  }
};
