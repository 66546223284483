// import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useProject, useMap } from 'Client/utils/hooks';
import { useSharingContext } from 'Client/context/sharingHooks';
import { SHARING_ACTION_TYPES } from 'Client/context/sharingReducer';
import { fetchContributionsCount } from 'Client/services/contributions';
import { SharingContextTypes } from 'Organisms/Sharing/types';
import { ContributionType } from 'Shared/types/contribution';
import {
  BodyText,
  TabHeader,
  Heading,
  LearnMoreLink,
  Meta,
  NewScreenIcon,
  SubHeading,
  ShareAction,
  ShareIcon,
  ViewMapButton,
} from './ProjectInfoView.styles';
import { setWelcomeToken } from '../utils';

const ProjectInfoView = (): JSX.Element => {
  const project = useProject();
  const { t } = useTranslation();
  const router = useRouter();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [commentCount, setCommentCount] = React.useState<number>(0);
  const [agreementCount, setAgreementCount] = React.useState<number>(0);
  const [, dispatchSharingContext] = useSharingContext();

  const {
    dispatch,
    state: { proposal, welcomePanel },
  } = useMap();
  const hideCommentsCount = proposal?.featureFlags?.notCommentable;

  const fetchData = async () => {
    const config: [
      ContributionType,
      React.Dispatch<React.SetStateAction<number>>,
    ][] = [
      [ContributionType.COMMENT, setCommentCount],
      [ContributionType.AGREEMENT, setAgreementCount],
    ];

    await Promise.all(
      config.map(([type, cb]) =>
        fetchContributionsCount({
          projectId: project._id,
          pageId: proposal?.pageId || undefined,
          type,
        })
          .then(cb)
          .catch(() => cb(0))
      )
    );

    setIsLoading(false);
  };

  const handleViewMap = () => {
    if (welcomePanel) {
      setWelcomeToken(router.query.slug[0]);
      dispatch({ type: 'CLOSE_WELCOME_PANEL' });
      dispatch({
        type: 'OPEN_INFO_PANEL',
        payload: {
          infoPanelOpen: false,
        },
      });
    } else {
      dispatch({ type: 'TOGGLE_INFO_PANEL' });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [proposal]);

  const handleShareClick = () => {
    dispatchSharingContext({
      type: SHARING_ACTION_TYPES.SET_CONTEXT,
      context: SharingContextTypes.PROPOSAL,
    });
    dispatchSharingContext({
      type: SHARING_ACTION_TYPES.SET_SLUG,
      slug: proposal.slug,
    });
    dispatchSharingContext({
      type: SHARING_ACTION_TYPES.SET_IS_MAP,
      isMap: true,
    });
    dispatchSharingContext({ type: SHARING_ACTION_TYPES.SET_OPEN, open: true });
  };

  return proposal ? (
    <div style={{ position: 'relative' }}>
      <TabHeader>
        <Heading variant="h3">
          {proposal?.infoPanel?.title || proposal?.card?.title}
        </Heading>
        {hideCommentsCount && (
          <ShareAction onClick={handleShareClick}>
            <ShareIcon />
          </ShareAction>
        )}
      </TabHeader>
      <SubHeading commentsCountHidden={hideCommentsCount}>
        {!hideCommentsCount && (
          <>
            <Meta>
              {/* TODO: disabled launch data info till further notice */}
              {/* <span>
            {t('Launched {{ launchDate }}', {
              launchDate: format(new Date(proposal.createdAt), 'do MMMM y'),
            })}
          </span> */}

              <span>
                {t('Comments {{comments}}', {
                  comments: isLoading ? t('Loading...') : commentCount,
                })}
              </span>
              {project?.features?.agreeComments && (
                <span>
                  {t('Agreements {{agreements}}', {
                    agreements: isLoading ? t('Loading...') : agreementCount,
                  })}
                </span>
              )}
            </Meta>
            <ShareAction onClick={handleShareClick}>
              <ShareIcon />
            </ShareAction>
          </>
        )}
      </SubHeading>
      <BodyText>
        {proposal?.infoPanel?.description || proposal?.card?.description}
      </BodyText>
      <ViewMapButton onClick={handleViewMap}>{t('View map')}</ViewMapButton>
      <LearnMoreLink>
        <NewScreenIcon />
        {proposal?.infoPanel?.link?.url && proposal?.infoPanel?.link?.label ? (
          <>
            <a
              href={proposal?.infoPanel?.link?.url}
              target="_blank"
              rel="noreferrer"
            >
              {proposal?.infoPanel?.link?.label}
            </a>
          </>
        ) : (
          <a href={`/${router.locale}`} target="_blank" rel="noreferrer">
            {t('Learn more about {{projectName}}', {
              projectName: project.name,
            })}
          </a>
        )}
      </LearnMoreLink>
    </div>
  ) : null;
};

export { ProjectInfoView };
