import * as React from 'react';
import { SimpleHoverModal } from 'Atoms/SimpleHoverModal/SimpleHoverModal.styles';
import {
  checkLengthAndSetModal,
  HTMLElementEvent,
  MouseEventCustom,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import { Cell } from './CellWithHoverModal.styles';

interface CellWithHoverModalProps {
  sizeType?: number;
  editMode?: boolean;
  text: string;
}

export const CellWithHoverModal: React.FC<CellWithHoverModalProps> = ({
  sizeType,
  editMode,
  text,
}: CellWithHoverModalProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  return (
    <Cell
      onMouseOver={(
        e:
          | React.MouseEvent<HTMLDivElement, MouseEvent>
          | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
      ) => {
        setOpenModal(() =>
          checkLengthAndSetModal(
            e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>,
            7
          )
        );
      }}
      onMouseOut={() => setOpenModal(false)}
      sizeType={sizeType}
      editMode={editMode}
    >
      <p>{text}</p>
      {openModal && <SimpleHoverModal>{text}</SimpleHoverModal>}
    </Cell>
  );
};
