import * as React from 'react';
import styled from 'styled-components';
import { Container, InitI18n } from 'Atoms';
import { Head } from '../components';
import { Footer } from './components';
import { EngagementPagesTemplateProps } from './types';

export const EngagementPagesTemplate: React.FC<
  EngagementPagesTemplateProps
> = ({ children, nextButtonAction }: EngagementPagesTemplateProps) => {
  return (
    <>
      <InitI18n />
      <Wrapper data-testid="EngagementPagesTemplate">
        <Head />
        <Container size="small">{children}</Container>
        <Footer nextButtonAction={nextButtonAction} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colorMappings.white};
`;

export default EngagementPagesTemplate;
