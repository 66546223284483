import * as React from 'react';
import { SwitchProps } from '../../types';
import { SwitchSvg } from './Switch.styles';

export const Switch = ({ checked, onClick }: SwitchProps) => {
  return (
    <SwitchSvg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="20"
      viewBox="0 0 35 20"
      fill="none"
      onClick={onClick}
    >
      {checked && (
        <>
          <rect y="0.5" width="35" height="19" rx="9.5" fill="#4BA66A" />
          <rect x="17" y="2.5" width="15" height="15" rx="7.5" fill="white" />
        </>
      )}
      {!checked && (
        <>
          <rect y="0.318604" width="35" height="19" rx="9.5" fill="#C7C7C7" />
          <rect x="2" y="2.3186" width="15" height="15" rx="7.5" fill="white" />
        </>
      )}
    </SwitchSvg>
  );
};
