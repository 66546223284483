import styled, { css } from 'styled-components';
import BaseCloseIcon from '@material-ui/icons/Close';
import MuiTypography from '@material-ui/core/Typography';
import MuiButton from '@material-ui/core/Button';

export const PanelContainer = styled('div')<{ isOpen: boolean }>`
  width: 23.4rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0.1rem 0 0.3rem -0.1rem;
  position: absolute;
  z-index: 2;
  height: calc(100vh - 4.375rem - 3.5rem);
  top: calc(4.375rem + 3.5rem);
  overflow: visible;
  left: 0;
  transition: left 0.2s ease-out;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      overflow: auto;
      height: calc(100vh - 4.375rem);
      top: 4.375rem;
      width: 100%;
    }
  `};
`;

export const InnerPanelContainer = styled('div')``;

export const CloseIcon = styled(BaseCloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  color: ${({ theme }) => theme.colors.white[500]};

  path {
    fill: ${({ theme }) => theme.colors.white[500]};
  }

  ${({ theme }) => {
    return css`
      ${theme.breakpoints.down('sm')} {
        right: 1rem;
      }
    `;
  }}
`;

export const ImageContainer = styled.div<{ image?: string }>`
  width: 100%;
  background-size: cover;
  /* Todo: make background image dynamic */
  background-image: url(${(props) =>
    props.image ||
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/c_fill,f_auto,h_150,q_auto,w_341/v1595582010/hackney_hquewm.jpg'});
  height: 12.5rem;
`;

export const ContentContainer = styled.div`
  margin: 0 1.25rem;
`;

export const Title = styled(MuiTypography)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: 700;
  margin-top: 1.25rem;
`;

export const Description = styled(MuiTypography)`
  margin-top: 1.25rem;
`;

export const ResponsesContainer = styled(MuiTypography)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors.grey[600]};
  border: 0.125rem solid ${({ theme }) => theme.colors.grey[600]};
  border-radius: 3.125rem;
  padding: 0.32rem 0.88rem;
  min-width: 7rem;
  display: flex;
  justify-content: center;
`;

export const Button = styled(MuiButton)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  width: 13.1875rem;
  margin: 6.25rem auto 0;
  display: block;
  background-color: ${({ theme }) => theme.colors.green['A700']};
  color: ${({ theme }) => theme.colors.white[500]};
  border-radius: 3.13rem;

  &:hover {
    background-color: transparent;
  }
`;
