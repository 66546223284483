import styled from 'styled-components';
import { TextArea as TextAreaBase } from 'Client/components/molecules';

export const Wrapper = styled.div`
  width: calc(100% + 0.375rem);
  background: ${({ theme }) => theme.colors.grey[50]};
  padding: 1.625rem 1.625rem 0.5rem;
  margin-left: -0.375rem;
`;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    color: ${({ theme }) => theme.colors.blue[700]};
    width: 1.0625rem;
    height: 1.0625rem;
    margin-right: 1.75rem;
  }
`;
export const UserContainer = styled.div`
  display: flex;
  width: 70%;
`;
export const UserImage = styled.div`
  background-color: ${({ theme }) =>
    theme.colorMappings.commentReplyUserImageBackground};
  border-radius: 50%;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5625rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 2.875rem;
  height: 2.875rem;
  margin: 1rem 0.75rem 0 0;
  color: ${({ theme }) => theme.colors.white[500]};
`;

export const TextWrapper = styled.div`
  width: 80%;
`;
export const UserName = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
`;

export const UserRole = styled.div`
  font-size: 1rem;
  line-height: 1.125rem;
  margin-top: 1rem;
`;

export const RadioRow = styled.form`
  margin: 0.6875rem 0 1.25rem 3.2rem;
`;
export const Option = styled.div`
  font-size: 1rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
`;
export const TextAreaRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TextArea = styled(TextAreaBase)`
  width: 100%;

  fieldset {
    border-color: ${({ theme }) => theme.colors.grey[500]};
  }
`;
export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.white[500]};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.blue[700]};
  border-radius: 0.1875rem;
  margin-top: 0.8125rem;
  min-width: 5.625rem;
  height: 2.25rem;
  border: none;

  svg {
    color: ${({ theme }) => theme.colors.white[500]};
    margin-right: 0.4375rem;
  }

  ${({ disabled }) => disabled && `opacity: 0.5;`};
`;

export const Label = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;

  margin: 0 0 0.5rem;
  padding: 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0 1rem;
  font-size: 1rem;
  line-height: 1.125rem;

  div {
    border-radius: 0 !important;
  }

  .MuiFormControl-root {
    width: 100%;
    min-width: 0 !important;
  }

  .react-select-container,
  .react-select-container > div {
    width: 100%;
  }
`;
