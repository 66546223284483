import { GetSelectedRowsCountProps } from '../types';

export const getSelectedRowsCount = ({
  isAllRowsCheckboxSelected,
  totalFilteredRows,
  allRenderedRows,
  selectedRows,
}: GetSelectedRowsCountProps): number => {
  if (isAllRowsCheckboxSelected) {
    const contributionsUnchecked = allRenderedRows.filter((row) => {
      return !selectedRows.includes(row);
    });
    return totalFilteredRows - contributionsUnchecked.length;
  }
  return selectedRows.length;
};
