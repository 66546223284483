import * as React from 'react';
import { Title, Text } from './TextItem.styles';
import { TextItemProps } from './types';

export const TextItem: React.FC<TextItemProps> = ({
  title,
  children,
}: TextItemProps) => {
  return (
    <div>
      <Title>{title}</Title>
      <Text>{children}</Text>
    </div>
  );
};
