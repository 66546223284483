import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
`;

export const Step = styled.div`
  margin-bottom: 0.9375rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Steps = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  margin-right: 0.25rem;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const Lines = styled.div`
  display: flex;
  width: 100%;
  height: 0.625rem;
  margin-top: 0.4375rem;
`;

export const Line = styled.div<{ active: boolean }>`
  content: '';
  width: 4.6875rem;
  height: 0.375rem;
  margin-right: 0.4375rem;
  border-radius: 0.1875rem;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.green[500] : theme.colors.grey.A200};
`;

export const TitleWrapper = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  display: flex;
`;

export const Number = styled.div`
  color: ${({ theme }) => theme.colors.green[500]};
`;

export const Title = styled.div`
  margin-left: 0.25rem;
`;
