import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { NewsSubscriptionCard, SeoMetaInfo } from 'Atoms';
import { NewsPostArticle } from 'Molecules';
import { HubPagesTemplate as Template } from 'Templates';
import { userHasPermission } from 'Client/utils/user';
import { useUser, useProject } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import { NewsPostGaudi, NewsPostAcorn } from 'Shared/types/news';
import { NewsItemPageProps } from './types';
import {
  Container,
  Link,
  NewsSubscriptionWrapper,
  Wrapper,
  PageHeader,
} from './NewsPage.styles';

const NewsItemPage: React.FC<NewsItemPageProps> = ({
  newsPost,
  projectName,
  openGraphInfo,
  isSubscribed,
}: NewsItemPageProps) => {
  const { t, i18n } = useTranslation();
  const project = useProject();
  const { user } = useUser();
  const router = useRouter();
  const { query } = router;
  console.log('query', query);
  const fromEmailParams = ['utm_campaign', 'utm_medium'];
  const queryParams = Object.keys(query);
  const fromEmail = !!queryParams.find((param) =>
    fromEmailParams.includes(param)
  );
  const showPanel =
    userHasPermission(user, Permissions.UPDATE_NEWS) ||
    userHasPermission(user, Permissions.ADD_NEWS);

  return (
    <Template openGraphInfo={openGraphInfo}>
      <Wrapper fromEmail={fromEmail}>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={projectName}
          page="news"
        />
        <NewsSubscriptionWrapper data-testid="NewsItemPage-NewsSubscriptionWrapper">
          <NewsSubscriptionCard
            email={user?.email}
            project={project}
            isSubscribed={isSubscribed}
          />
        </NewsSubscriptionWrapper>

        <Container>
          {newsPost && (
            <>
              <PageHeader variant="h1">
                {t('News on {{ projectName }}', { projectName })}
              </PageHeader>
              <Link href={`/${i18n.language}/news`} variant="h4">
                {t('Back to news')}
              </Link>
              <NewsPostArticle
                key={newsPost._id}
                id={newsPost._id}
                date={newsPost.date}
                slug={newsPost.slug}
                headline={
                  (newsPost as NewsPostGaudi).headline ||
                  (newsPost as NewsPostAcorn).content?.headline
                }
                copy={
                  (newsPost as NewsPostGaudi).copy ||
                  (newsPost as NewsPostAcorn).content?.content
                }
                author={
                  (newsPost as NewsPostGaudi).by ||
                  (newsPost as NewsPostAcorn).content?.author
                }
                sentEmailCount={
                  (newsPost as NewsPostGaudi).sentUpdateEmailCount?.count ||
                  (newsPost as NewsPostAcorn).sentEmailCount?.count
                }
                showEditPanel={showPanel}
                newsImage={newsPost.newsImage}
                newsImageAlt={newsPost.newsImageAlt}
              />
            </>
          )}
        </Container>
      </Wrapper>
    </Template>
  );
};

export { NewsItemPage };
