import * as React from 'react';
import parse from 'html-react-parser';
import { CloudinaryImage } from 'Organisms';
import { UseMessages } from '../../hooks';
import { EmailVisualizerProps } from '../../types';
import {
  Wrapper,
  Header,
  ImageDisplayer,
  EmailContent,
  EmailButton,
  Footer,
} from './EmailVisualizer.styles';

export const EmailVisualizer: React.FC<EmailVisualizerProps> = ({
  subjectText,
  headerText,
  emailMessage,
  buttonText,
  buttonLink,
}: EmailVisualizerProps) => {
  const { SUBJECT, HEADER_TEXT, EMAIL_MESSAGE, BUTTON_TEXT } = UseMessages();
  return (
    <Wrapper data-testid="EmailVisualizer-Wrapper">
      <Header>
        <p>{`Subject: ${subjectText || SUBJECT}`}</p>
      </Header>
      <ImageDisplayer>
        <p>{headerText || HEADER_TEXT}</p>
      </ImageDisplayer>
      <EmailContent>
        <p>{emailMessage ? parse(emailMessage) : EMAIL_MESSAGE}</p>
      </EmailContent>
      <EmailButton
        onClick={() => {
          if (!buttonLink) return;
          window.open(buttonLink, 'blank');
        }}
      >
        {buttonText || BUTTON_TEXT}
      </EmailButton>
      <Footer>
        <CloudinaryImage
          imageSrc={
            'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1593422927/stock%20images/powered-by-Commonplace.png'
          }
          alt="powered by commonplace"
          srcSets={[
            {
              imageHeight: 61,
              imageWidth: 170,
            },
          ]}
        />
      </Footer>
    </Wrapper>
  );
};
