import { FormValidationStatus } from 'Client/types';
interface setStatusParams {
  errorsArray: Array<boolean>;
  index: number;
  isEmail: boolean;
}

export const setStatus = ({
  errorsArray,
  index,
  isEmail,
}: setStatusParams): FormValidationStatus => {
  return (
    errorsArray[index] && {
      show: true,
      type: 'error',
      message: isEmail
        ? 'This email is not valid'
        : 'This field cannot be empty',
    }
  );
};
