import * as React from 'react';
import { CloseIcon } from 'Icons';
import { HeaderProps } from '../../types';
import { Wrapper } from './Header.styles';

export const Header: React.FC<HeaderProps> = ({ closeSidePanel }) => {
  return (
    <Wrapper data-testid="Header-Wrapper">
      <button onClick={closeSidePanel} data-testid="Header-button">
        <CloseIcon />
      </button>
    </Wrapper>
  );
};
