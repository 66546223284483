import { lighten } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const OpenButton = styled.button<{
  width?: string;
  rounded?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  ${({ width }) => `width: ${width ? width : '100%'};`}
  ${({ rounded }) => rounded && '  border-radius: 1.3125rem;'}
  ${({ theme, 'aria-expanded': ariaExpanded }) =>
    `background-color: ${
      ariaExpanded ? lighten(0.45, theme.colorMappings.brand) : 'white'
    };
  `}

  border: 1px solid #979797;
  padding: 0.75rem 1.563rem;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  color: inherit;
  & > label {
    ${({ theme, 'aria-expanded': ariaExpanded }) => `
      ${theme.breakpoints.up('xs')} {
        font-size: 0.875rem;
        text-weight: 600;
      }
      ${theme.breakpoints.down('xs')} {
        font-size: 1.25rem;
      }
      color: ${ariaExpanded ? theme.colorMappings.brand : 'black'};
    `}
    padding: 0;
    margin-bottom: 0;
  }
`;

export const OptionsSection = styled.div<{ showFullWidth?: boolean }>`
  ${({ theme, showFullWidth }) => `
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: ${showFullWidth ? 'auto' : '100%'};

    overflow-x: scroll;
    background: #ffffff;

    padding: 1rem;

    transition: 1s ease;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      font-size: 1rem;
    }

    ${theme.breakpoints.up('sm')} {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      z-index: 100;
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 450px;
      overflow-y: scroll;
      padding-right: 1.5rem;

    }
  `}
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  & > label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }
`;
