import * as React from 'react';
import { useRouter } from 'next/router';
import { CardProps } from '../../types';
import { Wrapper, Label, Value, Arrow } from './Card.styles';

export const Card: React.FC<CardProps> = ({
  label,
  value,
  url,
  color,
}: CardProps) => {
  const router = useRouter();

  const onClick = () => {
    if (!url) return;
    router.replace({
      pathname: url,
    });
  };

  return (
    <Wrapper
      data-testid="Cards-Wrapper"
      cardColor={color}
      clickable={!!url}
      onClick={onClick}
    >
      <Value>{value}</Value>
      <Label>{label}</Label>
      <Arrow
        data-testid="Cards-Arrow"
        bold={true}
        opacity={url ? '0.8' : '0'}
      />
    </Wrapper>
  );
};
