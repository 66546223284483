import * as React from 'react';
import Box from '@material-ui/core/Box';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Container, Typography } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import { HubPagesTemplate as Template } from 'Templates';
import { FaqsPageItem } from './components';
import { FaqsPageProps } from './types';

export const FaqsPage: React.FC<FaqsPageProps> = (props: FaqsPageProps) => {
  const { title, description, FAQs, navbarItems } = props;

  const orderedFaqs = FAQs?.sort((a, b) => (a.order > b.order ? 1 : -1));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const mX = isMobile ? 0.4 : 3;
  const mY = isMobile ? 2 : 3;

  return (
    <Template navbarItems={navbarItems}>
      <Container data-testid="faqs-page-container">
        <Box marginX={mX} marginY={mY}>
          {title && (
            <Typography variant={isMobile ? 'h3' : 'h1'} gutterBottom>
              {title}
            </Typography>
          )}
          {description && (
            <HtmlDescription typographyProps={{ variant: 'subtitle1' }}>
              {description}
            </HtmlDescription>
          )}
        </Box>
        <Box marginX={mX} marginY={mY}>
          {orderedFaqs &&
            orderedFaqs.map((faq) => {
              return (
                <FaqsPageItem key={`${faq.order}-${faq.question}`} {...faq} />
              );
            })}
        </Box>
      </Container>
    </Template>
  );
};
