import * as React from 'react';
import { makeRedirectDomain } from 'Client/utils/url';
import { useProject } from 'Client/utils/hooks';
import { BottomTabNavigationProps } from './types';
import {
  Wrapper,
  TabItem,
  StyledButton,
  CTAWrapper,
} from './BottomTabNavigation.styles';

export const BottomTabNavigation: React.FC<BottomTabNavigationProps> = ({
  selectedTab,
  tabOptions,
  onTabChange,
}: BottomTabNavigationProps) => {
  const project = useProject();
  const redirectDomain = makeRedirectDomain(project.id);

  return (
    <Wrapper>
      <div data-testid="tab-items-wrapper">
        {tabOptions.map((item) => (
          <TabItem
            isSelected={item.value === selectedTab}
            key={`${item.label + String(Math.random() * 10)}`}
            onClick={() => onTabChange(item.id)}
          >
            {item.label}
          </TabItem>
        ))}
        <CTAWrapper>
          <StyledButton
            onClick={() =>
              (document.location.href = `http://${redirectDomain}/v4/dashboard/visitors/utm-form`)
            }
          >
            Create tracking link
          </StyledButton>
        </CTAWrapper>
      </div>
    </Wrapper>
  );
};
