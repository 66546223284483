import * as React from 'react';
import router from 'next/router';
import { HeaderProps } from './types';
import { Wrapper, Title, BackButton, Arrow } from './Header.styles';

export const Header: React.FC<HeaderProps> = ({
  title,
  backButtonText,
  children,
}: HeaderProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <BackButton onClick={() => router.back()}>
        <Arrow opacity="1" />
        <span>{backButtonText}</span>
      </BackButton>
      {children}
    </Wrapper>
  );
};
