import * as React from 'react';
import { Typography } from 'Atoms';
import { EngagementPagesTemplate } from 'Templates/EngagementPages';
import { SPECIAL_CATEGORY_DATA } from 'Constants/demographics';
import { useProject } from 'Client/utils/hooks';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import {
  QuestionsContainer,
  MeetTheTeamPanel,
  SpecialCategoryQuestionsContainer,
  DemographicsHeader,
} from './components';
import { DemographicsPageProps } from './types';
import {
  Wrapper,
  IntroDescription,
  FooterTextWrapper,
  DemographicsHeaderSection,
  HeaderTextContent,
  HeaderImageContainer,
} from './DemographicsPage.styles';
import LightBulb from './components/LightBulb';
import { insertProjectNames } from './utils/insertProjectsNames';

export const DemographicsPage: React.FC<DemographicsPageProps> = ({
  header,
  subheader,
  lightBulb,
  questions,
  stakeholders,
  teamSectionIntro,
  teamSectionFooter,
  specialCategoryTitle,
  specialCategoryDescription,
  footerText,
}: DemographicsPageProps) => {
  const project = useProject();
  const nonSpecialQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType !== SPECIAL_CATEGORY_DATA
  );
  const specialCategoryQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
  );

  const treatedSubheader = insertProjectNames(subheader, project.name);
  const treatedTeamSectionIntro = insertProjectNames(
    teamSectionIntro,
    project.name
  );
  const treatedTeamSectionFooter = insertProjectNames(
    teamSectionFooter,
    project.name
  );

  const treatedSpecialCategoryDescription = insertProjectNames(
    specialCategoryDescription,
    project.name
  );

  return (
    <>
      <DemographicsHeaderSection>
        <HeaderTextContent>
          <DemographicsHeader>{header}</DemographicsHeader>
          <IntroDescription>{treatedSubheader}</IntroDescription>
        </HeaderTextContent>
        <HeaderImageContainer>
          <img
            src="/static/illustrations/demographics_header.svg"
            width="auto"
            height="auto"
          />
        </HeaderImageContainer>
      </DemographicsHeaderSection>
      <EngagementPagesTemplate>
        <Wrapper>
          <LightBulb>{lightBulb}</LightBulb>
          <QuestionsContainer questions={nonSpecialQuestions} />
          {specialCategoryQuestions && specialCategoryQuestions.length > 0 && (
            <SpecialCategoryQuestionsContainer
              title={specialCategoryTitle}
              description={treatedSpecialCategoryDescription}
              questions={specialCategoryQuestions}
            />
          )}
          <MeetTheTeamPanel
            intro={treatedTeamSectionIntro}
            footer={treatedTeamSectionFooter}
            stakeholders={stakeholders}
          />
          {footerText && (
            <FooterTextWrapper>
              <Typography>{footerText}</Typography>
            </FooterTextWrapper>
          )}

          <EditModeButton />
        </Wrapper>
      </EngagementPagesTemplate>
    </>
  );
};
