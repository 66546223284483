export enum ContributionModerationStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}
export enum ContributionModerationReasons {
  PROFANITY = 'Profanity words',
  PHONE = 'Phone number',
  EMAIL = 'Email address',
  URL = 'URL',
}

export interface ContributionModeration {
  status: ContributionModerationStatus;
  date: Date;
  url: string;
  email?: string;
  phone?: string;
  moderatedAnswers: {
    questionId: string;
    reasons: ContributionModerationReasons[];
  }[];
}
