import { gql } from '@apollo/client';

export const ADD_COMMENT_REPLY_MUTATION = gql`
  mutation AddCommentReply($commentReplyInput: CommentReply) {
    addCommentReply(commentReplyInput: $commentReplyInput) {
      message
      status
    }
  }
`;
