import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.white[500]};
  width: 100%;
  cursor: pointer;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey[100]};
`;

export const Cell = styled.div<{ sizeType: number }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  flex: ${({ sizeType }) => sizeType};
  height: 2.25rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
`;

export const ActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 7.5%;
  height: 2.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
`;

export const SortFilter = styled.div`
  display: none;
`;
