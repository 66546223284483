import * as React from 'react';
import styled from 'styled-components';
import { Button, Modal as ModalBase, TextField } from 'Molecules';

export const Modal = styled(({ ...props }) => (
  <ModalBase width="auto" height="auto" {...props} />
))`
  p {
    line-height: 1.5rem;
    margin: 0.6875rem 0 0.6875rem 0;
    font-weight: 400;
  }
`;

export const TextMain = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 0.6875rem 0 0.6875rem 0;
`;

export const ConfirmButton = styled(Button).attrs({
  colorMapping: 'CommonplaceBrand',
})`
  border-radius: 0.1875rem;
  color: ${({ theme }) => theme.colors.white[500]};
`;

export const StyledTextField = styled(TextField)<{ type?: string }>`
  width: 100%;
  min-width: 0 !important;
  margin: 1rem 0 0;

  .MuiOutlinedInput-root {
    border-radius: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
