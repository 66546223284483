import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import PenIcon from '@material-ui/icons/CreateOutlined';
import { useRouter } from 'next/router';
import { useProject, useUser } from 'Client/utils/hooks';
import { userHasPermission } from 'Client/utils/user';
import { makeRedirectDomain } from 'Client/utils/url';
import { Permissions } from 'Client/constants/permissions';
import { FloatingButtonWrapper } from 'Molecules';
import { SharingButtonRoutes } from 'Client/components/organisms/FloatingShareButton/types';
import { ButtonAnchor } from './AddNewsButton.styles';

const AddNewsButton: React.FC = () => {
  const project = useProject();
  const { user } = useUser();
  const { t } = useTranslation('customer');
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [addNewsUrl, setAddNewsUrl] = React.useState<string>('');

  React.useEffect(() => {
    const redirectDomain = makeRedirectDomain(project.id);
    if (project.features?.useNewsV2) {
      setAddNewsUrl(`http://${redirectDomain}/v5/dashboard/add-news`);
    } else {
      setAddNewsUrl(
        `http://${redirectDomain}/v4/dashboard/communications/news`
      );
    }
  }, [project]);
  const isShown = !isMobile && userHasPermission(user, Permissions.ADD_NEWS);
  const route = router?.route as SharingButtonRoutes;

  return (
    isShown && (
      <FloatingButtonWrapper baseX={8.2} baseY={2.6} route={route}>
        <ButtonAnchor
          href={addNewsUrl}
          data-testid="AddNewsButton"
          data-onboarding="addNewsButton"
        >
          <PenIcon fontSize="inherit" />
          <span>{t('Add news')}</span>
        </ButtonAnchor>
      </FloatingButtonWrapper>
    )
  );
};

export { AddNewsButton };
