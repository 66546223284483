import * as React from 'react';
import dynamic from 'next/dynamic';

export const NoSSRTable = dynamic(
  () => import('./Table').then((mod) => mod.Table),
  {
    loading: function Loading() {
      return <div>loading</div>;
    },
    ssr: false,
  }
);
