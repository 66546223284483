import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { Files } from 'Atoms/Files';
import { ErrorStatusOrHelperText } from 'Atoms';
import { RelatedDocumentsProps } from './types';
import { Wrapper, Header, Text } from './RelatedDocuments.styles';
import {
  filterDocumentsFromPlanningApp,
  filterDocumentsFromTypedFiles,
} from './util';

export const RelatedDocuments: React.FC<RelatedDocumentsProps> = ({
  typedFiles,
  documents,
  reference,
}) => {
  const project = useProject();
  const { t } = useTranslation();
  const { Photo, ...typedDocs } = typedFiles;

  const fileSections = documents
    ? filterDocumentsFromPlanningApp(documents)
    : filterDocumentsFromTypedFiles(typedDocs);

  if (!fileSections?.length && project?.features?.glaPlanningApps) {
    return (
      <div style={{ paddingBottom: '2rem' }}>
        <ErrorStatusOrHelperText
          status={{
            type: 'info',
            message: t(
              'No GLA documents available. For related documents submitted to GLA, search their planning website with reference: {{reference}}.',
              {
                reference,
              }
            ),
          }}
        />
      </div>
    );
  }

  return (
    <Wrapper>
      <Header>
        <Text>{t('Description')}</Text>
        <Text>{t('Type')}</Text>
        {!project?.features?.glaPlanningApps && (
          <Text>{t('Time to read')}</Text>
        )}
        <Text>{t('Date published')}</Text>
      </Header>
      {fileSections.map((files, i) => (
        <Files key={i} files={files} hiddenUrl={!!documents?.length} />
      ))}
    </Wrapper>
  );
};
