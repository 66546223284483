import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsAddMemberModalOpen,
  setMemberToEdit,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { TeamMember } from 'Shared/types/teamMembers';
import { RootState } from 'Client/redux-store';
import {
  Wrapper,
  InputWrapper,
  InputDiv,
  CreateNewDiv,
  SearchMemberResultRow,
} from './AddMembersShortcut.styles';
import { AddMemberShortcutProps } from './types';

export const AddMembersShortcut: React.FC<AddMemberShortcutProps> = ({
  color,
  projectId,
}: AddMemberShortcutProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<Array<TeamMember>>([]);

  const { teamMembers } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const handleClick = (e) => {
    if (e?.target !== e?.currentTarget) return;
    setOpen((previous) => !previous);
  };

  React.useEffect(() => {
    if (searchValue) {
      const newResult = teamMembers
        .map((m) => {
          const string = `${m.firstName} ${m.lastName} ${m.email}`;
          if (string.toLowerCase().includes(searchValue.toLowerCase())) {
            return m;
          }
          return;
        })
        .filter(Boolean);

      setSearchResult(newResult);
      return;
    }

    setSearchResult([]);
    return () => {
      setSearchResult([]);
    };
  }, [searchValue]);

  React.useEffect(() => {
    if (!open) {
      setSearchValue('');
    }
  }, [open]);

  return (
    <>
      {open ? (
        <InputWrapper color={color} onClick={(e) => handleClick(e)}>
          <InputDiv
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
            color={color}
          ></InputDiv>
          {searchResult.length > 0 &&
            searchResult.map((m, index) => {
              return (
                <SearchMemberResultRow
                  key={m.email}
                  lastChild={index === searchResult.length - 1}
                  onClick={() => {
                    setOpen(false);
                    dispatch(
                      setMemberToEdit({
                        email: m.email,
                        _id: '',
                        projectId: projectId,
                      })
                    );
                  }}
                >
                  {`${m.firstName} ${m.lastName} (${m.email})`}
                </SearchMemberResultRow>
              );
            })}
          <CreateNewDiv
            color={color}
            onClick={() => {
              setOpen(false);
              dispatch(setIsAddMemberModalOpen(true));
            }}
          >
            + Can&apos;t find this person? Create a new member
          </CreateNewDiv>
        </InputWrapper>
      ) : (
        <Wrapper
          onClick={(e) => handleClick(e)}
          data-testid="AddMembersShortcut-Wrapper"
        >
          + Add new team member
        </Wrapper>
      )}
    </>
  );
};
