import * as React from 'react';
import styled, { css } from 'styled-components';
import { ChevronIcon as BaseChevronIcon, MapIcon } from 'Atoms/Icons';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.white[500]};
    position: absolute;
    top: 2rem;
    ${theme.direction === 'rtl' ? 'left' : 'right'}: 1.25rem;
    height: 3.125rem;
    width: 8rem;
    z-index: 2;
    padding: 0;
    border: none;
    border-radius: 1.5625rem;
    box-shadow: 0.1875rem 0.25rem 0.1875rem ${theme.colorMappings.shadow};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & strong {
      padding: 0.375rem;
    }

    & > svg {
      margin-bottom: 0.25rem;
    }

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`;

export const Action = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.green['A700']};
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

// TODO hard-coded colour...
export const WhiteChevron = styled(({ ...props }) => (
  <BaseChevronIcon
    color={'#FFFFFF'}
    opacity={'1'}
    height={20}
    width={15}
    {...props}
  />
))`
  transform: rotate(180deg);
`;

// TODO hard-coded colour...
export const GreenMap = styled(({ ...props }) => (
  <MapIcon bold color={'#00A85A'} {...props} />
))``;
