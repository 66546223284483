/* eslint-disable max-lines-per-function */
/* 
  To be removed with a refactor to this page
*/
import * as React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/minimal';
import { DashboardPagesTemplate as Template } from 'Templates';
import { isValidJson } from 'Shared/utils';
import { SortOptions } from 'Shared/types/dashboard';
import { FilterOption } from 'Shared/types/filters';
import {
  handleTabUrls,
  redirectToTab,
  getTopFiltersFromUrl,
  mapFilters,
} from 'Pages/dashboard/utils';
import { useProject, useUser } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import {
  ExportModal,
  TabNavigation,
  TableWrapper,
  ActionBar,
} from 'Pages/dashboard/components';
import GlobalFiltersV2 from 'Pages/dashboard/components/GlobalFiltersV2/GlobalFilters';
import { SidePanel } from 'Client/components/molecules';
import { userHasPermission } from 'Client/utils/user';
import { CreateGroupFeedbackMessage } from '../contributions/types';
import { HiddenColumns } from '../components/Table';
import {
  RespondentsPageProps,
  DemographicQuestion,
  AppliedFilters,
} from './types';
import { MainContentContainer } from './RespondentsPage.styles';
import { GroupsPage } from './components/groups';
import {
  EXPORT_MUTATION,
  GET_RESPONDENTS_QUERY,
  GET_RESPONDENTS_ANSWERS_QUERY,
} from './RespondentsPage.gql';
import { useToggle } from './hooks';
import { ChartsWrapper, Header } from './components';
import {
  /* ADD_TO_GROUP, */
  CREATE_GROUP,
  GROUP_AND_EMAIL,
} from '../contributions/constants';
import { getSelectedRowsCount } from '../contributions/utils';
import { CreateGroup, FeedbackMessage } from '../contributions/components';
import { updateQueryWithoutReload } from '../utils/updateQuery';

export const RespondentsPage: React.FC<RespondentsPageProps> = ({
  tabOptions,
}: RespondentsPageProps) => {
  const { _id: projectId, id: projectName } = useProject();
  const { user } = useUser();
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = router;
  const {
    lastIndex,
    tab,
    nestedFilters: urlNestedFilters,
    topFilters: urlTopFilters,
  } = query;
  const [exportRequest] = useMutation(EXPORT_MUTATION);

  const [lastIndexState, setLastIndexState] = React.useState<number>(
    Number(lastIndex)
  );
  const [allAnswersV2, setAllAnswersV2] = React.useState({});
  const [filterOptions, setFilterOptions] = React.useState<FilterOption[]>([]);
  const [baseTableHeaders, setBaseTableHeaders] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState<number>(1);
  const [isGlobalFiltersOpen, setIsGlobalFiltersOpen] = React.useState(false);
  const [isExportModalOpen, setIsExportModalOpen] =
    React.useState<boolean>(false);
  const [exported, setExported] = React.useState<boolean>(false);
  const [exporting, setExporting] = React.useState<boolean>(false);
  const [demographicQuestions, setDemographicQuestions] =
    React.useState<DemographicQuestion>([]);
  const [respondents, setRespondents] = React.useState([]);
  const [filteredRespondents, setFilteredContributions] =
    React.useState<number>(null);
  const [dataTotal, setDataTotal] = React.useState<number>(null);
  const [hiddenColumns, setHiddenColumns] = React.useState<
    Array<HiddenColumns>
  >([]);
  const [isAllRowsCheckboxSelected, setIsAllRowsCheckboxSelected] = useToggle();
  const [appliedFilters, setAppliedFilters] = React.useState<AppliedFilters>({
    topFilters: null,
    nestedFilters: [],
  });
  const [tableSort, setTableSort] = React.useState<SortOptions>({
    columnId: null,
    sortDirection: 'asc',
  });
  const [flatRowsIds, setFlatRowsIds] = React.useState<Array<string>>([]);
  const [selectedActionBarOption, setSelectedActionBarOption] =
    React.useState<string>(null);
  const [selectedRowsIds, setSelectedRowsIds] = React.useState<Array<string>>(
    []
  );
  const [createGroupFeedbackMessage, setCreateGroupFeedbackMessage] =
    React.useState<CreateGroupFeedbackMessage>({
      messageType: 'error',
      groupName: '',
      isOpen: false,
    });

  const currentSelectedRows = getSelectedRowsCount({
    isAllRowsCheckboxSelected,
    totalFilteredRows: filteredRespondents,
    allRenderedRows: flatRowsIds,
    selectedRows: selectedRowsIds,
  });

  const groupItems = [
    /* {
      label: ADD_TO_GROUP,
      action: () => setSelectedActionBarOption(ADD_TO_GROUP),
    }, */
    {
      label: CREATE_GROUP,
      action: () => setSelectedActionBarOption(CREATE_GROUP),
      dataOnboarding: 'dashboard-create-group',
    },
    {
      label: GROUP_AND_EMAIL,
      action: () => setSelectedActionBarOption(GROUP_AND_EMAIL),
      dataOnboarding: 'dashboard-create-group-email',
    },
  ];
  const ActionBarButtons = [
    { label: 'Group', dropdown: true, dropdownItems: groupItems },
  ];

  const { data: filtersAnswers } = useQuery(GET_RESPONDENTS_ANSWERS_QUERY, {
    variables: {
      getRespondentsAnswersInput: {
        projectId,
        projectName,
      },
    },
  });

  const { data, loading, fetchMore } = useQuery(GET_RESPONDENTS_QUERY, {
    variables: {
      getRespondentsCountForProjectInput: projectName,
      getFilteredRespondentsCountForProjectInput: {
        projectName: projectName,
        filters: appliedFilters,
      },
      getDashboardRespondentsInput: {
        projectName: projectName,
        filters: appliedFilters,
        lastIndex: 0,
        limit: 100,
        sortOptions: tableSort,
      },
      getRespondentsTableColumnsInput: projectId,
      getRespondentsFilterOptionsInput: projectId,
      getNonSensitiveDemographicsQuestionsWithContentInput: {
        lang: 'en-GB',
        projectId: projectId,
      },
    },
  });

  const onAllRowsCheckboxToggle = () => setIsAllRowsCheckboxSelected();

  const handleCloseFeedbackMessage = () => {
    setCreateGroupFeedbackMessage((previous) => {
      return { ...previous, isOpen: false };
    });
  };

  const onExportModalClose = () => {
    setExported(false);
    setIsExportModalOpen(false);
  };

  const handleModalExport = async (exportOptions) => {
    try {
      setExporting(true);
      const toSend = {
        exportOptions,
        projectName: projectName,
        filters: appliedFilters,
        sortOptions: tableSort,
        projectId: projectId.toString(),
        hiddenColumns,
        headers: baseTableHeaders,
        questionsWithNames: demographicQuestions,
        user,
        reference: 'respondent',
      };

      exportRequest({ variables: { dashboardCsvExportInput: toSend } });
      setExported(true);
      setExporting(false);
    } catch (error) {
      setExported(false);
      setExporting(false);
      captureException(
        `error in handleModalExport @ RespondentsPage.tsx : ${error}`
      );
    }
  };

  React.useEffect(() => {
    const setInitialData = () => {
      const {
        getRespondentsCountForProject,
        getFilteredRespondentsCountForProject,
        getDashboardRespondents,
        getRespondentsTableColumns,
        getRespondentsFilterOptions,
        getNonSensitiveDemographicsQuestionsWithContent,
      } = data;
      setRespondents(getDashboardRespondents);
      setDataTotal(getRespondentsCountForProject);
      setFilteredContributions(getFilteredRespondentsCountForProject);
      setDemographicQuestions(getNonSensitiveDemographicsQuestionsWithContent);
      setBaseTableHeaders(getRespondentsTableColumns);
      setFilterOptions(getRespondentsFilterOptions);
    };

    if (data && Object.keys(data).length > 0) {
      setInitialData();
    }
  }, [data, tableSort]);

  React.useEffect(() => {
    const setAnswersData = () => {
      const { getRespondentsAnswers } = filtersAnswers;
      setAllAnswersV2(getRespondentsAnswers);
    };

    if (filtersAnswers && Object.keys(filtersAnswers).length > 0) {
      setAnswersData();
    }
  }, [filtersAnswers]);

  React.useEffect(() => {
    const loadMore = async (limit: number) => {
      fetchMore({
        variables: {
          getRespondentsCountForProjectInput: projectName,
          getFilteredRespondentsCountForProjectInput: {
            projectName: projectName,
            filters: appliedFilters,
          },
          getDashboardRespondentsInput: {
            projectName: projectName,
            filters: appliedFilters,
            lastIndex: undefined,
            limit,
            sortOptions: tableSort,
          },
          getRespondentsTableColumnsInput: projectId,
          getRespondentsFilterOptionsInput: projectId,
          getNonSensitiveDemographicsQuestionsWithContentInput: {
            lang: 'en-GB',
            projectId: projectId,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const {
            getRespondentsCountForProject,
            getFilteredRespondentsCountForProject,
            getDashboardRespondents,
            getRespondentsTableColumns,
            getRespondentsFilterOptions,
            getNonSensitiveDemographicsQuestionsWithContent,
          } = fetchMoreResult;
          setRespondents([...getDashboardRespondents]);
          setDataTotal(getRespondentsCountForProject);
          setFilteredContributions(getFilteredRespondentsCountForProject);
          setDemographicQuestions([
            ...prev.getNonSensitiveDemographicsQuestionsWithContent,
            ...getNonSensitiveDemographicsQuestionsWithContent,
          ]);
          setBaseTableHeaders([...getRespondentsTableColumns]);
          setFilterOptions([
            ...prev.getRespondentsFilterOptions,
            ...getRespondentsFilterOptions,
          ]);
        },
      });
    };

    if (lastIndexState) {
      loadMore(lastIndexState + 100 || 100);
    }
  }, [lastIndex, lastIndexState]);

  React.useEffect(() => {
    handleTabUrls({
      page: 'respondents',
      tabInQuery: tab as string,
      router,
      tabOptions,
      setTab: setSelectedTab,
    });
  }, [tab, router, tabOptions]);

  React.useEffect(() => {
    const applyFilters = () => {
      const filters = getTopFiltersFromUrl({ router });
      const topFilters = {
        status: filters.status,
        date: filters.date,
      };
      const nestedFilters = isValidJson(urlNestedFilters as string)
        ? JSON.parse(urlNestedFilters as string)
        : [];
      const mappedFilters = mapFilters(nestedFilters, topFilters);
      if (
        !mappedFilters.nestedFilters.length &&
        !Object.keys(mappedFilters.topFilters).length
      ) {
        setAppliedFilters({
          topFilters: null,
          nestedFilters: [],
        });
        return;
      }
      setAppliedFilters(mappedFilters);
    };

    applyFilters();
  }, [urlNestedFilters, urlTopFilters, router]);

  return (
    <Template currentTab={'Respondents'}>
      <Header
        totalRespondents={filteredRespondents}
        appliedFilters={{
          nestedFilters: appliedFilters.nestedFilters,
          topFilters: appliedFilters.topFilters,
        }}
      />
      <TabNavigation
        selectedTab={selectedTab}
        tabOptions={tabOptions}
        onTabChange={(tabId: string) =>
          redirectToTab({ newTab: tabId, page: 'respondents', router })
        }
        isExportEnabled={
          userHasPermission(user, Permissions.EXPORT_RESPONDENTS) &&
          selectedTab === 1
        }
        onExportClick={() => setIsExportModalOpen(true)}
        onFilterClick={() => setIsGlobalFiltersOpen(true)}
      />
      <MainContentContainer data-testid="RespondentsPage-MainContentContainer">
        {selectedTab === 1 && (
          <TableWrapper
            baseTableHeaders={baseTableHeaders}
            loading={loading}
            rowsData={respondents}
            filteredRows={respondents.length}
            dataTotal={dataTotal}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
            setSelectedRowsIds={setSelectedRowsIds}
            loadMoreData={(_lastId, lastIndex) => {
              setLastIndexState(lastIndex);
              updateQueryWithoutReload({ lastIndex: String(lastIndex) });
            }}
            tableSort={tableSort}
            setTableSort={setTableSort}
            onAllRowsCheckboxToggle={onAllRowsCheckboxToggle}
            isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
            setFlatRowsIds={setFlatRowsIds}
            dataName={t('respondents')}
            tableType="respondents"
          />
        )}
        {selectedTab === 2 && (
          <ChartsWrapper
            projectId={projectId.toString()}
            appliedFilters={{
              topFilters: appliedFilters.topFilters,
              nestedFilters: appliedFilters.nestedFilters,
            }}
            dataName={t('respondents')}
            filteredRows={filteredRespondents}
            dataTotal={dataTotal}
          />
        )}
        {selectedTab === 3 && <GroupsPage />}
      </MainContentContainer>
      <SidePanel
        isOpen={Boolean(
          selectedActionBarOption === CREATE_GROUP ||
            selectedActionBarOption === GROUP_AND_EMAIL
        )}
      >
        <CreateGroup
          reference="respondent"
          currentSelectedRows={currentSelectedRows}
          selectedRowsIds={selectedRowsIds}
          allRows={flatRowsIds}
          closeSidePanel={() => setSelectedActionBarOption(null)}
          setCreateGroupFeedbackMessage={setCreateGroupFeedbackMessage}
          filters={appliedFilters}
          isGroupEmail={selectedActionBarOption === GROUP_AND_EMAIL}
          isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
        />
      </SidePanel>
      <SidePanel isOpen={createGroupFeedbackMessage.isOpen}>
        <FeedbackMessage
          {...createGroupFeedbackMessage}
          closeSidePanel={handleCloseFeedbackMessage}
          setCurrentTab={setSelectedTab}
        />
      </SidePanel>
      {Boolean(selectedRowsIds.length && selectedTab === 1) && (
        <ActionBar
          totalSelected={currentSelectedRows}
          buttons={ActionBarButtons}
        />
      )}

      <GlobalFiltersV2
        isOpen={isGlobalFiltersOpen}
        onModalClose={() => setIsGlobalFiltersOpen(false)}
        pageType="respondents"
        additionalFilterable={allAnswersV2}
        allAnswers={{}}
        filterOptions={filterOptions}
      />

      {isExportModalOpen && (
        <ExportModal
          exporting={exporting}
          exported={exported}
          isOpen={isExportModalOpen}
          onModalClose={onExportModalClose}
          onExport={handleModalExport}
          hiddenColumns={hiddenColumns}
          filters={{}}
          pageType="respondents"
        />
      )}
    </Template>
  );
};
