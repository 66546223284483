import { RootState } from 'Client/redux-store';

export const selectProposalBySlug = (
  slug: string,
  isMap: boolean,
  state: RootState
) => {
  const { proposals, maps } = state.pages;
  const conditionToExit = isMap ? maps.length === 0 : proposals.length === 0;
  if (conditionToExit) {
    return null;
  }
  const curr = (isMap ? maps : proposals).find((prop) => prop.slug === slug);
  return curr;
};
