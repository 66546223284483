import styled, { css } from 'styled-components';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import { StatusDotProps } from './types';

export const Dot = styled(MuiTimelineDot)<StatusDotProps>`
  ${({ width, height, theme, status, middle }) => {
    return css`
      width: ${width}rem;
      height: ${height}rem;
      border-radius: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @keyframes pulse {
        0% {
          transform: scale(0.8);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.8);
        }
      }

      ${status === 'done' &&
      css`
        background-color: ${theme.colors.green['A700']};
      `};

      ${status === 'planned' &&
      css`
        background-color: ${theme.colors.grey[200]};
      `};

      ${status === 'active' &&
      css`
        background-color: ${theme.colors.yellow[700]};

        &:before {
          width: ${width * 2}rem;
          height: ${height * 2}rem;
          position: absolute;
          margin: 0 auto;
          border-radius: 100%;

          background-color: ${theme.colorMappings.focusHighlight};
          animation: pulse 2.5s -0.4s infinite;
          content: '';
          opacity: 0.3;
        }
      `};

      ${middle === 'blank' &&
      css`
        &:after {
          width: ${width / 2}rem;
          height: ${height / 2}rem;
          position: absolute;
          margin: 0 auto;
          border-radius: 100%;
          content: '';

          background-color: ${theme.colors.white[500]};
        }
      `}
    `;
  }};
`;
