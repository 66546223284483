import * as React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ResponseProps } from 'Client/pages/contributions/types';
import { RepliedIcon } from 'Atoms/Icons';

interface Props {
  response: ResponseProps;
}

export const Indicator = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: right;
    text-align: right;
    color: ${theme.colorMappings.editModePrimaryColor};

    path {
      color: ${theme.colorMappings.editModePrimaryColor};
    }

    b {
      margin-left: 0.5rem;
    }
  `}
`;

export const ContributionResponseIndicator: React.FC<Props> = ({
  response,
}: Props) => {
  if (!response) return null;
  const { visibility } = response;
  const { t } = useTranslation();

  const text =
    visibility === 'public' ? t('Replied publicly') : t('Replied privately');
  return (
    <Indicator>
      <RepliedIcon height={17} width={17} />
      <b>{text}</b>
    </Indicator>
  );
};
