import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusDot } from 'Atoms/StatusDot';
import { colorByStatus } from 'Constants/planningAppStatus';
import { useProject } from 'Client/utils/hooks';
import { CommentsCount } from '../CommentsCount';
import { DaysToComment } from '../DaysToComment';
import {
  Wrapper,
  Title,
  Description,
  ColumnWrapper,
  RowWrapper,
  StatusWrapper,
  CommentsWrapper,
  CommentsColumn,
  TitleWrapper,
  BackButtonWrapper,
  CommentsWrapperMobile,
} from './StatusSection.styles';
import { StatusSectionProps } from './types';
import { BackButton } from '..';
import { glaPlanningAppsStatuses } from '../../constants/glaConstants';

export const StatusSection: React.FC<StatusSectionProps> = ({
  name,
  refValue,
  isConsultationOpen,
  daysLeft,
  commentsCount,
  status,
  setSelectedTab,
}) => {
  const { t } = useTranslation();
  const project = useProject();
  return (
    <Wrapper>
      <ColumnWrapper>
        <TitleWrapper>
          <BackButtonWrapper>
            <BackButton />
          </BackButtonWrapper>
          <Title small={name.length > 25}>{name}</Title>
        </TitleWrapper>

        <RowWrapper>
          <Description>
            <span>{t('Ref.')}</span> {refValue}
          </Description>

          <StatusWrapper>
            <StatusDot
              glaColor={
                glaPlanningAppsStatuses?.find(
                  (glaStatus) => glaStatus?.label == status
                )?.color
              }
              status={colorByStatus[status]}
            />
            <Description>{status}</Description>
          </StatusWrapper>

          <CommentsWrapperMobile>
            <CommentsCount
              commentsCount={commentsCount}
              setSelectedTab={setSelectedTab}
            />
          </CommentsWrapperMobile>
        </RowWrapper>
      </ColumnWrapper>

      <CommentsColumn>
        {daysLeft > 0 && !project?.features?.glaPlanningApps && (
          <DaysToComment daysLeft={daysLeft} />
        )}

        {isConsultationOpen && (
          <CommentsWrapper>
            <CommentsCount
              commentsCount={commentsCount}
              setSelectedTab={setSelectedTab}
            />
          </CommentsWrapper>
        )}
      </CommentsColumn>
    </Wrapper>
  );
};
