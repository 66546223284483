import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'Client/redux-store';
import { setDeleteProjectModal } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import {
  ButtonsDiv,
  CancelButton,
} from '../../people/teamManagement/components/SharedModal.styles';
import {
  ConfirmButton,
  Modal,
  StyledTextField,
  TextMain,
} from './DeleteProjectModal.styles';

export const DeleteProjectModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('customer');
  const { deleteProjectModal } = useSelector(
    (state: RootState) => state.projectCentre
  );
  const { open, projectName } = deleteProjectModal;

  const [nameVerification, setNameVerification] = React.useState('');
  const [verificationError, setVerificationError] = React.useState(false);

  const handleConfirmButton = () => {
    if (!nameVerification || nameVerification !== projectName) {
      setVerificationError(true);
      return;
    }
    // TODO wire with backend
  };

  const handleClose = () =>
    dispatch(setDeleteProjectModal({ open: false, projectName: '' }));

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <TextMain>
        {t('Are you sure you want to delete the project ')}
        <b>{projectName}</b>?
      </TextMain>

      <p>
        {t(
          "You will lose all the content and data from this project and won't be able to retrieve it."
        )}
      </p>
      <StyledTextField
        label={''}
        handleChange={(e) => {
          setVerificationError(false);
          setNameVerification(String(e.target.value));
        }}
        placeholder={t('Type the name of the project to confirm your choice')}
        status={{
          show: verificationError,
          type: verificationError ? 'error' : 'success',
          message: verificationError && t('Project names do not match'),
        }}
      />
      <ButtonsDiv>
        <CancelButton onClick={() => handleClose()}>{t('Cancel')}</CancelButton>
        <ConfirmButton
          onClick={handleConfirmButton}
          disabled={!nameVerification}
        >
          {t('Confirm')}
        </ConfirmButton>
      </ButtonsDiv>
    </Modal>
  );
};
