import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject, useUser } from 'Client/utils/hooks';
import { useSharingContext } from 'Client/context/sharingHooks';
import { SHARING_ACTION_TYPES } from 'Client/context/sharingReducer';
import { proposalNewsSignUp } from 'Client/services/subscription/proposalNewsSignUp';
import { EmailPanel } from 'Client/components/organisms';
import {
  parseSyntaxValidationResult,
  validateEmail,
} from 'Client/utils/validators';
import { syntaxValidationRequest } from 'Client/services/validation';
import { SharingContextTypes } from 'Client/components/organisms/Sharing/types';
import useTrackEmailValidation from 'Client/utils/hooks/useAnalytics/useTrackEmailValidation';
import { scrollToSection } from '../../utils';
import {
  Wrapper,
  StyledButton,
  ShareWrapper,
  ShareIcon,
} from './MainImageButtons.styles';
import { MainImageButtonsProps } from './types';

export const MainImageButtons: React.FC<MainImageButtonsProps> = ({
  slug,
  proposalName,
  pageId,
  refValue,
  isConsultationOpen,
}) => {
  const { t } = useTranslation();
  const project = useProject();
  const [, dispatchSharingContext] = useSharingContext();
  const { user } = useUser();
  const trackEmailValidation = useTrackEmailValidation();

  const [email, setEmail] = React.useState('');
  const [showSignUp, setShowSignUp] = React.useState(false);
  const [inputError, setInputError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [signedUp, setSignedUp] = React.useState(false);
  const [firstAttemptEmail, setFirstAttemptEmail] = React.useState(email);
  const [emailValidationMessage, setEmailValidationMessage] =
    React.useState(null);

  const handleSignUp = async () => {
    if (!validateEmail(email)) {
      return setInputError(true);
    }
    setLoading(true);

    const externalEmailValidation = await syntaxValidationRequest({
      data: email,
    }).then(parseSyntaxValidationResult(t));
    trackEmailValidation(email, externalEmailValidation, {});
    const emailChanged = email !== firstAttemptEmail;
    if (emailChanged) {
      setFirstAttemptEmail(email);
    }
    const invalidEmail =
      (emailChanged && externalEmailValidation?.type === 'warning') ||
      externalEmailValidation?.type === 'error';

    if (invalidEmail) {
      setLoading(false);
      setEmailValidationMessage(externalEmailValidation);
      return setInputError(true);
    }

    try {
      await proposalNewsSignUp({
        email: user ? user.email : email,
        pageId,
        project,
        planApp: { planAppAddress: proposalName, planAppId: refValue },
      });

      setSignedUp(true);
      setInputError(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleLeaveAComment = () => {
    scrollToSection('comment-section');
  };

  const handleKeepMeUpdated = () => {
    setShowSignUp(!showSignUp);
  };

  const handleShareClick = () => {
    dispatchSharingContext({
      type: SHARING_ACTION_TYPES.SET_CONTEXT,
      context: SharingContextTypes.PLANNINGAPP,
    });
    dispatchSharingContext({
      type: SHARING_ACTION_TYPES.SET_PROPOSAL_ID,
      proposalId: slug,
    });
    dispatchSharingContext({
      type: SHARING_ACTION_TYPES.SET_SLUG,
      slug: proposalName,
    });
    dispatchSharingContext({ type: SHARING_ACTION_TYPES.SET_OPEN, open: true });
  };

  return (
    <Wrapper>
      {isConsultationOpen && (
        <StyledButton
          onClick={handleLeaveAComment}
          data-testid="leave-a-comment-button"
        >
          {t('Leave a comment')}
        </StyledButton>
      )}

      <StyledButton invertedColor onClick={handleKeepMeUpdated}>
        {t('Keep me updated')}
      </StyledButton>

      {showSignUp && (
        <EmailPanel
          onClose={() => setShowSignUp(false)}
          onSubmit={handleSignUp}
          emailValue={email}
          onChange={(value) => setEmail(value?.toLowerCase())}
          isFeedbackMessage={signedUp}
          inputError={inputError}
          emailValidationMessage={emailValidationMessage}
          loading={loading}
          proposalName={proposalName}
          header={t('Receive updates on this planning application')}
        />
      )}

      <ShareWrapper
        onClick={handleShareClick}
        data-testid="ShareButton-PlanningAppPage"
      >
        <ShareIcon />
      </ShareWrapper>
    </Wrapper>
  );
};
