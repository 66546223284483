import * as React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTheme } from '@material-ui/core';
import { Icon, Stroke, Style } from 'ol/style';
import GeoJSON, { GeoJSONGeometry } from 'ol/format/GeoJSON';
import { useTranslation } from 'react-i18next';
import { MapQuestion } from 'Shared/types';
import { QuestionPagesTemplate } from 'Client/templates';
import {
  useProject,
  useUser,
  useMap,
  useProposalContext,
} from 'Client/utils/hooks';
import { Page } from 'Shared/types/page';
import { AppTheme } from 'Client/types';
import { PinIcon } from 'Icons';
import { MapZoomButton } from 'Molecules';
import {
  MapQuestionAnswer,
  MapQuestionAnswerType,
} from 'Shared/types/question';
import { DrawStateValues, MapProjection, Xyz } from 'Shared/types/map';
import { CURSOR_PIN_SVG_ICON, PIN_SVG_ICON } from 'Client/constants/map';
import { getFeatureTypeByAnswerType } from 'Client/pages/map/utils';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import uploadImageToCloudinary from 'Client/services/cloudinary/uploadImageToCloudinary';
import centerOnFeature from 'Client/utils/map/centerOnFeature';
import getMapScreenshot from 'Client/utils/map/getMapScreenshot';
import { ZoomButtonsContainer } from 'Atoms/MapCTA';
import { Geometry } from 'Shared/types/contribution';
import CTA from './CTA';

const GeolytixMap = dynamic(() => import('Client/components/map/GeolytixMap'), {
  ssr: false,
});

const isValidType = (g: GeoJSONGeometry): g is Geometry =>
  ['Point', 'LineString', 'Polygon'].includes(g.type);

const getMapPreviewImageAroundPin = async (
  projectId: string,
  questionId: string,
  type: MapQuestionAnswerType
) => {
  const featureType = getFeatureTypeByAnswerType(type);
  const size = featureType === 'point' ? 400 : 1000;

  const [mapCanvas, featureLayerCanvas] = Array.from(
    document.getElementsByTagName('canvas')
  );
  const mapScreen = getMapScreenshot(mapCanvas, size, size);
  const featureScreen = getMapScreenshot(featureLayerCanvas, size, size);
  const newCanvas = document.createElement('canvas');
  newCanvas.width = size;
  newCanvas.height = size;

  newCanvas.getContext('2d')?.drawImage(mapScreen, 0, 0);
  newCanvas.getContext('2d')?.drawImage(featureScreen, 0, 0);

  const folder = `projects/${projectId}/contributions-uploads`;
  const filename = `${questionId}-${Date.now()}`;
  return uploadImageToCloudinary(folder, filename, newCanvas.toDataURL());
};

interface MapQuestionPageProps {
  question: MapQuestion;
  mapPage: Page;
}

export const MapQuestionPage: React.FC<MapQuestionPageProps> = ({
  question,
  mapPage,
}) => {
  const router = useRouter();
  const theme: AppTheme = useTheme();
  const { user } = useUser();
  const { t } = useTranslation();
  const { _id } = useProject();
  const { dispatch: mapDispatch, state } = useMap();
  const [{ answers }, dispatch] = useProposalContext();

  const [drawState, setDrawState] = React.useState(
    DrawStateValues.DRAWING_STARTED
  );
  const [answer, setAnswer] = React.useState<Geometry>(null);
  // get the existing answer only to display a fake pin on initial render if need be
  const [existingAnswer, setExistingAnswer] = React.useState<MapQuestionAnswer>(
    answers[question.id] as MapQuestionAnswer
  );
  const answerType = getFeatureTypeByAnswerType(question.answerType);
  const isPoint = answerType === 'Point';
  const xyz = state.xyz as Xyz;

  const handleInitMap = () => {
    const { content, ...rest } = mapPage;
    const flatMapPage = { ...content, ...rest, pageId: mapPage._id };
    mapDispatch({
      type: 'INITIALIZE_LAYERS',
      payload: {
        dispatch: mapDispatch,
        page: { ...flatMapPage },
        project: { _id },
        uniqueId: user?._id,
        userId: user?._id,
        lang: router.locale,
      },
    });
  };

  const initDrawing = (type) => {
    xyz.mapview.interaction.draw.begin({
      ...(type === 'Point'
        ? {}
        : {
            drawStyle: new Style({
              stroke: new Stroke({ color: '#000', width: 2 }),
            }),
          }),
      layer: xyz.layers.list.Contributions,
      type: type === 'shape' ? 'Polygon' : type,
    });

    xyz.mapview.interaction.draw.interaction.on('drawstart', (event) => {
      event.feature.addEventListener('change', (event) => {
        const coordsCount =
          event.target?.getProperties()?.geometry?.flatCoordinates?.length || 0;

        /* means drawing didn't started (or undid all points) */
        if (coordsCount < 1 && drawState !== DrawStateValues.DRAWING_STARTED)
          return setDrawState(DrawStateValues.DRAWING_STARTED);

        if (coordsCount <= 4 && question.answerType === 'line')
          return setDrawState(DrawStateValues.DRAWING_STARTED);

        if (coordsCount >= 6 && question.answerType === 'line')
          return setDrawState(DrawStateValues.HAS_MORE_THAN_TWO_POINTS);

        /* means less than 3 clicks */
        if (
          coordsCount < 10 &&
          drawState !== DrawStateValues.HAS_LESS_THAN_THREE_POINTS
        )
          return setDrawState(DrawStateValues.HAS_LESS_THAN_THREE_POINTS);

        /* means more than 2 clicks */
        if (
          coordsCount >= 10 &&
          drawState !== DrawStateValues.HAS_MORE_THAN_TWO_POINTS
        )
          return setDrawState(DrawStateValues.HAS_MORE_THAN_TWO_POINTS);
      });
    });

    xyz.mapview.interaction.draw.interaction.on('drawend', async (event) => {
      setExistingAnswer(null); // on any click interaction remove the fake pin

      const geometry = event.feature.getGeometry();
      const polygonGeodetic = geometry
        .clone()
        .transform(
          MapProjection.WEB_MERCATOR,
          MapProjection.WORLD_GEODETIC_GPS
        );

      const geoJson = new GeoJSON().writeGeometryObject(polygonGeodetic);

      if (!isValidType(geoJson)) return;

      if (geoJson.type === 'Point') {
        event.feature.setStyle(
          new Style({
            image: new Icon({
              src: PIN_SVG_ICON,
              anchor: [0.5, 1],
              scale: 2,
            }),
          })
        );
      }

      await centerOnFeature(xyz.map, geoJson);

      const answer: MapQuestionAnswer = {
        ...geoJson,
        previewImage: '',
      };

      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
        answers: {
          ...answers,
          [question.id]: answer,
        },
      });

      setAnswer(geoJson);
      setDrawState(DrawStateValues.DRAWING_FINISHED);
    });

    if (type === 'Point') {
      xyz.mapview.node.style.cursor = `url(${CURSOR_PIN_SVG_ICON}) 16 35, pointer`;
    }
  };

  const handleCloseCTAPanel = () => {
    xyz.mapview.interaction.draw.cancel();

    initDrawing(getFeatureTypeByAnswerType(question.answerType));

    return setDrawState(DrawStateValues.DRAWING_STARTED);
  };

  React.useEffect(() => {
    if (!xyz || !question.answerType) return;
    initDrawing(getFeatureTypeByAnswerType(question.answerType));
  }, [xyz, question.id, question.answerType]);

  React.useEffect(() => {
    if (xyz && existingAnswer) {
      centerOnFeature(xyz.map, existingAnswer);
    }
  }, [xyz, existingAnswer]);

  const handleSaveClick = async () => {
    await centerOnFeature(xyz.map, answer);
    const url = await getMapPreviewImageAroundPin(
      _id,
      question.id,
      question.answerType
    );

    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
      answers: {
        ...answers,
        [question.id]: {
          ...answer,
          previewImage: url,
        },
      },
    });

    if (url) {
      router.back();
    } else {
      // in the future we should show feedback to the user that something failed
    }
  };

  const headerCopy = {
    [DrawStateValues.DRAWING_STARTED]: {
      point: t('Add your first point'),
      shape: t('Draw your shape'),
      line: t('Draw a line'),
    },
    [DrawStateValues.HAS_LESS_THAN_THREE_POINTS]: {
      point: t('Add your first point'),
      shape: t('Add at least two more points'),
      line: t('Draw a line'),
    },
    [DrawStateValues.HAS_MORE_THAN_TWO_POINTS]: {
      point: t('Add your first point'),
      shape: t('Finish your shape'),
      line: t('Draw a line'),
    },
    [DrawStateValues.DRAWING_FINISHED]: {
      point: t('Add your first point'),
      shape: t('Draw your shape'),
      line: t('Draw a line'),
    },
  }[drawState][question.answerType];

  const descriptionCopy = {
    [DrawStateValues.DRAWING_STARTED]: {
      point: t(question.secondaryText),
      shape: t(
        'To start drawing your shape you will need to add a series of points.'
      ),
      line: t('Click on the map to start and to add more points'),
    },
    [DrawStateValues.HAS_LESS_THAN_THREE_POINTS]: {
      point: t(question.secondaryText),
      shape: t('Create a shape adding more than two points.'),
      line: t('Click on the map to start and to add more points'),
    },
    [DrawStateValues.HAS_MORE_THAN_TWO_POINTS]: {
      shape: t(
        'You can still add more points. To complete your shape, click on the first point.'
      ),
      line: t('Double-click on the last point to finish your line'),
    },
    [DrawStateValues.DRAWING_FINISHED]: {
      point: t(question.secondaryText),
      shape: t(
        'To start drawing your shape you will need to add a series of points.'
      ),
      line: t('Click on save once you are done drawing your line'),
    },
  }[drawState][question.answerType];

  const CtaHeader = headerCopy;
  const CtaDescription = descriptionCopy;
  const canChangeColour =
    !drawState.includes(DrawStateValues.DRAWING_STARTED) &&
    !drawState.includes(DrawStateValues.DRAWING_FINISHED);

  return (
    <QuestionPagesTemplate
      headerLabel={question.callToAction}
      questionId={question.id}
      onSaveClick={handleSaveClick}
      disableSave={drawState !== DrawStateValues.DRAWING_FINISHED}
    >
      <GeolytixMap
        onInit={handleInitMap}
        pageId={mapPage._id}
        customHeight={`calc(100vh - ${theme.proposals.questionsHeader.height} - ${theme.proposals.footer.height})`}
        isPositionFixed={true}
      />
      {existingAnswer && isPoint && (
        <PinIcon
          color={theme.colorMappings.mapPinIcon}
          width={40}
          height={40}
          style={{
            position: 'absolute',
            zIndex: 1000,
            top: 'calc(50% - 2.5rem)',
            left: 'calc(50% - 1.25rem)',
          }}
        />
      )}

      <CTA
        header={CtaHeader}
        content={CtaDescription}
        onCancel={handleCloseCTAPanel}
        answerType={question.answerType}
        changeColour={canChangeColour}
      />

      <ZoomButtonsContainer>
        <MapZoomButton />
      </ZoomButtonsContainer>
    </QuestionPagesTemplate>
  );
};
