import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

export const handleDates = (value: Date): { date: string; time: string } => {
  const castToDate = new Date(value);
  if (!castToDate || !isValid(castToDate)) return null;
  const date = format(castToDate, 'dd.MM.yyyy');
  const time = format(castToDate, 'HH:mm');
  return { date, time };
};
