import * as React from 'react';
import Select from 'react-select';
import { captureException } from '@sentry/react';
import { OptionItem } from 'Client/types';
import { Wrapper } from './GroupsSelect.styles';
import { GroupSelectProps } from '../../types';

export const GroupsSelect: React.FC<GroupSelectProps> = ({
  setGroupsIds,
}: GroupSelectProps) => {
  const [options, setOptions] = React.useState<Array<OptionItem>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<OptionItem>
  >([]);

  const onChange = (newOptions: Array<OptionItem>) => {
    if (!newOptions) {
      setSelectedOptions([]);
      return;
    }
    setSelectedOptions(newOptions);
  };

  React.useEffect(() => {
    const getGroups = async () => {
      try {
        const result = await fetch('/api/external/group/groupsName', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const { success, data } = await result.json();
        if (success === false) {
          setOptions([]);
          return;
        }
        const mappedGroups = data.map((group) => ({
          value: group._id,
          label: group.name,
        }));
        setOptions(mappedGroups);
      } catch (error) {
        captureException(`error in getGroups @ GroupsSelect.tsx : ${error}`);
      }
    };

    getGroups();
  }, []);

  React.useEffect(() => {
    if (!options || !options.length) return;

    if (!selectedOptions.length) return;

    const groupIds = selectedOptions.map((option) => option.value);

    setGroupsIds(groupIds);
  }, [options, setGroupsIds, selectedOptions]);

  return (
    <Wrapper data-testid="GroupsSelect-Wrapper">
      <Select
        classNamePrefix="react-select"
        value={selectedOptions}
        onChange={onChange}
        options={options}
        placeholder={'Select groups...'}
        isClearable={false}
        isMulti={true}
      />
    </Wrapper>
  );
};
