import { gql } from '@apollo/client';

export const GET_USER_TYPE = gql`
  query GetUserTypeFromEmail($email: String!) {
    getUserTypeFromEmail(email: $email) {
      type
      userName
    }
  }
`;
