import styled, { css } from 'styled-components';

export const ProjectInfoWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding-bottom: 3rem;
`;

export const InfoContent = styled.div<{
  errorBannerHeight: number;
  stageBannerHeight: number;
}>`
  margin: 0 auto;
  padding-top: ${({ theme, errorBannerHeight, stageBannerHeight }) =>
    `${
      Number(theme.proposals.masthead.height.replace('rem', '')) +
      errorBannerHeight +
      stageBannerHeight
    }rem`};
  max-width: ${({ theme }) => theme.container.width};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding-top: 1rem;
    }
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.container.width};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      position: relative;
    }
  `}
`;

export const InfoWrapper = styled.div`
  width: 50%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `}
`;

export const ImgWrapper = styled.div`
  width: 50%;
  display: flex;
  margin-left: 1rem;
  justify-content: flex-end;
  align-items: flex-start;

  & img {
    width: 100%;
    height: 95%;

    max-height: 30rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TimeLineWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colorMappings.planAppTimelineBackground};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      height: 14.5rem;
    }
  `}
`;

export const CommentSectionWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colorMappings.planAppCommentSectionBackground};
`;

export const TabsWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const BackButtonWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;

export const MobileTitle = styled.h2`
  display: none;
  font-size: 1.25rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: block;
      margin: 0;
      padding: 1rem;
      font-size: 1.25rem;
      background: #ffffff;
      color: ${({ theme }) => theme.colors.black[500]};
      font-weight: 700;
    }
  `}
`;
