import styled, { css } from 'styled-components';
import { ChevronIcon } from 'Icons';

export const Wrapper = styled.div<{ cardColor: string; clickable: boolean }>`
  background-color: ${({ cardColor }) => cardColor};
  width: 13.75rem;
  height: 5.25rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;
export const Label = styled.dt`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white[500]};
  font-weight: 700;
`;
export const Value = styled.dd`
  margin: 0;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white[500]};
  font-weight: 700;
`;
export const Arrow = styled(ChevronIcon)`
  transform: rotate(180deg);
  align-self: end;
  color: ${({ theme }) => theme.colors.white[500]};
`;
