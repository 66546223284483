import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { TableWrapperProps } from './types';
import { NoSSRTable } from '../Table';

export const TableWrapper: React.FC<TableWrapperProps> = ({
  tableMapHeadersParams,
  loading,
  rowsData,
  tableSort,
  setTableSort,
  loadMoreData,
  setFlatRowsIds,
}: TableWrapperProps) => {
  const [isWaypointInside, setIsWaypointInside] =
    React.useState<boolean>(false);

  return (
    <div data-testid="Table-Wrapper">
      <NoSSRTable
        tableMapHeadersParams={tableMapHeadersParams}
        rowsData={rowsData}
        loading={loading}
        loadMoreData={loadMoreData}
        setFlatRowsIds={setFlatRowsIds}
        isWaypointInside={isWaypointInside}
        tableSort={tableSort}
        setTableSort={setTableSort}
      />
      {!loading && (
        <Waypoint
          data-testId={'Table-Waypoint'}
          onEnter={() => setIsWaypointInside(true)}
          onLeave={() => setIsWaypointInside(false)}
          bottomOffset="-100px"
          topOffset="100px"
        />
      )}
    </div>
  );
};
