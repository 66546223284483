import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'Client/components/molecules';
import { Typography, DateDistanceLocalised } from 'Atoms';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0 1rem;
    }
  `}
`;

export const HideCommentsWrapper = styled.div`
  padding-bottom: 2rem;
  a {
    text-decoration: underline;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0.5rem 1rem;
    }
  `}
`;

export const Loader = styled(MuiCircularProgress)`
  margin: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  padding-bottom: 2rem;

  & button {
    float: right;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 1rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrap = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledCard = styled((props) => <MuiCard {...props} />)`
  width: 100%;
  border-radius: 0;
  margin-bottom: 1.5rem;

  ${({ theme }) => css`
    box-shadow: 0 0 1.875rem 0 ${theme.colorMappings.shadow};
    background-color: ${theme.palette.common.white};
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 0 auto 1.5rem;
    }
  `};
`;

export const Hr = styled.hr`
  width: 2.8rem;
  height: 0.07rem;
  border: 0;
  background-color: ${({ theme }) => theme.colors.grey[600]};
  margin: 1rem 0;
`;

export const Text = styled((props) => <Typography {...props} />)`
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.grey[800]};
  font-size: 0.875rem;
  padding: 0;

  & span {
    font-weight: bold;
  }
`;

export const ArrowIconButton = styled((props) => <MuiIconButton {...props} />)`
  ${({ theme }) => css`
    padding: 0;
    margin: 0;
    color: ${theme.colors.black[500]};

    .MuiSvgIcon-root {
      color: ${theme.colors.black[500]};
    }
  `};
`;

export const Comment = styled((props) => <Typography {...props} />)<{
  displayAll?: boolean;
}>`
  margin: 0;
  max-width: 80%;
  color: ${({ theme }) => theme.colors.black[500]};
  ${({ displayAll }) =>
    !displayAll &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    `}
`;

export const OptionWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0 1rem 1rem 0;
  flex-wrap: wrap;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grey[500]};
    background-color: ${theme.colors.white[500]};
  `}
`;

export const CardContent = styled((props) => <MuiCardContent {...props} />)`
  padding: 2rem 2.5rem;
  padding-right: 1rem;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 1.375rem 1.5rem;
    }
  `};
`;

export const ViewType = styled((props) => <MuiTimelineDot {...props} />)<{
  type: string;
}>`
  min-width: 1.875rem;
  min-height: 1.875rem;
  border-radius: 100%;

  ${({ theme, type }) => css`
    ${type === 'neutral comment' &&
    css`
      background-color: ${theme.colors.orange[500]};
    `};

    ${type === 'objection' &&
    css`
      background-color: ${theme.colors.red[500]};
    `};

    ${type === 'support' &&
    css`
      background-color: ${theme.colors.green[500]};
    `};
  `}
`;

export const DateDistance = styled((props) => (
  <DateDistanceLocalised {...props} />
))`
  color: ${({ theme }) => theme.colors.grey[800]};
  font-size: 0.875rem;
`;

export const StyledButton = styled((props) => (
  <Button borderRadius="0.1rem" {...props} />
))``;

export const SeeMoreButton = styled((props) => (
  <Button borderRadius="0.1rem" {...props} />
))`
  background-color: transparent;
  ${({ theme }) => css`
    border: 0.15rem solid ${theme.colorMappings.primaryButtonBackground};
    color: ${theme.colorMappings.primaryButtonBackground};
  `}
`;
