import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 1.5rem 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75;
  opacity: 0.7;
  margin-bottom: 1rem;

  & p {
    margin-right: 2rem;
  }

  & p:first-child {
    margin-left: 8rem;
    margin-right: 45%;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;

export const Text = styled((props) => <Typography {...props} />)``;
