import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';
import { MicrosoftSSOIcon } from 'Atoms/Icons/MicrosoftSSO/MicrosoftSSO';
import { EyeClosedBoldIcon } from 'Atoms/Icons/EyeBold/EyeClosedBold';
import { EyeOpenBoldIcon } from 'Atoms/Icons/EyeBold/EyeOpenBold';
import { Alert } from 'Atoms';
import {
  Wrapper,
  Title,
  Form,
  Header,
  Description,
  Label,
  ForgotPassword,
  Buttons,
  SignInButton,
  TextField,
  SSOButton,
  AlertTitle,
} from '../Shared.styles';

export const ProjectTeamLogin: React.FC<{
  username: string;
  prefilledEmail: string;
}> = (props: { prefilledEmail: string; username: string }) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { username, prefilledEmail } = props;

  React.useEffect(() => {
    setEmail(prefilledEmail);
  }, [prefilledEmail]);

  return (
    <Wrapper data-testid="ProjectTeamLogin-Wrapper" size="small">
      {(emailError || passwordError) && (
        <Alert fullWidth type="error">
          <AlertTitle>{t('Sorry!')}</AlertTitle>
          {t(
            'Email or password is incorrect. Please try again or reset your password.'
          )}
        </Alert>
      )}

      <Header size="small">
        <Title size="small">
          {username ? t('Welcome Back, ') + username + '!' : t('Welcome Back!')}
        </Title>
      </Header>
      <Description>
        {t(
          'Fill in your password to log in or use an integration to link your account.'
        )}
      </Description>
      <Form>
        <Label>{t('Email')}</Label>
        <TextField
          placeholder={t('Email')}
          label={'Email'}
          value={email}
          handleChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value);
          }}
          type="email"
        />

        <Label>{t('Password')}</Label>
        <TextField
          placeholder={t('Password')}
          label={'Password'}
          value={password}
          handleChange={(e) => {
            setPasswordError(false);
            setPassword(e.target.value);
          }}
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((current) => !current)}
                >
                  {showPassword ? <EyeOpenBoldIcon /> : <EyeClosedBoldIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Form>
      <ForgotPassword href={'reset-password'}>
        {t('Forgot your password?')}
      </ForgotPassword>
      <Buttons>
        <SignInButton data-ghostinspectorid="ProjectTeamLogin-Button">
          {t('Login')}
        </SignInButton>
      </Buttons>
      <Buttons>
        <Description>or</Description>
        <SSOButton
          data-ghostinspectorid="ProjectTeamMicrosoftLogin-Button"
          onClick={() => ''}
        >
          <MicrosoftSSOIcon />
          {t('Sign in with {{microsoft}}', { microsoft: 'Microsoft' })}
        </SSOButton>
      </Buttons>
    </Wrapper>
  );
};
