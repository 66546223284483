import * as React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';

const useReturnIfAllCustomers = (): void => {
  const { selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );
  const router = useRouter();

  React.useEffect(() => {
    if (selectedCustomer.customer === 'All customers') {
      router.back();
    }
  }, [selectedCustomer]);
  return;
};

export { useReturnIfAllCustomers };
