/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import fetch from 'isomorphic-unfetch';
import { fetchPlanningAppPage } from 'Client/services/planningapp/fetchPlanningAppPage';
import { getInfoPoint } from 'Client/services/map';
import { PageTypes } from 'Shared/types/page';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary';
import { PlanningAppDatabases } from 'Shared/types/planningapp';
import { useMap } from 'Client/utils/hooks';
import {
  ImageContainer,
  ContentContainer,
  Title,
  Description,
  Button,
} from './CustomLayerPanel.styles';
import { SlidePanel } from '../MapSlidePanel';

export const CustomLayerPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const [proposal, setProposal] = React.useState<any>();
  const [title, setTitle] = React.useState<any>('');
  const [description, setDescription] = React.useState<any>('');
  const [imageUrl, setImageUrl] = React.useState<any>('');
  const [externalLink, setExternalLink] = React.useState('');

  const {
    state: { selectedCustomLayer },
    dispatch,
  } = useMap();

  const getPlanningAppPage = async ({ proposalId, imageUrl }) => {
    const { planningAppPage } = await fetchPlanningAppPage({
      id: proposalId,
      origin: PlanningAppDatabases.MONGODB,
    });
    setProposal({
      title: planningAppPage.content.address,
      descripion: planningAppPage.content.proposal,
      slug: planningAppPage.slug,
      image: {
        src: imageUrl,
      },
    });
    setTitle(planningAppPage.content.address);
    setDescription(planningAppPage.content.proposal);
    setImageUrl(imageUrl || '');
  };

  const getProposal = async ({ proposalId, lang = 'en-GB' }) => {
    try {
      const { proposal } = await fetch(
        `/api/proposals/byId?proposalId=${proposalId}&lang=${lang}`
      ).then((res) => res.json());

      setProposal(proposal);
      setTitle(proposal.title);
      setDescription(proposal.description);
      setImageUrl(proposal.image?.src);
    } catch (error) {
      return null;
    }
  };

  const getPointProperties = async () => {
    setExternalLink(selectedCustomLayer.externalLink);
    if (selectedCustomLayer.infoPointId) {
      const pointData = await getInfoPoint({
        lang: router.locale,
        infoPointId: selectedCustomLayer.infoPointId,
      });
      const infoPoint = pointData.infoPoint.content;
      if (infoPoint.proposalId && infoPoint.isPlanningApp) {
        return getPlanningAppPage(infoPoint);
      }
      if (infoPoint.proposalId) {
        return getProposal({
          proposalId: infoPoint.proposalId,
          lang: router.locale,
        });
      }
      setTitle(infoPoint.title);
      setDescription(infoPoint.description);
      setImageUrl(infoPoint.imageUrl);
    } else {
      if (selectedCustomLayer.proposalId && selectedCustomLayer.isPlanningApp) {
        return getPlanningAppPage(selectedCustomLayer);
      }
      if (selectedCustomLayer.proposalId) {
        return getProposal({
          proposalId: selectedCustomLayer.proposalId,
          lang: router.locale,
        });
      }
      setTitle(selectedCustomLayer.title);
      setDescription(selectedCustomLayer.description);
      setImageUrl(selectedCustomLayer.imageUrl);
    }
  };

  React.useEffect(() => {
    if (selectedCustomLayer) {
      getPointProperties();
    }
  }, [selectedCustomLayer]);

  const closePanel = () => {
    dispatch({
      type: 'SELECT_CUSTOM_LAYER',
      payload: null,
    });
  };

  const handleClick = async () => {
    dispatch({
      type: 'CLEAR_CUSTOM_LAYER_HIGHLIGHT',
    });
    dispatch({
      type: 'CLEAR_LEFT_PANEL',
    });
    dispatch({ type: 'CLEAR_CONTRIBUTION_FLOW' });

    closePanel();

    if (selectedCustomLayer?.isPlanningApp) {
      router.push(`/${router.locale}/planningapps/${proposal?.slug}`);
      return;
    }
    if (externalLink) {
      window.open(externalLink);
      return;
    } else {
      router.push(
        `/${router.locale}/${
          proposal?.type === PageTypes.MAP ? 'map' : 'proposals'
        }/${proposal?.slug}/`
      );
    }
  };

  const userAgent = navigator.userAgent.toLowerCase();

  const isMobile =
    /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );

  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  const isDesktop = !(isMobile || isTablet);

  if (
    window.visualViewport?.width &&
    window.visualViewport.width >= 1366 &&
    isDesktop
  )
    return null;

  const thumbnail = imageUrl
    ? getCachedCloudinaryUrl(imageUrl, IMAGE_CLOUDINARY_TYPES.CUSTOM, {
        width: Math.ceil(23.4 * 16),
      })
    : null;

  return (
    <SlidePanel onClose={closePanel}>
      {imageUrl && <ImageContainer image={thumbnail || imageUrl} />}

      <ContentContainer>
        <Title data-testid="customLayerPanelTitle">{title}</Title>
        <Description data-testid="customLayerPanelDescription">
          {description}
        </Description>
        {!proposal && selectedCustomLayer.proposalId && <h4>Loading...</h4>}
        {(proposal || externalLink) && (
          <Button data-testid="viewProposalButton" onClick={handleClick}>
            {externalLink ? t('Go to website') : t('View proposal')}
          </Button>
        )}
      </ContentContainer>
    </SlidePanel>
  );
};
