import * as React from 'react';
import styled from 'styled-components';
import { getContrast, opacify } from 'polished';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { StatusDot as StatusDotBase } from 'Atoms/StatusDot';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  position: relative;
`;

export const Wrapper = styled.div<{ color: string }>`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  width: 100%;
  height: 4.375rem;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0.5rem 0.1875rem ${({ color }) => opacify(-0.53, color)};
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin-right: 1.375rem;

  img {
    object-fit: contain;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  width: 40%;
  flex: 1;
  margin-left: 1.8125rem;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  min-width: 20%;
  max-width: 20%;
  margin-right: 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > p {
    margin: 0;
    display: inline;
  }
`;

export const PeopleCounter = styled.div`
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const StatusCell = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  width: 7%;
  min-width: 5.1875rem;
  margin-right: 0.9375rem;
  text-transform: capitalize;

  span {
    align-self: center !important;
    margin-right: 0.9rem;
    border: none;
    width: 0.3125rem;
    height: 0.3125rem;

    &:before {
      filter: brightness(1.5) contrast(1.5) saturate(3);
    }
  }
`;

export const StatusDot = styled(({ ...props }) => (
  <StatusDotBase {...props} />
))``;

export const PlusIconWrapper = styled.div<{ color: string }>`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme, color }) =>
      getContrast(theme.colors.black[500], color) <= 1
        ? theme.colors.white[500]
        : theme.colors.black[500]};
  }
`;

export const ArrowIconWrapper = styled.div<{ color: string }>`
  cursor: pointer;
  width: 9%;
  min-width: 5.9375rem;
  height: 100%;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowIcon = styled(({ isOpen, ...props }) => (
  <ArrowBackIcon {...props} />
))`
  color: ${({ theme }) => theme.colors.grey[700]};
  width: 1.6875rem;
  height: 1.6875rem;
  transform: ${({ isOpen }) =>
    isOpen
      ? 'rotate(90deg) translateX(0.3125rem)'
      : 'rotate(270deg) translateX(0.3125rem)'};
  margin-left: 12%;
`;
export const TableContainer = styled.div<{ color: string }>`
  width: 100%;
  margin-top: 2.75rem;
  border-left: 0.5625rem solid ${({ color }) => color};
  border-radius: 0.3125rem;
`;
export const Table = styled.div`
  width: 100%;
`;
