import { gql } from '@apollo/client';

export const GET_CONTRIBUTION_DETAILS_QUERY = gql`
  query GetContribution($contributionId: String) {
    getContribution(contributionId: $contributionId) {
      contributionId
      _id
      surveySlug
      projectId
      origin
      date
      type
      status
      answers
      answersPopulated {
        questionId
        questionVersionId
        value
        questionContent
      }
      schemaVersion
      userId
      demographicsId
      commentId
      feeling
      draft
      location
      consents
      response
      map
      referral
      agreements
      utmCampaign
      utmContent
      utmMedium
    }
  }
`;

export const GET_RESPONDENT_QUERY = gql`
  query GetRespondent($getRespondentId: String) {
    getRespondent(id: $getRespondentId) {
      _id
      user_id
      project
      status
      redirectRequired
      signedUpDate
      questionData
    }
  }
`;
