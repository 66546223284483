import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Category } from 'Molecules';
import { getThumbnail } from 'Client/services/cloudinary';
import {
  Wrapper,
  Text,
  Hr,
  CategoryWrapper,
  InfoWrapper,
  LabelText,
  InfoText,
  TextWrapper,
  CarouselWrapper,
  ImgWrapper,
  DescriptionContainer,
  StyledLayoutScroll,
} from './FactsSection.styles';
import { FactsSectionProps } from './types';

export const FactsSection: React.FC<FactsSectionProps> = ({
  pageContent,
  typedFiles,
  mainImage,
  setMainImage,
}) => {
  const { t } = useTranslation();
  const { images } = pageContent;

  const displayImages =
    images ??
    typedFiles?.Photo?.map((image) => ({
      url: getThumbnail(image.public_id),
      name: image.display_name || image.filename,
    }));

  const info = [
    {
      label: t('ADDRESS'),
      value: pageContent.address,
      fontSize: '0.8rem',
    },
    {
      label: t('SITE AREA'),
      value: pageContent.siteArea ? (
        <InfoText fontSize={'1.2rem'}>{pageContent.siteArea} ha</InfoText>
      ) : (
        'Not available'
      ),
    },
    {
      label: t('STOREYS'),
      value: pageContent.storeys || '-',
    },
    {
      label: t('HOMES'),
      value: pageContent.units || '-',
    },
    {
      label: t('FLOOR AREA'),
      value: pageContent.floorArea ? (
        <InfoText fontSize={'1.2rem'} label>
          {pageContent.floorArea} m<sup>2</sup>
          <span>(excl residential)</span>
        </InfoText>
      ) : (
        'Not available'
      ),
    },
  ];

  return (
    <Wrapper data-testid="PlanningApp-FactsSection">
      <CategoryWrapper>
        <Category category={pageContent.category} width={30} height={30} />
      </CategoryWrapper>

      <InfoWrapper>
        {info.map((fact) => (
          <TextWrapper key={fact.label}>
            <LabelText>{fact.label}</LabelText>
            {typeof fact.value === 'string' ? (
              <InfoText fontSize={fact?.fontSize || '1rem'}>
                {fact.value}
              </InfoText>
            ) : (
              fact.value
            )}
          </TextWrapper>
        ))}
      </InfoWrapper>

      <Hr />

      <DescriptionContainer>
        <Text>{pageContent.proposal}</Text>
        <StyledLayoutScroll>
          <CarouselWrapper>
            <Carousel>
              {displayImages?.map((img) => (
                <ImgWrapper
                  key={img.url}
                  onClick={() =>
                    setMainImage({
                      url: img.url,
                      name: img.name,
                    })
                  }
                  active={mainImage.url === img.url}
                >
                  <img src={img.url} alt={img.name} />
                </ImgWrapper>
              ))}
            </Carousel>
          </CarouselWrapper>
        </StyledLayoutScroll>
      </DescriptionContainer>
    </Wrapper>
  );
};
