import * as React from 'react';
import styled, { css } from 'styled-components';
import { LayoutScroll } from 'Client/components/organisms';

export const Wrapper = styled.div`
  max-width: 100%;
  padding: 0.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 62.5rem;
      padding: 0;
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 8.5rem;
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      height: 8.5rem;
    }
  `}
`;

export const Hr = styled.hr`
  width: 100%;
  margin-top: 1.25rem;
  height: 0.19rem;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border: 0;
  border-radius: 0.3rem;
  z-index: 0;
`;

export const TimelineWrapper = styled.div`
  min-width: 55rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-top: 1rem;
`;

export const StatusWrapper = styled.div`
  width: 25%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: start;

  & span {
    align-self: center;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      height: 6.875rem;
      justify-content: space-between;
      text-align: center;
    }
  `}
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black[500]};
  font-size: 1.3rem;
  font-weight: 700;
  width: 12.5rem;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `}
`;

export const Text = styled.p<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colors.black[500]};
  margin: 0;
  ${({ bold }) =>
    bold &&
    css`
      margin-top: 0.5rem;
      font-weight: 700;
    `}
`;

export const StyledLayoutScroll = styled(
  ({ children, trackStyles, thumbStyles, scrollbarStyles }) => (
    <LayoutScroll
      trackStyles={trackStyles}
      thumbStyles={thumbStyles}
      scrollbarStyles={scrollbarStyles}
    >
      {children}
    </LayoutScroll>
  )
).attrs(({ theme }) => ({
  trackStyles: css`
    height: 0.8125rem !important;
    width: 16rem !important;
    border-radius: 0.5rem !important;
    margin-left: calc(50% - 8rem) !important;
    background-color: ${theme.colors.grey[400]} !important;
    bottom: -2rem !important;
  `,
  thumbStyles: css`
    border-radius: 0.5rem !important;
    background-color: ${theme.colorMappings.primaryButtonBackground} !important;
  `,
  scrollbarStyles: css`
    & .ScrollbarsCustom-Content {
      margin: 0 !important;
    }
  `,
}))`
  display: none;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: initial;
    }
  `}
`;
