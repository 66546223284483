import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Resizable } from 'react-resizable';
import BaseCloseIcon from '@material-ui/icons/Close';

export const Screen = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      background-color: ${({ theme }) => theme.colors.white[500]};
      width: 100%;
      position: fixed;
      height: 50vh;
      bottom: 0;
      z-index: 1300; // bigger than masthead
    }
  `};
`;

export const Content = styled(({ hasFooter, ...props }) => <div {...props} />)`
  ${({ hasFooter, theme }) => css`
    padding: 0;

    ${theme.breakpoints.down('sm')} {
      padding-bottom: ${hasFooter ? '8rem' : '4rem'};
    }
  `};
`;

export const fadeInUp = (isOpen: boolean) => keyframes`
  from {
    bottom: ${isOpen ? '-5rem' : '3.5rem'};
  }
  to {
    bottom: ${isOpen ? '3.5rem' : '-80%'};
  }
`;

export const fadeInLeftToRight = (isOpen: boolean) => keyframes`
  from {
    left: ${isOpen ? '-23.375rem' : '0'};
  }
  to {
    left: ${isOpen ? '0' : '-23rem'};
  }
`;

export const PanelContainer = styled(
  ({ height, isContributionPanel, ...props }) => (
    <Resizable
      height={height}
      isContributionPanel={isContributionPanel}
      {...props}
    />
  )
)`
  display: flex;
  flex-direction: column;
  width: 23.5rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  height: calc(
    100vh -
      ${({ theme, errorBannerHeight, stageBannerHeight }) =>
        `${
          Number(theme.proposals.masthead.height.replace('rem', '')) +
          errorBannerHeight +
          stageBannerHeight
        }rem`}
  );
  overflow-y: auto;
  position: absolute;
  height: calc(
    100vh -
      ${({ theme, isEmbed, errorBannerHeight, stageBannerHeight }) =>
        isEmbed
          ? '0px'
          : `${
              Number(theme.proposals.masthead.height.replace('rem', '')) +
              errorBannerHeight +
              stageBannerHeight
            }rem`}
  );

  @media screen and (min-width: 769px) and (max-width: 1366px) {
    /* Styles specific to viewport width between 769px and 1100px (Tablets) */
    top: 6.25rem;
    width: 100%;
    position: fixed;
    height: 100vh;
    bottom: 0;
    z-index: 1300;
  }

  // height: 100vh;WS
  z-index: 100;
  box-shadow: 0.1875rem -0.1875rem 0.625rem -0.125rem
    ${({ theme }) => theme.colorMappings.shadow};

  & > .react-resizable-handle-se {
    /* not sure what that is */
    width: 85%;
    height: 4rem;
    position: absolute;
    top: 0;
  }

  ${({
    theme,
    isOpen,
    isContributionPanel,
    isEmbed,
    errorBannerHeight,
    stageBannerHeight,
  }) => css`
    ${theme.breakpoints.up('sm')} {
      ${theme.direction === 'rtl' ? 'right: 0' : 'left: -23.5rem'};
      animation: ${fadeInLeftToRight(isOpen)} 0.3s forwards;
      animation-fill-mode: both;
    }

    ${theme.breakpoints.down('sm')} {
      /* remove some extra height when on mobile (- 1.625rem) to show a little bit of the map behind the slider */
      height: calc(
        100vh -
          ${`${
            Number(theme.proposals.masthead.height.replace('rem', '')) +
            errorBannerHeight +
            stageBannerHeight
          }rem`} - 1.625rem
      );
      bottom: 0;
      height: 80vh;

      padding-bottom: 0;
      width: 100%;
      z-index: 999;
      border-radius: 0.625rem 0.625rem 0 0;
      ${isContributionPanel &&
      css`
        /* set the height as high as the actual content of the contribution */
        height: unset;

        /* with max enough to show a little bit of the map behind the slider */
        max-height: calc(
          100vh -
            ${isEmbed
              ? '0px'
              : `${
                  Number(theme.proposals.masthead.height.replace('rem', '')) +
                  errorBannerHeight +
                  stageBannerHeight
                }rem`} - 1.625rem
        );

        /* and min to match the <Screen /> component which I don't know what it does */
        min-height: 50vh;
      `}
    }
  `};
`;

export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white[500]};
  position: absolute;
  right: 1.25rem;
  top: 0.75rem;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon = styled(BaseCloseIcon)`
  z-index: 4;
  path {
    fill: ${({ theme }) => theme.colors.grey[800]};
  }

  ${({ theme }) => {
    return css`
      ${theme.breakpoints.down('sm')} {
        right: 1rem;
      }
    `;
  }}
`;

export const Footer = styled(({ ...props }) => <div {...props} />)`
  ${({ theme }) => css`
    background-color: ${({ theme }) => theme.colors.white[500]};
    position: sticky;
    bottom: 0;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      position: fixed;
      bottom: 3.5rem;
    }
  `};
`;
