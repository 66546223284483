import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { fetchFilteredContributions } from 'Client/services/contributions/fetchContributions';
import { scrollToSection } from 'Client/pages/planningapps/utils';
import { SocialButtons } from 'Atoms/Card/CommentCardOld';
import { PlanningAppStage } from 'Shared/types/planningapp';
import { useProject } from 'Client/utils/hooks';
import { ErrorStatusOrHelperText } from 'Atoms';
import {
  Wrapper,
  FlexWrapper,
  Wrap,
  Text,
  StyledCard,
  StyledButton,
  ButtonWrapper,
  CardHeader,
  CardInfo,
  CardContent,
  ViewType,
  DateDistance,
  OptionWrapper,
  Hr,
  Comment,
  ArrowIconButton,
  SeeMoreButton,
  Loader,
  HideCommentsWrapper,
} from './CommentsTab.styles';
import { capitalize, dotSeparator } from '../../utils';
import { CommentsTabProps } from './types';

export const CommentsTab: React.FC<CommentsTabProps> = ({
  initialComments,
  commentsCount,
  slug,
  isConsultationOpen,
  stage,
}) => {
  const [comments, setComments] = React.useState(initialComments);
  const [displayAll, setDisplayAll] = React.useState([] as string[]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lastId, setLastId] = React.useState(
    initialComments[initialComments.length - 1]?._id
  );
  const { t } = useTranslation();
  const project = useProject();

  if (project?.features?.glaPlanningApps && stage !== PlanningAppStage.stage3) {
    return (
      <HideCommentsWrapper>
        <ErrorStatusOrHelperText
          status={{
            type: 'info',
            message: (
              <Trans>
                You will be able to{' '}
                <a
                  href="https://www.london.gov.uk/programmes-strategies/planning/planning-applications-and-decisions/commenting-planning-application"
                  target="_blank"
                  rel="noreferrer"
                >
                  comment on applications
                </a>{' '}
                from when an application is valid until the final decision has
                been made.
              </Trans>
            ),
          }}
        />
      </HideCommentsWrapper>
    );
  }

  if (!comments?.length) {
    return (
      <HideCommentsWrapper>
        <ErrorStatusOrHelperText
          status={{
            type: 'info',
            message: (
              <Trans>
                You will be able to{' '}
                <a
                  href="https://www.london.gov.uk/programmes-strategies/planning/planning-applications-and-decisions/commenting-planning-application"
                  target="_blank"
                  rel="noreferrer"
                >
                  comment on applications
                </a>{' '}
                from when an application is valid until the final decision has
                been made.
              </Trans>
            ),
          }}
        />
      </HideCommentsWrapper>
    );
  }

  const handleSeeMore = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const contributions = await fetchFilteredContributions({
      by: 'planningapp',
      proposalSlug: slug,
      lang: 'en-GB',
      lastFilteredId: lastId,
    });

    if (contributions.length) {
      setComments([...comments, ...contributions]);
      setLastId(contributions[contributions.length - 1]?._id);
    }

    setIsLoading(false);
  };

  const handleDisplayAllComment = (id: string) => {
    if (displayAll.includes(id)) {
      const filteredComments = displayAll.filter((_id) => _id != id);
      setDisplayAll(filteredComments);
      return;
    }

    setDisplayAll([...displayAll, id]);
  };

  const handleLeaveAComment = () => {
    scrollToSection('comment-section');
  };

  return (
    <Wrapper data-testid="PlanningApp-CommentsTab">
      {isConsultationOpen && (
        <ButtonWrapper>
          <StyledButton
            data-testid="PlanningApp-CommentsTab-LeaveAComment"
            type="button"
            onClick={handleLeaveAComment}
          >
            Leave a comment
          </StyledButton>
        </ButtonWrapper>
      )}
      <div>
        {comments?.map((comment) => {
          const viewType = String(
            comment.answersPopulated?.find(
              (answer) => answer.questionContent?.name === 'viewType'
            )?.value
          );
          const materialConsiderations = comment.answersPopulated?.find(
            (answer) => answer.questionContent?.name === viewType
          )?.value as string[];
          const siteRelation = comment.answersPopulated?.find(
            (answer) => answer.questionContent?.name === 'siteRelation'
          )?.value as string[];
          const respondentName = comment?.demographics?.firstName;

          return (
            <StyledCard key={comment._id}>
              <CardContent>
                <CardHeader>
                  <FlexWrapper>
                    <ViewType type={viewType} />
                    <CardInfo>
                      <FlexWrapper>
                        <Text>
                          <span>
                            {respondentName ? capitalize(respondentName) : ''}
                            &nbsp; • &nbsp;
                          </span>

                          {siteRelation?.map((relation, i, siteRelation) => {
                            if (i !== siteRelation.length - 1)
                              return `${relation} ${dotSeparator()} `;
                            return `${relation}`;
                          })}
                        </Text>
                      </FlexWrapper>
                      <FlexWrapper>
                        <Text>
                          {capitalize(viewType)} {dotSeparator()}
                        </Text>
                        <DateDistance date={String(comment.date)} />
                      </FlexWrapper>
                    </CardInfo>
                  </FlexWrapper>
                  <SocialButtons commentId={comment._id} hideAgreeButton />
                </CardHeader>

                {viewType === 'neutral comment' ? (
                  <Hr />
                ) : (
                  <Wrap>
                    {materialConsiderations.map((consideration) => (
                      <OptionWrapper key={consideration}>
                        {consideration}
                      </OptionWrapper>
                    ))}
                  </Wrap>
                )}
                <Comment displayAll={displayAll.includes(comment._id)}>
                  {
                    comment.answersPopulated?.find(
                      (answer) => answer.questionContent?.name === 'comment'
                    ).value
                  }
                </Comment>

                <ArrowIconButton
                  onClick={() => handleDisplayAllComment(comment._id)}
                >
                  {displayAll.includes(comment._id) ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ArrowIconButton>
              </CardContent>
            </StyledCard>
          );
        })}
      </div>
      <ButtonWrapper>
        {isLoading && <Loader />}
        {comments.length < commentsCount && (
          <SeeMoreButton disabled={isLoading} onClick={handleSeeMore}>
            {t('See more')}
          </SeeMoreButton>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};
