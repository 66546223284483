import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2.5rem 2.0625rem 0 0;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  svg {
    height: 0.7rem;
    width: 0.7rem;
    opacity: 0.65;
  }
`;
