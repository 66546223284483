import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
    margin-right: 0.5rem;
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.black[500]};
  font-size: 0.875rem;

  & span {
    font-weight: 700;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding-top: 0.125rem !important;
      margin-left: 0.6875rem !important;
      font-size: 1rem;
    }
  `}
`;

export const Button = styled.a`
  cursor: pointer;
  margin: 0;
`;
