import styled, { css } from 'styled-components';
import MuiLink from '@material-ui/core/Link';
import MuiCard from '@material-ui/core/Card';
import { Typography, Container as BaseContainer } from 'Atoms';

export const NewsItemCard = styled(MuiCard)``;

export const Container = styled(BaseContainer)`
  ${({ theme }) => css`
    ${theme.direction === 'rtl'
      ? 'margin-right: 7.5rem;'
      : 'margin-left: 7.5rem;'}

    ${theme.breakpoints.down('md')} {
      margin-left: 4.5rem;
    }
    ${theme.breakpoints.up('sm')} {
      width: 60%;
    }
    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
      width: 100%;
    }
  `};
`;

export const Wrapper = styled.div<{ fromEmail?: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  ${({ theme, fromEmail }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: ${fromEmail ? 'column-reverse' : 'column'};
    }
  `};
`;

export const PageHeader = styled(Typography)`
  font-size: 2.5rem;
  padding-top: 3.75rem;
`;

export const Description = styled(Typography)`
  margin-bottom: 2rem;

  ${({ theme }) => css`
    color: ${theme.colorMappings.accessibleSecondaryText};
  `}
  padding-top: 0.875rem;
`;

export const Link = styled(MuiLink)`
  margin: 1rem 0;
  color: ${({ theme }) => theme.colorMappings.hyperlink};
`;

export const NewsSubscriptionWrapper = styled.div`
  margin-top: 6.2rem;
  padding: 4rem 7.5rem 0 0;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      padding: 3rem 2rem;
    }
    ${theme.breakpoints.up('sm')} {
      width: 40%;
    }
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  `};
`;
