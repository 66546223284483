import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding-right: 2.0625rem;
  display: flex;
`;

export const MainContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const Content = styled.div`
  margin-top: 1.5625rem;
`;
