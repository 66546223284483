import { gql } from '@apollo/client';

export const GET_VISITORS_CHART_QUERY = gql`
  query Query($visitorsLineChartDataInput: VisitorsParams) {
    getVisitorsLineChartData(
      VisitorsLineChartDataInput: $visitorsLineChartDataInput
    ) {
      categories
      series {
        name
        data {
          y
          name
        }
      }
    }
  }
`;
