import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { BottomPanelContainer } from './BottomPanel.styles';

export const ResendMessage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BottomPanelContainer>
      <Typography bold fontSize={'1.125rem'}>
        {t(`Great!`)}
      </Typography>
      <Typography fontSize={'1.125rem'}>
        {t(`A new email has been sent, please check your email.`)}
      </Typography>
    </BottomPanelContainer>
  );
};
