import * as React from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { RootState } from 'Client/redux-store';
import { Permissions } from 'Client/constants/permissions';
import {
  setMembersToAdd,
  setMemberToEdit,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { useUser } from 'Client/utils/hooks';
import { CloseIcon } from 'Atoms/Icons';
import { usePermissionsProjectCentre } from 'Client/utils/hooks/useUser';
import {
  Wrapper,
  ActionCell,
  ActionDot,
  ActionModal,
  ActionButton,
  ActionSaveButton,
  StyledSelect,
  CancelEditButton,
} from './TeamUserRow.styles';
import { TeamUserRowProps } from './types';
import { distanceString } from './utils/formatDistanceString';
import { actions } from './constants/actions';
import {
  RESEND_INVITE_MUTATION,
  DELETE_INVITE_MUTATION,
} from './TeamUserRow.gql';
import { CellWithHoverModal } from '../CellWithHoverModal/CellWithHoverModal';
import { getRolesOptions } from '../AddMembersModal/constants/roleOptions';

export const TeamUserRow: React.FC<TeamUserRowProps> = (
  props: TeamUserRowProps
) => {
  const {
    firstName,
    lastName,
    email,
    role,
    dateAdded,
    lastSeen,
    color,
    isLastChild,
    projectId,
    invite,
    status,
    adminCount,
  } = props;
  const { memberToEdit } = useSelector(
    (state: RootState) => state.projectCentre
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useUser();
  const { permissions } = usePermissionsProjectCentre();
  const [resendInviteMutation] = useMutation(RESEND_INVITE_MUTATION);
  const [deleteInviteMutation] = useMutation(DELETE_INVITE_MUTATION);

  const [openActions, setOpenActions] = React.useState<boolean>(false);
  const [editRole, setEditRole] = React.useState<string>(role);
  const handleClick = (e) => {
    if (e?.target !== e?.currentTarget) return;
    setOpenActions((previous) => !previous);
  };

  const resendInvite = async (inviteId: string, projectId: string) => {
    const response = await resendInviteMutation({
      variables: {
        inviteId,
        projectId,
      },
    });

    if (response) {
      router.reload();
    }
  };

  const deleteInvite = async (inviteId: string) => {
    const response = await deleteInviteMutation({
      variables: {
        inviteId,
      },
    });

    if (response) {
      router.reload();
    }
  };

  const getMutationFunction = (name: string) => {
    if (name === 'Resend') {
      return resendInvite;
    }

    if (name === 'Delete') {
      return deleteInvite;
    }

    return;
  };

  const actionsComponents = actions
    .map((a) => {
      if (a.inviteOnly && status !== 'expired') {
        return;
      }
      if (a.acceptedOnly && invite) {
        return;
      }
      if (
        a.permissionsCheck({
          user,
          project: { _id: projectId },
          isAdmin: ['Admin', 'Lead admin'].includes(role),
        })
      ) {
        return (
          <ActionButton
            key={a.name}
            onClick={(e) => {
              handleClick(e);
              a.action({
                dispatch,
                props,
                mutationFunction: getMutationFunction(a.name),
              });
            }}
          >
            {a.icon}
            <p>{a.name}</p>
          </ActionButton>
        );
      }

      return;
    })
    .filter(Boolean);

  const cancelEdit = () => {
    dispatch(
      setMemberToEdit({
        email: '',
        _id: '',
        projectId: '',
      })
    );
  };

  const handleEditSave = () => {
    if (!editRole) return;

    dispatch(
      setMemberToEdit({
        email: '',
        _id: '',
        projectId: '',
      })
    );

    dispatch(
      setMembersToAdd({
        firstName: [firstName],
        lastName: [lastName],
        email: [email],
        role: [editRole],
        selectedProjects: [projectId],
      })
    );

    return;
  };

  if (
    memberToEdit.email &&
    memberToEdit.email === email &&
    projectId === memberToEdit.projectId
  ) {
    return (
      <Wrapper
        invite={invite}
        status={status}
        data-testid="TeamUserRow-Edit-Wrapper"
      >
        <CancelEditButton onClick={() => cancelEdit()}>
          <CloseIcon />
        </CancelEditButton>
        <CellWithHoverModal text={firstName} sizeType={3} editMode />
        <CellWithHoverModal text={lastName} sizeType={3} editMode />
        <CellWithHoverModal text={email} sizeType={3} editMode />
        <StyledSelect
          options={getRolesOptions(
            role,
            adminCount,
            permissions.includes(Permissions.INVITE_STAKEHOLDERS)
          )}
          // check if only admin
          onChange={(e) => setEditRole(e.value)}
          sizeType={3}
          color={color}
          placeholder="Role"
          defaultValue={{ label: role, value: role }}
        />
        <CellWithHoverModal
          text={format(new Date(Number(dateAdded)), 'dd MMM yyyy')}
          sizeType={2}
          editMode
        />
        <CellWithHoverModal
          text={distanceString(lastSeen)}
          sizeType={2}
          editMode
        />
        <ActionCell isLastChild={isLastChild}>
          <ActionSaveButton color={color} onClick={() => handleEditSave()}>
            Save
          </ActionSaveButton>
        </ActionCell>
      </Wrapper>
    );
  }

  return (
    <Wrapper invite={invite} status={status} data-testid="TeamUserRow-Wrapper">
      <CellWithHoverModal
        text={`${firstName} ${
          !invite && status
            ? `(${status[0].toUpperCase()}${status.slice(1)})`
            : ''
        }`}
        sizeType={3}
      />
      <CellWithHoverModal text={lastName} sizeType={3} />
      <CellWithHoverModal text={email} sizeType={3} />
      <CellWithHoverModal text={role} sizeType={3} />
      <CellWithHoverModal
        text={format(new Date(Number(dateAdded)), 'dd MMM yyyy')}
        sizeType={2}
      />
      <CellWithHoverModal text={distanceString(lastSeen)} sizeType={2} />
      <ActionCell
        isLastChild={isLastChild}
        onClick={() => setOpenActions((previous) => !previous)}
      >
        {actionsComponents.length > 0 ? (
          <ActionDot color={color}>...</ActionDot>
        ) : (
          <span> - </span>
        )}
      </ActionCell>
      {actionsComponents.length > 0 && openActions && (
        <ActionModal onClick={(e) => handleClick(e)}>
          {actionsComponents}
        </ActionModal>
      )}
    </Wrapper>
  );
};
