import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey['A200']};
  border-radius: 0.3125rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }) => theme.colors.white[500]};
  position: relative;
  padding: 0.8125rem 0 0.4375rem;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin-left: 1.6875rem;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  display: flex;
  .react-select__control {
    width: 8.2rem;
    min-height: 2rem;
    border: none;
  }
  .react-select__value-container {
    min-height: 0 !important;
    padding: 0 0 0 0.2rem !important;
    border: none;
  }
  .react-select__single-value,
  .react-select__option {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator {
    color: black;
    transform: scale(0.8);
  }
`;

export const IconsContainer = styled.div`
  width: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  svg {
    cursor: pointer;
  }
`;
