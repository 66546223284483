import { Filters } from '../reduxReducers/filters/FilterState';

export const removePropFromFilters = (
  filters: Filters[],
  prop: string
): Filters[] => {
  return [...filters]
    .map((query) => {
      if (Object.keys(query).includes(prop)) {
        const toReturn = Object.keys(query).reduce((acc, key) => {
          if (key !== prop) {
            acc[key] = query[key];
          }
          return acc;
        }, {});
        return toReturn;
      }

      return query;
    })
    .filter((query) => !!Object.keys(query).length);
};
