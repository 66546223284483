import React from 'react';
import { EditIcon, TrashSimpleIcon } from 'Atoms/Icons';
import { Permissions } from 'Client/constants/permissions';
import {
  setMemberToEdit,
  setRemoveMemberModal,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { teamMemberPermissionsCheck } from 'Client/pages/project-centre/components/ProjectsTable/constants/actions';
import { PermissionsParams } from 'Client/pages/project-centre/types';
import { User } from 'Shared/types';
import { SendIcon } from '../TeamUserRow.styles';
import { ActionFunctionParams } from '../types';

const getSuperRole = (user: User) => {
  return ['technical-support', 'customer-success', 'sales'].includes(
    user.superRole
  );
};

const adminPermissionsCheck = (
  permissionsParams: PermissionsParams,
  permissionIfAdmin: string,
  permissionIfNotAdmin: string
) => {
  const { user, project, isAdmin } = permissionsParams;
  if (isAdmin) {
    return teamMemberPermissionsCheck(user, project, permissionIfAdmin);
  }

  return teamMemberPermissionsCheck(user, project, permissionIfNotAdmin);
};

export const actions = [
  {
    name: 'Edit',
    icon: <EditIcon />,
    acceptedOnly: true,
    permissionsCheck: (permissions: PermissionsParams) => {
      const { user } = permissions;

      if (
        getSuperRole(user) ||
        adminPermissionsCheck(
          permissions,
          Permissions.EDIT_ADMIN,
          Permissions.EDIT_TEAM_MEMBERS
        )
      ) {
        return true;
      }
    },
    action: ({ dispatch, props }: ActionFunctionParams) => {
      dispatch(
        setMemberToEdit({
          email: props.email,
          _id: '',
          projectId: props.projectId,
        })
      );
    },
  },
  {
    name: 'Delete',
    icon: <TrashSimpleIcon />,
    permissionsCheck: (permissions: PermissionsParams) => {
      const { user } = permissions;

      if (
        getSuperRole(user) ||
        adminPermissionsCheck(
          permissions,
          Permissions.DELETE_ADMIN,
          Permissions.DELETE_TEAM_MEMBERS
        )
      ) {
        return true;
      }
    },
    action: ({ dispatch, props, mutationFunction }: ActionFunctionParams) => {
      if (props.invite) {
        mutationFunction(props._id); //deleteInvite
        return;
      }
      dispatch(
        setRemoveMemberModal({
          active: true,
          memberEmail: props.email,
          projectId: props.projectId,
        })
      );
    },
  },
  {
    name: 'Resend',
    icon: <SendIcon />,
    inviteOnly: true,
    permissionsCheck: (permissions: PermissionsParams) => {
      const { user, project } = permissions;

      if (
        getSuperRole(user) ||
        teamMemberPermissionsCheck(
          user,
          project,
          Permissions.INVITE_TEAM_MEMBERS
        )
      ) {
        return true;
      }
    },
    action: ({ props, mutationFunction }: ActionFunctionParams) => {
      mutationFunction(props._id, props.projectId); //resendInvite
    },
  },
];
