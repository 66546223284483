import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import fetch from 'isomorphic-unfetch';
import { SortOptions } from 'Shared/types/dashboard';
import { makeRedirectDomain } from 'Client/utils/url';
import { useProject } from 'Client/utils/hooks';
import { TableWrapper, ActionBar } from '../components';
import { HiddenColumns } from '../components/Table';
import { useToggle } from './hooks';
import { getSelectedRowsCount } from '../contributions/utils';
import { currentBasicColumns } from './constants/constantColumns';
import { RowsData } from '../components/Table/types';
import { SEND_EMAIL } from './constants';

const GroupsPage: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation('customer');
  const { id: projectName } = useProject();
  const [isAllRowsCheckboxSelected, setIsAllRowsCheckboxSelected] = useToggle();

  const [baseTableHeaders] = React.useState([
    {
      Header: 'BASIC INFO',
      id: 'general',
      columns: currentBasicColumns,
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedRowsIds, setSelectedRowsIds] = React.useState<string[]>([]);
  const [flatRowsIds, setFlatRowsIds] = React.useState<string[]>([]);
  const [hiddenColumns, setHiddenColumns] = React.useState<HiddenColumns[]>([]);
  const [tableSort, setTableSort] = React.useState<SortOptions>(null);
  const [totalRows, setTotalRows] = React.useState<number>();
  const [rowsData, setRowsData] = React.useState<RowsData[]>([]);

  const onAllRowsCheckboxToggle = () => setIsAllRowsCheckboxSelected();
  const loadMoreData = (_lastId: string, lastIndex: number) => {
    console.log('load more data', _lastId, lastIndex);
  };

  React.useEffect(() => {
    const getInitialData = async () => {
      setLoading(true);
      const res = await fetch('/api/external/group/pageData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sortOptions: tableSort,
        }),
      });
      const { groups } = await res.json();
      setRowsData(groups);
      setTotalRows(groups.length);
      setLoading(false);
    };

    getInitialData();
  }, [tableSort]);

  const ActionBarButtons = [
    {
      label: SEND_EMAIL,
      dropdown: false,
      onClick: () => {
        const stringifiedGroupNames = selectedRowsIds.join(',');
        const redirectDomain = makeRedirectDomain(projectName);
        const url = `http://${redirectDomain}/v5/dashboard/communications/group-email?ref=contribution&groups=${stringifiedGroupNames}`;
        const encodedUri = encodeURI(url);
        router.push(encodedUri);
      },
    },
  ];

  return (
    <>
      <TableWrapper
        baseTableHeaders={baseTableHeaders}
        loading={loading}
        dataTotal={totalRows}
        filteredRows={totalRows /*while we don't have filters*/}
        rowsData={rowsData}
        loadMoreData={loadMoreData}
        tableSort={tableSort}
        setTableSort={setTableSort}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        setSelectedRowsIds={setSelectedRowsIds}
        setFlatRowsIds={setFlatRowsIds}
        onAllRowsCheckboxToggle={onAllRowsCheckboxToggle}
        isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
        dataName={t(`groups`)}
        tableType="groups"
      />
      {Boolean(selectedRowsIds.length) && (
        <ActionBar
          totalSelected={getSelectedRowsCount({
            isAllRowsCheckboxSelected,
            totalFilteredRows: totalRows,
            allRenderedRows: flatRowsIds,
            selectedRows: selectedRowsIds,
          })}
          buttons={ActionBarButtons}
        />
      )}
    </>
  );
};

export { GroupsPage };
