import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { ProposalPagesTemplate as Template } from 'Templates';
import { SeoMetaInfo } from 'Atoms';
import { StartPageProps, AllProposalStepsComponent } from '../proposals';

export const PreviewProposalPage: React.FC<StartPageProps> = ({
  proposalTitle,
  steps,
  projectName,
  projectStage,
  ...props
}: StartPageProps) => {
  return (
    <Template proposalTitle={proposalTitle} {...props}>
      <SeoMetaInfo
        projectStage={projectStage}
        projectName={projectName}
        page="proposal"
      />
      <DragDropContext onDragEnd={() => null}>
        <AllProposalStepsComponent steps={steps} />
      </DragDropContext>
    </Template>
  );
};
