import * as React from 'react';
import { useRouter } from 'next/router';
import { useMap } from 'Client/utils/hooks';
import { Icon, Wrapper } from './OpenCloseTag.styles';
import { setWelcomeToken } from '../utils';

const OpenCloseTag = (): JSX.Element => {
  const router = useRouter();
  const {
    dispatch,
    state: { infoPanelOpen, image3d, isContributionFlowStarted, welcomePanel },
  } = useMap();

  const open = welcomePanel || infoPanelOpen;
  const hide = image3d || isContributionFlowStarted;

  const handleClick = () => {
    if (welcomePanel) {
      setWelcomeToken(router.query.slug[0]);
      dispatch({ type: 'CLOSE_WELCOME_PANEL' });
      dispatch({
        type: 'OPEN_INFO_PANEL',
        payload: {
          infoPanelOpen: false,
        },
      });
    } else {
      dispatch({ type: 'TOGGLE_INFO_PANEL' });
    }
  };

  return (
    <Wrapper
      onClick={handleClick}
      isOpen={open}
      hide={hide}
      data-testid="info-panel-open-close-tag"
    >
      <Icon isOpen={open} />
    </Wrapper>
  );
};

export { OpenCloseTag };
