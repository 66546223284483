import { gql } from '@apollo/client';

export const GET_RESPONDENTS_QUERY = gql`
  query Query(
    $getRespondentsCountForProjectInput: String
    $getFilteredRespondentsCountForProjectInput: GetFilteredRespondentsCountForProjectInput
    $getDashboardRespondentsInput: GetDashboardRespondentsInput
    $getRespondentsTableColumnsInput: String!
    $getRespondentsFilterOptionsInput: String!
    $getNonSensitiveDemographicsQuestionsWithContentInput: GetNonSensitiveDemographicsQuestionsWithContentInput
  ) {
    getRespondentsCountForProject(
      GetRespondentsCountForProjectInput: $getRespondentsCountForProjectInput
    )
    getFilteredRespondentsCountForProject(
      GetFilteredRespondentsCountForProjectInput: $getFilteredRespondentsCountForProjectInput
    )
    getDashboardRespondents(
      GetDashboardRespondentsInput: $getDashboardRespondentsInput
    )
    getRespondentsTableColumns(
      getRespondentsTableColumnsInput: $getRespondentsTableColumnsInput
    ) {
      id
      Header
      columns {
        Header
        accessor
        questionContent
      }
    }
    getRespondentsFilterOptions(
      getRespondentsFilterOptionsInput: $getRespondentsFilterOptionsInput
    ) {
      property
      conditions
      rules
      questionContent
    }
    getNonSensitiveDemographicsQuestionsWithContent(
      GetNonSensitiveDemographicsQuestionsWithContentInput: $getNonSensitiveDemographicsQuestionsWithContentInput
    )
  }
`;

// this query is only for filters, and takes a long while
// to run, so it's separated from the main query
export const GET_RESPONDENTS_ANSWERS_QUERY = gql`
  query Query($getRespondentsAnswersInput: GetRespondentsAnswersInput) {
    getRespondentsAnswers(
      getRespondentsAnswersInput: $getRespondentsAnswersInput
    )
  }
`;

export const EXPORT_MUTATION = gql`
  mutation Mutation($dashboardCsvExportInput: DashboardCSVExportInput) {
    handleDashboardCSVExport(
      dashboardCSVExportInput: $dashboardCsvExportInput
    ) {
      message
      status
    }
  }
`;
