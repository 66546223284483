import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: fit-content;
  div,
  button,
  footer {
    margin: 1.6rem;
  }
`;

export const Header = styled.div`
  margin-bottom: 1rem;
  p {
    margin: 0;
  }
`;

export const ImageDisplayer = styled.div`
  width: 100%;
  height: 5.25rem;
  margin: 1.6rem 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    background: ${theme.colors.green[500]};
    p {
      font-size: 1.15rem;
      margin: 0;
      color: ${theme.colors.white[500]};
      font-weight: ${theme.fontWeights.bold};
    }
  `}
`;

export const EmailContent = styled.div`
  p {
    max-width: 100%;
    overflow-wrap: break-word;
    hyphens: auto;
  }
`;

export const EmailButton = styled.button`
  align-self: center;
  height: 2.85rem;
  padding: 0.7rem 1.3rem;
  width: fit-content;
  border: none;
  border-radius: 0.15rem;
  font-size: 1.125rem;
  cursor: pointer;
  font-weight: 700;
  ${({ theme }) => css`
    background: ${theme.colors.green[500]};
    color: ${theme.colors.white[500]};
  `}
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem !important;
  padding-top: 1.4rem;
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey['A200']}`};
  picture > img {
    opacity: 1 !important;
  }
`;
