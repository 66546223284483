import * as React from 'react';
import { AgreementsSVG, CommentsSVG } from './SVGs';
import {
  Wrapper,
  Section,
  TextWrapper,
  Number,
  Text,
} from './ContributionsBanner.styles';
import { ContributionsBannerProps } from '../../types';

export const ContributionsBanner: React.FC<ContributionsBannerProps> = ({
  agreementsCount,
  commentsCount,
}: ContributionsBannerProps) => {
  return (
    <Wrapper>
      <Section>
        <CommentsSVG />
        <TextWrapper>
          <Number>{commentsCount}</Number>
          <Text>Comments</Text>
        </TextWrapper>
      </Section>
      <Section>
        <AgreementsSVG />
        <TextWrapper>
          <Number>{agreementsCount}</Number>
          <Text>Agreements</Text>
        </TextWrapper>
      </Section>
    </Wrapper>
  );
};
