import { t } from 'i18next';

export const projectListSortOptions = [
  {
    value: { field: 'Created date', order: 'descending' },
    label: t('Created date (descending)'),
  },
  {
    value: { field: 'Created date', order: 'ascending' },
    label: t('Created date (ascending)'),
  },
  {
    value: { field: 'Alphabetically', order: 'descending' },
    label: t('Alphabetically (descending)'),
  },
  {
    value: { field: 'Alphabetically', order: 'ascending' },
    label: t('Alphabetically (ascending)'),
  },
  // { work in progress
  //   value: { field: 'Project status', order: 'ascending' },
  //   label: t('Project status (ascending)'),
  // },
  // {
  //   value: { field: 'Project status', order: 'descending' },
  //   label: t('Project status (descending)'),
  // },
  {
    value: { field: 'Launch date', order: 'descending' },
    label: t('Launch date (descending)'),
  },
  {
    value: { field: 'Launch date', order: 'ascending' },
    label: t('Launch date (ascending)'),
  },
  {
    value: { field: 'Close date', order: 'descending' },
    label: t('Close date (descending)'),
  },
  {
    value: { field: 'Close date', order: 'ascending' },
    label: t('Close date (ascending)'),
  },
  {
    value: { field: 'Visitors', order: 'descending' },
    label: t('Visitors (descending)'),
  },
  {
    value: { field: 'Visitors', order: 'ascending' },
    label: t('Visitors (ascending)'),
  },
  {
    value: { field: 'Comments', order: 'descending' },
    label: t('Comments (descending)'),
  },
  {
    value: { field: 'Comments', order: 'ascending' },
    label: t('Comments (ascending)'),
  },
  {
    value: { field: 'Agreements', order: 'descending' },
    label: t('Agreements (descending)'),
  },
  {
    value: { field: 'Agreements', order: 'ascending' },
    label: t('Agreements (ascending)'),
  },
];
