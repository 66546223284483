import * as React from 'react';
import { useSticky } from 'react-table-sticky';
import { useTable, useBlockLayout, useResizeColumns } from 'react-table';
import { captureException } from '@sentry/react';
import { LoadRing } from 'Molecules';
import { DataHeader } from 'Client/pages/dashboard/components';
import { mapHeadersToColumns } from '../../utils';
import { TableProps } from './types';
import {
  Wrapper,
  TableContainer,
  TableHeaders,
  TableRow,
} from './Table.styles';
import { TableCell } from './components';
import { TrafficHeader } from '../TrafficTable/components';

const Table: React.FC<TableProps> = ({
  tableMapHeadersParams,
  rowsData,
  tableSort,
  setTableSort,
  loading,
  loadMoreData,
  setFlatRowsIds,
  isWaypointInside,
  isTrafficTable,
}: TableProps) => {
  const [isFetching, setFetching] = React.useState<boolean>(false);

  const columns = React.useMemo(() => {
    const { basicColumns } = tableMapHeadersParams;
    return mapHeadersToColumns({ basicColumns });
  }, [tableMapHeadersParams]);
  const data = React.useMemo(() => rowsData, [rowsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    flatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useResizeColumns,
    useBlockLayout,
    useSticky
  );

  const handleLoadMore = async (lastIndex, lastItem) => {
    try {
      setFetching(true);
      const lastId = lastItem.original._id;
      await loadMoreData(lastId, lastIndex);
    } catch (error) {
      captureException(`Error in loadMoreData @ Table : ${error}`);
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    if (isWaypointInside) {
      handleLoadMore(rows.length, rows[rows.length - 1]);
    }
  }, [isWaypointInside]);

  React.useEffect(() => {
    const flatRowsIds = flatRows.map((row) => row.original._id);
    setFlatRowsIds(flatRowsIds);
  }, [flatRows]);

  if (loading) {
    return <LoadRing loadingMessage="Loading" />;
  }

  return (
    <>
      <Wrapper data-testid="Table">
        <TableContainer {...getTableProps()} className="table sticky">
          <TableHeaders className="header">
            <div className="tr" {...headerGroups[1].getHeaderGroupProps()}>
              {headerGroups[1].headers.map((column) => {
                if (isTrafficTable) {
                  return (
                    <TrafficHeader
                      key={`trth:1:${column.id}`}
                      column={column}
                    />
                  );
                }

                return (
                  <DataHeader
                    key={`trth:1:${column.id}`}
                    column={column}
                    tableSort={tableSort}
                    setTableSort={setTableSort}
                    selectedColumnId={''}
                    setSelectedColumnId={() => ''}
                  >
                    {''}
                  </DataHeader>
                );
              })}
            </div>
          </TableHeaders>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow
                  key={`tbrh:${index}`}
                  {...row.getRowProps()}
                  data-testid="dashboard-table-row"
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        type={'visitors'}
                        key={`td:${index}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </div>
        </TableContainer>
        {isFetching && <LoadRing loadingMessage="Loading" />}
      </Wrapper>
    </>
  );
};

export { Table };
