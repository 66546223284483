import styled from 'styled-components';

export const Cell = styled.div<{ sizeType: number; editMode?: boolean }>`
  font-weight: 400;
  flex: ${({ sizeType }) => sizeType};
  height: 2.25rem;
  padding: 0.4375rem 0 0 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ editMode, theme }) =>
    editMode && `background-color: ${theme.colors.grey[200]};`}

  & > p {
    margin: 0;
    display: inline;
  }
`;
