import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { BaseLink } from 'Atoms/Link/Link';
import { CloseIcon } from 'Atoms/Icons/Close/Close';
import { Button } from 'Client/components/molecules';
import { cpBrandName } from 'Client/constants/brand';
import FilterSummary from './FilterSummary';
import DrawerSection from './DrawerSection';
import AlertForm from './AlertForm';
import { CloseButton } from '../FiltersSection/styles';

type ActiveToggle = { active: boolean };

const DrawerBackdrop = styled.div<ActiveToggle>`
  background-color: #000b;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  display: flex;
  justify-content: flex-end;
  transition: all 400ms ease;

  ${({ active }) =>
    active
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition-delay: 200ms;
        `};

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const DrawerContainer = styled.section<ActiveToggle>`
  background-color: white;
  height: 100%;
  width: 40rem;
  border-radius: 0.3125rem;
  box-shadow: inset 0 0 0.1875rem #0005;
  transition: all 400ms ease 200ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  ${({ active }) =>
    active
      ? css`
          transform: translateX(0%);
          visibility: visible;
          opacity: 1;
        `
      : css`
          transform: translateX(100%);
          @media (max-width: 960px) {
            transform: translateY(100%);
          }

          visibility: hidden;
          opacity: 0;
          transition-delay: 0ms;
        `};

  @media (max-width: 960px) {
    height: auto;
    max-height: 95vh;
    width: 100%;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin: 1.25rem 0;
  & > h2 {
    flex-grow: 1;
    margin: 0;
    font-size: 1.875rem;
  }
`;

type CreateAlertProps = ActiveToggle & {
  onClose: () => void;
};

export const CreateAlertDrawer: React.FC<CreateAlertProps> = ({
  active,
  onClose,
}) => {
  const { t } = useTranslation();
  const [successEmail, setSuccessEmail] = React.useState<string>();
  const closeIfBackdropClick = (event: React.MouseEvent) => {
    event.target === event.currentTarget && onClose();
  };

  return (
    <DrawerBackdrop active={active} onClick={closeIfBackdropClick}>
      <DrawerContainer active={active}>
        {successEmail ? (
          <>
            <DrawerSection
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2rem',
              }}
            >
              <CloseButton isMobile={true} onClick={onClose}>
                <CloseIcon width={16} height={16} color="#000" />
              </CloseButton>
            </DrawerSection>
            <DrawerSection style={{ textAlign: 'center' }}>
              <DrawerHeader style={{ justifyContent: 'center' }}>
                <img src="/static/illustrations/check-mark.png" height={30} />{' '}
                {t('Email alert created')}
              </DrawerHeader>
              <Trans>
                <p
                  style={{
                    maxWidth: '25rem',
                    margin: '3rem auto',
                    fontSize: '1.1rem',
                  }}
                >
                  {
                    "Great! Your email alert has been created. We'll send updates to"
                  }{' '}
                  <strong>{successEmail}</strong>
                </p>
              </Trans>
              <img src="/static/illustrations/girl-paper-plane.png" />
            </DrawerSection>
            <DrawerSection
              style={{
                borderTop: '#0002 1px solid',
                boxShadow: '#0002 0 0 1px 1px',
                textAlign: 'right',
                marginTop: '2rem',
              }}
            >
              <Button onClick={onClose}>{t('Close')}</Button>
            </DrawerSection>
          </>
        ) : (
          <>
            <DrawerSection>
              <DrawerHeader style={{ justifyContent: 'space-between' }}>
                <img src="/static/illustrations/paper-plane.svg" height={30} />{' '}
                <h2>{t('Create email alert')}</h2>
                <CloseButton isMobile={true} onClick={onClose}>
                  <CloseIcon width={16} height={16} color="#000" />
                </CloseButton>
              </DrawerHeader>
              <FilterSummary />
            </DrawerSection>
            <div>
              <AlertForm onSuccess={setSuccessEmail} />
              <DrawerSection>
                <Trans>
                  <p>
                    Manage your email alert list easily on your{' '}
                    <BaseLink
                      href="https://profile.commonplace.is"
                      target="_blank"
                      rel="noreferrer"
                      noPadding
                    >
                      {cpBrandName} profile
                    </BaseLink>
                    .
                  </p>
                </Trans>
              </DrawerSection>
            </div>
          </>
        )}
      </DrawerContainer>
    </DrawerBackdrop>
  );
};
