import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import { SortOptions } from 'Shared/types/dashboard';
import { TableWrapper } from '../TableWrapper';
import { currentBasicColumns } from './constantColumns';
import { GET_REFERRALS_TAB_QUERY } from './ReferralTab.gql';
import { VisitorsTabsProps } from '../types';

export const ReferralTab: React.FC<VisitorsTabsProps> = ({
  appliedFilters,
}: VisitorsTabsProps) => {
  const { id } = useProject();
  const [referrals, setReferrals] = React.useState([]);
  const [tableSort, setTableSort] = React.useState<SortOptions>(null);

  const { data, loading } = useQuery(GET_REFERRALS_TAB_QUERY, {
    variables: {
      referralsInput: {
        projectName: id,
        sortOptions: tableSort,
        dates: appliedFilters?.topFilters?.date
          ? appliedFilters.topFilters.date
          : { to: null, from: null },
      },
    },
  });

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setReferrals(data.getReferrals);
    }
  }, [data, tableSort]);

  return (
    <TableWrapper
      tableMapHeadersParams={{
        basicColumns: {
          header: '',
          id: 'general',
          columns: currentBasicColumns,
        },
      }}
      loading={loading}
      rowsData={referrals}
      tableSort={tableSort}
      setTableSort={setTableSort}
      loadMoreData={() => ''}
      setFlatRowsIds={() => ''}
    />
  );
};
