import styled from 'styled-components';
import { Checkbox as CheckboxBase } from 'Molecules';

export const Wrapper = styled.div`
  padding: 0.6rem 0;
`;

export const InputLabel = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 1.2rem;
  margin: 0;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const IconWrapper = styled.div`
  i {
    display: inline-block;
    display: flex;
    align-items: center;
  }
`;

export const CheckboxOptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: 100%;
  padding-right: 2rem;
  & > p {
    white-space: wrap;
    margin: 0;
    width: 70%;
  }
`;

export const Checkbox = styled(CheckboxBase)``;
