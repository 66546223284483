import * as React from 'react';
import { useRouter } from 'next/router';
import { makeRedirectDomain } from 'Client/utils/url';
import { TemplateCardProps } from './types';
import {
  Wrapper,
  ColorBar,
  Content,
  Image,
  Text,
  DescriptionTextWrapper,
  DescriptionSection,
  Title,
  Type,
  ButtonsWrapper,
  DescriptionText,
  ListTitle,
  ReadMoreButton,
  Arrow,
  CardHeader,
  PreviewButton,
  SelectButton,
  List,
  ListItem,
  TitleContainer,
} from './TemplateCard.styles';

export const TemplateCard: React.FC<TemplateCardProps> = ({
  props,
}: TemplateCardProps) => {
  const { content, subdomain } = props;
  const { title, category, description, list, image } = content;
  const router = useRouter();
  const { query } = router;
  const [readMore, setReadMore] = React.useState<boolean>(false);

  return (
    <Wrapper
      readMore={readMore}
      color={category.color}
      data-testid="TemplateCard-Wrapper"
    >
      <ColorBar color={category.color}></ColorBar>
      <Content>
        <Image>
          <img src={image.src} alt={image.alt} />
        </Image>
        <DescriptionSection>
          <CardHeader>
            <TitleContainer>
              <Title>{title}</Title>
              <Type color={category.color}>{category.name}</Type>
            </TitleContainer>
            <ButtonsWrapper>
              <PreviewButton
                target="blank"
                href={`http://${makeRedirectDomain(subdomain)}`}
              >
                Preview
              </PreviewButton>
              <SelectButton
                color={category.color}
                onClick={() =>
                  router.replace({
                    pathname: '/project-centre/create-project/step-1',
                    query: { ...query, template: subdomain },
                  })
                }
              >
                Select
              </SelectButton>
            </ButtonsWrapper>
          </CardHeader>
          <DescriptionTextWrapper>
            <DescriptionText>{description}</DescriptionText>
            <ListTitle>{list.title}</ListTitle>
            <List>
              {list.items.map((li, index) => {
                if (readMore || index < 3) {
                  return <ListItem key={index}>{li}</ListItem>;
                }
              })}
            </List>
            {list.items.length > 3 && (
              <ReadMoreButton
                color={category.color}
                readMore={readMore}
                onClick={() => setReadMore((previous) => !previous)}
              >
                <Text>{!readMore ? 'Read More' : 'Read Less'}</Text>
                <Arrow />
              </ReadMoreButton>
            )}
          </DescriptionTextWrapper>
        </DescriptionSection>
      </Content>
    </Wrapper>
  );
};
