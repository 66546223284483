import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'Client/pages/edit/components/Form/';
import { filterOpeningHours } from 'Client/services/map';
import { Spinner } from 'Client/pages/thanks/components/Stats.style';
import { ErrorStatusOrHelperText } from 'Atoms';
import { Checkbox } from '../../FiltersView.styles';
import { CustomPinProps } from '../../types';
import { weekDays } from './constants';
import {
  TimeInput,
  TimeWrapper,
  Wrapper,
  FilterButton,
  CheckBoxContainer,
} from './OpeningHoursFilter.styles';

export const OpeningHoursFilter = ({
  setPivotFilter,
  proposal,
}: Omit<CustomPinProps, 'pivotQuestion'>) => {
  const { t } = useTranslation();
  const [selectedDays, setSelectedDays] = React.useState(weekDays);
  const [opensBefore, setOpensBefore] = React.useState<number>();
  const [closesAfter, setClosesAfter] = React.useState<number>();
  const [errorStatus, setErrorStatus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const filterValues = (hour: string): number => {
    const hours = hour.split(':');
    const hoursNumber = Number(hours[0]) + Number(hours[1]) / 100;
    return hoursNumber;
  };

  const submitFilter = async () => {
    if (!opensBefore || !closesAfter || !selectedDays?.length) {
      setErrorStatus({
        type: 'error',
        message: t('Please fill in all fields'),
      });
      return;
    }
    setIsLoading(true);
    try {
      const { success, contributionsIds } = await filterOpeningHours({
        pageId: proposal.pageId,
        opensBefore,
        closesAfter,
        weekDays: selectedDays,
      });
      setIsLoading(false);

      if (!success) {
        setErrorStatus({
          type: 'error',
          message: t('Something went wrong, please try again later!'),
        });
        return;
      }
      setPivotFilter(
        contributionsIds?.filter((_, idx) => idx < 100),
        true
      ); // "true" determines that it's an array of contributionIds
    } catch (error) {
      setIsLoading(false);
      setErrorStatus({
        type: 'error',
        message: t('Something went wrong, please try again later!'),
      });
    }
  };

  React.useEffect(() => {
    if (opensBefore && closesAfter && selectedDays?.length) {
      setErrorStatus(null);
    }
  }, [selectedDays, opensBefore, closesAfter]);

  return (
    <div>
      <Wrapper>
        <CheckBoxContainer>
          {weekDays.map((key) => (
            <Checkbox
              key={key}
              data-testid="PivotTabCheckbox"
              label={key}
              checked={selectedDays?.includes(key)}
              onChange={() => {
                setSelectedDays((currentValue) =>
                  currentValue.includes(key)
                    ? currentValue.filter((day) => day !== key)
                    : [...currentValue, key]
                );
              }}
              status={{ show: false }}
            />
          ))}
        </CheckBoxContainer>

        <TimeWrapper>
          <Label htmlFor="opensAt" label={t('Opening time')} bold />
          <TimeInput
            type="time"
            id="opensAt"
            onChange={(event) => {
              const { value } = event.target;
              if (!value) return;
              setOpensBefore(filterValues(value));
            }}
          />
          <Label htmlFor="closesAfter" label={t('Closing time')} bold />
          <TimeInput
            type="time"
            id="closesAfter"
            onChange={(event) => {
              const { value } = event.target;
              if (!value) return;
              setClosesAfter(filterValues(value));
            }}
          />
        </TimeWrapper>
      </Wrapper>

      <FilterButton onClick={submitFilter} disabled={isLoading}>
        {t('Filter places')}
      </FilterButton>
      {isLoading && <Spinner />}
      {errorStatus && <ErrorStatusOrHelperText status={errorStatus} />}
    </div>
  );
};
