import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PageFeatures } from 'Shared/types/page';
import { Wrapper, Button } from './TabsNavigation.styles';
import { PanelTabIndex } from '../types';

type Props = {
  activeTab: PanelTabIndex;
  onClick: (index: PanelTabIndex) => void;
  proposalFeatureFlags: PageFeatures;
};

const TabsNavigation = (props: Props): JSX.Element => {
  const { activeTab, onClick, proposalFeatureFlags } = props;
  const { t } = useTranslation();

  const canShowLayersTab = !proposalFeatureFlags?.hideMapLayersTab;
  const canShowProjectInfoTab = !proposalFeatureFlags?.hideProjectInfoTab;
  const canShowFiltersTab = !proposalFeatureFlags?.hideMapFiltersTab;

  const handleClick = (index: PanelTabIndex) => () => onClick(index);

  return (
    <Wrapper>
      {canShowProjectInfoTab && (
        <Button
          selected={activeTab === PanelTabIndex.INFO_TAB}
          onClick={handleClick(PanelTabIndex.INFO_TAB)}
        >
          {t('Project info')}
        </Button>
      )}
      {canShowLayersTab && (
        <Button
          selected={activeTab === PanelTabIndex.LAYERS_TAB}
          onClick={handleClick(PanelTabIndex.LAYERS_TAB)}
        >
          {t('Layers')}
        </Button>
      )}
      {canShowFiltersTab && (
        <Button
          selected={activeTab === PanelTabIndex.FILTERS_TAB}
          onClick={handleClick(PanelTabIndex.FILTERS_TAB)}
        >
          {t('Filters')}
        </Button>
      )}
    </Wrapper>
  );
};

export { TabsNavigation };
