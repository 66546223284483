import Select from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  text-align: left;

  & > div:first-child,
  & > div:nth-child(2) {
    height: 2.9863rem;
    border-radius: ${({ placeholder }) =>
      placeholder === 'Select role' ? '0 5px 5px 0' : 0};
    border-color: black;
    font-size: 1rem;
  }
`;
