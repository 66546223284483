export const modalOptions = {
  projectName: {
    title: 'Project name',
    text: `Your project name will be the first thing visitors will see. It should be short and clear.
    Please do not include special characters and emojis.`,
  },
  projectUrl: {
    title: 'URL',
    text: `It should be short and clear and reflect your project name.
    Please do not include special characters and emojis.`,
  },
  location: {
    title: 'Location',
    text: `Here’s an easy way to find it for your city, ward or region: Go to Google Maps and right click on a point to show its latitude and longitude.`,
  },
  languages: {
    title: 'Language',
    text: `To ensure your project will engage the right population, you can have it in more than one language. Upon selecting additional languages, your Success manager will get in touch with you. `,
  },
  dates: {
    title: 'Estimated dates',
    text: `These dates will help structure your project communication plan.`,
  },
  default: {
    title: 'Tell us about your project',
    text: `We need some basic informations about your project before you get started with your commonplace.


    Don’t worry if you are not completely sure about them, this data here is not definitive and can be amended at any point until you launch it.`,
  },
};
