import * as React from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { useMutation } from '@apollo/client';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import { usePermissions, useProject, useUser } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import { ToolTip } from 'Client/components/molecules';
import { DateFilter } from 'Pages/dashboard/components';
import { HeaderProps } from '../../types';
import {
  Wrapper,
  ContentArea,
  Title,
  InfoSection,
  InfoWrapper,
  InfoLabel,
  InfoValue,
  FlexWrapper,
  ImportButton,
} from './Header.styles';
import { GENERATE_TOKEN_REQUEST } from './Header.gql';

export const Header: React.FC<HeaderProps> = ({
  appliedFilters,
}: HeaderProps) => {
  const {
    publicRuntimeConfig: { dataImporterUri },
  } = getConfig();
  const router = useRouter();
  const { name, _id: projectId, launchDate, endDate, features } = useProject();
  const { user } = useUser();
  const { can } = usePermissions();
  const [generateTokenRequest] = useMutation(GENERATE_TOKEN_REQUEST);
  const [token, setToken] = React.useState('');

  const mapProjectsDate = (projectDate: string) => {
    if (!projectDate) return '';
    const date = new Date(projectDate);
    if (!isValid(date)) return '';
    const formattedDate = format(date, 'dd MMMM yyyy');
    return formattedDate;
  };

  const headerInfo = [
    { label: 'LAUNCH DATE', value: mapProjectsDate(launchDate) },
    { label: 'CLOSE DATE', value: mapProjectsDate(endDate) },
  ];

  const renderImportButton = () => {
    if (features.importData) {
      if (can(Permissions.USE_IMPORT_DATA) && token) {
        return (
          <ImportButton
            onClick={() => {
              router.push(`${dataImporterUri}/?token=${token}`);
            }}
          >
            Import Data
          </ImportButton>
        );
      }

      return (
        <ToolTip
          hoverableElement={<ImportButton disabled>Import Data</ImportButton>}
        >
          This can only be done by Lead admins and admins
        </ToolTip>
      );
    }

    return null;
  };

  React.useEffect(() => {
    const generateToken = async () => {
      const { data } = await generateTokenRequest({
        variables: {
          generateDataImporterTokenInput: {
            projectId,
            projectName: name,
            user: {
              _id: user?._id,
              email: user?.email,
              status: user?.status,
              superRole: user?.superRole,
              language: user?.language,
              id: user?.id,
              role: {
                _id: user?.role?._id,
                name: user?.role?.name,
                displayName: user?.role?.displayName,
              },
            },
          },
        },
      });

      const token = data.generateDataImporterToken;

      setToken(token);
    };

    generateToken();
  }, [user, projectId]);

  return (
    <Wrapper data-testid="Header-Wrapper">
      <ContentArea>
        <Title>{name}</Title>
        <InfoSection>
          {headerInfo.map((item) => {
            if (!item.value) return null;
            return (
              <InfoWrapper key={item.label}>
                <InfoLabel>{item.label}</InfoLabel>
                <InfoValue>{item.value}</InfoValue>
              </InfoWrapper>
            );
          })}
        </InfoSection>
      </ContentArea>
      <FlexWrapper>
        {renderImportButton()}
        <DateFilter pageType="overview" appliedFilters={appliedFilters} />
      </FlexWrapper>
    </Wrapper>
  );
};
