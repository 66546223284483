import styled, { css } from 'styled-components';
import { TabBar } from 'Client/components/molecules/TabBar';

export const Wrapper = styled.div`
  margin-top: 5rem;
`;

export const NavBarWrapper = styled.div`
  width: 50%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      overflow-x: auto;
    }
  `}
`;

export const StyledBar = styled(TabBar).attrs({
  borderBottomHeight: 0.25,
})`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      overflow-x: auto;
    }
  `}
`;

export const Content = styled.div`
  margin-top: 2rem;
`;
