import {
  CommentCard,
  Section,
  SectionColumn,
  SentimentDot,
  SectionRow,
  MenuButton,
  MenuShareButton,
  MenuAgreeButton,
  ReadMoreText,
  SurveyBadge,
  PivotContainer,
  TooltipContent,
} from './CommentCard';

const combined = Object.assign(CommentCard, {
  Section,
  SectionColumn,
  SentimentDot,
  SectionRow,
  MenuButton,
  MenuShareButton,
  MenuAgreeButton,
  ReadMoreText,
  SurveyBadge,
  PivotContainer,
  TooltipContent,
});

export { combined as CommentCard };
