import React from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  Modal as ModalBase,
  TextArea as TextAreaBase,
} from 'Client/components/molecules';

export const Modal = styled(({ ...props }) => (
  <ModalBase width="31rem" height="27rem" {...props} />
))`
  padding: 5.75rem 2.5rem !important;

  hr {
    width: 100%;
    position: absolute;
    top: 21%;
    left: 0px;
    border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  }

  p {
    text-align: left;
    font-size: 1.125rem;
  }
`;

export const TextArea = styled(({ ...props }) => <TextAreaBase {...props} />)`
  width: 26rem !important;
  min-width: 20rem !important;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5625rem 0 2rem 0;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey[500]};
`;

export const DeleteButton = styled(Button).attrs({
  colorMapping: 'CommonplaceBrand',
})`
  border-radius: 0.1875rem;
  color: ${({ theme }) => theme.colors.white[500]};
`;

export const CancelButton = styled(Button)`
  border: none;
  margin-right: 1.1875rem;
  cursor: pointer;
  ${({ theme }) =>
    css`
      color: ${theme.colors.grey[900]};
      background: ${theme.colors.white[500]};

      &:hover {
        color: ${theme.colors.black[500]};
        background-color: ${theme.colors.white[500]};
        border: 1px solid ${theme.colors.black[500]};
      }
    `}
`;
