import { gql } from '@apollo/client';

export const GET_PROJECT_QUERY = gql`
  query Query($projectInput: ProjectInput) {
    getProject(ProjectInput: $projectInput) {
      id
      name
      _id
    }
  }
`;

export const GET_PROJECT_BY_NAME_QUERY = gql`
  query Query($name: String) {
    getProjectByName(name: $name) {
      _id
      id
      name
    }
  }
`;

export const CREATE_PROJECT_MUTATION = gql`
  mutation Mutation($cloneProjectInput: CloneProjectInput) {
    cloneAcornProject(CloneProjectInput: $cloneProjectInput) {
      _id
      id
      name
      latitude
      longitude
      locale
      launchDate
      endDate
    }
  }
`;
