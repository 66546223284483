import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  MixpanelEventTypes,
  useAnalytics,
  useUser,
  useMap,
} from 'Client/utils/hooks';
import { ContributionCard } from 'Client/pages/contributions/components/ContributionCard';
import { CloseIcon } from 'Atoms/Icons';
import { getUserAgreements } from 'Client/services/map';
import { SlidePanel } from '../MapSlidePanel';
import {
  CustomCloseButton,
  PanelBody,
  PanelHeader,
} from './ContributionPanel.styles';

export const ContributionPanel: React.FC = () => {
  const { t } = useTranslation();
  const {
    state: { selectedContribution, userAgreements },
    dispatch,
  } = useMap();
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const { user } = useUser();

  React.useEffect(() => {
    trackEvent(MixpanelEventTypes.VIEW_COMMENT, {
      path: router.asPath,
      contributionId: selectedContribution._id,
      panel: 'side',
    });
  }, [selectedContribution]);

  const userAgreementId = React.useMemo(() => {
    if (!user) return undefined;

    return userAgreements.find(
      (agr) =>
        agr.commentId === selectedContribution._id && agr.userId === user._id
    )?._id;
  }, [userAgreements, user, selectedContribution._id]);

  const closePanel = () => {
    dispatch({
      type: 'SELECT_CONTRIBUTION',
      payload: null,
    });
  };

  const refetch = async () => {
    await getUserAgreements().then((agreements) => {
      dispatch({
        type: 'SET_USER_AGREEMENTS',
        payload: { userAgreements: agreements },
      });
    });
  };

  if (!selectedContribution) return null;

  return (
    <SlidePanel isContributionPanel>
      <PanelHeader>
        <h3>{t('Selected contribution')}</h3>
        <CustomCloseButton onClick={closePanel}>
          <CloseIcon />
        </CustomCloseButton>
      </PanelHeader>
      {selectedContribution.deleted ? (
        <PanelBody data-contribution-id={selectedContribution?._id}>
          <p>{t('This comment has been deleted.')}</p>
        </PanelBody>
      ) : (
        <ContributionCard
          contributionId={selectedContribution?._id}
          contributionDate={selectedContribution.date}
          userAgreementId={userAgreementId}
          answers={selectedContribution.answersPopulated}
          isOwnComment={false}
          isMapComment={true}
          pageId={selectedContribution.pageId}
          response={selectedContribution.response}
          contrProposalSlug={selectedContribution.surveySlug}
          refetchUserAgreements={refetch}
          card={false}
          isSurvey={selectedContribution.survey}
        />
      )}
    </SlidePanel>
  );
};
