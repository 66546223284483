import * as React from 'react';
import Head from 'next/head';
import { Waypoint } from 'react-waypoint';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'Client/constants/roles';
import { fetchFilteredContributions } from 'Client/services/contributions/fetchContributions';
import { SeoMetaInfo } from 'Atoms';
import { HubPagesTemplate as Template } from 'Templates';
import { useProject, useRoles, useUser } from 'Client/utils/hooks';
import { theme } from 'Client/components/theme';
import { cpBrandName } from 'Client/constants/brand';
import { RoundCommentIcon } from 'Atoms/Icons/Comment';
import { ContributionPageProps } from './types';
import { Banner, CommentsByUser, MemoizedContributionList } from './components';
import {
  HeadingContainer,
  Info,
  ListContainer,
  Loader,
  LoadingPanel,
  SeeAllContributionsButtonContainer,
  SeeOtherResponsesButton,
  StyledButton,
} from './ContributionsPage.styles';
import { GET_USER_AGREEMENTS_QUERY } from './ContributionsPage.gql';
import { BoldInfoIcon } from '../../components/atoms/Icons/Info/BoldInfo';

export const ContributionsPage: React.FC<ContributionPageProps> = ({
  contributions: initialLoadContributions,
  userId,
  proposalCard,
  contributionIdParam,
  proposalSlug,
  projectName,
  proposalType,
  ...props
}: ContributionPageProps) => {
  const project = useProject();
  const { user } = useUser();
  const { query, push } = useRouter();
  const { isRole } = useRoles();
  const { t } = useTranslation();
  const [lastFilteredId, setLastFilteredId] = React.useState('');
  const [contributions, setContributions] = React.useState(
    initialLoadContributions
  );
  React.useEffect(() => {
    // we need to reinitialise the contributions when switching language
    // and the new question labels are fetched
    setContributions(initialLoadContributions);
  }, [initialLoadContributions]);
  const isFetching = React.useRef(false);
  const [isLoading, setLoading] = React.useState(false);
  const [filters] = React.useState({
    positive: false,
    mostlyPositive: false,
    neutral: false,
    mostlyNegative: false,
    negative: false,
  });
  const firstUpdate = React.useRef(true);
  const [userAgreements, setUserAgreements] = React.useState([]);
  const canReply = isRole([
    USER_ROLES.TECHNICAL_SUPPORT,
    USER_ROLES.ADMIN,
    USER_ROLES.COMMUNICATIONS_MANAGER,
    USER_ROLES.SALES,
    USER_ROLES.LEAD_ADMIN,
    USER_ROLES.CUSTOMER_SUCCESS,
  ]);

  const handleRedirectToProposal = () => {
    if (proposalType === 'map') {
      push(`/map/${proposalSlug}`);
    } else {
      push(`/proposals/${proposalSlug}/step1`);
    }
  };

  let userAgreementsData;
  let refetchUserAgreements = () => null;
  if (user) {
    const { data, refetch } = useQuery(GET_USER_AGREEMENTS_QUERY, {
      variables: { params: { userId: user._id, projectId: project._id } },
    });
    userAgreementsData = data;
    refetchUserAgreements = refetch;
  }
  React.useEffect(() => {
    if (userAgreementsData && userAgreementsData.getUserAgreements) {
      setUserAgreements(userAgreementsData.getUserAgreements);
    }
  }, [userAgreementsData]);
  // const filtersRef = React.useRef({}); // this was probably used due to a mismatch between init state and updated state, and
  // can pbb be completely removed if we use redux (or else). Keeping it commented out for future ref until implemented.

  const lastId =
    initialLoadContributions && initialLoadContributions.length > 0
      ? React.useRef(
          initialLoadContributions[initialLoadContributions.length - 1]._id
        )
      : { current: '' };

  // React.useEffect(() => {
  //   filtersRef.current = filters;
  // }, [filters]);

  const handleLoadMore = () => {
    if (!isFetching.current) {
      isFetching.current = true;
      // loadMoreContributions(filtersRef.current);
      loadMoreContributions(filters);
    }
  };

  React.useEffect(() => {
    const { scrollTo } = query;
    if (typeof window !== 'undefined' && scrollTo) {
      const commentCard = document.getElementById(`${scrollTo}`);
      if (commentCard) {
        const scrollTop = commentCard.offsetTop - 56;
        window.scrollBy({ top: scrollTop });
      }
    }
  }, [query, query.scrollTo]);

  const loadMoreContributions = (filters) => {
    try {
      setLoading(true);
      const sentiments = Object.entries(filters)
        .map(([k, v]) => {
          if (!v) return undefined;
          if (k === 'negative') return '0';
          if (k === 'mostlyNegative') return '25';
          if (k === 'neutral') return '50';
          if (k === 'mostlyPositive') return '75';
          if (k === 'positive') return '100';
        })
        .filter((x) => !!x || x === '0');
      if (lastId.current) {
        setLastFilteredId(lastId.current);
        fetchFilteredContributions({
          sentiments,
          lastFilteredId: lastId.current || lastFilteredId,
          by: proposalSlug ? 'proposal' : userId ? 'user' : 'all',
          userId,
          proposalSlug,
        }).then((res) => {
          setTimeout(() => {
            setContributions((c) => [...c, ...res]);
            isFetching.current = false;
            lastId.current = res[res.length - 1]?._id;
            setLoading(false);
          }, 750);
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const filterContributions = (filters) => {
    const sentiments = Object.entries(filters)
      .map(([k, v]) => {
        if (!v) return undefined;
        if (k === 'negative') return '0';
        if (k === 'mostlyNegative') return '25';
        if (k === 'neutral') return '50';
        if (k === 'mostlyPositive') return '75';
        if (k === 'positive') return '100';
      })
      .filter((x) => !!x || x === '0');
    fetchFilteredContributions({
      sentiments,
      by: proposalSlug ? 'proposal' : userId ? 'user' : 'all',
      userId,
      proposalSlug,
    }).then((res) => {
      setContributions(res);
      isFetching.current = false;
      lastId.current = res[res.length - 1]?._id;
    });
  };

  React.useEffect(() => {
    if (!firstUpdate.current) {
      filterContributions(filters);
    }
    firstUpdate.current = false;
  }, [filters]);
  const [lockedWaypoint, setLockedWaypoint] = React.useState(
    !!contributionIdParam
  );

  return (
    <Template {...props} showGamingBanner={true}>
      <>
        {userId || contributionIdParam ? (
          <Head>
            <meta name="robots" content="noindex" />
          </Head>
        ) : proposalSlug ? (
          <SeoMetaInfo
            projectStage={project.stage}
            projectName={project.name}
            proposalCardDescription={proposalCard?.description}
            page="contributionsSlug"
          />
        ) : (
          <SeoMetaInfo
            projectStage={project.stage}
            projectName={project.name}
            projectOgDescription={project?.openGraph?.description}
            page="contributionsAll"
          />
        )}
        <HeadingContainer data-testid="ContributionsPage-HeadingContainer">
          {contributionIdParam ? null : userId ? (
            <CommentsByUser userId={userId} />
          ) : (
            <Banner
              project={{ ...project, name: projectName }}
              proposal={proposalCard && { ...proposalCard, slug: proposalSlug }}
            />
          )}
          <Info>
            <BoldInfoIcon color={theme.colors.grey[900]} />
            <a
              href="https://www.commonplace.is/about/who-what-how#how-to-comment-react"
              target="_blank"
              rel="noreferrer"
            >
              {t(
                'Learn more about how commenting and reacting works on {{cpBrandName}}',
                { cpBrandName }
              )}
            </a>
          </Info>

          {proposalSlug && (
            <StyledButton
              onClick={handleRedirectToProposal}
              startIcon={<RoundCommentIcon />}
              data-testid="ContributionsPage-AnswerProposalButton"
            >
              {t('Answer the proposal')}
            </StyledButton>
          )}
        </HeadingContainer>
        {/* {!isEmpty(groupedSentiments) && (
          <SentimentButtons
            filters={filters}
            setFilters={setFilters}
            isMobile={isMobile}
            groupedSentiments={groupedSentiments}
          />
        )} */}
        <ListContainer isProposalPage={!!proposalSlug}>
          <MemoizedContributionList
            contributions={contributions}
            userAgreements={userAgreements}
            user={user}
            refetchUserAgreements={refetchUserAgreements}
            canReply={canReply}
          />
          {typeof window !== 'undefined' && !lockedWaypoint && (
            <>
              <Waypoint
                scrollableAncestor={window}
                onEnter={handleLoadMore}
                onLeave={() => {}}
              />
            </>
          )}
          {contributionIdParam && lockedWaypoint && (
            <SeeAllContributionsButtonContainer>
              <SeeOtherResponsesButton onClick={() => setLockedWaypoint(false)}>
                {t('See other responses')}
              </SeeOtherResponsesButton>
            </SeeAllContributionsButtonContainer>
          )}
          {isLoading && (
            <LoadingPanel>
              <Loader style={{ height: '1.5625rem', width: '1.5625rem' }} />
              <div>Loading</div>
            </LoadingPanel>
          )}
        </ListContainer>
      </>
    </Template>
  );
};
