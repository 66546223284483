import MuiButton from '@material-ui/core/Button';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import styled, { css } from 'styled-components';

export const Loader = styled(MuiCircularProgress)`
  margin: 0.5rem;
`;

export const LoadingPanel = styled.div`
  background: linear-gradient(
    180deg,
    #f0f0f0 21.16%,
    rgba(255, 255, 255, 0) 100%
  );
  color: #666666;
  height: 9.5rem;
  max-width: 56rem;
  margin: 2rem auto;
  margin-bottom: 10vh;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & svg {
    color: #666666;
  }

  & > p {
    margin: 0;
  }
`;

export const HeadingContainer = styled.div`
  ${({ theme }) => css`
    margin: auto;
    padding: 3rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      padding: 2rem 0.5rem;
    }
  `}
`;

export const ListContainer = styled.div<{ isProposalPage?: boolean }>`
  ${({ theme, isProposalPage }) =>
    isProposalPage &&
    css`
      padding-top: 4rem;
      background-color: ${theme.colorMappings.controlCenterContentBackground};
    `}
`;

export const SentimentContainer = styled.div`
  max-width: 56rem;
  margin: 0.625rem auto;
`;

export const SentimentButton = styled(MuiButton)<{
  active: boolean;
  isMobile: boolean;
  sentiment:
    | 'sentimentPositive'
    | 'sentimentMostlyPositive'
    | 'sentimentNeutral'
    | 'sentimentMostlyNegative'
    | 'sentimentNegative';
}>`
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
  font-size: ${({ theme }) => theme.fontSizes.s};

  ${({ theme, sentiment }) => css`
    ${theme.breakpoints.down('sm')} {
      border: 0.0625rem solid ${theme.colorMappings[sentiment]};
      border-radius: 3.125rem;
      margin-left: 0.625rem;
      min-width: 3.4375rem;
    }
  `};

  .MuiButton-label::before {
    background-color: ${({ theme, sentiment }) =>
      theme.colorMappings[sentiment]};
    content: '';
    display: block;
    position: inherit;
    width: 0.9375rem;
    height: 0.9375rem;
    margin-right: 0.625rem;
    border-radius: 100%;
  }

  ${({ theme, sentiment, active, isMobile }) => {
    return (
      active &&
      isMobile &&
      css`
        background-color: ${theme.colorMappings[sentiment]};

        &:hover {
          background-color: ${theme.colorMappings[sentiment]};
        }

        .MuiButton-label::before {
          background-color: ${({ theme }) => theme.colors.white[500]};
          content: '';
          display: block;
          position: inherit;
          width: 0.9375rem;
          height: 0.9375rem;
          margin-right: 0.625rem;
          border-radius: 100%;
        }
      `
    );
  }};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  & > a {
    color: ${({ theme }) => theme.colors.grey[900]};
    text-decoration: underline;
  }
`;

export const StyledButton = styled(MuiButton)`
  ${({ theme }) => css`
    padding: 0.813rem 1.563rem; // 13px 25px
    border-radius: 0.313rem;
    border: none;
    font-weight: 600;
    font-size: 1.188rem; // 19px
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 1.563rem;
    background-color: ${theme.colors.green[500]};
    color: ${theme.colors.white[500]};

    transform: translateY(80%);

    &:hover {
      background-color: ${theme.colors.green[600]};
      cursor: pointer;
    }

    &:focus {
      outline: 2px solid ${theme.colors.green[900]};
    }
  `}
`;

export const SeeAllContributionsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  padding-bottom: 5rem;
`;

export const SeeOtherResponsesButton = styled(StyledButton)`
  transform: unset;
`;
