import styled from 'styled-components';

export const Wrapper = styled.div<{ position: number }>`
  width: 33%;
  display: flex;
  background: ${({ theme }) => theme.colors.white[500]};
  flex-direction: column;
  margin: 0.5rem 0 4rem 1.875rem;
  padding: 1.5625rem 1.875rem 1.25rem;
  position: fixed;
  top: ${({ position }) => (position !== 0 ? position : 300)}px;
  border-left: 0.3125rem solid
    ${({ theme }) => theme.colorMappings.controlCenterNavbarBackground};
`;

export const TextWrapper = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.4375rem;
`;

export const TitleWrapper = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.4375rem;
`;
