import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Typography } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from './FaqsPageItem.styles';
import { Faq } from '../types';

type FaqsPageItemProps = Omit<Faq, 'order'>;

export const FaqsPageItem: React.FC<FaqsPageItemProps> = (
  props: FaqsPageItemProps
) => {
  const { question, answer } = props;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [icon, setIcon] = React.useState<JSX.Element>(<AddIcon />);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    setIcon(
      isMobile ? <ExpandMoreIcon /> : expanded ? <RemoveIcon /> : <AddIcon />
    );
  }, [expanded, isMobile]);

  const handleClick = () => setExpanded(!expanded);

  return (
    <Box marginY={1} data-testid="faqs-page-faq-item">
      <Accordion expanded={expanded} onChange={handleClick}>
        <AccordionSummary expandIcon={icon}>
          <Typography variant="h3">{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HtmlDescription>{answer}</HtmlDescription>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
