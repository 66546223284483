import * as React from 'react';
import { Dot } from './TimelineStatusDot.styles';
import { StatusDotProps } from './types';

export const TimelineStatusDot: React.FC<StatusDotProps> = ({
  status,
  width = 1.75,
  height = 1.75,
  middle = 'blank',
}) => {
  return <Dot status={status} width={width} height={height} middle={middle} />;
};
