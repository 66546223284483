import { gql } from '@apollo/client';

export const GET_RESPONDENT_DETAILS_QUERY = gql`
  query Query($getRespondentInput: GaudiRespondentInput) {
    getGaudiRespondent(GetRespondentInput: $getRespondentInput) {
      _id
      agreements
      comments
      signedUpDate
      status
      project
      userConsents
      answersWithQuestions {
        questionValue
        questionCategory
      }
      contributions {
        answersPopulated {
          questionId
          questionVersionId
          value
          questionContent
        }
        date
        projectId
        surveySlug
        type
        response
        _id
        map
        agreedTo {
          _id
          surveySlug
          date
          type
          status
          answersPopulated {
            questionId
            questionVersionId
            value
            questionContent
          }
          response
          map
        }
      }
      averageSentiment
    }
  }
`;
