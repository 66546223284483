import styled from 'styled-components';
import { opacify } from 'polished';

export const Wrapper = styled.div`
  padding: 3.75rem 0 1.25rem 0;
  border-bottom: ${({ theme }) =>
    `1px solid ${opacify(-0.4, theme.colors.grey[600])}`};
  display: flex;
  justify-content: space-between;
`;

export const ContentArea = styled.div``;

export const Title = styled.h1`
  margin: 0 0 1.125rem;
  color: ${({ theme }) => theme.colorMappings.black};
  font-size: 2.5rem;
`;

export const InfoSection = styled.section`
  display: flex;
  gap: 6rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoLabel = styled.span`
  opacity: 0.5;
  color: ${({ theme }) => theme.colorMappings.black};
  font-size: 0.875rem;
`;

export const InfoValue = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
`;

export const ImportButton = styled.button`
  background-color: ${({ theme }) => theme.colors.green[500]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white[500]};
  border: none;
  font-size: 0.875rem;
  font-weight: 700;
  min-height: 40px;
  padding: 0.625rem 1.25rem;
  margin-top: 0.5rem;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colorMappings.primaryButtonFocus};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.green[600]};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.green[100]};
    border: none;
    color: ${({ theme }) => theme.colors.white[500]};
  }
`;
