import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { Container, SeoMetaInfo } from 'Atoms';
import { HubPagesTemplate as Template } from 'Templates';
import { useProject, useMap } from 'Client/utils/hooks';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { BannerRole, NavigationalMap } from 'Organisms';
import { CustomLayerPanel } from 'Client/components/molecules/CustomLayerPanel';
import { HtmlDescription } from 'Client/components/molecules';
import { ImageMapPro } from 'Client/components/molecules/ImageMapPro/ImageMapPro';
import { ImageComparison } from 'Client/components/organisms/ImageComparison';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../edit/constants';
import type {
  HubPageProps,
  HeroContent,
  TextContent,
  ProgrammeContent,
  NavigationalMapContent,
  ProposalsContent,
  ContactTeamContent,
  PlanningAppsContent,
  ImageMapProContent,
} from './types';
import {
  News,
  Milestones,
  Hero,
  Proposals,
  PlanningApps,
  ProjectTeam,
  Section,
  CustomHero,
  DynamicProposals,
  ProgrammeWrapper,
  ContactTeam,
} from './components';
import { MilestoneItemProps } from '../timeline/types';
import { ViewTabs } from '../planningapps/components/ViewTabs';
import { DynamicProposalsContent } from '../shared/tiles/types';
import { TwoColumnsVisualizer } from '../proposals/components/TwoColumns/TwoColumnsVisualizer';
import AccordionVisualizer from '../proposals/components/Accordion/AccordionVisualizer';
import { TwoColumns, AccordionContent } from '../proposals/types';

const HubPage: React.FC<HubPageProps> = ({
  proposals,
  planningApps,
  newsPosts,
  stakeholders,
  userHasSubscribed,
  navbarItems,
  ...props
}: HubPageProps) => {
  const { milestonesPage, hubPage } = useSelector(
    (state: RootState) => state.pages
  );
  const project = useProject();
  const { i18n } = useTranslation();
  const {
    state: { selectedCustomLayer },
  } = useMap();
  React.useEffect(() => {
    // needed to trigger a rerender when the lang toggle changes
  }, [i18n.language]);

  const milestones = (milestonesPage.content?.milestones ||
    []) as Array<MilestoneItemProps>;

  if (hubPage?.content?.sections && hubPage?.content?.sections?.length > 0) {
    const { sections } = hubPage.content;
    const sectionHero = sections.find((section) => section.type === 'hero');
    const sectionPlanningApps = sections.find(
      (section) => section.type === 'planningapps'
    );
    const sectionProposals = sections.find(
      (section) => section.type === 'proposals'
    );
    const sectionsWithoutHero = sections.filter(
      (section) => section.type !== 'hero'
    );
    const programmeSection = sections?.find(
      (section) => section.type === 'programme'
    ) as unknown as ProgrammeContent;
    const navigationalMapContent = sections.find(
      (section) =>
        section.type === EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP
    );
    const imageMapPro = sections.find(
      (section) => section.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO
    );
    const sectionHeroContent = sectionHero.content as HeroContent;

    const sectionComponentMap = {
      hero: (
        <Hero
          title={sectionHeroContent?.title}
          hasNews={project?.features?.news}
          hideSubscription={project?.features?.hideProjectNewsSubscription}
          description={sectionHeroContent?.description}
          learnMoreProps={{
            href: sectionHeroContent?.learnMore?.link?.url,
            text: sectionHeroContent?.learnMore?.link?.text,
          }}
          backgroundImage={sectionHeroContent?.backgroundImage}
          layout={sectionHeroContent.layout}
          userHasSubscribed={userHasSubscribed}
          {...props}
        />
      ),
      news: <News newsPosts={newsPosts} isHub />,
      proposals: (
        <Proposals
          title={(sectionProposals?.content as ProposalsContent)?.title}
          proposals={proposals}
        />
      ),
      planningapps: (sectionPlanningApps?.content as PlanningAppsContent)
        ?.tabs ? (
        <ViewTabs
          tabs={(sectionPlanningApps?.content as PlanningAppsContent)?.tabs}
          planningApps={planningApps}
          hubHeader
          hubTitle={
            (sectionPlanningApps?.content as PlanningAppsContent)?.title
          }
        />
      ) : (
        <PlanningApps planningApps={planningApps} />
      ),
      milestones: <Milestones milestones={milestones} />,
      team: <ProjectTeam stakeholders={stakeholders} />,
      programme: <ProgrammeWrapper content={programmeSection?.content} />,
      navigationalMap: (
        <NavigationalMap
          content={navigationalMapContent?.content as NavigationalMapContent}
        />
      ),
    };

    const mapSectionComponent = (type: string, index: number) => {
      if (type === 'programme') {
        const matchingSection = sections[
          index + 1 // this is done because the hero component is generally taken out
        ] as unknown as ProgrammeContent;
        return <ProgrammeWrapper content={matchingSection?.content} />;
      }
      if (type === EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS) {
        const matchingSection = sections[index + 1];
        const content =
          matchingSection.content as unknown as DynamicProposalsContent;
        return <DynamicProposals content={content} />;
      }
      if (type === EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM) {
        const matchingSection = sections[index + 1];
        return (
          <ContactTeam
            content={matchingSection.content as ContactTeamContent}
          />
        );
      }
      if (type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO) {
        return (
          <ImageMapPro content={imageMapPro?.content as ImageMapProContent} />
        );
      }
      if (type === EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS) {
        const matchingSection = sections[index + 1];
        const sectionContent = matchingSection?.content as TwoColumns;
        return (
          <TwoColumnsVisualizer
            left={{ content: sectionContent?.left.content ?? '' }}
            right={{ content: sectionContent?.right.content ?? '' }}
          />
        );
      }
      if (type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON) {
        const matchingSection = sections[index + 1];
        return (
          <ImageComparison imageComparisonSlider={matchingSection?.content} />
        );
      }
      if (type === EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION) {
        const matchingSection = sections[index + 1];
        return (
          <AccordionVisualizer
            accordionContent={matchingSection?.content as AccordionContent}
          />
        );
      }
      return sectionComponentMap[type];
    };

    return (
      <>
        {selectedCustomLayer && <CustomLayerPanel />}
        {project?.features?.bannerRole && <BannerRole />}
        <Template
          navbarItems={navbarItems}
          customHero={
            <CustomHero
              hasNews={project?.features?.news}
              hideSubscription={project?.features?.hideProjectNewsSubscription}
              heroContent={sectionHeroContent}
              userHasSubscribed={userHasSubscribed}
            />
          }
          {...props}
        >
          <SeoMetaInfo
            projectStage={project.stage}
            projectName={project.name}
            page="hub"
          />
          <FullWidthContainer>
            <>
              {sectionsWithoutHero.map((section, index) => {
                if (!section.active) return null;
                if (section.type === EDITABLE_CONTENT_BLOCK_TYPES.TEXT) {
                  const content = section.content as TextContent;
                  return (
                    <ContainerDefaults key={`section:${section.type}-${index}`}>
                      <HtmlDescription>{content.html}</HtmlDescription>
                    </ContainerDefaults>
                  );
                }
                return (
                  <Section
                    key={`section:${section.type}-${index}`}
                    type={section.type}
                    Component={mapSectionComponent(section.type, index)}
                  />
                );
              })}
            </>
            <EditModeButton />
          </FullWidthContainer>
        </Template>
      </>
    );
  }
  // failsafe for no existing hub page version for locale:
  return (
    <>
      {project?.features?.bannerRole && <BannerRole />}
      <SeoMetaInfo
        projectStage={project.stage}
        projectName={project.name}
        page="hub"
      />
      <Template
        navbarItems={navbarItems}
        customHero={
          <CustomHero
            hasNews={project?.features?.news}
            hideSubscription={project?.features?.hideProjectNewsSubscription}
            heroContent={{
              title: '',
              description: '',
            }}
            userHasSubscribed={userHasSubscribed}
          />
        }
        {...props}
      >
        <span />
      </Template>
    </>
  );
};

const FullWidthContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colorMappings.pageBackgroundColor};
  padding-bottom: 2rem;
`;

const ContainerDefaults = styled(Container)`
  font-size: 1rem;
  overflow: auto;
`;

export { HubPage };
