import styled from 'styled-components';
import { darken } from 'polished';
import { Typography } from 'Atoms';
import { Button } from 'Client/components/molecules';

export const PendingModerationWrapper = styled.div<{
  canSeeUnderModerationComments: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #dee3f1;
  padding: 14px 20px;
  border-radius: 3px;
  position: relative;
  ${({ canSeeUnderModerationComments }) =>
    canSeeUnderModerationComments && 'margin-bottom: 20px;'}
`;
export const ModerationRightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7%;
  height: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Subtitle = styled(Typography)`
  font-size: 0.875rem;
`;

export const ModeratedQuestions = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
`;

export const ModerationButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  gap: 0.5rem;
  height: 100%;
  width: 25%;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const ModerationButton = styled(Button)`
  width: 16rem;
  height: 3rem;
  border-radius: 5px;
  border: 0px;
  padding: 6px 15px;
  & > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    & > p {
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }

  &:hover {
    border: 0px;
    width: 16rem;
  }
  &:disabled {
    background-color: #bdbdbd;
    color: white;
  }
`;

export const ModerationApproveButton = styled(ModerationButton)`
  background-color: #00a863;
  &:hover {
    background-color: ${darken(0.1, '#00a863')};
  }
`;

export const ModerationRejectButton = styled(ModerationButton)`
  background-color: #c82a1d;
  &:hover {
    background-color: ${darken(0.1, '#c82a1d')};
  }
`;
