import * as React from 'react';
import { Permissions } from 'Client/constants/permissions';
import { CloseIcon } from 'Atoms/Icons';
import { setStatus } from './setStatus';
import { setUserValue } from './setUserValue';
import {
  Form,
  StyledTextField,
  RoleSelectWrapper,
  RemoveRowButton,
} from '../AddMembersModal.styles';
import { SelectWithStatus } from '../../SelectWithStatus';
import { rolesOptions } from '../constants';
import { removeUserValue } from './removeUserValue';
import { rolesOptionsStakeholder } from '../constants/roleOptions';

interface renderFormParams {
  usersCount: number;
  firstName: Array<string>;
  setFirstNames: React.Dispatch<React.SetStateAction<Array<string>>>;
  firstNameErrors: Array<boolean>;
  lastName: Array<string>;
  setLastNames: React.Dispatch<React.SetStateAction<Array<string>>>;
  lastNameErrors: Array<boolean>;
  email: Array<string>;
  setEmails: React.Dispatch<React.SetStateAction<Array<string>>>;
  emailErrors: Array<boolean>;
  setRoles: React.Dispatch<React.SetStateAction<Array<string>>>;
  role: Array<string>;
  roleErrors: Array<boolean>;
  cleanErrors: () => void;
  setUsersCount: React.Dispatch<React.SetStateAction<number>>;
  body: HTMLElement | null;
  permissions: Array<string>;
}

export const renderForm = ({
  usersCount,
  firstName,
  setFirstNames,
  firstNameErrors,
  lastName,
  setLastNames,
  lastNameErrors,
  email,
  setEmails,
  emailErrors,
  setRoles,
  role,
  roleErrors,
  cleanErrors,
  setUsersCount,
  body,
  permissions,
}: renderFormParams) => {
  const removeRow = (index) => {
    [setFirstNames, setLastNames, setEmails, setRoles].map((fn) => {
      return removeUserValue({ setFunction: fn, index, cleanErrors });
    });
    setUsersCount(usersCount - 1);
  };

  return [...Array(usersCount)].map((_c, index) => {
    return (
      <Form key={index}>
        <StyledTextField
          value={firstName[index]}
          handleChange={(e) =>
            setUserValue({
              setFunction: setFirstNames,
              index,
              value: e.target.value as string,
              cleanErrors,
            })
          }
          label="First Name"
          placeholder="First Name"
          status={setStatus({
            errorsArray: firstNameErrors,
            index,
            isEmail: false,
          })}
        />

        <StyledTextField
          value={lastName[index]}
          handleChange={(e) =>
            setUserValue({
              setFunction: setLastNames,
              index,
              value: e.target.value as string,
              cleanErrors,
            })
          }
          label="Last Name"
          placeholder="Last Name"
          status={setStatus({
            errorsArray: lastNameErrors,
            index,
            isEmail: false,
          })}
        />

        <StyledTextField
          type="email"
          value={email[index]}
          handleChange={(e) =>
            setUserValue({
              setFunction: setEmails,
              index,
              value: String(e.target.value).toLowerCase(),
              cleanErrors,
            })
          }
          label="Email"
          placeholder="Email"
          status={setStatus({ errorsArray: emailErrors, index, isEmail: true })}
        />
        <RoleSelectWrapper>
          <SelectWithStatus
            options={
              permissions.includes(Permissions.INVITE_STAKEHOLDERS)
                ? rolesOptionsStakeholder
                : rolesOptions
            }
            menuPortalTarget={body}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                zIndex: 99999,
              }),
              menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: '99999999',
              }),
            }}
            value={
              role[index]
                ? { value: role[index], label: role[index] }
                : { value: 'Select role', label: 'Select role' }
            }
            onChange={(e) =>
              setUserValue({
                setFunction: setRoles,
                index,
                value: e.value as string,
                cleanErrors,
              })
            }
            placeholder="Select role"
            status={setStatus({
              errorsArray: roleErrors,
              index,
              isEmail: false,
            })}
          />
        </RoleSelectWrapper>
        {usersCount > 1 && (
          <RemoveRowButton onClick={() => removeRow(index)}>
            <CloseIcon></CloseIcon>
          </RemoveRowButton>
        )}
      </Form>
    );
  });
};
