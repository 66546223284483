import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 4rem;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemLabel = styled.span`
  color: black;
  opacity: 0.5;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ItemValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ItemValue = styled.span<{ hasDot?: boolean; dotColor?: string }>`
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  ${({ hasDot, dotColor }) =>
    hasDot &&
    css`
      &:before {
        content: '';
        background: ${dotColor};
        height: 0.625rem;
        width: 0.625rem;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.375rem;
      }
    `}
`;

export const TimeValue = styled.span`
  margin-left: 0.2rem;
`;
