import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  min-height: calc(100% - 5rem - 3.75rem - 2.25rem);
  padding: 6.25rem 2.875rem 1.5625rem 3.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
`;
export const ImageWrapper = styled.div`
  margin: 3rem 0 4rem;
`;

export const TextDiv = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
export const Bold = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
`;
export const Text = styled.div`
  font-size: 1.125rem;
  line-height: 1.375rem;
`;

export const Button = styled.div`
  padding: 1rem;
  height: 2.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.green[500]};
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.white[500]};
  cursor: pointer;
`;
