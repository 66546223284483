import { theme } from 'Theme';
import { CardProps, GetCardsDataProp } from './types';

export const defaultProjectOverviewCount = () => ({
  visitorsCount: null,
  respondentsCount: null,
  contributionsCount: null,
  newsSubscribersCount: null,
});

export const getCardsData = ({
  visitorsCount,
  respondentsCount,
  contributionsCount,
  newsSubscribersCount,
}: GetCardsDataProp): Array<CardProps> => {
  return [
    {
      label: 'VISITORS',
      value: visitorsCount,
      url: '/v5/dashboard/visitors',
      color: theme.colorMappings.dashboardOverviewVisitorsCard,
    },
    {
      label: 'RESPONDENTS',
      value: respondentsCount,
      url: '/v5/dashboard/respondents',
      color: theme.colorMappings.dashboardOverviewRespondentsCard,
    },
    {
      label: 'CONTRIBUTIONS',
      value: contributionsCount,
      url: '/v5/dashboard/contributions',
      color: theme.colorMappings.dashboardOverviewContributionsCard,
    },
    {
      label: 'NEWS SUBSCRIBERS',
      value: newsSubscribersCount,
      url: null,
      color: theme.colorMappings.dashboardOverviewNewSubscribersCard,
    },
  ];
};
