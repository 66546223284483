import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';
import { HtmlDescription, Button } from 'Molecules';
import {
  ShareIcon as ShareIconBase,
  NewScreenIcon as NewScreenIconBase,
} from 'Icons';

export const BodyText = styled(({ ...props }) => (
  <HtmlDescription typographyProps={{ variant: 'body1' }} {...props} />
))`
  margin: 0.75rem 0;
`;

export const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Heading = styled(Typography)`
  font-size: 1.5rem;
`;

export const SubHeading = styled.div<{ commentsCountHidden: boolean }>`
  ${({ theme, commentsCountHidden }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.grey[300]};
    ${commentsCountHidden && 'height: 5px'};
  `}
`;

export const Meta = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.grey[700]};
    font-size: ${theme.texts.secondaryText.fontSize};
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
  `}
`;

export const NewScreenIcon = styled(({ ...props }) => (
  <NewScreenIconBase bold width={18} height={18} {...props} />
))``;

export const LearnMoreLink = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green['A700']};
    font-size: ${theme.fontSizes.s};
    font-weight: 700;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & svg {
      margin-top: 0.125rem;
      margin-right: 0.25rem;
    }
  `}
`;

export const ShareIcon = styled(({ ...props }) => (
  <ShareIconBase width={18} height={18} {...props} />
))``;

export const ShareAction = styled.div`
  ${({ theme }) => css`
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.375rem 0;
    padding-right: 0.125rem;
    border-radius: 50%;
    border: 1px solid ${theme.colors.grey[300]};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ViewMapButton = styled((props) => <Button {...props} />)`
  ${({ theme }) => css`
    background: ${theme.colors.green['A700']};
    border: unset;
    border-radius: 1.5rem;
    width: 8rem;
    color: ${theme.colors.white[500]};

    span {
      display: inline;
      min-width: 5rem;
      font-size: 0.875rem;
    }

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`;
