import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  position: sticky;
  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 0.75rem;
  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.grey[600]}`};
`;

export const Value = styled.div`
  display: inline;
  color: rgb(0 0 0 / 58%);
  font-weight: 500;
`;

export const Modal = styled.div<{ isLastHeader: boolean }>`
  background-color: ${({ theme }) => theme.colors.white[500]};
  width: 14rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  right: ${({ isLastHeader }) => (isLastHeader ? '2.5rem' : '-15%')};
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
`;
