import { NextRouter } from 'next/router';
import { addNewQueryParam } from 'Client/utils/url';
import { QuickFilter } from 'Client/components/molecules/TabbedMultiselectAutocomplete/types';
import { Filters } from 'Client/utils/reduxReducers/filters/FilterState';
import { LabelValueOptions as BaseLabelValueOptions } from 'Client/pages';
import { DateSelectedOptions } from 'Client/components/molecules/DateSelector/types';
import { MultiselectOption } from 'Shared/types/question';

interface LabelValueOptions extends BaseLabelValueOptions {
  checked?: boolean;
}

interface TabbedInputSelection {
  [key: string]: LabelValueOptions[];
}

export const getFiltersFromUrl = (
  router: NextRouter
): {
  urlFilters: Filters[];
  values: QuickFilter;
} => {
  const { planAppFilters } = router.query;
  if (!planAppFilters) return { urlFilters: [], values: {} };

  const decodedFilters = decodeURIComponent(planAppFilters as string);
  const planAppValues = decodedFilters.split('&planAppValues=').pop();
  const filters = decodedFilters.split('&planAppValues=').shift();

  const values = planAppValues
    ? (JSON.parse(planAppValues as string) as QuickFilter)
    : null;

  const urlFilters = filters
    ? (JSON.parse(filters as string) as Filters[])
    : null;

  return { urlFilters, values };
};

export const removeUrlFilters = () => {
  if (typeof window === 'undefined') return;
  window.history.pushState({}, document.title, window.location.pathname);
};

export const setFiltersOnUrl = (filters: Filters[], values: QuickFilter) => {
  if (typeof window === 'undefined') return;

  const mappedValues = Object.keys(values).reduce((acc, key) => {
    const value = values[key] || {};

    if (isString(value) || isDateSelectedOptions(value)) {
      acc[key] = value;
    } else if (isLabelValueOptions(value)) {
      acc[key] = value
        .filter((item) => item?.checked)
        .map((item) => item.value);
    } else if (isTabbedInputSelection(value)) {
      acc[key] = Object.keys(value).reduce((acc2, key2) => {
        acc2[key2] = value[key2].map((item) => item.value);
        return acc2;
      }, {});
    } else if (isMultiselectOption(value)) {
      const { icon, checked, ...rest } = value;
      acc[key] = rest;
    }
    return acc;
  }, {});

  const stringifiedValues = encodeURIComponent(
    `${JSON.stringify(filters)}&planAppValues=${JSON.stringify(mappedValues)}`
  );

  const filterParams = addNewQueryParam(
    window.location.search,
    'planAppFilters',
    stringifiedValues
  );
  window.history.pushState(null, null, filterParams);
};

const isString = (value: unknown): value is string => typeof value === 'string';

const isLabelValueOptions = (value: unknown): value is LabelValueOptions[] =>
  Array.isArray(value) && value.every((item) => item.label && item.value);

const isTabbedInputSelection = (
  value: unknown
): value is TabbedInputSelection =>
  Object.keys(value).every(
    (key) => isString(key) && isLabelValueOptions(value[key])
  );

const isDateSelectedOptions = (
  value: unknown
): value is DateSelectedOptions[] =>
  Array.isArray(value) &&
  value.every(
    (item) => (item.type === 'fixed' || item.type === 'custom') && item.field
  );

const isMultiselectOption = (value: unknown): value is MultiselectOption =>
  Object.keys(value).includes('label') && Object.keys(value).includes('value');
