import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { OnboardingPagesTemplate as PageTemplate } from 'Templates';
import { LoadRing } from 'Client/components/molecules';
import { useReturnIfAllCustomers } from 'Client/utils/hooks/useReturnIfAllCustomers';
import { setCurrentTab } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { RootState } from 'Client/redux-store';
import { TemplatesPageProps } from '../types';
import { Wrapper } from '../ProjectListPage.styles';
import { InfoRow, TopBar } from '../components';
import { Content } from './TemplatesPage.styles';
import { TemplateCard } from './components';
import { GET_TEMPLATES_QUERY } from './TemplatesPage.gql';
import { filterOptions, filterSortOptions } from './utils/filterSortOptions';

export const TemplatesPage: React.FC<TemplatesPageProps> = () => {
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  useReturnIfAllCustomers();
  const { selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const [templates, setTemplates] = React.useState([]);
  const [sortOptions, setSortOptions] = React.useState({
    sortDirection: '',
    columnId: '',
  });
  const { data, loading } = useQuery(GET_TEMPLATES_QUERY, {
    variables: {
      getTemplatesInput: { ...sortOptions, customer: selectedCustomer },
    },
  });
  const setInitialData = () => {
    const { getTemplates: gTemplates } = data;
    setTemplates(gTemplates);
  };

  React.useEffect(() => {
    dispatch(setCurrentTab('Projects'));
  }, []);

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setInitialData();
    }
  }, [data]);

  return (
    <PageTemplate>
      <Wrapper data-testid="TemplatesPage-Wrapper">
        <TopBar
          title="TEMPLATES"
          buttonsProps={[
            {
              type: 'createCustom',
              disabled: false,
              onClick: () =>
                router.replace({
                  pathname: '/project-centre/create-project/step-1',
                  query: { ...query },
                }),
              permissionsCheck: () => true,
            },
          ]}
        />
        <InfoRow
          showFilter
          filterOptions={filterOptions}
          defaultValue={{
            value: 'createdAt',
            label: 'Date',
          }}
          onFilterChange={(e) => setSortOptions(filterSortOptions(e))}
        />
        <Content>
          {loading ? (
            <LoadRing loadingMessage={'Loading'} />
          ) : (
            templates.map((template) => {
              return <TemplateCard props={template} key={template._id} />;
            })
          )}
        </Content>
      </Wrapper>
    </PageTemplate>
  );
};
