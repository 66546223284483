import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'Client/components/molecules/Button';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TimeInput = styled((props) => <input type="time" {...props} />)`
  height: 2rem;
  width: 8rem;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > input {
    margin-bottom: 2rem;
  }
`;

export const FilterButton = styled((props) => <Button {...props} />)`
  width: 100%;
  margin-top: 1.25rem;
`;

export const CheckBoxContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
