import * as React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'Client/components/molecules';
import { ShareIcon as ShareIconBase } from 'Icons';

export const Wrapper = styled.div`
  width: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;

  div {
    bottom: unset;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      flex-direction: column;
      margin: 0 1rem;
      align-items: initial;
    }
  `}
`;

export const StyledButton = styled(({ invertedColor, ...props }) => (
  <Button borderRadius="0.1rem" {...props} />
))`
  max-width: 40%;
  ${({ invertedColor, theme }) =>
    invertedColor &&
    css`
      background-color: transparent;
      border: 0.15rem solid ${theme.colorMappings.primaryButtonBackground};
      color: ${theme.colorMappings.primaryButtonBackground};
    `}

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 100%;
      margin: 0.5rem 0;
    }
  `}
`;

export const ShareIcon = styled(({ ...props }) => (
  <ShareIconBase width={18} height={18} {...props} />
))``;

export const ShareWrapper = styled.div`
  ${({ theme }) => css`
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.375rem 0;
    padding-right: 0.125rem;
    border-radius: 50%;
    border: 1px solid ${theme.colors.grey[300]};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    ${theme.breakpoints.down('xs')} {
      margin-top: 1rem;
      align-self: center;
    }
  `};
`;
