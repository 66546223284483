import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { setIsAddMemberModalOpen } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { usePermissionsProjectCentre } from 'Client/utils/hooks/useUser';
import {
  Modal,
  Wrapper,
  SubmitMembersButton,
  ProjectSelectionDiv,
  ImgDiv,
  Label,
  AddMore,
  FormDiv,
  ProjectSelectWrapper,
} from './AddMembersModal.styles';
import {
  getProjectListAsOptions,
  setStatus,
  renderForm,
  verifyFields,
  handleSetProjects,
} from './utils';
import { SelectWithStatus } from '../SelectWithStatus/SelectWithStatus';
import { OptionWithCheckbox } from '../OptionWithCheckbox';
import { AddMembersModalProps } from './types';
import { ButtonsDiv, CancelButton } from '../SharedModal.styles';
import { setProjectsPlaceholder } from './utils/setProjectsPlaceholder';

export const AddMembersModal: React.FC<AddMembersModalProps> = ({
  onSubmit,
}: AddMembersModalProps) => {
  const dispatch = useDispatch();
  const { permissions } = usePermissionsProjectCentre();
  const { isAddMemberModalOpen, selectedCustomer, customerProjectList } =
    useSelector((state: RootState) => state.projectCentre);
  const [firstName, setFirstNames] = React.useState<Array<string>>(['']);
  const [firstNameErrors, setFirstNameErrors] = React.useState<Array<boolean>>([
    false,
  ]);
  const [lastName, setLastNames] = React.useState<Array<string>>(['']);
  const [lastNameErrors, setLastNameErrors] = React.useState<Array<boolean>>([
    false,
  ]);
  const [email, setEmails] = React.useState<Array<string>>(['']);
  const [emailErrors, setEmailErrors] = React.useState<Array<boolean>>([false]);
  const [role, setRoles] = React.useState<Array<string>>(['']);
  const [roleErrors, setRoleErrors] = React.useState<Array<boolean>>([false]);
  const [selectedProjects, setSelectedProjects] = React.useState<Array<string>>(
    []
  );
  const [projectError, setProjectError] = React.useState<boolean>(false);
  const [usersCount, setUsersCount] = React.useState<number>(1);
  const [bodyState, setBody] = React.useState<HTMLElement | null>(null);

  const handleAddMore = () => {
    setUsersCount((previous) => (previous += 1));
    setFirstNames((previous) => [...previous, '']);
    setLastNames((previous) => [...previous, '']);
    setEmails((previous) => [...previous, '']);
    setRoles((previous) => [...previous, '']);
  };

  const onClose = () => {
    dispatch(setIsAddMemberModalOpen(false));
  };

  const handleSubmit = () => {
    const submitData = {
      firstName,
      lastName,
      email,
      role,
      selectedProjects,
    };
    if (
      verifyFields({
        firstName,
        setFirstNameErrors,
        lastName,
        setLastNameErrors,
        email,
        setEmailErrors,
        role,
        setRoleErrors,
        selectedProjects,
        setProjectError,
      })
    ) {
      onSubmit(submitData, selectedCustomer);
      cleanFields();
      onClose();
    }
  };

  const Option = OptionWithCheckbox({
    handleSetProjects,
    setSelectedProjects,
    selectedProjects,
    setProjectError,
  });

  const cleanErrors = () => {
    setEmailErrors([]);
    setFirstNameErrors([]);
    setLastNameErrors([]);
    setRoleErrors([]);
    setProjectError(false);
  };

  const cleanFields = () => {
    setEmails([]);
    setFirstNames([]);
    setLastNames([]);
    setRoles([]);
    setUsersCount(1);
    setSelectedProjects([]);
  };

  React.useEffect(() => {
    // prevent errors when running on the server
    setBody(document.body);
  }, []);

  return (
    <Modal open={isAddMemberModalOpen} onClose={onClose}>
      <Wrapper>
        <ProjectSelectionDiv>
          <ImgDiv>
            <img
              src="/static/illustrations/team_management_add_member.png"
              width="86"
              height="86"
            />
            <Label>Select the projects where new members will be added:</Label>
          </ImgDiv>
          <ProjectSelectWrapper>
            <SelectWithStatus
              options={getProjectListAsOptions({
                projects: customerProjectList,
              })}
              placeholder={setProjectsPlaceholder(selectedProjects)}
              components={{ Option }}
              closeMenuOnSelect={false}
              status={setStatus({
                errorsArray: [projectError],
                index: 0,
                isEmail: false,
              })}
            />
          </ProjectSelectWrapper>
        </ProjectSelectionDiv>
        <FormDiv>
          <Label>You are inviting:</Label>
          {renderForm({
            usersCount,
            firstName,
            setFirstNames,
            firstNameErrors,
            lastName,
            setLastNames,
            lastNameErrors,
            email,
            setEmails,
            emailErrors,
            setRoles,
            role,
            roleErrors,
            cleanErrors,
            setUsersCount,
            body: bodyState,
            permissions,
          })}
          <AddMore onClick={() => handleAddMore()}>+ Add more</AddMore>
        </FormDiv>
        <ButtonsDiv>
          <CancelButton
            onClick={() => {
              cleanFields();
              onClose();
            }}
          >
            Cancel
          </CancelButton>
          <SubmitMembersButton onClick={handleSubmit}>
            Send invite
          </SubmitMembersButton>
        </ButtonsDiv>
      </Wrapper>
    </Modal>
  );
};
