import { gql } from '@apollo/client';

export const GET_TRAFFIC_TABLE_QUERY = gql`
  query GetTrafficTableData($trafficInput: VisitorsParams) {
    getTrafficTableData(TrafficInput: $trafficInput) {
      categories
      visitors
      bounced
      informed
      contributed
      aware
    }
  }
`;
