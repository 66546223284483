import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { OnboardingPagesTemplate as Template } from 'Templates';
import {
  setCurrentPage,
  setCurrentTab,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { LoadRing } from 'Client/components/molecules';
import { RootState } from 'Client/redux-store';
import { Permissions } from 'Client/constants/permissions';
import { useUser } from 'Client/utils/hooks';
import { projectCentreSuperRoles } from 'Client/templates/OnboardingPages/components/OnboardingMasthead/constants/superRoles';
import { userHasProjectCentrePermission } from 'Client/utils/user';
import { decodeCustomerNameFromURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { ProjectListPageProps } from './types';
import {
  Wrapper,
  Content,
  HeaderCell,
  HeaderText,
  TableHeader,
} from './ProjectListPage.styles';
import { InfoRow, ProjectsTable, TopBar } from './components';
import { headers } from './components/ProjectsTable';
import { DeleteProjectModal } from './components/DeleteProjectModal/DeleteProjectModal';
import { projectListSortOptions } from './utils/projectListSortOptions';

export const ProjectListPage: React.FC<ProjectListPageProps> = () => {
  const router = useRouter();
  const { query } = router;
  const { user } = useUser();
  const dispatch = useDispatch();
  const { t } = useTranslation('customer');
  const { selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const [isWaypointInside, setIsWaypointInside] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [totalProjectsCount, setTotalProjectsCount] = React.useState<number>(0);
  const [noMoreProjects, setNoMoreProjects] = React.useState<boolean>(false);
  const [projectListSelectedSort, setProjectListSelectedSort] = React.useState({
    field: 'Alphabetically',
    order: 'ascending',
  });

  React.useEffect(() => {
    dispatch(setCurrentTab('Projects'));
    dispatch(setCurrentPage('Project list'));
  }, []);

  return (
    <Template>
      <Wrapper data-testid="ProjectListPage-Wrapper">
        <TopBar
          title={t('Projects')}
          subtitle={`${totalProjectsCount} projects`}
          buttonsProps={[
            {
              type: 'newProject',
              disabled: selectedCustomer.customer === 'All customers',
              onClick: () => {
                if (selectedCustomer.customer !== 'All customers') {
                  router.replace({
                    pathname: '/project-centre/templates',
                    query: {
                      customer: decodeCustomerNameFromURL(query.customer),
                    },
                  });
                }
              },
              modalText: t(
                'Select the customer you want to create a project for'
              ),
              permissionsCheck: () =>
                userHasProjectCentrePermission(
                  user,
                  Permissions.CREATE_PROJECT
                ) || projectCentreSuperRoles.includes(user.superRole),
            },
          ]}
        />
        <InfoRow
          showFilter
          filterOptions={projectListSortOptions}
          defaultValue={{
            value: { field: 'Created date', order: 'descending' },
            label: 'Created date (descending)',
          }}
          onFilterChange={(e) => setProjectListSelectedSort(e.value)}
        />
        <TableHeader>
          <span />
          {headers.map((h) => {
            return (
              <HeaderCell key={h.text}>
                {h.icon}
                <HeaderText>{h.text}</HeaderText>
              </HeaderCell>
            );
          })}
        </TableHeader>
        <Content>
          <ProjectsTable
            setTotalProjectsCount={setTotalProjectsCount}
            setLoading={setLoading}
            setIsWaypointInside={setIsWaypointInside}
            isWaypointInside={isWaypointInside}
            setNoMoreProjects={setNoMoreProjects}
            filters={{
              sort: {
                ...projectListSelectedSort,
              },
            }}
          />
        </Content>
        {!loading && !!totalProjectsCount && (
          <Waypoint
            onEnter={() => {
              if (totalProjectsCount < 100) {
                return;
              }

              if (noMoreProjects) {
                return;
              }
              setIsWaypointInside(true);
            }}
            onLeave={() => {
              setIsWaypointInside(false);
            }}
            bottomOffset="-100px"
          />
        )}
        {loading && !!totalProjectsCount && (
          <LoadRing loadingMessage={'Loading'} />
        )}
      </Wrapper>

      <DeleteProjectModal />
    </Template>
  );
};
