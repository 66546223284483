import { gql } from '@apollo/client';

export const GET_CUSTOMER_QUERY = gql`
  query GetCustomerFromProjectName($projectName: String) {
    getCustomerFromProjectName(projectName: $projectName) {
      _id
      name
      projects {
        _id
        id
        name
      }
    }
  }
`;

export const GET_CUSTOMER_FILTERS = gql`
  query GetProjectFiltersFromCustomer($customerId: String) {
    getProjectFiltersFromCustomer(customerId: $customerId)
  }
`;

export const INVITE_MEMBER = gql`
  mutation InviteTeamMember($inviteMemberInput: InviteMemberInput) {
    inviteTeamMember(InviteMemberInput: $inviteMemberInput) {
      _id
      email
      firstName
      lastName
    }
  }
`;
