import * as React from 'react';
import styled from 'styled-components';
import { opacify } from 'polished';
import { Button, TextField, Modal as ModalBase } from 'Molecules';

export const Modal = styled(({ ...props }) => (
  <ModalBase width="auto" height="auto" {...props} />
))``;

export const Wrapper = styled.div`
  min-height: 22.625rem;
  max-height: 32rem;
  min-width: 46rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

export const ProjectSelectionDiv = styled.div`
  background: ${({ theme }) => theme.colors.yellow[100]};
  padding: 0.875rem 2.3125rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ImgDiv = styled.div`
  display: flex;
  align-items: center;
  width: 55%;

  img {
    margin-right: 1.1875rem;
  }
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
`;

export const FormDiv = styled.div`
  max-width: 90vw;
  padding: 2.375rem 0.3125rem 4.375rem;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colorMappings.CommonplaceBrand}
    ${({ theme }) => theme.colors.grey[500]};

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grey[500]};
    padding: 1rem;
    margin: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
    border-radius: 0.625rem;
    border: 0;
  }
`;

export const Form = styled.div`
  display: flex;
  margin-bottom: 0.3125rem;

  & > div:first-child {
    .MuiOutlinedInput-root {
      border-radius: 5px 0 0 5px !important;
    }
  }
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddMore = styled.div`
  width: 6.25rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.0625rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green[500]};
`;

export const SubmitMembersButton = styled(Button)`
  border-radius: 0.1875rem;
  color: ${({ theme }) => theme.colors.white[500]};
`;

export const StyledTextField = styled(TextField)<{ type?: string }>`
  width: ${({ type }) => (type === 'email' ? '34%' : '22%')};
  min-width: 0 !important;

  .MuiOutlinedInput-root {
    border-radius: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const ProjectSelectWrapper = styled.div`
  width: 40%;
  min-width: 0;
`;

export const RoleSelectWrapper = styled.div`
  width: 25%;
  min-width: 0;
`;

export const OptionWrapper = styled.div<{ border: boolean }>`
  border-bottom: ${({ border, theme }) =>
    border && `0.0625rem dashed ${theme.colors.grey[500]}`};
  div {
    display: flex;
    align-items: center;
  }
  div:hover,
  div:focus,
  div:active {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.green[500]};
    background-color: ${({ theme }) => opacify(-0.95, theme.colors.green[500])};
  }
  input {
    min-height: 1.125rem;
    min-width: 1.125rem;
    max-width: 1.125rem;
    max-height: 1.125rem;
    margin: 0.6rem;
  }
  .disabled {
    &:hover {
      pointer-events: none;
    }
  }
  p {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const RemoveRowButton = styled.div`
  border: 0.0625rem solid ${({ theme }) => theme.colors.red[500]};
  color: ${({ theme }) => theme.colors.red[500]};
  border-radius: 0.3125rem;
  width: 3.125rem;
  margin-left: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
