import { CampaignRowTitle, DateCell } from '../Table/components';

const width = '150';

export const currentBasicColumns = [
  {
    Header: 'Subject',
    accessor: 'subject',
    Cell: CampaignRowTitle,
  },
  {
    Header: 'Type',
    accessor: 'emailType',
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: DateCell,
    width,
  },
  {
    Header: 'Sent to',
    accessor: 'sentTo',
    width,
  },
  {
    Header: 'Delivered',
    accessor: 'delivered',
    width,
  },
  {
    Header: 'Opened',
    accessor: 'opened',
    width,
  },
  {
    Header: 'Clicked',
    accessor: 'clicked',
    width,
  },
  {
    Header: 'Spam',
    accessor: 'spam',
    width,
  },
  {
    Header: 'Unsubscribed',
    accessor: 'unsubscribed',
    width,
  },
  {
    Header: 'Bounced',
    accessor: 'bounced',
    width,
  },
];
