import { MapHeadersToColumnsParams } from '../../contributions/types';

const headersGroupsSharedProps = {
  disableResizing: true,
};

export const mapHeadersToColumns = (
  params: MapHeadersToColumnsParams
): Array<Record<string, unknown>> => {
  const { basicColumns } = params;

  const startingColumns = [
    {
      ...headersGroupsSharedProps,
      id: basicColumns?.id,
      Header: basicColumns.header,
      columns: basicColumns.columns.length > 0 ? [...basicColumns.columns] : [],
    },
  ];

  return startingColumns.filter((c) => c !== null);
};
