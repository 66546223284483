import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SearchIcon } from 'Atoms/Icons';
import { LabelValueOptions } from 'Client/pages';
import { RootState } from 'Client/redux-store';
import { InputLabel } from 'Atoms';
import { FilterOptions } from 'Client/pages/map/types';
import {
  Wrapper,
  Content,
  Input,
  InputContainer,
  SearchInputIcon,
  OptionsContainer,
  OptionItem,
  OptionLabel,
  SectionsContainer,
  SectionItem,
  Tab,
  CheckboxInput,
} from './TabbedMultiselectAutocomplete.styles';
import { TabbedInputSelection } from './types';

interface TabbedMultiselectAutocompleteProps {
  sections: FilterOptions[];
  width?: string;
  height?: string;
  label?: string;
  placeholder?: string;
  value: TabbedInputSelection;
  name?: string;
  onChange: (filters: TabbedInputSelection) => void;
}

export const TabbedMultiselectAutocomplete: React.FC<
  TabbedMultiselectAutocompleteProps
> = ({
  sections,
  width = '100%',
  height = 'auto',
  label,
  placeholder,
  onChange,
  value,
  name,
  ...props
}: TabbedMultiselectAutocompleteProps) => {
  const { t } = useTranslation();
  const { filterValues } = useSelector((state: RootState) => state.filters);

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [showFilteredOptions, setShowFilteredOptions] =
    React.useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = React.useState<
    FilterOptions['options']
  >([]);
  const [position, setPosition] = React.useState(0);

  const selectedOptionsInitialState = sections.reduce(
    (acc, section) => ({
      ...acc,
      [section.name]: [],
    }),
    {}
  );

  React.useEffect(() => {
    if (!filterValues?.[name]) {
      onChange(selectedOptionsInitialState);
    }
  }, []);

  const [selectedOptions, setSelectedOptions] =
    React.useState<TabbedInputSelection>(
      filterValues?.[name] as TabbedInputSelection
    );

  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    setSelectedOptions(filterValues?.[name] as TabbedInputSelection);
  }, [filterValues]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelectOption = (
    option: LabelValueOptions,
    target: HTMLInputElement
  ) => {
    const allCheckboxes = target.parentNode.parentNode.children;

    const clickedCheckbox = target;

    const position = Array.from(allCheckboxes).findIndex(
      (child) => child.children[0] === clickedCheckbox
    );

    const section = sections[selectedTab]?.name;
    const newSelectedOptions = Object.keys(selectedOptions)?.length
      ? { ...selectedOptions }
      : sections.reduce((acc, item) => {
          acc[item.name] = [];
          return acc;
        }, {});

    const isSelected = newSelectedOptions[section]?.some(
      (item) => item.value === option.value
    );
    const newOptions: TabbedInputSelection = {
      ...newSelectedOptions,
      [section]: isSelected
        ? newSelectedOptions[section].filter(
            (item) => item.value !== option.value
          )
        : [...newSelectedOptions[section], option],
    };

    setSelectedOptions(newOptions);
    onChange(newOptions);
    setPosition(position);
  };

  React.useEffect(() => {
    if (!inputValue) return;

    const filteredOptions = sections[selectedTab].options.filter((option) =>
      (option as LabelValueOptions)?.label
        ?.toLowerCase()
        ?.includes(inputValue?.toLowerCase())
    );

    setFilteredOptions(filteredOptions);
    setShowFilteredOptions(true);
  }, [inputValue]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const options = sections[selectedTab]?.options;

    if (e.code === 'ArrowUp' && position > 0) {
      e.preventDefault();
      setPosition(position - 1);
    }

    if (e.code === 'ArrowDown' && position < options.length - 1) {
      e.preventDefault();
      setPosition(position + 1);
    }

    if (e.code === 'Space') {
      e.preventDefault();
      const activeCheckbox = e.target;

      (activeCheckbox as HTMLElement).click();
    }

    if (e.code === 'Tab' && selectedTab < sections.length - 1) {
      e.preventDefault();
      setSelectedTab(selectedTab + 1);
      setPosition(0);
    }

    if (e.code === 'Tab' && selectedTab >= sections.length - 1) {
      e.preventDefault();
      setShowOptions(false);
    }

    return;
  };

  const focusOnActiveCheckbox = React.useCallback(() => {
    if (showOptions && sections[selectedTab]?.options.length !== 0) {
      const activeCheckbox = document.querySelector('.activeCheckbox');

      (activeCheckbox as HTMLElement).focus();
    }
  }, [showOptions, sections, selectedTab]);

  React.useEffect(() => {
    focusOnActiveCheckbox();
  }, [position, focusOnActiveCheckbox]);

  return (
    <Wrapper width={width} height={height}>
      <InputLabel htmlFor="tabbed-field">{label}</InputLabel>
      <Content ref={wrapperRef}>
        <InputContainer
          tabIndex={0}
          aria-expanded={showOptions}
          onKeyDownCapture={(e) => {
            if (e.code === 'Space') setShowOptions(true);
          }}
        >
          <Input
            tabIndex={7}
            id="tabbed-field"
            {...props}
            placeholder={placeholder || t('Search among the tabs')}
            onFocus={() => {
              setShowOptions(true);
            }}
            handleInputChange={(value) => {
              setInputValue(value || '');
            }}
            onBlur={() => {
              if (!inputValue) {
                setShowFilteredOptions(false);
              }
            }}
          />
          <SearchInputIcon htmlFor="search-address">
            <SearchIcon width={20} height={20} color="#999999" />
          </SearchInputIcon>
        </InputContainer>
        {showOptions && (
          <Tab onKeyDownCapture={handleKeyDown}>
            <SectionsContainer>
              {sections.map((section, idx) => (
                <SectionItem
                  selected={idx === selectedTab}
                  htmlFor="tabbed-field"
                  onClick={() => {
                    setSelectedTab(idx);
                    setShowFilteredOptions(false);
                    setFilteredOptions([]);
                  }}
                  key={`${section?.name}-${idx}`}
                >
                  {`${section.label} (${section.options.length})`}
                </SectionItem>
              ))}
            </SectionsContainer>
            <OptionsContainer>
              {(showFilteredOptions
                ? filteredOptions
                : sections[selectedTab]?.options
              ).map((opt, index) => {
                /* If is object it means that the option type is neither number or string */
                const isObject = typeof opt === 'object';
                const optionProp = isObject
                  ? (opt as LabelValueOptions).label
                  : opt;
                return (
                  <OptionItem
                    key={`${optionProp}-${sections[selectedTab]?.name}`}
                  >
                    <CheckboxInput
                      type="checkbox"
                      name=""
                      className={position === index ? 'activeCheckbox' : null}
                      id={(opt as LabelValueOptions).value as string}
                      onChange={(event) =>
                        handleSelectOption(
                          opt as LabelValueOptions,
                          event.target
                        )
                      }
                      checked={selectedOptions?.[
                        sections[selectedTab]?.name
                      ]?.some(
                        (item) =>
                          item.value === (opt as LabelValueOptions)?.value
                      )}
                    />
                    <OptionLabel
                      htmlFor={(opt as LabelValueOptions).value as string}
                    >
                      {optionProp}
                    </OptionLabel>
                  </OptionItem>
                );
              })}
            </OptionsContainer>
          </Tab>
        )}
      </Content>
    </Wrapper>
  );
};
