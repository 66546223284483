import { gql } from '@apollo/client';

export const GENERATE_TOKEN_REQUEST = gql`
  mutation Mutation(
    $generateDataImporterTokenInput: GenerateDataImporterTokenInput!
  ) {
    generateDataImporterToken(
      GenerateDataImporterTokenInput: $generateDataImporterTokenInput
    )
  }
`;
