import * as React from 'react';
import { FooterProps } from '../../types';
import { Wrapper, LeftSideButton, RightSideButton } from './Footer.styles';

export const Footer: React.FC<FooterProps> = ({
  leftButtonLabel,
  onLeftButtonClick,
  rightButtonLabel,
  onRightButtonClick,
}: FooterProps) => {
  return (
    <Wrapper data-testid="Footer-Wrapper">
      <LeftSideButton onClick={onLeftButtonClick}>
        {leftButtonLabel}
      </LeftSideButton>
      <RightSideButton onClick={onRightButtonClick}>
        {rightButtonLabel}
      </RightSideButton>
    </Wrapper>
  );
};
