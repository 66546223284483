import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 2rem;
  ${({ theme }) => css`
    box-shadow: 0px 0.4rem 0.75rem -0.35rem ${theme.colors.purple[700]};
  `}
  height: 40%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
`;
export const TitlesWrapper = styled.div`
  margin-bottom: 2.8125rem;
`;
export const Title = styled.h1`
  margin: 0;
  font-size: 2.5rem;
`;
export const SubTitle = styled.p<{ loading: boolean }>`
  font-size: 1rem;
  margin: 0;
  ${({ theme }) => css`
    color: ${theme.colors.black[400]};
  `}

  ${({ loading }) =>
    loading &&
    css`
      &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: '...';
        width: 0px;
      }

      @keyframes ellipsis {
        to {
          width: 40px;
        }
      }

      @-webkit-keyframes ellipsis {
        to {
          width: 40px;
        }
      }
    `}
`;
export const GroupSelectWrapper = styled.div`
  margin-bottom: 1.21875rem;
`;
export const Label = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  opacity: 0.9;
  ${({ theme }) => css`
    color: ${theme.colors.black[400]};
  `}
`;
