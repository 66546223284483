import { gql } from '@apollo/client';

export const GET_EMAILS_TAB_QUERY = gql`
  query GetEmailBatches($emailBatchesInput: VisitorsParams) {
    getEmailBatches(EmailBatchesInput: $emailBatchesInput) {
      subject
      emailType
      date
      sentTo
      delivered
      opened
      clicked
      spam
      unsubscribed
      bounced
    }
  }
`;
