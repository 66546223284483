import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 2.625rem 1rem 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const Count = styled.p`
  font-size: 2.5rem;
  line-height: 2.9375rem;
  color: ${({ theme }) => theme.colors.green[500]};
  font-weight: 700;
  margin-left: 2.625rem;
  margin-top: 0;
`;

export const MainTitle = styled.p`
  font-size: 2.5rem;
  line-height: 2.9375rem;
  color: #000;
  font-weight: 700;
  margin-left: 0.625rem;
  margin-top: 0;
`;

export const Title = styled.p`
  font-size: 1.4375rem;
  line-height: 1.1875rem;
  color: #000;
  font-weight: 700;
`;

export const Section = styled.div`
  margin: 3.3125rem 1.875rem 5.25rem;
  min-height: 18.75rem;
`;

export const BottomTabsSection = styled.div`
  margin: 3.3125rem 1.875rem 5.25rem 4.125rem;
  min-height: 18.75rem;
`;
