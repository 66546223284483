import { ENGLISH_LANGUAGES_NAMES } from 'Client/constants/languages';

export const mapLanguages = (languages) => {
  const array = Object.keys(languages).map((l) => {
    if (l === 'en-GB') {
      return;
    }

    return {
      label: `${languages[l]} (${ENGLISH_LANGUAGES_NAMES[l]})`,
      value: l,
    };
  });

  array.sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }

    return -1;
  });

  return array.filter(Boolean);
};
