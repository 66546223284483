import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isFuture, format } from 'date-fns';
import { fromLonLat } from 'ol/proj';
import { NavigationalMap } from 'Client/components/organisms';
import { useProject, useMap } from 'Client/utils/hooks';
import { fetchMapPage } from 'Client/services/map';
import { Xyz } from 'Shared/types/map';
import { AppDetailsProps } from './types';
import {
  Wrapper,
  MapWrapper,
  InfoWrapper,
  MapTabWrapper,
  MapTab,
  Text,
  Title,
  ContentWrapper,
  Hr,
  AvatarWrapper,
  Avatar,
  EmailText,
} from './AppDetails.styles';

export const AppDetails: React.FC<AppDetailsProps> = ({ map, pageContent }) => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const project = useProject();
  const { t } = useTranslation();
  const { dispatch, state } = useMap();
  const xyz = state.xyz as Xyz;

  const tabs = [
    {
      label: t('Map view'),
      value: 1,
    },
    {
      label: t('Satellite view'),
      value: 2,
    },
    pageContent?.display3d
      ? {
          label: t('3D view'),
          value: 3,
        }
      : null,
  ].filter(Boolean);

  const info = [
    {
      label: t('Reference:'),
      value: pageContent.reference,
    },
    ...(project?.features?.glaPlanningApps
      ? [
          {
            label: t('Stage:'),
            value: pageContent.stage,
          },
          {
            label: t('Stage reference:'),
            value: pageContent.glaReference,
          },
        ]
      : [
          {
            label: t('Type:'),
            value: t('Major planning application'),
          },
        ]),
    {
      label: t('Decision:'),
      value: isFuture(new Date(pageContent.targetDate))
        ? `Expected decision ${format(
            new Date(pageContent.targetDate),
            'dd/MM/yyyy'
          )}`
        : pageContent.status,
    },
    {
      label: t('Applicant:'),
      value: pageContent.applicant,
    },
  ];

  const handleMapTabs = async (id: number) => {
    setSelectedTab(id);

    if (id === 1) {
      xyz.layers.list['3d View'].remove();
      xyz.layers.list['Satellite'].remove();
      xyz.layers.list['Mapbox Base'].show();
    } else if (id === 2) {
      xyz.layers.list['3d View'].remove();
      xyz.layers.list['Satellite'].show();
      xyz.layers.list['Mapbox Base'].remove();
    } else {
      xyz.layers.list['Satellite'].remove();
      xyz.layers.list['Mapbox Base'].show();
      xyz.layers.list['3d View'].show();

      const center = fromLonLat([map.lng, map.lat]);
      xyz.map.getView().animate({
        center,
        zoom: 16,
      });

      const mapPage = await fetchMapPage({ slug: map.baseMapSlug });

      dispatch({
        type: 'FILTER_3D_BY_PAGE_ID',
        payload: { pageId: mapPage._id },
      });
    }

    dispatch({
      type: 'CHANGE_MODE',
      payload: id === 1 ? 'map' : id === 2 ? 'satellite' : '3d',
    });
  };

  React.useEffect(() => {
    if (!xyz?.map || !pageContent?.map) return;

    const coords = [map.lng, map.lat];
    const center = (window as any).ol?.proj.fromLonLat(coords);
    xyz.map.getView().setCenter(center);
    xyz.map.getView().adjustZoom(map?.zoom || 18);
  }, [xyz?.map]);

  return (
    <Wrapper data-testid="PlanningApp-AppDetails">
      <InfoWrapper>
        {info.filter(Boolean).map((appInfo) => (
          <ContentWrapper key={appInfo.label}>
            <label>{appInfo.label}</label>
            <Text>{appInfo.value}</Text>
          </ContentWrapper>
        ))}

        <Hr />
        <Title>{t('LOCATION')}</Title>

        <ContentWrapper>
          <label>{t('Site:')}</label>
          <Text>{pageContent.address}</Text>
        </ContentWrapper>
        <ContentWrapper>
          <label>{t('Ward:')}</label>
          <Text>{pageContent.ward}</Text>
        </ContentWrapper>

        {!project?.features?.glaPlanningApps && (
          <>
            <Hr />
            <Title>{t('LEAD PLANNING OFFICER')}</Title>

            <AvatarWrapper>
              <Avatar />
              <div>
                <Text bold>{pageContent.caseOfficer}</Text>
                <a
                  href={`mailto:${pageContent.caseOfficerEmail}?subject=${pageContent.reference}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <EmailText>{pageContent.caseOfficerEmail}</EmailText>
                </a>
              </div>
            </AvatarWrapper>
          </>
        )}
      </InfoWrapper>
      {pageContent?.map && (
        <MapWrapper>
          <NavigationalMap
            content={{
              slug: map.baseMapSlug,
            }}
            view={{
              lat: map.lat,
              lng: map.lng,
              display3d: true,
            }}
            header={false}
          />
          <MapTabWrapper>
            <MapTab
              selectedTab={selectedTab}
              tabs={tabs}
              onTabChange={handleMapTabs}
            />
          </MapTabWrapper>
        </MapWrapper>
      )}
    </Wrapper>
  );
};
