import { GeoJSONGeometry } from 'ol/format/GeoJSON';
import { get, transformExtent } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import { MapProjection, Xyz } from 'Shared/types/map';
import { MapPageProps } from 'Client/pages';

export const resetZoomLevel = (proposal: Partial<MapPageProps>, xyz: Xyz) => {
  const { view } = proposal?.geolytixWorkspace?.locales?.UK;
  const coords: GeoJSONGeometry = {
    type: 'Point',
    coordinates: [view.lng, view.lat],
  };

  const extent = boundingExtent([coords.coordinates]);
  const transformed = transformExtent(
    extent,
    get(MapProjection.WORLD_GEODETIC_GPS),
    get(MapProjection.WEB_MERCATOR)
  );

  const dpi = window.devicePixelRatio || 1;
  xyz.mapview.flyToBounds(transformed, {
    padddings: [10, 10, 10, 10].map((padding) => padding * dpi),
    maxZoom: view.z,
  });
};
