import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { SeoMetaInfo } from 'Atoms';
import { useProject } from 'Client/utils/hooks';
import { PlanningAppPageProps } from 'Shared/types/planningapp';
import { PlanningAppPagesTemplate as Template } from 'Templates';
import { useBanner } from 'Client/utils/hooks/useBanner';
import {
  BackButton,
  StatusSection,
  FactsSection,
  TimeLine,
  CommentSection,
  NavBar,
  MainImageButtons,
} from './components';
import { getPlanningAppTitle, scrollToSection } from './utils';
import {
  ProjectInfoWrapper,
  TimeLineWrapper,
  TabsWrapper,
  Content,
  CommentSectionWrapper,
  ImgWrapper,
  InfoWrapper,
  InfoContent,
  FlexWrapper,
  ButtonsWrapper,
  BackButtonWrapper,
  MobileTitle,
} from './PlanningAppPage.styles';
import { getTimelineMilestones } from './utils/getTimelineMilestones';

const PlanningAppPage: React.FC<PlanningAppPageProps> = ({
  pageData,
  pageContent,
  questions,
  commentsCount,
  daysLeftToComment,
  slug,
  initialComments,
  typedFiles,
  userDemographics,
}) => {
  const router = useRouter();
  const project = useProject();
  const { t } = useTranslation();
  const [mainImage, setMainImage] = React.useState(
    typedFiles?.Photo?.length
      ? {
          url: typedFiles.Photo[0]?.url,
          name: typedFiles.Photo[0]?.display_name,
        }
      : {
          ...pageContent?.images?.find((image) => image.isMainImage),
        }
  );
  const statusToShowCommentSection = [
    'Consultation open',
    'Awaiting committee',
  ];
  const isConsultationOpen =
    pageContent?.openForComments ||
    statusToShowCommentSection.includes(pageContent.status);
  const [selectedTab, setSelectedTab] = React.useState(
    Number(router.query?.tab) || 1
  );

  if (router.query?.tab && typeof window !== 'undefined') {
    scrollToSection('tabs-bar');
  }

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return (
    <Template>
      <SeoMetaInfo
        projectStage={project.stage}
        projectName={project.name}
        proposalTitle={getPlanningAppTitle(pageContent.address)}
        customerName={project.customer}
        page="planningapp"
      />
      <ProjectInfoWrapper data-testid="planningAppPage-info">
        <InfoContent
          errorBannerHeight={errorBannerHeight}
          stageBannerHeight={stageBannerHeight}
        >
          <BackButtonWrapper>
            <BackButton />
          </BackButtonWrapper>
          <FlexWrapper>
            <InfoWrapper>
              <StatusSection
                name={
                  pageContent?.name || getPlanningAppTitle(pageContent.address)
                }
                refValue={pageContent.reference}
                isConsultationOpen={isConsultationOpen}
                daysLeft={daysLeftToComment}
                commentsCount={commentsCount}
                status={pageContent.status}
                setSelectedTab={setSelectedTab}
              />
              <FactsSection
                pageContent={pageContent}
                typedFiles={typedFiles}
                mainImage={mainImage}
                setMainImage={setMainImage}
              />
            </InfoWrapper>
            <ImgWrapper>
              <img src={mainImage?.url} alt={mainImage?.name} />
            </ImgWrapper>
          </FlexWrapper>
          <ButtonsWrapper>
            <MainImageButtons
              refValue={pageContent.reference}
              slug={slug}
              proposalName={getPlanningAppTitle(pageContent.address)}
              pageId={pageData._id}
              isConsultationOpen={isConsultationOpen}
            />
          </ButtonsWrapper>
        </InfoContent>
      </ProjectInfoWrapper>

      <TimeLineWrapper data-testid="planningAppPage-timeline">
        <MobileTitle>{t('TIMELINE')}</MobileTitle>
        <Content>
          <TimeLine
            milestones={getTimelineMilestones({
              pageContent,
              project,
            })}
          />
        </Content>
      </TimeLineWrapper>

      <TabsWrapper>
        <Content>
          <NavBar
            slug={slug}
            commentsCount={commentsCount}
            pageContent={pageContent}
            initialComments={initialComments}
            typedFiles={typedFiles}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Content>
      </TabsWrapper>

      {isConsultationOpen && questions && (
        <CommentSectionWrapper>
          <Content>
            <CommentSection
              daysLeft={daysLeftToComment}
              pageContent={pageContent}
              questions={questions}
              slug={slug}
              pageName={getPlanningAppTitle(pageContent.address)}
              pageId={pageData._id}
              userDemographics={userDemographics}
            />
          </Content>
        </CommentSectionWrapper>
      )}
    </Template>
  );
};

export { PlanningAppPage };
