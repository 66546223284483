import { Theme } from '@material-ui/core';
import styled, { css } from 'styled-components';
import * as React from 'react';
import { QuestionRenderer, AddressField } from 'Client/components/organisms';
import {
  TextField,
  RadioButton,
  Button,
  Checkbox,
  PhoneNumberField,
} from 'Client/components/molecules';
import { AddressFieldProps } from 'Client/components/organisms/AddressField/types';

export const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      align-items: center;
    }
  `}
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black[500]};
  margin-top: 3rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  margin-top: 3rem;

  & div {
    margin-bottom: 0.5rem;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & p {
    font-size: 1.125rem;
    width: 25%;
    font-weight: 700;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 40%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      justify-content: space-between;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  margin-left: 25%;
  margin-top: 2rem;

  padding-bottom: 5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 2rem auto;

      & button {
        max-width: 100%;
        width: 100%;
      }
    }
  `}
`;

export const Description = styled.p`
  font-size: 0.875rem;
  max-width: 45%;
  margin-left: 25%;
  margin-bottom: 2rem;

  & a {
    font-size: 0.875rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 1rem auto;
      max-width: 90%;
      width: 100%;
    }
  `}
`;

export const AutocompleteWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  p {
    flex-wrap: wrap;
    margin-left: 25%;
  }

  & label {
    font-size: 1.125rem;
    width: 25%;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;

      & label {
        width: auto;
      }

      p {
        flex-wrap: wrap;
        margin: 0;
      }
    }
  `}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25%;
  max-width: 55%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: 100%;
      margin: 0;
    }
  `}
`;

export const StyledQuestionRenderer = styled((props) => (
  <QuestionRenderer roundedBorder={true} showSecondaryText={false} {...props} />
))`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & label {
    font-size: 1.125rem;
    width: 25%;
  }

  & div {
    max-width: 60%;
  }

  .MuiTextField-root {
    width: 100%;

    & div {
      max-width: 80%;
    }
  }

  & button {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: 1rem;
    padding: 0.5rem 1.3rem;
    margin: 0 1rem 1rem 0;
  }
  & span {
    flex-wrap: wrap;
    margin-left: 25%;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      & span {
        margin: 0;
      }
      & label {
        width: auto;
      }

      & div {
        max-width: 100%;
        width: 100%;
      }

      .MuiTextField-root {
        & div {
          max-width: 100%;
        }
      }
    }
  `}
`;

export const StyledAutocomplete = styled((props: AddressFieldProps) => (
  <AddressField {...props} />
))`
  max-width: 30%;
  margin-right: 20%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: 100%;
      margin: 0;
    }
  `}
`;

const TextFieldStyles = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & label {
    font-size: 1.125rem;
    width: 25%;
  }

  .MuiFormControl-root {
    width: 30%;
    margin-right: 10rem;
  }

  & span {
    flex-wrap: wrap;
    margin-left: 25%;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    width: 100%;

    & span {
      margin: 0;
    }

    & label {
      width: auto;
    }

    .MuiFormControl-root {
      width: 100%;
      margin: 0;
    }

    &.MuiFormControlLabel-root {
      width: 100%;
    }
  }
`;

export const StyledTextField = styled((props) => <TextField {...props} />)`
  ${TextFieldStyles};
`;

export const StyledPhoneNumber = styled((props) => (
  <PhoneNumberField {...props} />
))`
  ${TextFieldStyles};
`;

export const StyledRadioButton = styled((props) => <RadioButton {...props} />)`
  margin-right: 2rem;

  .MuiIconButton-root {
    .MuiSvgIcon-root {
      padding: 0;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.white[500]};
      fill: ${({ theme }) => theme.colors.white[500]};
      border: 0.1rem solid
        ${({ theme }) => theme.colorMappings.disabledPlanAppButton};
    }

    &:hover {
      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
      }
    }
    color: ${({ theme }) => theme.colors.black[500]};
  }

  .MuiButtonBase-root {
    padding-bottom: 0;
    padding-left: 0;
  }

  &.MuiFormControlLabel-root {
    width: auto;
    .Mui-checked {
      .MuiSvgIcon-root {
        background-color: transparent;
        fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
        border: 0;
      }
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      margin: 0;

      & label {
        width: auto;
      }
    }
  `}
`;

export const StyledButton = styled((props) => (
  <Button borderRadius="0.15rem" {...props} />
))``;

export const StyledCheckbox = styled((props) => (
  <Checkbox status="" labelPlacement="end" {...props} />
))`
  .MuiButtonBase-root {
    padding: 0.15rem 1rem 0 0;
  }

  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }

  &.MuiFormControlLabel-root {
    &:hover {
      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
      }
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
      }
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      align-items: flex-start;
    }
  `}
`;

export const InfoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    gap: 0.5rem;

    background: #f4f4f4;
    border: 0.0625rem solid ${theme.colorMappings.brand};
    border-radius: 0.25rem;
    & > p {
      margin: 0;
    }
    a {
      padding: 0;
    }
  `}
`;
