import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MixpanelEventTypes, useAnalytics } from 'Client/utils/hooks';
import { Alert } from 'Atoms';
import {
  Wrapper,
  Title,
  Form,
  Buttons,
  Description,
  Header,
  Label,
  SignInButton,
  TextField,
  Tip,
  Image,
} from '../Shared.styles';

export const CommunityMemberLogin: React.FC<{
  email: string;
}> = (props) => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [code, setCode] = React.useState<string>('');
  const [codeError, setCodeError] = React.useState<boolean>(false);
  const [warning, setShowWarning] = React.useState<boolean>(false);
  const { email } = props;

  const resendCode = () => {
    trackEvent(MixpanelEventTypes.RESEND_LOGIN_CODE, { email });
    setShowWarning(true);
  };

  const verifyCode = () => {
    setShowWarning(false);
    trackEvent(MixpanelEventTypes.CLICKED_COMMUNITY_LOGIN, { email });
    if (!code.match(/^[0-9]{6}$/g)) {
      setCodeError(true);
      return;
    }
  };

  return (
    <Wrapper data-testid="CommunityMemberLogin-Wrapper">
      <Header>
        <Image>
          <img
            src="/static/illustrations/code_verification_title.png"
            alt="lock icon"
          />
        </Image>
        <Title>{t('Enter your 6 digit code to login')}</Title>
      </Header>
      <Description>
        {t(
          'A one-time code has been sent to you, please check your email inbox.'
        )}
      </Description>
      {warning && (
        <Alert fullWidth type="tip" size="big">
          {t('New code sent to your email, check again')}
        </Alert>
      )}
      {codeError && (
        <Alert fullWidth type="error">
          {t(
            "The code entered doesn't match the one we sent. Please double-check and try again or request a new code."
          )}
        </Alert>
      )}
      <Form>
        <Label>{t('6 digit code')}</Label>
        <TextField
          placeholder=""
          label={'6 digit code'}
          value={code}
          inputProps={{ maxLength: 6 }}
          handleChange={(e) => {
            setCodeError(false);
            setCode(e.target.value);
          }}
        ></TextField>
        <Tip left>
          {email && t(`We sent a code to`)} <b>{email}</b>
        </Tip>
      </Form>
      <Buttons>
        <SignInButton
          data-ghostinspectorid="CommunityMemberLogin-Button"
          onClick={() => verifyCode()}
        >
          {t('Confirm')}
        </SignInButton>
      </Buttons>
      <Tip>
        <a
          onClick={() => {
            resendCode();
            setCodeError(false);
          }}
        >
          {t('Request new code')}
        </a>
      </Tip>
    </Wrapper>
  );
};
