import { mapSentiments } from 'Client/pages/dashboard/utils';
import { RatingOption } from 'Client/pages/proposals/types';
import { PopulatedAnswer } from 'Shared/types/contribution';

export const getSentiment = (answers: Array<PopulatedAnswer>) => {
  const relevant =
    answers?.length > 0 &&
    answers?.find((an) => an.questionContent?.name === 'feeling');

  if (!relevant || !Number.isInteger(relevant.value)) return {};

  const sentiment = (relevant.value as number) / 25 + 1;

  const sentimentOptions = relevant.questionContent
    .options as Array<RatingOption>;

  const sentimentOption = sentimentOptions?.find(
    (opt) => opt.value === relevant.value
  );
  if (!sentimentOption) return {};

  return {
    sentimentDescription: sentimentOption.label,
    sentiment,
    commentSentiment: relevant.value,
  };
};

export const getSentimentLabel = (sentiment: number | string) => {
  if (!sentiment) return null;
  return mapSentiments(sentiment).label;
};
