import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, SeoMetaInfo } from 'Atoms';
import { HubPagesTemplate as Template } from 'Templates';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { useProject } from 'Client/utils/hooks';
import { cpBrandName } from 'Client/constants/brand';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { StakeholderCard } from './components/StakeholderCard/StakeholderCard';
import { ProjectTeamPageProps } from './types';
import {
  FullWidthContainer,
  Intro,
  Heading,
  StakeholdersWrapper,
} from './ProjectTeamPage.styles';

const ProjectTeamPage: React.FC<ProjectTeamPageProps> = ({
  stakeholders,
  title,
  description,
  navbarItems,
  ...props
}: ProjectTeamPageProps) => {
  const project = useProject();
  const { t } = useTranslation();

  return (
    <Template greyBackground {...props} navbarItems={navbarItems}>
      <FullWidthContainer>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={project.name}
          page="projectTeam"
        />

        <Container>
          <Heading variant="h1">{title}</Heading>
          <Intro>{description}</Intro>
        </Container>

        <StakeholdersWrapper>
          {stakeholders.map((stakeholder, index) => (
            <StakeholderCard
              key={`${stakeholder.name}-${index}`}
              name={stakeholder.name}
              logo={stakeholder.logo}
              logoAltText={
                stakeholder?.privacyPolicy
                  ? t('A link redirecting to the stakeholder privacy policy')
                  : stakeholder.logoAltText
              }
              privacyPolicy={t(`Read {{name}} privacy policy`, {
                name: stakeholder.name,
              })}
              responsibilities={stakeholder?.responsibilities}
              url={stakeholder?.privacyPolicy}
              role={stakeholder?.role}
            />
          ))}
          <StakeholderCard
            data-testid="Commonplace-stakeholder-card"
            name={'Commonplace'}
            logo={
              'https://res.cloudinary.com/commonplace-digital-limited/image/upload/q_auto,f_auto/Commonplace/commonplace-icon-green.png'
            }
            logoAltText={t(
              'A link redirecting to {{cpBrandName}} privacy policy',
              {
                cpBrandName,
              }
            )}
            privacyPolicy={t('Read {{cpBrandName}} privacy policy', {
              cpBrandName,
            })}
            responsibilities={t(
              `{{cpBrandName}} connects you to the people who create the places where you live, work and play. A platform to speak and be heard by developers, councils, and public bodies to initiate better decisions and places for all.`,
              { cpBrandName }
            )}
            url={PRIVACY_POLICY_URL}
          />
        </StakeholdersWrapper>
        <EditModeButton />
      </FullWidthContainer>
    </Template>
  );
};

export { ProjectTeamPage };
