import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const PillWrapper = styled.div<{
  color: string;
  label: string;
  selected: boolean;
}>`
  border-radius: 1rem;
  height: fit-content;
  max-height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  ${({ selected, color, label }) => css`
    border: ${`1px solid ${color}`};
    cursor: ${label === 'anonymous' ? 'default' : 'pointer'};
    ${selected &&
    css`
      background: ${color};
      p {
        color: white;
        div {
          display: none;
        }
      }
    `};
  `}
`;

export const PillContent = styled.p`
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  span {
    font-weight: 700;
  }
`;

export const PillDot = styled.div<{ color: string }>`
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
  background: ${({ color }) => color};
`;
