import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 2rem;
  div > .MuiFormControl-root {
    margin-bottom: 1.6rem;
  }
  .MuiFormControl-root {
    min-width: 0 !important;
    width: 100%;
  }
  .MuiInputLabel-root {
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.black[400]};
  }
`;
