import * as React from 'react';
import { components } from 'react-select';
import { StyledCheckbox } from 'Atoms';
import { OptionWrapper } from '../AddMembersModal/AddMembersModal.styles';
import { specialValues } from '../AddMembersModal/utils';

export const OptionWithCheckbox = ({
  handleSetProjects,
  setSelectedProjects,
  selectedProjects,
  setProjectError,
}) => {
  const Option = (props) => (
    <OptionWrapper
      border={specialValues.includes(props.value)}
      onClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();

        handleSetProjects({
          value: props.value,
          setSelectedProjects,
          selectedProjects,
          setProjectError,
        });
      }}
    >
      <components.Option {...props}>
        <StyledCheckbox
          type="checkbox"
          checked={
            selectedProjects.includes(props.value) ||
            props.value === 'unselect all'
          }
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            handleSetProjects({
              value: props.value,
              setSelectedProjects,
              selectedProjects,
              setProjectError,
            });
          }}
        />
        <p>{props.label}</p>
      </components.Option>
    </OptionWrapper>
  );

  return Option;
};
