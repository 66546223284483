import * as React from 'react';
import {
  Wrapper,
  Cell,
  ActionCell,
  SortFilter,
} from './TeamTableHeader.styles';

export const TeamTableHeader: React.FC = () => {
  return (
    <Wrapper data-testid="TeamTableHeader-Wrapper">
      <Cell sizeType={3}>First name</Cell>
      <Cell sizeType={3}>Last name</Cell>
      <Cell sizeType={3}>Email</Cell>
      <Cell sizeType={3}>
        Role <SortFilter></SortFilter>
      </Cell>
      <Cell sizeType={2}>
        Date added<SortFilter></SortFilter>
      </Cell>
      <Cell sizeType={2}>
        Last seen <SortFilter></SortFilter>
      </Cell>
      <ActionCell>Action</ActionCell>
    </Wrapper>
  );
};
