import { useProject } from 'Client/utils/hooks';

export const UseMessages = () => {
  const { name } = useProject();
  const SUBJECT = `Personal invitation: ${name}, have your say`;
  const HEADER_TEXT = `Have your say on ${name}`;
  const EMAIL_MESSAGE = 'Add email content...';
  const BUTTON_TEXT = 'Have your say';
  return { SUBJECT, HEADER_TEXT, EMAIL_MESSAGE, BUTTON_TEXT };
};
