import * as React from 'react';
import { readableColor, opacify } from 'polished';
import styled from 'styled-components';
import Select from 'react-select';
import { IconProps } from 'Atoms/Icons/types';
import { SendIconBold } from 'Atoms/Icons';
import { theme } from 'Client/components/theme';
import { getTeamMemberRowBGColor } from './utils/getTeamMemberRowBGColor';

export const Wrapper = styled.div<{
  invite?: boolean;
  status?: 'pending' | 'expired' | string;
}>`
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme, invite, status }) =>
    getTeamMemberRowBGColor(invite, status, theme)};
  width: 100%;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey[100]};
  position: relative;
`;

export const EditTextCell = styled.input<{ sizeType: number; color: string }>`
  display: flex;
  align-items: center;
  font-weight: 400;
  flex: ${({ sizeType }) => sizeType};
  height: 2.25rem;
  width: inherit;
  padding: 0;
  padding-left: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
  border: none;
  &:focus-visible {
    outline: none;
  }
`;

export const StyledSelect = styled(Select)<{
  sizeType: number;
  color: string;
}>`
  flex: ${({ sizeType }) => sizeType};
  height: 2.25rem;
  padding: 0 0.3125rem;
  min-height: unset;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
  font-size: 400;
  & > div:first-child,
  & > div:nth-child(2) {
    height: 2rem;
    min-height: unset;
    border: none;
    border-radius: 0;
  }
`;

export const ActionCell = styled.div<{ isLastChild: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 7.5%;
  height: 2.25rem;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
  border-bottom-right-radius: ${({ isLastChild }) =>
    isLastChild && '0.3125rem'};
`;

export const ActionDot = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding-bottom: 0.4375rem;
  cursor: pointer;
  color: ${({ color }) => readableColor(color)};
`;

export const ActionSaveButton = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ color }) => readableColor(color)};
  background-color: ${({ color }) => color};
  cursor: pointer;
  box-shadow: inset 0 -0.0625rem 0 ${({ theme }) => theme.colors.grey[200]};
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;

export const ActionModal = styled.div`
  position: absolute;
  top: 50%;
  right: 4%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  z-index: 11;
  pointer-events: none;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[100]};
  box-shadow: 0rem 0.25rem 0.25rem
    ${({ theme }) => opacify(-0.92, theme.colors.black[500])};
  border-radius: 0.125rem;

  :before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    z-index: 10;
    pointer-events: auto;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  pointer-events: auto;
  z-index: 13;

  svg {
    margin: 0 0.3125rem 0 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colorMappings.teamMemberActionHover};

    svg {
      stroke: ${({ theme }) => theme.colorMappings.teamMemberActionHover};
    }
  }
`;

export const SendIcon = styled(({ ...props }: IconProps) => (
  <SendIconBold
    {...props}
    color={theme.colorMappings.teamMemberActionIcons}
    width={16}
    height={16}
  />
))`
  stroke: ${theme.colorMappings.teamMemberActionIcons};
`;

export const CancelEditButton = styled.div`
  background-color: ${({ theme }) => theme.colors.red[400]};
  position: absolute;
  width: 2.25rem;
  left: -2.25rem;
  top: -0.0625rem;
  height: 2.3125rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 13px;
    height: 13px;
    color: ${({ theme }) => theme.colors.white[500]};
    cursor: pointer;
  }
`;
