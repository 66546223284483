import * as React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { isValid } from 'date-fns';
import { getSentiment } from 'Client/pages/contributions/utils';
import { useProject, useUser } from 'Client/utils/hooks';
import { Contribution } from 'Shared/types/contribution';
import { DashboardDetailsPageTemplates as Template } from 'Templates';
import { ResponseProps } from 'Client/pages/contributions/types';
import { Permissions } from 'Client/constants/permissions';
import { mapStatus } from 'Pages/dashboard/utils';
import {
  Header,
  InfoPanel,
  SidePanel,
} from 'Pages/dashboard/components/DetailsPages';
import { ContributionCard } from 'Client/pages/contributions/components/ContributionCard';
import { ContributionDetailsPageProps } from '../types';
import {
  GET_CONTRIBUTION_DETAILS_QUERY,
  GET_RESPONDENT_QUERY,
} from './ContributionDetails.gql';
import { Wrapper, MainContent, Content } from './ContributionDetails.styles';

export const ContributionDetails: React.FC<ContributionDetailsPageProps> = ({
  id,
}: ContributionDetailsPageProps) => {
  const { user } = useUser();
  const canReply = user.role.permissions.includes(
    Permissions.ADD_COMMENT_REPLY
  );
  const project = useProject();
  const router = useRouter();

  const [date, setDate] = React.useState(null);
  const [agreements, setAgreements] = React.useState(0);
  const [goBack, setGoBack] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [referral, setReferral] = React.useState(null);
  const [response, setResponse] = React.useState<ResponseProps>(null);
  const [utmCampaign, setUtmCampaign] = React.useState(null);
  const [utmMedium, setUtmMedium] = React.useState(null);
  const [sentiment, setSentiment] = React.useState(null);
  const [answers, setAnswers] = React.useState(null);
  const [surveySlug, setSurveySlug] = React.useState(null);
  const [contributionUserId, setContributionUserId] = React.useState(null);
  const [contributionPageId, setContributionPageId] = React.useState(null);
  const [contributionDemographicsId, setContributionDemographicsId] =
    React.useState(null);
  const [contribution, setContribution] = React.useState<Contribution>(null);
  const [, setRespondent] = React.useState(null);
  const { data, loading } = useQuery(GET_CONTRIBUTION_DETAILS_QUERY, {
    variables: {
      contributionId: id,
    },
  });

  const { data: respondentsData, loading: respondentsLoading } = useQuery(
    GET_RESPONDENT_QUERY,
    {
      variables: {
        getRespondentId: contributionUserId,
      },
    }
  );

  const setInitialData = () => {
    const {
      date: gDate,
      agreements: gAgreements,
      answersPopulated,
      status: gStatus,
      location: gLocation,
      referral: gReferral,
      response: gResponse,
      utmCampaign: gUtmCampaign,
      utmMedium: gUtmMedium,
      surveySlug: gSurveySlug,
      demographicsId: gDemographicsId,
      userId: gUserId,
      pageId: gPageId,
      projectId,
    } = data.getContribution;

    if (projectId !== project._id) {
      setGoBack(true);
      router.back();
      return;
    }
    setContribution(data.getContribution);
    if (isValid(new Date(gDate))) {
      setDate(gDate);
    }
    setStatus(mapStatus(gStatus));
    setAgreements(gAgreements);
    setLocation(gLocation);
    setReferral(gReferral);
    setResponse(gResponse);
    setUtmCampaign(gUtmCampaign);
    setUtmMedium(gUtmMedium);
    setAnswers(answersPopulated);
    setContributionUserId(gUserId);
    setContributionPageId(gPageId);
    setContributionDemographicsId(gDemographicsId);
    setSurveySlug(gSurveySlug);

    const { sentimentDescription } = getSentiment(answersPopulated);
    setSentiment(sentimentDescription);
  };

  const setInitialRespondentsData = () => {
    setRespondent(respondentsData.getRespondent);
  };

  React.useEffect(() => {
    if (data) {
      setInitialData();
    }
  }, [data]);

  React.useEffect(() => {
    if (respondentsData) {
      setInitialRespondentsData();
    }
  }, [respondentsData]);

  if (goBack) {
    return (
      <Template currentTab={'Contributions'}>
        <Wrapper data-testid="ContributionDetails-Back-Wrapper"></Wrapper>
      </Template>
    );
  }

  const panelData = [
    {
      type: 'contributions',
      projectId: project._id,
      projectName: project.id,
      _id: id,
      items: [
        {
          label: 'location',
          value: {
            latitude:
              location && location.coordinates ? location.coordinates[1] : null,
            longitude:
              location && location.coordinates ? location.coordinates[0] : null,
            slug: surveySlug,
          },
        },
        {
          label: 'contribution id',
          value: id,
        },
        {
          label: 'respondent',
          value: contributionDemographicsId,
        },
        {
          label: 'referrals',
          value: referral,
        },
        {
          label: 'utm campaign',
          value: utmCampaign,
        },
        {
          label: 'utm medium',
          value: utmMedium,
        },
      ],
    },
  ];

  return (
    <Template currentTab={'Contributions'}>
      <MainContent>
        <Header title="Contribution" backButtonText="Back">
          <InfoPanel
            info={[
              { label: 'sentiment', value: sentiment },
              { label: 'status', value: status?.label },
              { label: 'date', value: date },
              agreements ? { label: 'agreements', value: agreements } : null,
              /* This one needs some work in the future */
              { label: 'not grouped', value: null },
            ].filter(Boolean)}
          />
        </Header>
        <Content>
          {!loading && !respondentsLoading && (
            <ContributionCard
              answers={answers}
              backUrl={`/${
                contribution?.map ? 'map' : 'proposals'
              }/${surveySlug}`}
              contributionId={id}
              contributionDate={date}
              isOwnComment={user._id === contributionUserId}
              isMapComment={!!contribution?.map}
              refetchUserAgreements={() => '' /* can't agree */}
              contrProposalSlug={surveySlug}
              response={response}
              pageId={contributionPageId}
              canReply={canReply}
              detailsPage
            />
          )}
        </Content>
      </MainContent>
      <SidePanel panelData={panelData} />
    </Template>
  );
};
