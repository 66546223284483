export const imageUrls = {
  default:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1643374331/Project%20creation%20form/Default.png',
  projectName:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1643367736/Project%20creation%20form/Project%20Name.png',
  projectUrl:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1643367734/Project%20creation%20form/Url.png',
  location:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1643367730/Project%20creation%20form/Location.png',
  languages:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1643367730/Project%20creation%20form/Location.png',
  dates:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1643374331/Project%20creation%20form/Default.png',
};
