import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { RootState } from 'Client/redux-store';
import {
  setFilterValues,
  setSortFilter,
} from 'Client/utils/reduxReducers/filters/filtersReducer';
import { LabelValueOptions } from 'Client/pages';
import { SortByProps } from './types';
import {
  Wrapper,
  Header,
  OpenModal,
  Options,
  Option,
} from './SortByDropdown.styles';

export const SortByDropdown: React.FC<SortByProps> = ({ tabs, name }) => {
  // Hooks
  const { t } = useTranslation();
  const { filterValues } = useSelector((state: RootState) => state.filters);
  const dispatchFiltersRdx = useDispatch();

  const reduxValue = filterValues?.[name] as LabelValueOptions;

  const [selectedTab, setSelectedTab] = React.useState(
    reduxValue?.label || tabs[0]?.value
  );
  const [selectedValue, setSelectedValue] = React.useState(reduxValue?.value);
  const [showOptions, setShowOptions] = React.useState(false);
  const wrapperRef = React.useRef(null);

  // Handlers
  const handleSelect = (tab, option) => {
    setSelectedTab(tab.value);
    setSelectedValue(option.value);
    dispatchFiltersRdx(
      setSortFilter({
        sort: {
          field: tab.value,
          order: option.value,
        },
      })
    );
    dispatchFiltersRdx(
      setFilterValues({
        filters: {
          ...filterValues,
          [name]: {
            label: tab.value,
            value: option.value,
          },
        },
      })
    );
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef}>
      <Header>
        {t('Sort by:')}
        <OpenModal
          aria-pressed={showOptions}
          aria-expanded={showOptions}
          onClick={() => setShowOptions(!showOptions)}
        >
          <span>{tabs?.find((tab) => tab.value == selectedTab).label}</span>
          {showOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </OpenModal>
      </Header>
      {showOptions && (
        <Options>
          {tabs.map((tab) => (
            <div key={tab.value}>
              <span>{tab.label}</span>
              {tab.options.map((option) => (
                <Option
                  key={option.value}
                  onClick={() => handleSelect(tab, option)}
                  onKeyDown={(e) => {
                    if (e.code === 'Space') {
                      e.preventDefault();
                      handleSelect(tab, option);
                    }
                  }}
                  selected={
                    option.value == selectedValue && tab.value == selectedTab
                  }
                >
                  <label htmlFor={option.value} tabIndex={0}>
                    {option.label}
                  </label>
                </Option>
              ))}
            </div>
          ))}
        </Options>
      )}
    </Wrapper>
  );
};
