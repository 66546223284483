import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 2rem 0;
  border: 3px solid ${({ theme }) => theme.colors.purple[300]};
  border-left: 1rem solid ${({ theme }) => theme.colors.purple[300]};

  padding: 1rem;
`;

export const OptionsWrapped = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0.5rem;
  min-width: 11.625rem;
`;
