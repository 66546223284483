import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => !isLoading && 'border: 1px solid #d8d8d8'};
  border-radius: 0.3125rem;
  position: relative;
  background-color: ${({ theme }) => theme.colorMappings.chartBackground};
  margin: 0 2.5rem 4.375rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin: 0 0 4.375rem;
    }
  `}
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 1.25rem;
  max-width: 35rem;
`;

export const ResizeContainer = styled.div<{ isMaxWidth: boolean }>`
  width: 1.75rem;
  height: 4.3125rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.8125rem;
  top: 50%;
  z-index: 99;
  background-color: #fff;
  cursor: pointer;
  ${({ isMaxWidth }) =>
    !isMaxWidth &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
