import { captureException } from '@sentry/node';

export const reverseGeocode = async (lat: number, lng: number) => {
  try {
    return await fetch(`/api/map/reverseGeocode?lat=${lat}&lng=${lng}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then(async (res) => {
        const parsedResponse = await res.json();
        return {
          newGeocode: parsedResponse?.data?.newGeocode?.result?.[0],
        };
      })
      .catch((err) => err);
  } catch (err) {
    captureException(err);
    return { geocode: null, newGeocode: null };
  }
};
