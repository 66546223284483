import { t } from 'i18next';
import { format } from 'date-fns';
import { getThumbnail } from 'Client/services/cloudinary';
import { TypedFiles } from 'Shared/types/cloudinary';
import { Document } from 'Shared/types/planningapp';

type FileSections = {
  name: string;
  url: string;
  thumbnail: string;
  info: {
    header: string;
    value: string;
    width: number;
  }[];
}[][];

export const filterDocumentsFromTypedFiles = (
  typedDocs: TypedFiles
): FileSections => {
  return Object.keys(typedDocs || {}).map((key) => {
    return typedDocs[key].map((file) => {
      const { filename, display_name, url, public_id, pages, created_at } =
        file;
      const name = display_name || filename;
      const thumbnail = getThumbnail(public_id);

      const info = [
        {
          header: t('Type'),
          value: key,
          width: 24,
        },
        {
          header: t('Time to read'),
          value: pages ? `${pages} min` : '',
          width: 9,
        },
        {
          header: t('Created at'),
          value: created_at
            ? format(new Date(file.created_at), 'dd-MM-yyyy')
            : '',
          width: 15,
        },
      ];

      return {
        name,
        url,
        thumbnail,
        info,
      };
    });
  });
};

export const filterDocumentsFromPlanningApp = (
  documents: Document[]
): FileSections => {
  const filePerType = documents.reduce((acc, file) => {
    const { type, name, url, date } = file;

    const filteredType = type ? type : t('Other');

    const info = [
      {
        header: t('Type'),
        value: filteredType,
        width: 20,
      },
      {
        header: t('Created at'),
        value: date ? format(new Date(date), 'dd-MM-yyyy') : '',
        width: 23,
        align: 'left',
      },
    ];

    if (acc[filteredType]) {
      return {
        ...acc,
        [filteredType]: [
          ...acc[filteredType],
          {
            name,
            url,
            info,
          },
        ],
      };
    }

    return {
      ...acc,
      [filteredType]: [
        {
          name,
          url,
          info,
        },
      ],
    };
  }, {});

  return Object.keys(filePerType).map((key) => {
    return filePerType[key];
  });
};
