import * as React from 'react';
import { IconProps } from '../types';

export const LineIcon: React.FC<IconProps> = ({
  color = 'none',
  width = 21,
  height = 21,
  stroke = '#7A3995',
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 17 21"
    fill={color}
    width={width}
    height={height}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2669 2.84881L8.44653 1.97988C7.86803 1.9156 7.44481 2.51299 7.69732 3.03743L13.3418 14.7606C13.5418 15.1759 13.318 15.671 12.8741 15.7953L1.01709 19.1152"
      stroke={stroke}
      strokeWidth="2.22222"
    />
    <circle cx="7.57244" cy="1.90228" r="1.40228" fill="#CBA6DA" />
    <circle cx="15.425" cy="2.64721" r="1.40228" fill="#CBA6DA" />
    <circle cx="13.1464" cy="15.4869" r="1.40228" fill="#CBA6DA" />
    <circle cx="1.40228" cy="19.0977" r="1.40228" fill="#CBA6DA" />
  </svg>
);
