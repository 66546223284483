import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 25rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.75rem;

  background-color: ${({ theme }) => theme.colors.black[100]};
  outline: 1px solid #dfdfdf;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
`;

export const Tab = styled.div<{ isSelected?: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black[500]};
  opacity: 0.4;
  padding: 0.75rem;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: auto;

  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.1rem;
      outline-width: 0.1875rem;
    }
  `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      opacity: unset;
      border-bottom: 0.3125rem solid ${({ theme }) => theme.colorMappings.brand};
    `}

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const TabContent = styled.div<{ animation: string }>`
  @keyframes slideLeft {
    0% {
      transform: translateX(75%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(-75%);
    }
    100% {
      transform: translateX(0);
    }
  }
  ${({ animation }) =>
    animation == 'left' &&
    css`
      animation: slideLeft 0.3s ease-in-out;
    `}

  ${({ animation }) =>
    animation == 'right' &&
    css`
      animation: slideRight 0.3s ease-in-out;
    `}
`;

export const Content = styled.div`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  outline: 1px solid #dfdfdf;
`;

export const FixedOption = styled.a<{ isSelected?: boolean }>`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.colors.black[500]};
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colorMappings.brand};

      ${({ theme }) => css`
        &:focus {
          outline-color: ${theme.colorMappings.focusHighlight};
          outline-style: solid;
          outline-offset: 0.5rem;
          outline-width: 0.1875rem;
        }
      `}
    `}

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const CustomOption = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
  }

  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black[500]};

  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.5rem;
      outline-width: 0.1875rem;
    }
  `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colorMappings.brand};
    `}
`;
