import styled from 'styled-components';

export const Wrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3rem;
  background: ${({ theme }) => theme.colors.white[500]};
`;

export const Value = styled.div`
  display: inline;
  margin-right: 0.5rem;
  font-weight: 600;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]} !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
`;
