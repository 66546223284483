import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { OnboardingPagesTemplate as Template } from 'Templates';
import { useReturnIfAllCustomers } from 'Client/utils/hooks/useReturnIfAllCustomers';
import { setCurrentTab } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { formatCustomerNameForURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { CreateProjectFormPageProps } from '../types';
import { Wrapper } from '../ProjectListPage.styles';
import { Footer, InfoRow, TopBar } from '../components';
import {
  FormWrapper,
  ImageWrapper,
  Content,
} from './CreateProjectFormPage.styles';
import { imageUrls } from './constants/cloudinaryUrls';
import { Form } from './components/Form/Form';
import { FormModal, FormTitle } from './components';
import { modalOptions } from './constants/modalOptions';

export const CreateProjectFormPage: React.FC<CreateProjectFormPageProps> = ({
  step,
  template,
}: CreateProjectFormPageProps) => {
  useReturnIfAllCustomers();
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = React.useState(imageUrls.default);
  const [focused, setFocused] = React.useState<string>('');
  const [position, setPosition] = React.useState<number>(0);
  const [clicked, setClicked] = React.useState<boolean>(false);
  const formWrapper = React.useRef(null);
  const imageWrapper = React.useRef(null);

  const setPositionWithBoundaries = () => {
    const index = Object.keys(modalOptions).findIndex((o) => o === focused);
    const marginBottom = 100;

    if (index >= 0) {
      const currentInput =
        formWrapper.current.children[1].children[index].getBoundingClientRect();

      const { top: imageWrapperTop, bottom: imageWrapperBottom } =
        imageWrapper.current.getBoundingClientRect();
      const { top: inputTop, bottom: inputBottom } = currentInput;

      if (
        inputTop > imageWrapperTop &&
        inputBottom < imageWrapperBottom - marginBottom
      ) {
        setPosition(inputTop);
      }
    }
  };

  React.useEffect(() => {
    dispatch(setCurrentTab('Projects'));
  }, []);

  React.useEffect(() => {
    if (focused) {
      setImageUrl(imageUrls[focused]);
      setPositionWithBoundaries();
    }
  }, [focused, imageUrls]);

  return (
    <Template>
      <Wrapper data-testid="CreateProjectFormPage-Wrapper">
        <TopBar title="PROJECT INFORMATION" />
        <InfoRow />
        <Content>
          <FormWrapper
            ref={formWrapper}
            onScroll={() => {
              setPositionWithBoundaries();
            }}
          >
            <FormTitle step={step} />
            <Form
              setFocused={setFocused}
              setPosition={setPosition}
              validate={clicked}
              setClicked={setClicked}
              template={template}
            />
          </FormWrapper>
          <ImageWrapper imageUrl={imageUrl} ref={imageWrapper}>
            <FormModal
              title={modalOptions[focused || 'default'].title}
              text={modalOptions[focused || 'default'].text}
              position={position}
            />
          </ImageWrapper>
        </Content>
        <Footer
          onClickNext={() => {
            setClicked(true);
          }}
          backHref={`/project-centre?customer=${formatCustomerNameForURL(
            query.customer
          )}`}
        />
      </Wrapper>
    </Template>
  );
};
