import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { CancelIcon, LegendIcon } from 'Atoms/Icons';
import { useMap, useProject } from 'Client/utils/hooks';
import { CONTRIBUTIONS_LAYER } from 'Client/constants/map';
import {
  Title,
  Legend,
  LegendButton,
  LegendContainer,
  LegendList,
  CancelWrapper,
  MobileKeyButton,
  SentimentBar,
} from './MapLegends.styles';
import { LegendItem } from './LegendItem';

export const MapLegends = ({ isLeftPanelOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const project = useProject();
  const mapEditV2 = project.features.mapEditModeV2;
  const {
    state: { proposal, isContributionFlowStarted },
  } = useMap();

  const [isOpen, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);

  const sentiment = mapEditV2
    ? []
    : [
        {
          title: t('Positive'),
          color: theme['colorMappings'].sentimentPositive,
        },
        {
          title: t('Mostly Positive'),
          color: theme['colorMappings'].sentimentMostlyPositive,
        },
        { title: t('Neutral'), color: theme['colorMappings'].sentimentNeutral },
        {
          title: t('Mostly Negative'),
          color: theme['colorMappings'].sentimentMostlyNegative,
        },
        {
          title: t('Negative'),
          color: theme['colorMappings'].sentimentNegative,
        },
      ];

  const isMobile = useMediaQuery(theme['breakpoints'].down('sm'));

  React.useEffect(() => {
    if (isOpen && isContributionFlowStarted) {
      setOpen(false);
    }
  }, [isContributionFlowStarted, setOpen, isOpen]);

  React.useEffect(() => {
    if (proposal?.geolytixWorkspace?.locales?.UK?.legends) {
      setList(
        proposal.geolytixWorkspace.locales.UK.legends.filter((l) => l.active)
      );
    }
  }, [proposal]);

  const toggleOpen = () => setOpen(!isOpen);

  const hideSentimentLegends =
    !!proposal?.featureFlags?.hideMapSentimentLegends;
  return list.length ? (
    <>
      {isMobile && (
        <MobileKeyButton
          onClick={toggleOpen}
          active={isOpen}
          isLeftPanelOpen={isLeftPanelOpen}
        >
          <LegendIcon title={t('Legend')} />
          {!hideSentimentLegends && <SentimentBar />}
        </MobileKeyButton>
      )}
      <Legend isOpen={isOpen} data-testid="MapLegends">
        {!isMobile && (
          <LegendButton isOpen={isOpen} onClick={toggleOpen}>
            {!isOpen && (
              <>
                <LegendIcon title={t('Legend')} />
                {!hideSentimentLegends && <SentimentBar />}
              </>
            )}
            {isOpen && (
              <>
                <Title isOpen={isOpen}>{t('KEY')}</Title>
                <CancelWrapper onClick={toggleOpen}>
                  <CancelIcon />
                </CancelWrapper>
              </>
            )}
          </LegendButton>
        )}
        <LegendContainer isOpen={isOpen}>
          {isOpen &&
            !hideSentimentLegends &&
            sentiment.length &&
            !proposal?.geolytixWorkspace?.locales?.UK?.layers?.[
              CONTRIBUTIONS_LAYER
            ]?.pivotQuestion && (
              <LegendList>
                {sentiment.map((el) => (
                  <LegendItem
                    key={el.title}
                    title={el.title}
                    color={el.color}
                  />
                ))}
              </LegendList>
            )}
          {isOpen && list.length && (
            <LegendList>
              {list.map((el) => (
                <LegendItem
                  key={el.title}
                  title={el.title}
                  color={el.color}
                  svg={el.svg}
                />
              ))}
            </LegendList>
          )}
        </LegendContainer>
      </Legend>
    </>
  ) : null;
};
