import * as React from 'react';
import styled from 'styled-components';
import { HtmlDescription } from 'Client/components/molecules';
import { DemographicsLightBulb } from 'Atoms/Icons/LightBulb/DemographicsLightBulb';

const LightBulb = ({ children }) => {
  return (
    <Wrapper>
      <SvgContainer>
        <DemographicsLightBulb />
      </SvgContainer>
      <TextContainer>
        <HtmlDescription>{children}</HtmlDescription>
      </TextContainer>
    </Wrapper>
  );
};

export default LightBulb;

const Wrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${theme.colors.green[200]};
  color: ${theme.colors.green['A200']};
  position: relative;
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid ${theme.colors.white[800]};
  gap: 2rem;
    ${theme.breakpoints.down('sm')}{
      gap: 1rem;
    }
`}
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')}{
      & > svg {
        width: 63px;
        height: 63px;
      }
    }
  `}
`;
const TextContainer = styled.div`
  & > div > div > span {
    ${({ theme }) => `
      ${theme.breakpoints.up('sm')}{
        font-size: 1.25rem;
        line-height: 1.625rem;
      }
    `}
  }
`;
