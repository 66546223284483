import * as React from 'react';
import { PlanningAppPagesTemplate as Template } from 'Templates';
import {
  PlanningAppsPageWithContentProps,
  PlanningAppsPageTabs,
} from 'Shared/types/planningapp';
import { Content } from './PlanningAppPage.styles';
import { ViewTabs } from './components';

export const PlanningApps: React.FC<
  PlanningAppsPageWithContentProps<PlanningAppsPageTabs.LIST>
> = ({ content, planningApps }) => {
  const { tabs, title } = content;

  return (
    <Template>
      <Content>
        <div style={{ marginTop: '3rem' }}>
          <h1>{title}</h1>
        </div>

        <ViewTabs tabs={tabs} planningApps={planningApps} />
      </Content>
    </Template>
  );
};
