import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import { SortOptions } from 'Shared/types/dashboard';
import { TableWrapper } from '../TableWrapper';
import { currentBasicColumns } from './constantColumns';
import { GET_CAMPAIGNS_TAB_QUERY } from './CampaignsTab.gql';
import { VisitorsTabsProps } from '../types';

export const CampaignsTab: React.FC<VisitorsTabsProps> = ({
  appliedFilters,
}: VisitorsTabsProps) => {
  const { id } = useProject();
  const [campaigns, setCampaigns] = React.useState([]);
  const [tableSort, setTableSort] = React.useState<SortOptions>(null);

  const { data, loading } = useQuery(GET_CAMPAIGNS_TAB_QUERY, {
    variables: {
      campaignsInput: {
        projectName: id,
        sortOptions: tableSort,
        dates: appliedFilters?.topFilters?.date
          ? appliedFilters.topFilters.date
          : { to: null, from: null },
      },
    },
  });

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setCampaigns(data.getCampaigns);
    }
  }, [data, tableSort]);

  return (
    <TableWrapper
      tableMapHeadersParams={{
        basicColumns: {
          header: '',
          id: 'general',
          columns: currentBasicColumns,
        },
      }}
      loading={loading}
      rowsData={campaigns}
      tableSort={tableSort}
      setTableSort={setTableSort}
      loadMoreData={() => ''}
      setFlatRowsIds={() => ''}
    />
  );
};
