import * as React from 'react';
import styled from 'styled-components';
import { HubPagesTemplate as Template } from 'Templates';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { useProject } from '../../utils/hooks';
import { PreviewPageProps } from '../edit/types';
import { HeroContent } from '../hub';
import {
  Hero,
  News,
  Proposals,
  Milestones,
  ProjectTeam,
  Section,
} from '../hub/components';

export const PreviewHubPage: React.FC<PreviewPageProps> = (
  props: PreviewPageProps
) => {
  const project = useProject();
  const { proposals, newsPosts, milestones, stakeholders, hubContent } = props;

  if (!hubContent) {
    return (
      <>
        <Template {...props}>
          <h1>Version not found</h1>
        </Template>
      </>
    );
  }
  if (hubContent && hubContent?.content.sections?.length > 0) {
    const { sections } = hubContent.content;
    const sectionHero = hubContent?.content.sections.find(
      (section) => section.type === 'hero'
    );

    const sectionHeroContent = sectionHero.content as HeroContent;
    const sectionComponentMap = {
      hero: (
        <Hero
          title={sectionHeroContent?.title}
          hasNews={project?.features?.news}
          description={sectionHeroContent?.description}
          learnMoreProps={{
            href: sectionHeroContent?.learnMore?.link?.url,
            text: sectionHeroContent?.learnMore?.link?.text,
          }}
          layout={sectionHeroContent?.layout}
          backgroundImage={sectionHeroContent?.backgroundImage}
          {...props}
        />
      ),
      news: <News newsPosts={newsPosts} isHub />,
      proposals: <Proposals proposals={proposals} />,
      milestones: <Milestones milestones={milestones} />,
      team: <ProjectTeam stakeholders={stakeholders} />,
    };

    return (
      <>
        <Template {...props}>
          <FullWidthContainer>
            <>
              {sections.map((section, index) => (
                <Section
                  key={`section:${section.type}-${index}`}
                  type={section.type}
                  Component={sectionComponentMap[section.type]}
                />
              ))}
            </>
            <EditModeButton />
          </FullWidthContainer>
        </Template>
      </>
    );
  }
  return null;
};

const FullWidthContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  padding-bottom: 2rem;
`;
