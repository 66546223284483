import * as React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import MuiButton from '@material-ui/core/Button';
import MuiTypography from '@material-ui/core/Typography';
import { ChevronIcon as ChevronIconBase } from 'Atoms/Icons';
import { Checkbox as CheckboxBase } from 'Molecules/Checkbox';

type Props = {
  sentimentCount: { [sentiment: string]: number };
  positiveChecked: boolean;
  mostlyPositiveChecked: boolean;
  neutralChecked: boolean;
  mostlyNegativeChecked: boolean;
  negativeChecked: boolean;
  setPositiveChecked: (boolean) => void;
  setMostlyPositiveChecked: (boolean) => void;
  setNegativeChecked: (boolean) => void;
  setMostlyNegativeChecked: (boolean) => void;
  setNeutralChecked: (boolean) => void;
};

export const FilterPanel = ({
  sentimentCount,
  positiveChecked,
  mostlyPositiveChecked,
  neutralChecked,
  mostlyNegativeChecked,
  negativeChecked,
  setPositiveChecked,
  setMostlyPositiveChecked,
  setNeutralChecked,
  setMostlyNegativeChecked,
  setNegativeChecked,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isOpen, setIsOpen] = React.useState(true);

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const [scrollTop, setScrollTop] = React.useState(0);
  const [fixedPanel, setFixedPanel] = React.useState(false);

  React.useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setFixedPanel(scrollTop > 70);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <>
      <PanelContainer isOpen={isOpen} fixedPanel={fixedPanel}>
        <OpenCloseTag onClick={handleOpenClose}>
          <ChevronIcon isOpen={isOpen} />
        </OpenCloseTag>
        <FiltersTitle>{t('Contribution filters')}</FiltersTitle>

        <CheckBoxContainer>
          <Checkbox
            data-testid="sentiment-checkbox"
            label={t('Positive • {{positiveContributions}}', {
              positiveContributions: sentimentCount[100],
            })}
            onChange={(e) => {
              const { checked } = e.target;
              setPositiveChecked(checked);
            }}
            status={{ show: false }}
            checked={positiveChecked}
            sentiment={'sentimentPositive'}
          />
          <Checkbox
            data-testid="sentiment-checkbox"
            label={t('Mostly positive • {{mostlyPositiveContributions}}', {
              mostlyPositiveContributions: sentimentCount[75],
            })}
            onChange={(e) => {
              const { checked } = e.target;
              setMostlyPositiveChecked(checked);
            }}
            status={{ show: false }}
            checked={mostlyPositiveChecked}
            sentiment={'sentimentMostlyPositive'}
          />
          <Checkbox
            data-testid="sentiment-checkbox"
            label={t('Neutral • {{neutralContributions}}', {
              neutralContributions: sentimentCount[50],
            })}
            onChange={(e) => {
              const { checked } = e.target;
              setNeutralChecked(checked);
            }}
            status={{ show: false }}
            checked={neutralChecked}
            sentiment={'sentimentNeutral'}
          />
          <Checkbox
            data-testid="sentiment-checkbox"
            label={t('Mostly negative • {{mostlyNegativeContributions}}', {
              mostlyNegativeContributions: sentimentCount[25],
            })}
            onChange={(e) => {
              const { checked } = e.target;
              setMostlyNegativeChecked(checked);
            }}
            status={{ show: false }}
            checked={mostlyNegativeChecked}
            sentiment={'sentimentMostlyNegative'}
          />
          <Checkbox
            data-testid="sentiment-checkbox"
            label={t('Negative • {{negativeContributions}}', {
              negativeContributions: sentimentCount[0],
            })}
            onChange={(e) => {
              const { checked } = e.target;
              setNegativeChecked(checked);
            }}
            status={{ show: false }}
            checked={negativeChecked}
            sentiment={'sentimentNegative'}
          />
        </CheckBoxContainer>

        <BackToMap
          aria-label="Back to map"
          onClick={() => {
            router.push('/proposals/awesome-map/step1');
          }}
        >
          {t('Back to map')}
        </BackToMap>
      </PanelContainer>
    </>
  );
};

const BackToMap = styled(MuiButton)`
  color: ${({ theme }) => theme.colorMappings.brand};
  position: absolute;
  bottom: 0.63rem;
  left: 0.63rem;
`;

const FiltersTitle = styled(MuiTypography)`
  margin-left: 2.19rem;
  margin-top: 2.19rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const PanelContainer = styled('div')<{ isOpen: boolean; fixedPanel: boolean }>`
  width: 23.4rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0.1rem 0 0.3rem -0.1rem;
  position: absolute;
  z-index: 2; /* Todo: change to z-index from theme */
  height: calc(100vh - 7.9rem);
  top: 7.9rem;
  overflow: visible;
  left: 0;
  transition: left 0.2s ease-out;

  ${({ fixedPanel }) =>
    fixedPanel &&
    css`
      height: calc(100vh - 3.5rem);
      position: fixed;
      top: 3.5rem;
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      left: -23.4rem;
    `};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      height: calc(
        100vh - 4.375rem - 3.5rem
      ); /* viewport - navigation height - footer height */
    }

    ${theme.breakpoints.down('xs')} {
      overflow: auto;
      width: 100%;
    }
  `};
`;

const OpenCloseTag = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0.1rem 0 0.3rem -0.1rem;
  position: absolute;
  top: 1.88rem;
  right: -1.5rem;
  width: 1.5rem;
  height: 3.12rem;
  z-index: 2; /* Todo: change to z-index from theme */
  overflow: visible;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `};
`;

const ChevronIcon = styled(ChevronIconBase)<{ isOpen: boolean }>`
  transition: transform 0.2s ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 2.19rem;
  margin-top: 0.94rem;
`;

const Checkbox = styled(CheckboxBase)<{
  sentiment:
    | 'sentimentPositive'
    | 'sentimentMostlyPositive'
    | 'sentimentNeutral'
    | 'sentimentMostlyNegative'
    | 'sentimentNegative';
}>`
  .MuiTypography-root {
    margin-left: 0.63rem;
  }

  ${({ theme, sentiment }) =>
    sentiment &&
    css`
      .MuiSvgIcon-root {
        fill: ${theme.colorMappings[sentiment]} !important;
      }
    `};
`;
