import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { NewsPostAcornDb, NewsPostGaudi } from 'Shared/types/news';

type Params = {
  acornNews: NewsPostAcornDb;
  gaudiNews?: NewsPostGaudi;
};

export const saveProjectNews = async ({
  acornNews,
  gaudiNews,
}: Params): Promise<{
  success: boolean;
  data?: NewsPostAcornDb;
  message?: string;
}> => {
  try {
    const res = await fetch('/api/news', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(acornNews),
    });
    const acornResponse = await res.json();

    if (gaudiNews) {
      try {
        await fetch('/api/news?originDb=gaudi', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ _id: acornResponse?.data?._id, ...gaudiNews }),
        });
        await res.json();
      } catch (err) {
        captureException(
          `Error in saveProjectNews @ saveProjectNews.ts when saving news post to gaudi db: ${err}`
        );
      }
    }
    return acornResponse;
  } catch (error) {
    captureException(`Error in saveProjectNews @ saveProjectNews.ts: ${error}`);
    return {
      success: false,
      message: `Error while saving news posts: ${error}`,
    };
  }
};
