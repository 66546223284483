import React from 'react';
import { useProject } from 'Client/utils/hooks';
import { HubPagesTemplate as Template } from 'Templates';
import { Container, SeoMetaInfo } from 'Atoms';
import { TilesSectionRender } from './components/tilesSectionRender';
import { TilesPageContent } from './types';
import { ProposalsContainer } from './TilesPage.styles';
import { EditModeButton } from '../edit/components/EditModeButton';

export const TilesPage: React.FC<{ content: TilesPageContent }> = ({
  ...props
}) => {
  const project = useProject();

  const { sections } = props.content;

  return (
    <>
      <Template {...props}>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={project.name}
          page="proposals"
        />
        <Container>
          <ProposalsContainer>
            {sections.map((section, index) => (
              <TilesSectionRender
                {...section}
                key={`${section?.type}-${index}`}
              />
            ))}
          </ProposalsContainer>
        </Container>
        <EditModeButton />
      </Template>
    </>
  );
};
