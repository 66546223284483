import { useRouter } from 'next/router';
import * as React from 'react';
import { useBanner } from 'Client/utils/hooks/useBanner';
import {
  Content,
  PanelContainer,
  CloseIcon,
  Footer,
  Screen,
  CloseButton,
} from './MapSlidePanel.styles';
import { SlidePanelProps } from './types';

export const SlidePanel = ({
  onClose,
  children,
  footer,
  isContributionPanel,
}: SlidePanelProps): JSX.Element => {
  const router = useRouter();
  const { embed: embedUrl } = router.query;
  const isEmbed = embedUrl === 'true';

  const [height, setHeight] = React.useState(25);
  const [isOpen, setIsOpen] = React.useState(true);

  const closePanel = () => {
    setIsOpen(false);
    // Fade out animation time for closing panel
    setTimeout(() => onClose(), 400);
  };

  const onResize = (e, { size }) => {
    e.preventDefault();
    if (height === 25 && size.height < 25) setHeight(80);
    else if (height === 25 && size.height > 25) closePanel();
    else if (height === 80 && size.height > 80) setHeight(25);
  };

  const onScroll = (e) => {
    if (height === 25 && e.target.scrollTop > 0) {
      setHeight(80);
    }
  };

  const onClick = () => {
    if (height === 25) {
      setHeight(80);
    }
  };

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return (
    <Screen>
      <PanelContainer
        data-testid="SlidePanel"
        isOpen={isOpen}
        height={height}
        onResizeStop={onResize}
        isContributionPanel={isContributionPanel}
        isEmbed={isEmbed}
        errorBannerHeight={errorBannerHeight}
        stageBannerHeight={stageBannerHeight}
      >
        <Content
          height={height}
          onScroll={onScroll}
          onClick={onClick}
          hasFooter={footer}
        >
          {onClose && (
            <CloseButton onClick={closePanel}>
              <CloseIcon />
            </CloseButton>
          )}
          {children}
          {footer && <Footer isOpen={isOpen}>{footer}</Footer>}
        </Content>
      </PanelContainer>
    </Screen>
  );
};
