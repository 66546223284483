import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { ProjectProps } from 'Shared/types';

interface ProposalNewsSignUpParams {
  email: string;
  lang?: string;
  pageId: string;
  project: ProjectProps;
  planApp: {
    planAppAddress: string;
    planAppId: string;
  };
}

export const proposalNewsSignUp = async ({
  email,
  lang = 'en-GB',
  pageId,
  planApp,
}: ProposalNewsSignUpParams) => {
  try {
    const response = await fetch(`/api/consent`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        lang,
        consents: [
          {
            type: CONSENT_TYPES.PROPOSAL_NEWS,
          },
        ],
        pageId,
      }),
    });
    await fetch('/api/email/proposalNewsConfirmation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailAddress: email,
        planApp,
      }),
    });
    if (!response.ok) {
      throw new Error('Could not save proposal news consent.');
    }
  } catch (error) {
    captureException(
      `Error in proposalNewsSignUp @ proposalNewsSignUp.ts : ${error}`
    );

    throw new Error();
  }
};
