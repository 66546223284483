import * as React from 'react';
import { Background, Description } from './DaysToComment.styles';
import { DaysToCommentProps } from './types';

export const DaysToComment: React.FC<DaysToCommentProps> = ({ daysLeft }) => {
  return (
    <Background>
      <Description data-testid="planningapps-days-left-to-comment">
        <span>
          {daysLeft} DAY{`${daysLeft > 1 ? 'S' : ''}`}
        </span>{' '}
        LEFT TO COMMENT
      </Description>
    </Background>
  );
};
