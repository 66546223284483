import styled from 'styled-components';

export const Wrapper = styled.div``;

export const SectionTitle = styled.h1`
  max-width: 56rem;
  margin: 0 auto 1.375rem;
  font-weight: 700;
  font-size: 2rem;
  color: black;
`;

export const SectionListWrapper = styled.div`
  margin-bottom: 2.5rem;
  .contribution-details-card {
    margin: 0 auto 2rem;
  }
`;
