import * as React from 'react';
import { ContributionsListProps } from '../../types';
import {
  Wrapper,
  SectionTitle,
  SectionListWrapper,
} from './ContributionsList.styles';

export const ContributionsList: React.FC<ContributionsListProps> = ({
  title,
  children,
}: ContributionsListProps) => {
  return (
    <Wrapper>
      <SectionTitle>{title}</SectionTitle>
      <SectionListWrapper>{children}</SectionListWrapper>
    </Wrapper>
  );
};
