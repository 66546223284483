import styled, { css } from 'styled-components';
import * as React from 'react';
import { readableColor, lighten } from 'polished/lib';
import { Checkbox, TextField } from 'Client/components/molecules';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
`;

export const FiltersSectionContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 1rem;
  padding-bottom: 1rem;
  & > #filter-section-right-side {
    display: flex;
    flex-direction: ${isMobile ? 'row-reverse' : 'row'};
    align-items: center;
    gap: 1rem;
    width: ${isMobile ? '100%' : '85%'};
  }
  & > #filter-section-left-side {
    width 15%;
    display: flex;
    justify-content: flex-end;
  }
  `}
`;

export const PlanAppFilterButtonBase = styled.button<{
  width?: string;
  textAlign?: 'flex-start' | 'center' | 'flex-end';
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  gap: 0.5rem;

  text-decoration: none;
  color: inherit;
  ${({ width }) => (width ? `width: ${width};` : '')}
  appearance: none;
  border-radius: 1.3125rem;
  border: 0.0625rem solid #979797;
  background-color: white;
  padding: 0.75rem 1.563rem;
  font-weight: 600;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: #0005;
  }
`;

export const PlanAppBellButton = styled.button`
  border-radius: 50%;
  border: 0;
  box-shadow: inset 0 0 0.125rem 0.0625rem #000;
  background-color: #f5b044;
  height: 2.25rem;
  width: 2.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:disabled {
    background-color: #eee;
    box-shadow: inset 0 0 0.125rem 0.0625rem #0005;
  }
`;

export const MoreFiltersButton = styled(PlanAppFilterButtonBase)`
  ${({ theme, 'aria-expanded': ariaExpanded }) =>
    ariaExpanded &&
    `background-color: ${lighten(0.45, theme.colorMappings.brand)};`}
`;
export const FiltersDraw = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  align-items: center;
  z-index: 1201;
  background: #ffffff;
  box-shadow: 0rem 1.25rem 1.25rem rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  ${({ isMobile }) =>
    isMobile &&
    `
    border-radius: 1.875rem 1.875rem 0 0;
    position: fixed;
    height: fit-content;
    bottom: 0;
    left: 0;
  `}
`;

export const DrawHeader = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  position: relative;
  ${({ isMobile }) => `padding-top: ${isMobile ? '2rem' : '0.5rem'};`}
  & > div {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > h1 {
      padding-left: 1rem;
    }
    & > * {
      margin: 0;
    }
  }
`;

export const CloseButton = styled.button<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.colors.white[500]};
  position: absolute;
  right: 2%;
  top: 0.75rem;
  height: 1rem;
  width: 1rem;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: inherit;
  ${({ isMobile }) =>
    isMobile &&
    `
    position: relative;
    top: 0;
  `}
`;

export const ClearFiltersButton = styled.button<{ hasFilters?: boolean }>`
  border: 0rem;
  background: transparent;
  border-bottom: 0.0625rem solid black;
  cursor: pointer;
  width: 6.3rem;
  padding-bottom: 0.1875rem;
  text-decoration: none;
  color: inherit;
  ${({ hasFilters }) =>
    !hasFilters &&
    css`
      display: none;
    `}
`;

export const DrawFilters = styled.div<{
  filtersQtd: number;
  isMobile: boolean;
}>`
  overflow: visible;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0rem 1rem 2rem 1rem;
  min-width: calc(100% / 3 - 2rem);

  ${({ isMobile }) =>
    isMobile
      ? `
        flex-direction: column;
        gap: 1rem;
        overflow: scroll;
        flex-wrap: no-wrap;
        justify-content: flex-start;
        gap: 1rem;
        padding-top: 2rem;
        & > div {
          width: 100%;
        }
      `
      : `
        justify-content: space-between;
        gap: 1rem;
        flex-direction:row;
        flex-wrap: wrap;
          & > div {
            width: calc(33% - 2rem);
          }
      `};
`;

export const GeneralFilter = styled(({ ...props }) => <TextField {...props} />)`
  ${({ isMobile }) => (isMobile ? 'width: 100%;' : 'width: 32%;')}
  min-width: unset !important;

  padding-bottom: 20px;

  & label {
    font-size: 12px;
    padding: 0;
  }
`;

export const DrawFilterItem = styled.div`
  height: auto;
  position: relative;

  & > div {
    min-width: unset !important;
    width: 100%;
  }
  .MuiFormLabel-root {
    padding-top: 0 !important;
  }
  .react-select__control {
    border-radius: 0.3rem;
  }
  .react-select__value-container {
    padding: 0 0.9rem !important;
  }
  .react-select__placeholder {
    font-size: 1rem !important;
  }
`;

export const CheckboxField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const CheckboxLabel = styled.label``;
export const CheckboxOptions = styled.div``;

export const CheckboxItem = styled(Checkbox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const BottomSection = styled.div`
  height: 4.7rem;
  width: 100%;
  background-color: ${({ theme }) => lighten(0.45, theme.colorMappings.brand)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2.5rem;
`;

export const ApplyFiltersButton = styled.button<{ isMobile: boolean }>`
  appearance: none;
  background-color: ${({ theme }) => theme.colorMappings.brand};
  color: ${({ theme }) => readableColor(theme.colorMappings.brand)};
  border-radius: 0.125rem;
  border: 0;
  padding: 0.938rem 2.438rem;
  margin: 1rem 0;
  font-weight: 600;
  cursor: pointer;
  ${({ isMobile }) => (isMobile ? 'width: 100%;' : 'width: auto')};
`;
