import React from 'react';

const Clock = ({ width = 18, height = 18, fill = '#000' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6481 0.851859C5.00608 1.20984 5.00608 1.79024 4.6481 2.14822L2.1481 4.64822C1.79012 5.0062 1.20972 5.0062 0.851737 4.64822C0.493757 4.29024 0.493757 3.70984 0.851737 3.35186L3.35174 0.85186C3.70972 0.493879 4.29012 0.493879 4.6481 0.851859ZM13.3517 0.85186C13.7097 0.493879 14.2901 0.493879 14.6481 0.85186L17.1481 3.35186C17.5061 3.70984 17.5061 4.29024 17.1481 4.64822C16.7901 5.0062 16.2097 5.0062 15.8517 4.64822L13.3517 2.14822C12.9938 1.79024 12.9938 1.20984 13.3517 0.85186ZM8.99992 4.08337C5.82428 4.08337 3.24992 6.65774 3.24992 9.83337C3.24992 13.009 5.82428 15.5834 8.99992 15.5834C12.1756 15.5834 14.7499 13.009 14.7499 9.83337C14.7499 6.65774 12.1756 4.08337 8.99992 4.08337ZM1.41659 9.83337C1.41659 5.64521 4.81176 2.25004 8.99992 2.25004C13.1881 2.25004 16.5833 5.64521 16.5833 9.83337C16.5833 14.0215 13.1881 17.4167 8.99992 17.4167C4.81176 17.4167 1.41659 14.0215 1.41659 9.83337ZM8.99992 5.58337C9.50618 5.58337 9.91659 5.99378 9.91659 6.50004V9.45368L11.3148 10.8519C11.6727 11.2098 11.6727 11.7902 11.3148 12.1482C10.9568 12.5062 10.3764 12.5062 10.0184 12.1482L8.35174 10.4816C8.17983 10.3096 8.08325 10.0765 8.08325 9.83337V6.50004C8.08325 5.99378 8.49366 5.58337 8.99992 5.58337Z"
        fill={fill}
      />
    </svg>
  );
};

export default Clock;
