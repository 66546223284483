import * as React from 'react';
import styled, { css } from 'styled-components';
import { ChevronIcon } from 'Atoms/Icons';

export const Wrapper = styled(({ isOpen, hide, ...props }) => (
  <div {...props} />
))`
  ${({ isOpen, hide, theme }) => css`
    background-color: ${theme.colors.white[500]};
    position: absolute;
    top: 3rem;
    ${theme.direction === 'rtl' ? 'left' : 'right'}: ${isOpen
      ? '-2.25rem'
      : '-3.5rem'};
    ${theme.direction === 'rtl' && 'transform: scale(-1)'};
    width: ${isOpen ? '2.25rem' : '3.5rem'};
    height: 4rem;
    z-index: 2;
    border: 1px solid ${theme.colorMappings.greyText};
    border-left: none;
    border-radius: 0 0.3rem 0.3rem 0;
    box-shadow: 0.1875rem 0.25rem 0.1875rem ${theme.colorMappings.shadow};
    overflow: visible;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${hide && 'display: none;'}

    ${theme.breakpoints.down('xs')} {
      ${isOpen && 'display: none;'}
      top: 1.5rem;
      ${theme.direction === 'rtl' ? 'left' : 'right'}: -2.25rem;
      width: 2.25rem;
    }
  `}
`;

export const Icon = styled(({ isOpen, ...props }) => (
  <ChevronIcon opacity={'0.75'} {...props} />
))`
  ${({ isOpen }) => css`
    transition: transform 0.2s ease-out;
    ${!isOpen && 'transform: rotate(180deg);'}
  `};
`;
