import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 32.5%;
  min-width: 15rem;
  ${({ theme }) => css`
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__dropdown-indicator {
      svg {
        color: ${theme.colors.black[500]};
      }
    }
    .react-select__placeholder {
      font-size: 1rem;
      color: ${theme.colors.grey[700]};
    }
    .react-select__menu {
      z-index: 10;
      padding: 0.4rem 0;
      border-radius: 0.35rem;
    }
    .react-select__menu-list {
      max-height: 10rem;
    }
    .react-select__option {
      font-size: 1rem;
      font-weight: ${theme.fontWeights.semi};
      color: ${theme.colors.black[500]};
      &:hover {
        color: ${theme.colors.green[500]};
      }
      .react_select__options--is-focused {
        background-color: ${theme.colors.blue[50]};
      }
    }
    .react-select__multi-value__label {
      font-size: 0.9rem;
      font-weight: ${theme.fontWeights.semi};
      background: ${theme.colors.blue[100]};
    }
    .react-select__multi-value__remove {
      background: ${theme.colors.red[500]} !important;
      cursor: pointer;
      svg {
        color: ${theme.colors.white[500]};
      }
    }
  `}
`;
