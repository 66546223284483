import { darken, lighten } from 'polished';
import styled from 'styled-components';
import { AppTheme } from 'Client/types';

export const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 1.4375rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BaseIcon = styled.div<{ show: boolean }>`
  width: 1.4375rem;
  position: absolute;
  height: 1.4375rem;
  transition: all 400ms ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const CancelIcon = styled(BaseIcon)`
  background: transparent
    url('/static/illustrations/draw-search-area-controls/cancel-area-search.png')
    no-repeat center center;
`;

export const DrawIcon = styled(BaseIcon)`
  background: transparent
    url('/static/illustrations/draw-search-area-controls/search-area.png')
    no-repeat center center;
`;

export const DrawButton = styled.button<{
  variant: 'cancel' | 'start' | 'confirm';
}>`
  @media (min-width: 501px) {
    top: 2.125rem;
    right: 2.125rem;
  }

  @media (max-width: 500px) {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  position: absolute;
  padding: 0.625rem 0.9375rem;
  white-space: nowrap;

  font-weight: bold;
  background-color: ${({ theme }: { theme: AppTheme }) =>
    theme.colorMappings.white};
  border-radius: 0.1875rem;
  border: #000 0.0625rem solid;
  font-size: 1.25em;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  transition: all 400ms ease;
  box-shadow: 0 0 0.125rem #0002;

  &:hover {
    background-color: ${({ theme }: { theme: AppTheme }) =>
      darken(0.1, theme.colorMappings.white)};
    cursor: pointer;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return `
        border-color: #CF5757;
        color: #8e1c1c;
        &:hover {
          background-color: ${lighten(0.35, '#CF5757')}
        }`;
      case 'start':
        return ``;
      case 'confirm':
        return `
          border-color: #412dc9;
          color: #412dc9;
          background: #fff
          &:hover {
            background-color: ${lighten(0.35, '#412dc9')};
          }`;
    }
  }}
`;

export const MobileConfirmButton = styled(DrawButton)`
  bottom: 4.5rem;
  width: 13.75rem;
  @media (min-width: 500px) {
    display: none;
  }
`;
