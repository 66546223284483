import styled, { css } from 'styled-components';
import { Button } from 'Molecules';

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5625rem 2rem;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey[500]};
`;

export const CancelButton = styled(Button)`
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[600]};
  border-radius: 0.1875rem;
  margin-right: 1.1875rem;
  cursor: pointer;
  ${({ theme }) =>
    css`
      color: ${theme.colors.grey[900]};
      background: ${theme.colors.white[500]};

      &:hover {
        color: ${theme.colors.black[500]};
        background-color: ${theme.colors.white[500]};
        border: 1px solid ${theme.colors.black[500]};
      }
    `}
`;
