import * as React from 'react';
import { lighten } from 'polished';
import { theme } from 'Client/components/theme';
import { MultiselectOption } from 'Shared/types/question';
import {
  Wrapper,
  InputLabel,
  OptionsContainer,
  CheckboxOptionItem,
  IconWrapper,
  Checkbox,
} from './CheckboxFilter.styles';

interface CheckboxFilterProps {
  options: MultiselectOption[];
  value?: MultiselectOption[];
  onChange?: (option: MultiselectOption[]) => void;
  label?: string;
}
export const CheckboxFilter = ({
  options,
  label,
  value = [],
  onChange,
}: CheckboxFilterProps) => {
  const initialOpts = value.length
    ? options.map((opt) => ({
        ...opt,
        checked: value.find((v) => v.value === opt.value)?.checked || false,
      }))
    : options;
  const handleChange = (value: string) => {
    const newOptions = initialOpts.map((opt) => {
      if (opt.value === value) {
        return { ...opt, checked: !opt.checked };
      }
      return opt;
    });

    return onChange(newOptions);
  };
  const formatIcon = (icon: React.ReactNode): string => {
    const height = 35;
    const width = 35;
    const color = lighten(0.4, theme.colorMappings.brand);
    return String(icon)
      .replace('height="24"', `height="${height}"`)
      .replace('width="24"', `width="${width}"`)
      .replace('6578Z" fill="white"/>', `6578Z" fill="${color}"/>`);
  };
  return (
    <Wrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <OptionsContainer>
        {initialOpts.map((option) => (
          <CheckboxOptionItem
            key={option.value}
            onClick={() => {
              handleChange(option.value);
            }}
          >
            <Checkbox checked={option.checked} />
            <IconWrapper>
              {option.icon && (
                <i
                  id={option.value}
                  dangerouslySetInnerHTML={{
                    __html: formatIcon(option.icon),
                  }}
                />
              )}
            </IconWrapper>
            <p>{option.label}</p>
          </CheckboxOptionItem>
        ))}
      </OptionsContainer>
    </Wrapper>
  );
};
