import * as React from 'react';
import { SettingsIcon } from 'Atoms/Icons';
import { SettingsMenu } from 'Client/pages/dashboard/charts/components/SettingsMenu';
import { VisitorsChartHeaderProps } from 'Client/pages/dashboard/charts/types';
import { Wrapper, IconsContainer } from './ChartHeader.styles';

export const ChartHeader: React.FC<VisitorsChartHeaderProps> = ({
  settingsFunctions,
}: VisitorsChartHeaderProps) => {
  const [openSettingsMenu, setOpenSettingsMenu] =
    React.useState<boolean>(false);

  return (
    <Wrapper data-testid="ChartHeader-Wrapper">
      <IconsContainer>
        <div onClick={() => setOpenSettingsMenu((previous) => !previous)}>
          <SettingsIcon />
        </div>
      </IconsContainer>
      {openSettingsMenu && (
        <SettingsMenu settingsFunctions={settingsFunctions} />
      )}
    </Wrapper>
  );
};
