import * as React from 'react';
import { QuestionRenderer } from 'Organisms';
import { MapQuestion } from 'Shared/types';
import { QuestionPageProps } from './types';
import { MapQuestionPage } from './components/MapQuestionPage';

export const QuestionPage: React.FC<QuestionPageProps> = ({
  question,
  mapPage,
}) => {
  if (question.type === 'map') {
    return (
      <MapQuestionPage question={question as MapQuestion} mapPage={mapPage} />
    );
  }
  // this is a placeholder for other question types.
  // obviously TODO if we want to expand this page/functionality, maybe use different component altogether
  return <QuestionRenderer question={question} onChange={() => null} />;
};
