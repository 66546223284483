interface removeUserValueParams {
  setFunction: React.Dispatch<React.SetStateAction<unknown>>;
  index: number;
  cleanErrors: () => void;
}

export const removeUserValue = ({
  setFunction,
  index,
  cleanErrors,
}: removeUserValueParams) => {
  setFunction((previous) => {
    const newArray = [...previous];
    newArray.splice(index, 1).filter(Boolean);
    return [...newArray];
  });

  if (cleanErrors) {
    cleanErrors();
  }
};
