import styled from 'styled-components';

export const TitleRow = styled.p`
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  span {
    color: ${({ theme }) => theme.colors.green[500]};
  }
`;
