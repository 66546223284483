import { Grid as MuiGrid } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import fetch from 'isomorphic-unfetch';
import Typography from '@material-ui/core/Typography';
import { signIn, useSession } from 'next-auth/react';
import styled from 'styled-components';
import getConfig from 'next/config';
import { TextField, Button, LoadRing } from 'Molecules';
import { useProject } from 'Client/utils/hooks';
import { makeRedirectDomain } from 'Client/utils/url';

const {
  publicRuntimeConfig: { env },
} = getConfig();

/**
 * TODO use a better form strategy if kept
 */
export const LoginPage: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const { t } = useTranslation();
  const { features, id } = useProject();
  const { data } = useSession();
  const projectName = id;

  const redirectDomain = React.useMemo(
    () => makeRedirectDomain(projectName),
    [projectName]
  );

  const useNewLogin = features.useLoginV2;

  const callbackUrl =
    env === 'local' ? `http://${redirectDomain}` : `https://${redirectDomain}`;

  React.useEffect(() => {
    if (useNewLogin && !data?.user && window) {
      signIn('descope', { callbackUrl: callbackUrl });
    }
  }, [data?.user, useNewLogin, callbackUrl]);

  const login = async () => {
    const result = await fetch('/api/auth', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const json = await result.json();

    if (result.status === 401) {
      setError(json.message);
    } else {
      window.location.href = '/';
    }
  };

  if (features.useLoginV2)
    return (
      <LoadRingContainer>
        <LoadRing />
      </LoadRingContainer>
    );

  return (
    <MuiGrid container spacing={1} direction="column" alignContent="center">
      <MuiGrid item xs={6}>
        <TextField
          value={email}
          label={t('Email')}
          showLabel
          width="18rem"
          handleChange={(e) => setEmail(e.target.value as string)}
          type="email"
          placeholder={t('e.g. sam@smith.com')}
        />
        <Typography id="email_error" variant="srOnly">
          {t('Email error validation')}
        </Typography>
      </MuiGrid>
      <MuiGrid item xs={6}>
        <TextField
          value={password}
          handleChange={(e) => setPassword(e.target.value as string)}
          label={t('Password')}
          showLabel
          width="18rem"
          type="password"
          placeholder={t('Password')}
        />
      </MuiGrid>
      <span>{error}</span>
      <MuiGrid item xs={6}>
        <Button type="submit" onClick={login}>
          {t('Login')}
        </Button>
      </MuiGrid>
    </MuiGrid>
  );
};

const LoadRingContainer = styled.div`
  margin-top: 100px;
`;
