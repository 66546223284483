import * as React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { NATIVE_LANGUAGES } from 'Client/constants/languages';
import { OptionItem } from 'Client/types';
import { CreateProjectForm } from 'Client/pages/project-centre/types';
import { useUser } from 'Client/utils/hooks';
import { RootState } from 'Client/redux-store';
import { projectCentreSuperRoles } from 'Client/templates/OnboardingPages/components/OnboardingMasthead/constants/superRoles';
import { getHighestRoleOnCustomer } from 'Client/utils/user/getHighestRoleOnCustomer';
import { decodeCustomerNameFromURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { TextInput } from 'Client/components/molecules/TextInput/TextInput';
import { ProjectProperties } from 'Client/pages/dashboard/settings/components/ProjectProperties/ProjectProperties';
import { DropdownInput } from 'Client/pages/dashboard/settings/components/DropdownInput/DropdownInput';
import { Label } from 'Client/components/molecules/TextInput/TextInput.styles';
import { InputWrapper, TextFieldWrapper, Wrapper } from './Form.styles';
import { FormProps } from './types';
import {
  closeDateValidation,
  latitudeValidation,
  launchDateValidation,
  longitudeValidation,
  urlValidation,
} from './utils/validationFunctions';
import { mapLanguages } from './utils/mapLanguages';
import {
  GET_PROJECT_BY_NAME_QUERY,
  GET_PROJECT_QUERY,
  CREATE_PROJECT_MUTATION,
} from './Form.gql';

export const Form: React.FC<FormProps> = ({
  setFocused,
  validate,
  setClicked,
  template,
}: FormProps) => {
  const router = useRouter();
  const { t } = useTranslation('customer');
  const { query } = router;
  const { user } = useUser();
  const { selectedCustomer, customerProjectList } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const [projectName, setProjectName] = React.useState<string>('');
  const [url, setUrl] = React.useState<string>('');
  const [latitude, setLatitude] = React.useState<string>('');
  const [longitude, setLongitude] = React.useState<string>('');
  const [language, setLanguage] = React.useState<Array<string>>([]);
  const [launchDate, setLaunchDate] = React.useState<Date>();
  const [closeDate, setCloseDate] = React.useState<Date>();
  const [isTraining, setIsTraining] = React.useState<boolean>(false);
  const [isDemo, setIsDemo] = React.useState<boolean>(false);
  const [isCsSiteBuild, setIsCsSiteBuild] = React.useState<boolean>(false);

  const [projectNameError, setProjectNameError] = React.useState<string>('');
  const [nameAlreadyTaken, setNameAlreadyTaken] =
    React.useState<boolean>(false);
  const [urlAlreadyTaken, setUrlAlreadyTaken] = React.useState<boolean>(false);
  const [urlError, setUrlError] = React.useState<string>('');
  const [latitudeError, setLatitudeError] = React.useState<string>('');
  const [longitudeError, setLongitudeError] = React.useState<string>('');
  const [closeDateError, setCloseDateError] = React.useState<string>('');
  const [launchDateError, setLaunchDateError] = React.useState<string>('');

  const [form, setForm] = React.useState<CreateProjectForm>();

  const [createProjectRequest] = useMutation(CREATE_PROJECT_MUTATION);

  const { data: urlData, loading: urlLoading } = useQuery(GET_PROJECT_QUERY, {
    variables: {
      projectInput: {
        project: url,
      },
    },
  });

  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PROJECT_BY_NAME_QUERY,
    {
      variables: {
        name: projectName,
      },
    }
  );

  const validation = () => {
    let isValid = true;

    if (projectName === '') {
      isValid = false;
      setProjectNameError('This field is compulsory');
    } else if (nameAlreadyTaken) {
      isValid = false;
      setProjectNameError('This name is already taken');
    } else if (projectName.length > 75) {
      isValid = false;
      setProjectNameError('This name is too long');
    } else {
      setProjectNameError('');
    }

    if (url === '') {
      isValid = false;
      setUrlError('This field is compulsory');
    } else if (urlAlreadyTaken) {
      isValid = false;
      setUrlError('This URL is already taken');
    } else if (url.length > 64) {
      isValid = false;
      setUrlError('This URL is too long');
    } else if (urlValidation(url)) {
      isValid = false;
      setUrlError('This URL contains forbidden characters');
    } else {
      setUrlError('');
    }

    if (latitudeValidation(latitude)) {
      isValid = false;
      setLatitudeError(`Invalid latitude value`);
    } else {
      setLatitudeError('');
    }

    if (longitudeValidation(longitude)) {
      isValid = false;
      setLongitudeError(`Invalid longitude value`);
    } else {
      setLongitudeError('');
    }

    if (launchDateValidation(launchDate)) {
      isValid = false;

      const message = launchDateValidation(launchDate);
      setLaunchDateError(message);
    } else {
      setLaunchDateError('');
    }

    if (closeDateValidation(launchDate, closeDate)) {
      isValid = false;
      const message = closeDateValidation(launchDate, closeDate);
      setCloseDateError(message);
    } else {
      setCloseDateError('');
    }

    if (
      !selectedCustomer.customer ||
      selectedCustomer.customer === 'All customers'
    ) {
      isValid = false;
      setProjectNameError('Please select a valid customer');
    }

    const role = getHighestRoleOnCustomer(user, customerProjectList);

    if (isValid && !nameLoading && !urlLoading) {
      setForm({
        newProjectName: projectName,
        url,
        location: {
          latitude: Number(latitude),
          longitude: Number(longitude),
        },
        languages: [...language],
        launchDate,
        closeDate,
        projectNameToClone: template ? template : 'templatedefault',
        user: {
          email: user.email,
          _id: user._id,
          role: role,
        },
        isTraining,
        isDemo,
        isCsSiteBuild,
        customer: selectedCustomer,
      });
    }
  };

  React.useEffect(() => {
    if (validate) {
      validation();
    }
    setClicked(false);
  }, [validate]);

  React.useEffect(() => {
    if (urlData?.getProject) {
      const { id } = urlData.getProject;
      if (id) {
        setUrlAlreadyTaken(true);
        return;
      }
    }
    setUrlAlreadyTaken(false);
  }, [urlData]);

  React.useEffect(() => {
    if (nameData?.getProjectByName) {
      const { id } = nameData.getProjectByName;
      if (id) {
        setNameAlreadyTaken(true);
        return;
      }
    }
    setNameAlreadyTaken(false);
  }, [nameData]);

  React.useEffect(() => {
    if (form) {
      createProjectRequest({
        variables: {
          cloneProjectInput: { ...form },
        },
      });
      router.replace({
        pathname: '/project-centre/waiting',
        query: { customer: decodeCustomerNameFromURL(query.customer) },
      });
    }
  }, [form]);

  return (
    <Wrapper data-testid="Form-Wrapper">
      <InputWrapper>
        <TextInput
          onFocus={() => setFocused('projectName')}
          onChange={(e) => setProjectName(String(e.target.value))}
          label={'Project name'}
          placeholder="Type here your project name"
          error={projectNameError.length > 0}
          errorMessage={projectNameError}
          labelFontWeight={700}
          gap={0.25}
        />
      </InputWrapper>

      <InputWrapper>
        <TextInput
          onFocus={() => setFocused('projectUrl')}
          onChange={(e) => setUrl(String(e.target.value).toLowerCase())}
          placeholder={t('Project URL:')}
          value={url}
          error={urlError.length > 0}
          errorMessage={urlError}
          label={t('URL:')}
          gap={0.25}
          commonplacePlaceholder
          labelFontWeight={700}
        />
      </InputWrapper>

      <InputWrapper>
        <Label fontWeight={700}>{t('Location:')}</Label>
        <TextFieldWrapper>
          <TextInput
            onFocus={() => setFocused('location')}
            onChange={(e) => setLatitude(String(e.target.value))}
            type="number"
            labelFontWeight={700}
            placeholder={t('Latitude')}
            error={latitudeError.length > 0}
            errorMessage={latitudeError}
          />
          <TextInput
            onFocus={() => setFocused('location')}
            onChange={(e) => setLongitude(String(e.target.value))}
            type="number"
            label={''}
            placeholder={t('Longitude')}
            error={longitudeError.length > 0}
            errorMessage={longitudeError}
          />
        </TextFieldWrapper>
      </InputWrapper>

      <InputWrapper>
        <Label fontWeight={700}>
          {t('Add another language than English:')}
        </Label>
        <DropdownInput
          menuPlacement="auto"
          onFocus={() => setFocused('languages')}
          placeholder={t('No other language needed')}
          options={mapLanguages(NATIVE_LANGUAGES)}
          onChange={(e: OptionItem) => {
            const optionArray = e as unknown as Array<OptionItem>;
            const languageArray =
              !!optionArray &&
              (optionArray.map((v) => v.value) as Array<string>);
            if (languageArray) {
              setLanguage(languageArray);
            } else {
              setLanguage([]);
            }
          }}
          multipleSelect
          isMulti={true as false}
        />
      </InputWrapper>

      <InputWrapper>
        <TextFieldWrapper>
          <TextInput
            labelFontWeight={700}
            onFocus={() => setFocused('dates')}
            type="date"
            onChange={(e) => setLaunchDate(new Date(e.target.value))}
            label={t('Estimated launch date')}
            error={launchDateError.length > 0}
            placeholder="dd/mm/yyyy"
            errorMessage={launchDateError}
            gap={0.25}
          />

          <TextInput
            labelFontWeight={700}
            placeholder="dd/mm/yyyy"
            onFocus={() => setFocused('dates')}
            type="date"
            onChange={(e) => setCloseDate(new Date(e.target.value))}
            label={t('Estimated close date')}
            error={closeDateError.length > 0}
            errorMessage={closeDateError}
            gap={0.25}
          />
        </TextFieldWrapper>
      </InputWrapper>

      {projectCentreSuperRoles.includes(user.superRole) && (
        <ProjectProperties
          isCsSiteBuild={isCsSiteBuild}
          isDemo={isDemo}
          isTraining={isTraining}
          setIsCsSiteBuild={setIsCsSiteBuild}
          setIsDemo={setIsDemo}
          setIsTraining={setIsTraining}
        />
      )}
    </Wrapper>
  );
};
