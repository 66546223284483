import styled from 'styled-components';

export const MainContentContainer = styled.div`
  position: relative;
  min-height: 16rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
