import styled, { css } from 'styled-components';
import { Button as BaseButton } from 'Molecules/Button';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding: 0.125rem 1.6875rem;
  display: flex;
  flex-direction: row;
`;

export const Button = styled(BaseButton)<{ selected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.s};
  padding: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border-color: ${({ theme }) => theme.colors.white[500]};
  color: ${({ theme }) => theme.colors.black[500]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.white[500]};
    border-color: ${({ theme }) => theme.colors.white[500]};
    color: ${({ theme }) => theme.colors.black[500]};
  }

  ${({ selected }) =>
    selected &&
    css`
      &::before {
        position: absolute;
        content: '';
        height: 0.125rem;
        bottom: 0;
        background-color: ${({ theme }) => theme.colorMappings.brand};
        width: 100%;
      }
    `};
`;
