import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Button, TextField } from 'Client/components/molecules';
import useValidateEmail from 'Client/utils/hooks/useValidateEmail';
import { useProject } from 'Client/utils/hooks';
import { RootState } from 'Client/redux-store';
import DrawerSection from './DrawerSection';

const FormContainer = styled(DrawerSection)`
  background-color: #f5f4fc;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ValidationMessageContainer = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? '3rem' : '0')};
  transition: all 300ms ease 300ms;
  overflow: hidden;
`;

const AlertForm: React.FC<{ onSuccess: (email: string) => void }> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const project = useProject();
  const { filters } = useSelector((state: RootState) => state.filters);

  const [loading, setLoading] = React.useState(false);
  const [alertName, setAlertName] = React.useState<string | null>();
  const [email, setEmail] = React.useState<string | null>();
  const [validationResult, setValidationResult] = React.useState<
    null | 'warning' | 'error'
  >(null);
  const [validationMessage, setValidationMessage] = React.useState<
    string | React.ReactNode | null
  >();

  const validateEmail = useValidateEmail();
  const [saveConsent, { loading: savingConsent }] = useMutation(
    gql`
      mutation PappAlert(
        $name: String!
        $email: String!
        $filters: [JSON]!
        $project: String!
      ) {
        createPappAlert(
          name: $name
          email: $email
          filters: $filters
          project: $project
        ) {
          success
          consentId
        }
      }
    `,
    {
      variables: {
        name: alertName,
        email,
        filters,
        project: project.id,
      },
    }
  );

  const onSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { validation, message } = await validateEmail(email);
    setValidationResult(validation);
    setValidationMessage(message);

    if (
      validation === 'error' ||
      (validation === 'warning' && !validationResult)
    ) {
      setLoading(false);
      return;
    }

    setValidationResult(null);
    await saveConsent();
    onSuccess(email);
  };

  return (
    <form onSubmit={onSubmit}>
      <FormContainer>
        <TextField
          style={{ width: '100%' }}
          showLabel
          label={t('Email alert name')}
          placeholder={t('Notification 1')}
          required
          handleChange={(e) => setAlertName(e.target.value.toString())}
        />
        <TextField
          style={{ width: '100%' }}
          showLabel
          label={t('Email')}
          placeholder={t('your@email.com')}
          type="email"
          required
          handleChange={(event) => {
            setEmail(event.target.value.toString());
            setValidationResult(null);
          }}
        />
        <ValidationMessageContainer
          open={!!validationResult && !!validationMessage}
        >
          {validationMessage}
        </ValidationMessageContainer>
        <Button
          fullWidth
          type="submit"
          disabled={loading || validationResult === 'error'}
        >
          {loading || savingConsent
            ? t('Loading...')
            : validationResult === 'warning'
            ? t('Submit anyway')
            : t('Create alert')}
        </Button>
      </FormContainer>
    </form>
  );
};

export default AlertForm;
