import * as React from 'react';
import { TrafficRowTitleProps } from './types';
import { Wrapper, Value, Modal } from './TrafficRowTitle.styles';
import { switchText } from '../../utils';

const TrafficRowTitle: React.FC<TrafficRowTitleProps> = ({
  value,
}: TrafficRowTitleProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  if (!value) return <span></span>;
  return (
    <Wrapper
      data-testid="TrafficRowTitle"
      onMouseOver={() => setOpenModal(true)}
      onMouseOut={() => setOpenModal(false)}
    >
      <Value data-testid="TrafficRowTitle-Value">{value}</Value>
      {openModal && value !== 'All traffic' && (
        <Modal>{switchText(value)}</Modal>
      )}
    </Wrapper>
  );
};

export { TrafficRowTitle };
