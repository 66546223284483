import { gql } from '@apollo/client';

export const GET_PROJECT_OVERVIEW_QUERY = gql`
  query GetProjectOverview(
    $getProjectsOverviewInput: GetProjectsOverviewInput
  ) {
    getProjectOverview(GetProjectsOverviewInput: $getProjectsOverviewInput) {
      visitorsCount
      respondentsCount
      contributionsCount
      newsSubscribersCount
    }
  }
`;
