import styled from 'styled-components';
import { Button } from 'Molecules';

export const Wrapper = styled.div`
  width: calc(100% - 3.75rem);
  min-width: calc(100% - 15.625rem);
  overflow: auto;
  color: ${({ theme }) => theme.colors.black[500]};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2.875rem 1rem 3.9375rem;
`;

export const ActionsRow = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AddTeamMemberButton = styled(Button)`
  border-radius: 0.1875rem;
  color: ${({ theme }) => theme.colors.white[500]};
`;
