import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { useMap } from 'Client/utils/hooks';
import { Action, GreenMap, WhiteChevron, Wrapper } from './ToMapButton.styles';
import { setWelcomeToken } from '../utils';

const ToMapButton = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation();
  const {
    dispatch,
    state: { welcomePanel },
  } = useMap();

  const handleClick = () => {
    if (welcomePanel) {
      setWelcomeToken(router.query.slug[0]);
      dispatch({ type: 'CLOSE_WELCOME_PANEL' });
      dispatch({
        type: 'OPEN_INFO_PANEL',
        payload: {
          infoPanelOpen: false,
        },
      });
    } else {
      dispatch({ type: 'TOGGLE_INFO_PANEL' });
    }
  };

  return (
    <Wrapper onClick={handleClick} data-testid="info-panel-to-map-button">
      <GreenMap />
      <Typography variant="body1">
        <strong>{t('Map')}</strong>
      </Typography>
      <Action>
        <WhiteChevron />
      </Action>
    </Wrapper>
  );
};

export { ToMapButton };
