import axios from 'axios';
import { Locations } from 'Server/services/location/types';

export const getLocations = async (region: string): Promise<Locations> => {
  try {
    const { data } = await axios.get(`/api/locations?region=${region}`);

    return data?.locations;
  } catch (error) {
    return {};
  }
};
