import React from 'react';
import { validateEmail } from 'Client/utils/validators';

interface VerifyAndSendProps {
  emailParams: {
    email: string;
    setEmail?: React.Dispatch<React.SetStateAction<string>>;
    setEmailError: React.Dispatch<React.SetStateAction<boolean>>;
  };
  passwordParams?: {
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    setPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
  };
  trackEvent?: () => void;
}

export const verifyAndSend = ({
  emailParams,
  passwordParams,
  trackEvent,
}: VerifyAndSendProps) => {
  const { email, setEmailError } = emailParams;

  trackEvent();

  let emptyFields = () => {
    setEmailError(false);
  };

  setEmailError(false);

  if (!validateEmail(email)) {
    setEmailError(true);
    return false;
  }

  if (passwordParams) {
    const { password, setPassword, setPasswordError } = passwordParams;

    emptyFields = () => {
      setEmailError(false);
      setPasswordError(false);
      setPassword('');
    };

    setPasswordError(false);
    if (password.length < 1) {
      setPasswordError(true);
      return false;
    }
  }

  emptyFields();
  return true;
};
