import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Question } from 'Shared/types';
import { PaperClipIcon } from 'Atoms/Icons';
import { RespondentUpload } from 'Client/pages/proposals/components/RespondentUpload/RespondentUpload';

type Props = {
  attachFile: Question;
  onChange: (qId: string, val: string) => void;
};

const Wrapper = styled.div`
  margin: 0.5rem 25%;
  max-width: 55%;
  padding-bottom: 1rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 0.5rem 0;
      max-width: 100%;
    }
  `}
`;

const AttachWrapper = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;

  color: ${({ theme }) => theme.colorMappings.brand};
  svg {
    margin-right: 0.3125rem;
    fill: ${({ theme }) => theme.colorMappings.brand};
  }
`;

export const AttachFile: React.FC<Props> = ({ attachFile, onChange }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Wrapper>
      <AttachWrapper
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
        aria-expanded={isOpen}
      >
        <PaperClipIcon />
        {t('Attach file')}
      </AttachWrapper>

      {isOpen && <RespondentUpload question={attachFile} onChange={onChange} />}
    </Wrapper>
  );
};
