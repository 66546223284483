import * as React from 'react';
import {
  Wrapper,
  Step,
  TextWrapper,
  Steps,
  Text,
  Lines,
  Line,
  TitleWrapper,
  Number,
  Title,
} from './FormTitle.styles';
import { FormTitleProps } from './types';

const values = {
  'step-1': {
    steps: '1/2',
    text: 'Project details',
    lines: [true, false],
    number: 1,
    title: 'Project details',
  },
};

export const FormTitle: React.FC<FormTitleProps> = ({
  step,
}: FormTitleProps) => {
  return (
    <Wrapper>
      <Step>
        <TextWrapper>
          <Steps>{values[step].steps}</Steps>
          <Text>• {values[step].text}</Text>
        </TextWrapper>
        <Lines>
          {values[step].lines.map((l, index) => {
            return <Line key={index} active={l} />;
          })}
        </Lines>
      </Step>
      <TitleWrapper>
        <Number>{values[step].number}.</Number>
        <Title>{values[step].title}</Title>
      </TitleWrapper>
    </Wrapper>
  );
};
