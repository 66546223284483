import styled, { css } from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = styled(MuiAccordion)`
  ${({ theme }) => css`
    box-shadow: 0 0.1875rem 0.35rem ${theme.colorMappings.shadow};
  `}
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  ${({ theme }) => css`
    color: ${theme.colorMappings.black};
    background: ${theme.colorMappings.pageBackgroundColor};
    border: 1px solid ${theme.colors.grey[300]};
    border-radius: 0.25rem;
    padding: 0 2rem;
    width: 100%;

    div.MuiAccordionSummary-content {
      margin: 1.4rem 0.5rem;
    }

    .MuiSvgIcon-root {
      color: ${theme.colorMappings.black};
    }

    &.Mui-expanded {
      color: ${theme.colorMappings.hyperlink};
      border-radius: 0.25rem 0.25rem 0 0;

      .MuiSvgIcon-root {
        color: ${theme.colorMappings.hyperlink};
      }
    }
  `}
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  ${({ theme }) => css`
    color: ${theme.colorMappings.greyTextDarker};
    border: 1px solid ${theme.colors.grey[300]};
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    padding: 1.4rem 2.5rem;
  `}
`;
