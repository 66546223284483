import * as React from 'react';
import { FeedbackMessageProps } from './types';
import { Header, Footer } from './components';
import {
  Wrapper,
  Content,
  Title,
  Message,
  Bold,
  ImageContainer,
  StyledCircleCheckIcon,
  StyledCircleCancelIcon,
} from './FeedbackMessage.styles';

export const FeedbackMessage: React.FC<FeedbackMessageProps> = ({
  messageType,
  closeSidePanel,
  emailTitle,
  repliableRespondents,
}) => {
  const handleGoToGroups = () => {
    window.location.href = '/v5/dashboard/contributions';
    closeSidePanel();
  };
  return (
    <Wrapper data-testid="FeedbackMessage-Wrapper">
      <Header closeSidePanel={closeSidePanel} />
      {messageType === 'success' ? (
        <Content>
          <Title data-testid="Title-Success">
            <StyledCircleCheckIcon data-testid="Circle-Check-Icon" />
            Email sent
          </Title>
          <Message data-testid="Message-Success">
            <p>
              Congratulations! Your email{' '}
              <Bold data-testid="Bold-Email-Title">{emailTitle}</Bold> has been
              sent to{' '}
              <Bold data-testid="Bold-Total-Respondents">
                {repliableRespondents} respondent
                {repliableRespondents !== 1 && 's'}.
              </Bold>
            </p>
          </Message>
          <ImageContainer data-testid="Image-Container-Success">
            <img
              src="/static/illustrations/dashboard_group-success.png"
              width="320"
              height="320"
            />
          </ImageContainer>
        </Content>
      ) : (
        <Content>
          <Title data-testid="Title-Error">
            <StyledCircleCancelIcon data-testid="Circle-Cancel-Icon" />
            Error
          </Title>
          <Message data-testid="Message-Error">
            <p>An error occured.</p>
            <p>
              The <Bold>email</Bold> containing your download link could not be
              sent.
            </p>
            <p>Please try again.</p>
          </Message>
          <ImageContainer data-testid="Image-Container-Error">
            <img
              src="/static/illustrations/dashboard_error.png"
              width="340"
              height="300"
            />
          </ImageContainer>
        </Content>
      )}
      <Footer
        leftButtonLabel="Close"
        onLeftButtonClick={() => closeSidePanel()}
        rightButtonLabel="Go to Groups"
        onRightButtonClick={handleGoToGroups}
      />
    </Wrapper>
  );
};
