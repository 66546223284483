import * as React from 'react';
import { useQuery } from '@apollo/client';
import {
  ChartRenderer,
  LoadingDisplay,
  NoDataDisplay,
} from 'Client/pages/dashboard/charts/components';
import {
  ChartsFunctionsObject,
  VisitorsLineChartData,
} from 'Client/pages/dashboard/charts/types';
import { useProject } from 'Client/utils/hooks';
import { IframeModal } from 'Client/pages/dashboard/charts/components/IframeModal/IframeModal';
import { ChartHeader } from '../ChartHeader';
import { Wrapper } from './ChartWrapper.styles';
import { GET_VISITORS_CHART_QUERY } from '../../../VisitorsPage.gql';
import { VisitorsTabsProps } from '../../types';

export const ChartWrapper: React.FC<VisitorsTabsProps> = ({
  appliedFilters,
}: VisitorsTabsProps) => {
  const [isNoData] = React.useState<boolean>(false);
  const [timeSeries, setTimeSeries] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [isIframeOpen, setIsIframeOpen] = React.useState<boolean>(false);

  const { id, _id } = useProject();

  const { data, loading } = useQuery(GET_VISITORS_CHART_QUERY, {
    variables: {
      visitorsLineChartDataInput: {
        projectName: id,
        dates: appliedFilters?.topFilters?.date
          ? appliedFilters.topFilters.date
          : { to: null, from: null },
      },
    },
  });
  React.useEffect(() => {
    let _chartData = {} as VisitorsLineChartData;

    if (data) {
      _chartData = data.getVisitorsLineChartData;
    }

    if (_chartData) {
      const { series: gSeries, categories: gCategories } = _chartData;
      if (gSeries && gCategories) {
        setTimeSeries(gSeries);
        setCategories(gCategories);
      }
    }
  }, [data, appliedFilters]);

  const generateIframe = () => {
    setIsIframeOpen(true);
  };

  const [settingsFunctions, setSettingsFunctions] =
    React.useState<ChartsFunctionsObject>({
      viewInFullScreen: undefined,
      printChart: undefined,
      downloadPNG: undefined,
      downloadJPEG: undefined,
      downloadPDF: undefined,
      downloadSVG: undefined,
      downloadCSV: undefined,
      downloadXLS: undefined,
      generateIframe,
    });

  const chartBalancer = () => {
    if (loading) return <LoadingDisplay />;
    if (isNoData) return <NoDataDisplay />;

    return (
      <ChartRenderer
        questionTitle={'Visitors'}
        timeSeries={timeSeries}
        chartType={'visitorsLine'}
        dataType={'visitors'}
        setSettingsFunctions={setSettingsFunctions}
        series={[]}
        donutSeries={[]}
        wordcloudData={[]}
        months={[]}
        mapSeries={undefined}
        enableMapChart={false}
        categories={categories}
        pivotCriteria={''}
        contributionsIds={[{ contributionId: '', pageId: '' }]}
      />
    );
  };

  return (
    <Wrapper isLoading={loading}>
      {!loading && <ChartHeader settingsFunctions={settingsFunctions} />}
      {chartBalancer()}

      {isIframeOpen && (
        <IframeModal
          setIsIframeOpen={setIsIframeOpen}
          isIframeOpen={isIframeOpen}
          data={{
            type: 'visitors',
            id: 'visitors',
            name: 'Project visitors',
            label: 'Project visitors',
            content: {},
            section: {
              title: '',
              showSectionTitle: false,
            },
          }}
          chartTypes={{ visitors: 'visitorsLine' }}
          pivotCriteria={{
            visitors: { type: '', category: '', questionName: '' },
          }}
          projectId={_id}
          id={'visitors'}
          appliedFilters={appliedFilters}
          projectName={id}
        ></IframeModal>
      )}
    </Wrapper>
  );
};
