import styled, { css } from 'styled-components';

export const PanelHeader = styled.div`
  padding: 0 0.5rem 0 1rem;
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomCloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;

  &:hover {
    background-color: ${({ theme }) => theme.colorMappings.separators};
  }
`;

export const PanelBody = styled.div`
  /* used only when showing deleted comment */
  padding: 0 2.5rem;
  margin-top: 0.625rem;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      height: 50vh; /* to match the <Screen/> component which I'm not sure what it does */
    }
  `}
`;
