import * as React from 'react';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import { Wrapper, Text, Button } from './CommentsCount.styles';
import { CommentsCountProps } from './types';
import { scrollToSection } from '../../utils';

export const CommentsCount: React.FC<CommentsCountProps> = ({
  commentsCount,
  setSelectedTab,
}) => {
  const handleBeTheFirstToComment = () => {
    scrollToSection('comment-section');
  };

  const handleCommentsCount = () => {
    setSelectedTab(3);

    scrollToSection('tabs-section');
  };

  return (
    <Wrapper>
      <ModeCommentOutlinedIcon fontSize="small" />

      {commentsCount > 0 ? (
        <Button type="button" onClick={handleCommentsCount}>
          <Text>
            <span>{commentsCount}</span> Comment
            {`${commentsCount > 1 ? 's' : ''}`}
          </Text>
        </Button>
      ) : (
        <Button type="button" onClick={handleBeTheFirstToComment}>
          <Text>Be the first to comment</Text>
        </Button>
      )}
    </Wrapper>
  );
};
