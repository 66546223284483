import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 1rem;
`;

export const InputWrapper = styled.div<{ first?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: ${({ first }) => first && '2.75rem'};
  padding: 0 0.5rem;
  gap: 0.25rem;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  align-items: flex-start;

  .MuiFormControl-root {
    margin: 0;
    padding: 0;
  }

  .MuiFormControl-root:first-child {
    margin-right: 2.75rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `};
`;
