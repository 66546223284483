import styled from 'styled-components';

export const MainContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const ContributionsListWrapper = styled.div`
  background: ${({ theme }) =>
    theme.colorMappings.controlCenterContentBackground};
  padding: 2rem 12.5rem;
`;
