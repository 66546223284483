import * as React from 'react';
import { Link } from 'Atoms';
import { SimpleSelect } from 'Client/components/molecules';
import { OptionItem } from 'Client/types';
import { useUser } from 'Client/utils/hooks';
import { makeRedirectDomain } from 'Client/utils/url';
import { Permissions } from 'Client/constants/permissions';
import { userHasPermission } from 'Client/utils/user';
import { SidePanelProps } from './types';
import { TextItem } from '../TextItem';
import {
  ContributionsSectionWrapper,
  DemographicsSectionsWrapper,
  SectionTitle,
  ConsentItemWrapper,
  ConsentItemTitle,
  StyledCheckIcon,
  ActionsDropDown,
} from './SidePanel.styles';
import { DeleteModal } from '../DeleteModal/DeleteModal';

export const SidePanel: React.FC<SidePanelProps> = ({
  panelData,
}: SidePanelProps) => {
  const [action, setAction] = React.useState<string>('');
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { user } = useUser();

  const actionsSwitcher = React.useCallback((action: string) => {
    switch (action) {
      case 'delete':
        return setDeleteModal(true);
    }
  }, []);

  React.useEffect(() => {
    actionsSwitcher(action);
  }, [action, actionsSwitcher]);

  const demographicsSection = panelData.find(
    (section) => section.type === 'demographics'
  );
  const contributionsSection = panelData.find(
    (section) => section.type === 'contributions'
  );
  const consentsSections = panelData.find(
    (section) => section.type === 'consents'
  );
  const contributionId =
    contributionsSection &&
    contributionsSection.items.find((item) => item.label === 'contribution id');

  if (contributionsSection) {
    return (
      <ContributionsSectionWrapper>
        {contributionsSection.items
          .filter((item) => item.value)
          .map((item) => {
            if (item.label === 'location') {
              const hasValue =
                typeof item.value === 'object' &&
                item.value.latitude &&
                item.value.longitude;
              if (!hasValue) return null;
              const { latitude, longitude, slug } = item.value as {
                latitude: string;
                longitude: string;
                slug: string;
              };
              const domainLink = makeRedirectDomain(
                contributionsSection.projectName
              );
              const linkToRedirect = `http://${domainLink}/map/${slug}?cid=${contributionsSection._id}`;
              return (
                <TextItem key={item.label} title={item.label}>
                  Latitude: {latitude}
                  <br />
                  Longitude: {longitude}
                  <br />
                  {slug && (
                    <Link
                      to={linkToRedirect}
                      color="CommonplaceBrand"
                      noPadding
                    >
                      See in map
                    </Link>
                  )}
                </TextItem>
              );
            }
            return (
              <TextItem key={item.label} title={item.label}>
                {item.value}
              </TextItem>
            );
          })}
        {userHasPermission(user, Permissions.DELETE_CONTRIBUTION) && (
          <ActionsDropDown>
            <p>Actions</p>
            <SimpleSelect
              options={[{ value: 'delete', label: 'Delete comment' }]}
              isClearable={false}
              handleChange={(e: OptionItem) => e.value && setAction(e.value)}
              placeholder="Actions"
            />
          </ActionsDropDown>
        )}
        <DeleteModal
          contributionId={String(contributionId.value)}
          open={deleteModal}
          onClose={() => {
            setDeleteModal(false);
            setAction('');
          }}
        />
      </ContributionsSectionWrapper>
    );
  }

  return (
    <DemographicsSectionsWrapper>
      {demographicsSection && (
        <>
          <SectionTitle>Demographics</SectionTitle>
          {demographicsSection.items
            .filter((item) => item.value)
            .map((item) => (
              <TextItem key={item.label} title={item.label}>
                {item.value}
              </TextItem>
            ))}
        </>
      )}
      {consentsSections && (
        <>
          <SectionTitle>Consents</SectionTitle>
          {consentsSections.items
            .filter((item) => item.value)
            .map((item) => (
              <ConsentItemWrapper key={item.label}>
                <StyledCheckIcon />
                <ConsentItemTitle>{item.value}</ConsentItemTitle>
              </ConsentItemWrapper>
            ))}
        </>
      )}
    </DemographicsSectionsWrapper>
  );
};
