import React from 'react';
import { PlanningApps as PlanningAppsTiles } from 'Client/pages/hub/components';
import { ListViewProps } from './types';
import { ListContainer } from './styles';

export const ListView: React.FC<ListViewProps> = ({
  hideHubHeader,
  planningApps,
  setFilteredPlanningApps,
}) => {
  return (
    <ListContainer>
      <PlanningAppsTiles
        hideHubHeader={hideHubHeader}
        planningApps={planningApps}
        setFilteredPlanningApps={setFilteredPlanningApps}
      />
    </ListContainer>
  );
};
