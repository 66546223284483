import styled, { css } from 'styled-components';
import { CommentCard } from 'Client/components/CommentCard';
import { UserIcon } from 'Atoms/Icons';

export const ContributionResponseSection = styled(CommentCard.Section)`
  padding: 2rem;

  ${({ theme }) => css`
    background-color: ${theme.colorMappings.pageBackgroundColor};
    background-image: url('https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1646224083/confetti_cv8rko.png');
    background-repeat: no-repeat;
    background-position: top 0.25rem left 0.5rem;
    background-size: 3.5rem;
  `}
`;

export const Avatar = styled(UserIcon)`
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: content-box;
  overflow: visible;

  ${({ theme }) => css`
    background: ${theme.colorMappings.planningAppWithdrawn};
    border: 0.625rem solid transparent;
  `}
`;

export const Info = styled.div`
  display: flex;
  align-items: self-end;

  > * + * {
    margin-left: 1rem;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
`;
