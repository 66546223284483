import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import { NoSSRTable } from '../Table';
import { currentBasicColumns } from './constantColumns';
import { GET_TRAFFIC_TABLE_QUERY } from './TrafficTable.gql';
import { Wrapper } from './TrafficTable.styles';
import { TrafficTableProps } from './types';

export const TrafficTable: React.FC<TrafficTableProps> = ({
  setVisitorsCount,
  appliedFilters,
}: TrafficTableProps) => {
  const { id } = useProject();
  const [rowsData, setRowsData] = React.useState([]);

  const { data, loading } = useQuery(GET_TRAFFIC_TABLE_QUERY, {
    variables: {
      trafficInput: {
        projectName: id,
        dates: appliedFilters?.topFilters?.date
          ? appliedFilters.topFilters.date
          : { to: null, from: null },
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      setRowsData(data.getTrafficTableData);
      setVisitorsCount(data.getTrafficTableData[0]?.visitors);
    }
  }, [data]);

  return (
    <Wrapper data-testid="Table-Wrapper">
      <NoSSRTable
        tableMapHeadersParams={{
          basicColumns: {
            header: '',
            id: 'general',
            columns: currentBasicColumns,
          },
        }}
        rowsData={rowsData}
        loading={loading}
        loadMoreData={() => ''}
        setFlatRowsIds={() => ''}
        isWaypointInside={false}
        isTrafficTable
        tableSort={undefined}
        setTableSort={() => ''}
      />
    </Wrapper>
  );
};
