import { v4 as uuidv4 } from 'uuid';
import { MilestoneItemProps, MilestoneStageType } from 'Client/pages/timeline';
import { AddSectionBlockTypes } from '../components/SectionPanel';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const createNewMilestonesSectionBlock = (
  droppingSectionType: AddSectionBlockTypes
) => {
  let newMilestoneBlock;
  if (droppingSectionType === AddSectionBlockTypes.MILESTONE) {
    const sampleMilestoneContent: MilestoneItemProps = {
      name: 'New Milestone',
      description: 'Milestone description',
      stage: MilestoneStageType.PLANNED,
      startDate: 'Start date',
      endDate: 'End date',
      order: 0,
      _id: uuidv4(),
    };

    newMilestoneBlock = {
      component: 'milestone',
      data: {
        content: sampleMilestoneContent,
        type: EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE,
        title: 'New Milestone',
      },
    };
  }

  return newMilestoneBlock;
};
