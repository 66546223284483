import * as React from 'react';
import { ResponseProps } from 'Client/pages/contributions/types';
import { DateDistanceLocalised } from 'Atoms';
import { ClientOnly } from 'Client/utils/wrappers/ClientOnly';
import {
  ContributionResponseSection,
  Avatar,
  Name,
  Info,
} from './ContributionResponse.styles';

interface Props {
  response: ResponseProps;
}

export const ContributionResponse: React.FC<Props> = ({ response }: Props) => {
  if (!response) return null;
  const { comment, date, stakeholder } = response;

  const splitName = stakeholder.split(',');

  const splitComment = comment.split(/(?:\r\n|\r|\n)/g);

  return (
    <ClientOnly>
      <ContributionResponseSection>
        <Info>
          <Avatar color="white" />
          <Name>
            {splitName.map((name, index) => {
              if (index === 0) return <b key={index}>{name}</b>;
              return <span key={index}>{name}</span>;
            })}
          </Name>
          <DateDistanceLocalised date={date.toString()} />
        </Info>
        {splitComment.map((x, index) =>
          x.length ? <p key={index}>{x}</p> : null
        )}
      </ContributionResponseSection>
    </ClientOnly>
  );
};
