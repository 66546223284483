import styled from 'styled-components';
import { CheckIcon } from 'Icons';

const baseWrapper = styled.div`
  width: 28%;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  box-shadow: -0.3125rem 0 1rem rgba(0, 0, 0, 0.1);
`;

export const ContributionsSectionWrapper = styled(baseWrapper)`
  padding: 8.75rem 0 9.75rem 1.6875rem;
  & > div {
    margin-bottom: 1.5625rem;
  }
`;

export const DemographicsSectionsWrapper = styled(baseWrapper)`
  padding: 2.75rem 0 9.75rem 1.6875rem;
  & > div {
    margin-bottom: 0.4rem;
  }
`;

export const SectionTitle = styled.h1`
  margin: 1.25rem 0;
  color: black;
  font-weight: 700;
`;

export const ConsentItemWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

export const ConsentItemTitle = styled.span`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-weight: 400;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  transform: scale(0.7);
  color: ${({ theme }) => theme.colors.green['A700']};
  font-weight: 700;
  margin-right: 0.4rem;
`;

export const ActionsDropDown = styled.div``;
