import * as React from 'react';
import { ErrorStatusOrHelperText } from 'Atoms';
import { SelectWithStatusProps } from './types';
import { StyledSelect } from './SelectWithStatus.styles';

export const SelectWithStatus: React.FC<SelectWithStatusProps> = ({
  status,
  ...props
}: SelectWithStatusProps) => (
  <>
    <StyledSelect {...props} />

    {status?.message && <ErrorStatusOrHelperText status={status} />}
  </>
);
