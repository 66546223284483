import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NewsSubscriptionCard, SeoMetaInfo } from 'Atoms';
import { NewsPostListItem } from 'Molecules';
import { HubPagesTemplate as Template } from 'Templates';
import { userHasPermission } from 'Client/utils/user';
import { useUser, useProject } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import { NewsPostGaudi, NewsPostAcorn } from 'Shared/types/news';
import { AddNewsButton } from './components';
import { NewsPageProps } from './types';
import {
  Container,
  Description,
  NewsSubscriptionWrapper,
  Wrapper,
  PageHeader,
} from './NewsPage.styles';

const NewsPage: React.FC<NewsPageProps> = ({
  newsPosts,
  projectName,
  openGraphInfo,
  isSubscribed,
  navbarItems,
}) => {
  const { t } = useTranslation();
  const project = useProject();
  const { user } = useUser();

  const showPanel =
    userHasPermission(user, Permissions.UPDATE_NEWS) ||
    userHasPermission(user, Permissions.ADD_NEWS);

  return (
    <Template openGraphInfo={openGraphInfo} navbarItems={navbarItems}>
      <Wrapper>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={projectName}
          page="news"
        />

        <NewsSubscriptionWrapper data-testid="NewsPage-NewsSubscriptionWrapper">
          <NewsSubscriptionCard
            email={user?.email}
            project={project}
            isSubscribed={isSubscribed}
          />
        </NewsSubscriptionWrapper>

        <Container>
          {newsPosts && (
            <>
              <PageHeader variant="h1">{t('Latest news')}</PageHeader>
              <Description variant="body2">
                {t(
                  'Stay involved in the discussion. Keep up to date with the latest news and share it with your fellow community members.'
                )}
              </Description>
              <AddNewsButton />
              {newsPosts?.map((newsPost) => (
                <NewsPostListItem
                  key={newsPost._id}
                  id={newsPost._id}
                  date={newsPost.date}
                  slug={newsPost.slug}
                  headline={
                    (newsPost as NewsPostGaudi).headline ||
                    (newsPost as NewsPostAcorn).content?.headline
                  }
                  copy={
                    (newsPost as NewsPostGaudi).copy ||
                    (newsPost as NewsPostAcorn).content?.content
                  }
                  author={
                    (newsPost as NewsPostGaudi).by ||
                    (newsPost as NewsPostAcorn).content?.author
                  }
                  newsImage={newsPost.newsImage}
                  newsImageAlt={newsPost.newsImageAlt}
                  sentEmailCount={
                    (newsPost as NewsPostGaudi).sentUpdateEmailCount?.count ||
                    (newsPost as NewsPostAcorn).sentEmailCount?.count
                  }
                  showEditPanel={showPanel}
                  showExcerptOnMobile
                  preview
                  isHub={false}
                />
              ))}
            </>
          )}
        </Container>
      </Wrapper>
    </Template>
  );
};

export { NewsPage };
