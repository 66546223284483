import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { captureException } from '@sentry/node';
import { NavigationalMap } from 'Client/components/organisms';
import { CustomLayerHighlight } from 'Client/components/molecules/CustomLayerHighlight';
import { setQueryByAreaFilter } from 'Client/utils/reduxReducers/filters/filtersReducer';
import { RootState } from 'Client/redux-store';
import { QUERY_BY_AREA } from 'Server/utils/gql/geolytix.gql';
import { Xyz } from 'Shared/types/map';
import { QueryByAreaFilters } from 'Client/utils/reduxReducers/filters/FilterState';
import { MapTables } from 'Client/pages/map/types';
import { useMap } from 'Client/utils/hooks';
import { MapViewProps } from './types';
import { MapContainer } from './styles';
import { DrawSearchAreaControls } from '../DrawSearchAreaControls/DrawSearchAreaControls';
import { MapLegend } from './components/MapLegend';
import { glaPlanningAppsCategories } from '../../constants/glaConstants';

interface QueryByAreaApiReturn {
  queryByArea: { features: { id: string; reference?: string }[] };
}

export const MapView: React.FC<MapViewProps> = ({
  mapPageId,
  initialFilters,
  clearQueryByArea,
  queryByAreaLayer,
  queryListView,
}) => {
  const client = useApolloClient();
  const dispatchFiltersRdx = useDispatch();
  const {
    dispatch,
    state: { highlightedCustomLayer, ...state },
  } = useMap();
  const xyz = state.xyz as Xyz;
  const { queryByArea: reduxQueryByArea } = useSelector(
    (state: RootState) => state.filters
  );

  const mapFilters = xyz?.layers?.list?.Custom?.filter?.current;

  React.useEffect(() => {
    if (!xyz) return;
    if (reduxQueryByArea?.area) {
      try {
        /* We need to create the layer each time
         * as the map is initialized every time you enter the tab
         */
        queryByAreaLayer?.current?.getSource().clear();
        const _queryByAreaLayer = new global.ol.layer.Vector({
          map: xyz.map,
          source: new global.ol.source.Vector({
            projection: `EPSG:${xyz.layers.list.Custom.srid}`,
          }),
          style: xyz.mapview.layer.styleFunction(xyz.layers.list.Custom),
        });
        queryByAreaLayer.current = _queryByAreaLayer;
        const areaGeoJson = reduxQueryByArea.area;
        const lineFeature = new global.ol.Feature(
          new global.ol.geom.LineString(
            areaGeoJson.coordinates[0].map((c) => {
              return [c[0], c[1]];
            })
          )
        );
        lineFeature.setStyle(
          new global.ol.style.Style({
            stroke: new global.ol.style.Stroke({
              color: '#000000',
              width: 3,
            }),
          })
        );
        queryByAreaLayer.current.getSource().addFeature(lineFeature);
      } catch (err) {
        captureException(err);
      }
    } else {
      if (!queryByAreaLayer.current) return;
      queryByAreaLayer.current.getSource().clear();
    }
  }, [reduxQueryByArea, xyz?.layers?.list?.Custom]);

  const queryByArea = async (areaFilters: QueryByAreaFilters) => {
    try {
      /* Fetch all points inside selected area */
      const { data } = await client.mutate({
        mutation: QUERY_BY_AREA,
        variables: {
          table: MapTables.CUSTOM_LAYERS,
          area: areaFilters.area,
          filters: initialFilters,
        },
      });
      const {
        queryByArea: { features },
      }: QueryByAreaApiReturn = data;

      const featureIds = features.map((feature) => feature.id);

      const featureRefs = features
        .map((feature) => feature.reference)
        .filter(Boolean);

      const _mapFilters = [
        ...(typeof mapFilters === 'string' ? JSON.parse(mapFilters) : [{}]),
      ];
      Object.assign(_mapFilters[0], {
        id: { in: featureIds },
      });
      const queryByAreaFilter = {
        area: areaFilters.area,
        ids: featureIds,
        references: featureRefs,
      };
      dispatchFiltersRdx(setQueryByAreaFilter(queryByAreaFilter));

      dispatch({
        type: 'SET_MAP_FILTERS',
        payload: {
          filters: _mapFilters,
          table: 'Custom',
        },
      });
      /* Set filters (List view query) */
      await queryListView(queryByAreaFilter);
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <>
      <MapContainer>
        <NavigationalMap
          content={{
            pageId: mapPageId,
          }}
          header={false}
        />

        <DrawSearchAreaControls
          onChange={(area) => queryByArea({ area, ids: [], references: [] })}
          clearQueryByArea={clearQueryByArea}
        />
      </MapContainer>
      {highlightedCustomLayer &&
        highlightedCustomLayer.position?.x &&
        highlightedCustomLayer.position?.y &&
        highlightedCustomLayer?.hoverablePopup && (
          <CustomLayerHighlight isNavigationalMap />
        )}
      <MapLegend legends={glaPlanningAppsCategories} />
    </>
  );
};
