import { readableColor, opacify } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 1rem 0;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  width: 99%;
  height: 2.0625rem;
  cursor: pointer;
  margin-bottom: 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
  box-shadow: inset 0rem -0.0625rem 0rem ${({ theme }) => theme.colors.grey[600]};
  border-radius: 0rem 0rem 0rem 0.375rem;
`;

export const InputWrapper = styled.div<{ color: string }>`
  width: 99%;
  background: ${({ color }) => color};
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
  border-radius: 0 0 0.3125rem 0.3125rem;
  position: absolute;
  top: 100%;
  z-index: 14;
  pointer-events: none;

  ::before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) =>
      opacify('-0.6', theme.colors.black[500])};
    content: '';
    pointer-events: auto;
    z-index: 10;
  }
`;

export const InputDiv = styled.input<{ color: string }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1rem 0;
  height: 2.375rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
  border: 0.125rem solid ${({ theme }) => theme.colors.white[500]};
  border-radius: 0 0 0.3125rem 0.3125rem;
  box-shadow: 0rem 0.1875rem 0rem 0rem ${({ theme }) => theme.colors.white[500]};
  z-index: 14;
  pointer-events: auto;

  &:focus-visible {
    outline: none;
    border: 0.125rem solid ${({ color }) => opacify('-0.5', color)};
  }
`;

export const CreateNewDiv = styled.div<{ color: string }>`
  width: 100%;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1rem 0;
  height: 2.375rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: ${({ color }) => readableColor(color)};
  border-radius: 0 0 0.3125rem 0.3125rem;
  box-shadow: 0rem 0.5rem 0.375rem -0.375rem ${({ theme }) => opacify('-0.5', theme.colors.black[500])};
  cursor: pointer;
  pointer-events: auto;
  z-index: 11;
`;

export const SearchMemberResultRow = styled.div<{ lastChild?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1rem 0;
  height: 2.375rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
  cursor: pointer;
  pointer-events: auto;
  z-index: 15;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }

  ${({ lastChild, theme }) =>
    lastChild &&
    `
    border-radius: 0 0 0.3125rem 0.3125rem;
    box-shadow: 0rem 0.5rem 0.375rem -0.375rem ${opacify(
      '-0.5',
      theme.colors.black[500]
    )};
    z-index: 14;
  `};
`;
