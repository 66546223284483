import * as React from 'react';

// when thesere is a misalignment between user for SSR. We need to force the component only to render client side.
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return <>{children}</>;
};
