import React from 'react';
import styled, { css } from 'styled-components';
import { Autocomplete } from 'Client/components/molecules';

export const Wrapper = styled.div<{ width?: string; height?: string }>`
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const Content = styled.div`
  z-index: 10;
  width: 100%;
  position: relative;

  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.1rem;
      outline-width: 0.1875rem;
    }
  `}
`;

export const Tab = styled.div`
  background: #ffffff;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.295755);
  border-radius: 0.125rem;

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colors.grey[400]};
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background: #efefef;
  padding: 1.2rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.295755);
  white-space: nowrap;
  max-width: 100%;
  overflow-x: scroll;
`;

export const SectionItem = styled.label<{ selected: boolean }>`
  cursor: pointer;
  padding-bottom: 0.5rem;
  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 0.3125rem solid #4936cb;
      font-weight: 600;
    `}
`;

export const OptionsContainer = styled.div`
  height: 15.625rem;
  overflow-y: scroll;
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.9375rem;
`;

export const CheckboxInput = styled.input`
  // Remove the default checkbox image/style:
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;

  position: relative;
  width: 0.938rem;
  height: 0.938rem;
  border: 0.125rem solid ${({ theme }) => theme.colorMappings.brand};
  border-radius: 0.188rem;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colorMappings.brand};
    border-color: ${({ theme }) => theme.colorMappings.brand};
  }

  &:checked:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: 1px solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.1rem;
      outline-width: 0.1875rem;
    }
  `}
`;

export const OptionLabel = styled.label`
  gap: 0.5rem;
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  display: block;
`;

export const InputContainer = styled.div`
  background-color: hsl(0, 0%, 100%);
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  transition: 0.2s ease;
  position: relative;

  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.2rem;
      outline-width: 0.1875rem;
    }
  `}
`;

export const SearchInputIcon = styled.label`
  position: absolute;
  ${({ theme }) => css`
    ${theme.direction === 'rtl' ? 'right: 0;' : 'left: 0.8rem;'}
  `}
  z-index: 10;
  background-color: hsl(0, 0%, 100%);
  padding-right: 0.5rem;
  height: 1.25rem;
`;

export const Input = styled((props) => <Autocomplete {...props} />)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin: 0;
    }
  `}
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.3125rem;
  .react-select__menu {
    display: none;
  }
  .react-select__menu {
    width: 100%;
  }

  .react-select__control {
    width: 100%;
    font-size: 1rem;
    height: 3rem;
    min-height: 1rem;
    padding-left: 1.5rem;
    border-radius: 0.3125rem;

    .react-select__value-container {
      margin: 0;
      font-size: 1rem;

      & input {
        margin: 0;
      }
    }
    .react-select__placeholder {
      padding-left: 0.55rem;

      ${({ theme }) =>
        css`
          ${theme.direction === 'rtl' ? 'right' : 'left'}: 0.625rem;
        `}
      font-size: 1rem;
    }
  }

  .react-select__input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .MuiListItemText-primary {
    font-weight: 400;
  }
`;
