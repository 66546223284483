import styled from 'styled-components';

export const Wrapper = styled.header`
  padding: 2rem 2rem 2.2rem;
  min-height: 6rem;
  box-shadow: 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.31);
  display: flex;
  justify-content: space-between;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: inherit;
  align-self: flex-start;
`;

export const DropDownGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
