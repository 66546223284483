import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getTabIcon } from 'Pages/planningapps/utils';
import { pluralizeWord } from 'Client/utils/stringManipulations';
import { TabBarProps } from './types';
import { TabBarWrapper, Results, TabsContainer, Tab } from './TabBar.styles';

const TabBar: React.FC<TabBarProps> = ({
  selectedTab,
  tabs,
  onTabChange,
  borderBottomHeight,
  color,
  navBorderWidth,
  fontWeight,
  borderSpacing,
  isFullWidth,
  showResults,
  isGla,
  results,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <TabBarWrapper
      navBorderWidth={navBorderWidth}
      isFullWidth={isFullWidth}
      {...props}
    >
      {showResults && (
        <Results>
          <span>{results}</span> {pluralizeWord(t('result'), results)}
        </Results>
      )}
      <TabsContainer id="tab-bar-tabs-container">
        {tabs.map((tab, idx) => (
          <Tab
            aria-controls={`tab-bar-tab-${tab.value}`}
            role="tablist"
            isGla={isGla}
            key={tab.value}
            aria-selected={selectedTab === tab.value}
            aria-checked={selectedTab === tab.value}
            isSelected={selectedTab === tab.value}
            disabled={selectedTab === tab.value}
            onClick={() => onTabChange(tab.value)}
            tabIndex={idx}
            borderBottomHeight={borderBottomHeight}
            color={color}
            fontWeight={fontWeight}
            borderSpacing={borderSpacing}
          >
            {tab?.icon && getTabIcon(tab.icon)}
            {tab.label}
          </Tab>
        ))}
      </TabsContainer>
    </TabBarWrapper>
  );
};

export { TabBar };
