import * as React from 'react';
import {
  Wrapper,
  TitlesWrapper,
  Title,
  SubTitle,
  GroupSelectWrapper,
  Label,
} from './Header.styles';
import { HeaderProps } from '../../types';

export const Header: React.FC<HeaderProps> = ({
  children,
  repliableRespondents,
  totalRespondents,
  loadingGroupInfo,
}: HeaderProps) => {
  return (
    <Wrapper data-testid="Header-Wrapper">
      <TitlesWrapper>
        <Title>Group Email</Title>
        {loadingGroupInfo ? (
          <SubTitle loading={loadingGroupInfo}>Loading group info</SubTitle>
        ) : (
          <SubTitle loading={loadingGroupInfo}>
            You are messaging&nbsp;
            <strong>
              {`${totalRespondents} respondents (${repliableRespondents} are open to receiving direct replies)`}
            </strong>
          </SubTitle>
        )}
      </TitlesWrapper>
      <GroupSelectWrapper>
        <Label>Respondent group</Label>
        {children}
      </GroupSelectWrapper>
    </Wrapper>
  );
};
