import * as React from 'react';
import { useMutation } from '@apollo/client';
import { useUser } from 'Client/utils/hooks';
import {
  Modal,
  ButtonsDiv,
  CancelButton,
  DeleteButton,
  TextArea,
} from './DeleteModal.styles';
import { DELETE_COMMENT_MUTATION } from './DeleteModal.gql';
import { DeleteModalProps } from './types';

export const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  contributionId,
}: DeleteModalProps) => {
  const [reason, setReason] = React.useState<string>('');
  const [deleteCommentMutation] = useMutation(DELETE_COMMENT_MUTATION);
  const { user } = useUser();

  const handleSubmit = () => {
    if (reason) {
      deleteCommentMutation({
        variables: {
          deleteContributionInput: {
            contributionId: contributionId,
            reason: reason,
            userId: user._id,
          },
        },
      });
    }

    onClose();
    setReason('');
  };

  return (
    <Modal
      open={open}
      title={'Are you sure you want to delete this comment?'}
      onClose={() => {
        onClose();
        setReason('');
      }}
    >
      <hr />
      <p>
        <b>It will be removed from the counts, the table and the charts.</b>
      </p>
      <p>Please provide a reason for deleting it:</p>
      <TextArea
        label={''}
        handleChange={(e) => setReason(String(e?.target?.value))}
        placeholder="Type here your reason"
      />
      <ButtonsDiv>
        <CancelButton
          onClick={() => {
            onClose();
            setReason('');
          }}
        >
          Cancel
        </CancelButton>
        <DeleteButton disabled={reason === ''} onClick={handleSubmit}>
          Delete Comment
        </DeleteButton>
      </ButtonsDiv>
    </Modal>
  );
};
