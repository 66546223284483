import * as React from 'react';
import { FooterProps } from '../../types';
import { Wrapper, RightButton, LeftButton } from './Footer.styles';

export const Footer: React.FC<FooterProps> = ({
  leftButtonText,
  leftButtonAction,
  rightButtonText,
  rightButtonAction,
}: FooterProps) => {
  return (
    <Wrapper data-testid="Footer-Wrapper">
      <LeftButton onClick={leftButtonAction}>{leftButtonText}</LeftButton>
      <RightButton onClick={rightButtonAction}>{rightButtonText}</RightButton>
    </Wrapper>
  );
};
