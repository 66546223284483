import * as React from 'react';
import { TrafficCellProps } from './types';
import { Wrapper, Value, Percentage, NoData } from './TrafficCell.styles';

const TrafficCell: React.FC<TrafficCellProps> = ({
  value,
}: TrafficCellProps) => {
  if (!value) return <NoData data-testid="TrafficCell"> - </NoData>;
  const [fullValue, percentage] = value.split(' ');

  return (
    <Wrapper data-testid="TrafficCell">
      <Value data-testid="TrafficCell-Value">{fullValue}</Value>
      <Percentage data-testid="TrafficCell-Percentage">{percentage}</Percentage>
    </Wrapper>
  );
};

export { TrafficCell };
