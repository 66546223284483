import * as React from 'react';
import styled from 'styled-components';
import { opacify, readableColor, getContrast } from 'polished';
import { ArrowBackIos } from '@material-ui/icons';

export const Wrapper = styled.div<{ color: string; readMore: boolean }>`
  border: 0.0625rem solid
    ${({ theme, color }) =>
      getContrast(theme.colors.white[500], color) <= 1
        ? theme.colors.grey[100]
        : color};
  color: ${({ theme }) => theme.colors.black[500]};
  border-radius: 0.375rem;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  margin-bottom: 0.9375rem;
`;

export const ColorBar = styled.div<{ color: string }>`
  background-color: ${({ theme, color }) =>
    getContrast(theme.colors.white[500], color) <= 1
      ? theme.colors.grey[100]
      : color};
  border-radius: 0.25rem 0 0 0.25rem;
  min-width: 0.5625rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Image = styled.div`
  padding: 2.1875rem;
  display: flex;
  align-items: center;

  img {
    width: 12.5rem;
  }
`;

export const DescriptionSection = styled.div`
  width: 100%;
`;

export const CardHeader = styled.div`
  width: 100%;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.25rem 0;
  margin-bottom: 1rem;

  div {
    display: flex;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-basis: 6.25rem;
  flex-grow: 1;
  margin-right: 1rem;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.375rem;
  padding-right: 0.875rem;
  display: flex;
  align-items: center;
  align-self: end;
  margin-right: 0.625rem;
`;

export const Type = styled.div<{ color: string }>`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme, color }) =>
    getContrast(theme.colors.white[500], color) < 7
      ? theme.colors.black[500]
      : color};
  background-color: ${({ theme, color }) =>
    getContrast(theme.colors.white[500], color) <= 1
      ? theme.colors.grey[100]
      : opacify(-0.9, color)};
  padding: 0.25rem 0.625rem;
  height: 1.5625rem;
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  align-self: end;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: 1.5rem;
`;

export const PreviewButton = styled.a`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 5.5625rem;
  height: 2.1875rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[500]};
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
`;

export const SelectButton = styled.div<{ color: string }>`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 5.5625rem;
  height: 2.1875rem;
  cursor: pointer;
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, color }) =>
    getContrast(theme.colors.white[500], color) <= 1
      ? theme.colors.grey[100]
      : color};
  color: ${({ color }) => readableColor(color)};
`;

export const DescriptionTextWrapper = styled.div``;

export const DescriptionText = styled.div`
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const ListTitle = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.25rem;
`;

export const List = styled.ul`
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const ReadMoreButton = styled.div<{ readMore: boolean; color: string }>`
  color: ${({ theme, color }) =>
    getContrast(theme.colors.white[500], color) < 7
      ? theme.colors.black[500]
      : color};
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  align-items: ${({ readMore }) => (readMore ? 'flex-end' : 'center')};
  margin-bottom: 1.125rem;
  cursor: pointer;

  svg {
    width: 0.625rem;
    height: 0.625rem;
    transform: ${({ readMore }) =>
      readMore ? 'rotate(90deg)' : 'rotate(270deg)'};
    color: ${({ theme, color }) =>
      getContrast(theme.colors.white[500], color) < 7
        ? theme.colors.black[500]
        : color};
  }
`;

export const Text = styled.div`
  margin-right: 0.375rem;
`;

export const Arrow = styled(({ props }) => <ArrowBackIos {...props} />)``;
