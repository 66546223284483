import { gql } from '@apollo/client';

export const GET_CAMPAIGNS_TAB_QUERY = gql`
  query GetCampaigns($campaignsInput: VisitorsParams) {
    getCampaigns(CampaignsInput: $campaignsInput) {
      campaign
      responded
      visitors
      subscribed
    }
  }
`;
