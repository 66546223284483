import { ObjectId } from 'mongoose';
import { TeamMember } from 'Shared/types/teamMembers';

export const getTeamMembersFromProject = (
  teamMembers: Array<TeamMember>,
  currentProjectId: string | ObjectId,
  memberToEdit?: {
    email: string;
    _id: string;
    projectId: string;
  }
) => {
  const projectTeam = teamMembers
    .map((member) =>
      getMemberWithCurrentProject(member, currentProjectId, memberToEdit)
    )
    .filter(Boolean);
  return projectTeam;
};

export const getMemberWithCurrentProject = (
  member: TeamMember,
  currentProjectId: string | ObjectId,
  memberToEdit?: {
    email: string;
    _id: string;
    projectId: string;
  }
) => {
  const project = member.projects.find((project) => {
    return String(project._id) === String(currentProjectId);
  });
  const toBeAdded =
    memberToEdit?.email === member.email &&
    memberToEdit?.projectId === currentProjectId;

  if (project) {
    return { ...member, currentProject: project };
  }

  if (toBeAdded) {
    return {
      ...member,
      currentProject: {
        _id: currentProjectId,
        role: '',
        scope: '',
      },
    };
  }

  return;
};

export const getAdminCount = (
  teamMembers: Array<TeamMember>,
  currentProjectId: string
) => {
  return teamMembers.reduce((count, member) => {
    const isAdminOnCurrentProject = member.projects.find((project) => {
      return (
        String(project._id) === String(currentProjectId) &&
        ['Admin', 'admin', 'Lead admin', 'lead admin'].includes(project.role)
      );
    });

    if (isAdminOnCurrentProject) return count + 1;
    return count;
  }, 0);
};
