import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import {
  ChartRenderer,
  LoadingDisplay,
} from 'Client/pages/dashboard/charts/components';
import { ChartsFunctionsObject } from 'Client/pages/dashboard/charts/types';
import { IframeModal } from 'Client/pages/dashboard/charts/components/IframeModal/IframeModal';
import { ChartWrapperProps } from '../../types';
import { ChartHeader } from '../ChartHeader';
import { Wrapper } from './ChartsWrapper.styles';
import { GET_PROJECT_OVERVIEW_CHART_QUERY } from './ChartsWrapper.gql';

export const ChartWrapper: React.FC<ChartWrapperProps> = ({
  appliedFilters,
}: ChartWrapperProps) => {
  const { _id: projectId, id: projectName } = useProject();

  const generateIframe = () => {
    setIsIframeOpen(true);
  };

  const initialSettingsFunctions = {
    viewInFullScreen: undefined,
    printChart: undefined,
    downloadPNG: undefined,
    downloadJPEG: undefined,
    downloadPDF: undefined,
    downloadSVG: undefined,
    downloadCSV: undefined,
    downloadXLS: undefined,
    generateIframe: generateIframe,
  } as ChartsFunctionsObject;
  const initialChartData = {
    categories: [],
    timeSeries: [],
  };

  const [isIframeOpen, setIsIframeOpen] = React.useState<boolean>(false);
  const [chartData, setChartData] = React.useState(initialChartData);
  const [settingsFunctions, setSettingsFunctions] = React.useState(
    initialSettingsFunctions
  );

  const { data, loading } = useQuery(GET_PROJECT_OVERVIEW_CHART_QUERY, {
    variables: {
      getProjectsOverviewChartInput: {
        projectId: projectId,
        date: appliedFilters?.topFilters?.date
          ? appliedFilters.topFilters.date
          : { from: null, to: null },
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      const { series: gSeries, categories: gCategories } =
        data.getProjectOverviewChart;
      setChartData({
        categories: gCategories || [],
        timeSeries: gSeries || [],
      });
    }
  }, [data]);

  const chartBalancer = () => {
    if (loading) return <LoadingDisplay />;
    return (
      <ChartRenderer
        mapSeries={undefined}
        enableMapChart={false}
        questionTitle={'ProjectOverview'}
        chartType={'visitorsLine'}
        dataType={'visitors'}
        setSettingsFunctions={setSettingsFunctions}
        timeSeries={chartData.timeSeries}
        categories={chartData.categories}
        series={[]}
        donutSeries={[]}
        wordcloudData={[]}
        months={[]}
        pivotCriteria={''}
        contributionsIds={[{ contributionId: '', pageId: '' }]}
      />
    );
  };

  return (
    <Wrapper data-testid="ChartWrapper-Wrapper">
      {!loading && <ChartHeader settingsFunctions={settingsFunctions} />}
      {chartBalancer()}

      {isIframeOpen && (
        <IframeModal
          setIsIframeOpen={setIsIframeOpen}
          isIframeOpen={isIframeOpen}
          data={{
            type: 'overview',
            id: 'overview',
            name: 'Project overview',
            label: 'Project overview',
            content: {},
            section: {
              title: '',
              showSectionTitle: false,
            },
          }}
          chartTypes={{ overview: 'visitorsLine' }}
          pivotCriteria={{
            overview: { type: '', category: '', questionName: '' },
          }}
          projectId={projectId}
          id={'overview'}
          appliedFilters={appliedFilters}
          projectName={projectName}
        ></IframeModal>
      )}
    </Wrapper>
  );
};
