import * as React from 'react';
import { TrafficHeaderProps } from './types';
import { Wrapper, Value, Modal } from './TrafficHeader.styles';
import { switchText } from '../../utils';

const TrafficHeader: React.FC<TrafficHeaderProps> = ({
  column,
}: TrafficHeaderProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  if (column.parent) {
    return (
      <Wrapper
        {...column.getHeaderProps()}
        className="th"
        onMouseOver={() => setOpenModal(true)}
        onMouseOut={() => setOpenModal(false)}
      >
        <Value>{column.Header}</Value>
        {openModal && column.Header !== 'Visitors' && (
          <Modal isLastHeader={column.Header === 'Contributed'}>
            {switchText(column.Header)}
          </Modal>
        )}
      </Wrapper>
    );
  }
  return <></>;
};

export { TrafficHeader };
