import { gql } from '@apollo/client';

export const GET_USER_AGREEMENTS_QUERY = gql`
  query GetUserAgreements($params: UserAgreementsParams) {
    getUserAgreements(UserAgreementsInput: $params) {
      _id
      origin
      projectId
      date
      type
      status
      demographicsId
      userId
      map
      commentId
    }
  }
`;

const commonPagesOutput = `
  _id
  projectId
  type
  slug
  createdAt
  active
  content
  contentVersionId
  userId
  order
`;

export const GET_PROPOSALS_AND_MAPS_PAGES_QUERY = gql`
  query Query($params: ProposalPagesParams) {
    getProposalPages(ProposalPagesInput: $params) {
      ${commonPagesOutput}
      stage
      showMinutesLeft
      category
    }

    getMapPages(ProposalPagesInput: $params) {
      ${commonPagesOutput}
      stage
      showMinutesLeft
      category
    }
  }
`;
