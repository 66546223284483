import * as React from 'react';
import { useQuery } from '@apollo/client';
import { DashboardDetailsPageTemplates as Template } from 'Templates';
import {
  Header,
  InfoPanel,
  SidePanel,
} from 'Pages/dashboard/components/DetailsPages';
import { ContributionCard } from 'Client/pages/contributions/components/ContributionCard';
import { ContributionsBanner, ContributionsList } from './components';
import {
  MainContent,
  ContributionsListWrapper,
} from './RespondentsDetails.styles';
import { GET_RESPONDENT_DETAILS_QUERY } from './RespondentsDetails.gql';
import { RespondentsDetailsProps } from './types';

export const RespondentsDetails: React.FC<RespondentsDetailsProps> = ({
  id,
}: RespondentsDetailsProps) => {
  const initialState = {
    status: null,
    signedUpDate: null,
    averageSentiment: null,
    agreements: [],
    comments: [],
    consents: [],
    answers: [],
  };
  const [respondentsData, setRespondentsData] = React.useState(initialState);

  const { data } = useQuery(GET_RESPONDENT_DETAILS_QUERY, {
    variables: {
      getRespondentInput: {
        respondentId: id,
        language: 'en-GB',
      },
    },
  });

  React.useEffect(() => {
    if (!data || !data.getGaudiRespondent) return;
    const {
      signedUpDate: gSignedUpDate,
      status: gStatus,
      userConsents: gUserConsents,
      answersWithQuestions: gAnswersWithQuestions,
      contributions: gContributions,
      averageSentiment: gAverageSentiment,
    } = data.getGaudiRespondent;

    const agreementsList = gContributions
      .filter((c) => c.type === 'agreement')
      .map((c) => c.agreedTo);
    const commentsList = gContributions.filter((c) => c.type === 'comment');

    const mappedRespondentData = {
      status: gStatus,
      signedUpDate: new Date(gSignedUpDate),
      averageSentiment: gAverageSentiment,
      agreements: agreementsList,
      comments: commentsList,
      consents: gUserConsents,
      answers: gAnswersWithQuestions,
    };
    setRespondentsData(mappedRespondentData);
  }, [data]);

  const {
    status,
    signedUpDate,
    averageSentiment,
    agreements,
    comments,
    consents,
    answers,
  } = respondentsData;

  const mappedInfo = [
    { label: 'status', value: status },
    { label: 'average sentiment', value: averageSentiment },
    { label: 'sign up date', value: signedUpDate },
    { label: 'unique id', value: id },
  ];

  const mappedPanelData = [
    {
      type: 'demographics',
      items: consents.map(({ questionCategory, questionValue }) => {
        return { label: questionCategory, value: questionValue };
      }),
    },
    {
      type: 'consents',
      items: answers.map(({ type }) => {
        return { label: type, value: type };
      }),
    },
  ];

  return (
    <Template currentTab="Respondents">
      <MainContent>
        <Header title="Respondent" backButtonText="Back">
          <InfoPanel info={mappedInfo} />
        </Header>
        <ContributionsBanner
          commentsCount={comments.length}
          agreementsCount={agreements.length}
        />
        <ContributionsListWrapper>
          <ContributionsList title={`${comments.length} Comments`}>
            {comments.map((contribution) => (
              <ContributionCard
                key={contribution._id}
                answers={contribution.answersPopulated}
                backUrl={
                  contribution.map
                    ? `/map/${contribution.surveySlug}`
                    : `/proposals/${contribution.surveySlug}`
                }
                contributionId={contribution._id}
                contributionDate={contribution.date}
                isOwnComment={true}
                isMapComment={!!contribution?.map}
                refetchUserAgreements={() => '' /* can't agree */}
                contrProposalSlug={contribution.surveySlug}
                response={contribution.response}
                pageId={contribution.pageId}
                canReply
                detailsPage
              />
            ))}
          </ContributionsList>
          <ContributionsList title={`${agreements.length} Agreements`}>
            {agreements.map((contribution) => (
              <ContributionCard
                key={contribution._id}
                answers={answers}
                backUrl={`/${contribution?.map ? 'map' : 'proposals'}/${
                  contribution.surveySlug
                }`}
                contributionId={id}
                contributionDate={contribution.date}
                isOwnComment={true}
                isMapComment={!!contribution?.map}
                refetchUserAgreements={() => '' /* can't agree */}
                contrProposalSlug={contribution.surveySlug}
                response={contribution.response}
                pageId={contribution.pageId}
                canReply
                detailsPage
              />
            ))}
          </ContributionsList>
        </ContributionsListWrapper>
      </MainContent>
      <SidePanel panelData={mappedPanelData} />
    </Template>
  );
};
