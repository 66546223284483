import { validateEmail } from 'Client/utils/validators';
import { setUserValue } from './setUserValue';

interface verifyFieldsParams {
  firstName: Array<string>;
  setFirstNameErrors: React.Dispatch<React.SetStateAction<Array<boolean>>>;
  lastName: Array<string>;
  setLastNameErrors: React.Dispatch<React.SetStateAction<Array<boolean>>>;
  email: Array<string>;
  setEmailErrors: React.Dispatch<React.SetStateAction<Array<boolean>>>;
  role: Array<string>;
  setRoleErrors: React.Dispatch<React.SetStateAction<Array<boolean>>>;
  selectedProjects: Array<string>;
  setProjectError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const verifyFields = (verifyFieldsParams: verifyFieldsParams) => {
  let pass = true;

  const {
    firstName,
    setFirstNameErrors,
    lastName,
    setLastNameErrors,
    email,
    setEmailErrors,
    role,
    setRoleErrors,
    selectedProjects,
    setProjectError,
  } = verifyFieldsParams;

  const verifyEach = (
    array: Array<string>,
    setErrorsArray: React.Dispatch<React.SetStateAction<Array<boolean>>>,
    isEmail: boolean
  ) => {
    array.forEach((value, index) => {
      if (!value || (isEmail && !validateEmail(value))) {
        setUserValue({
          setFunction: setErrorsArray,
          index,
          value: true,
          cleanErrors: undefined,
        });
        pass = false;
        return;
      }

      setUserValue({
        setFunction: setErrorsArray,
        index,
        value: false,
        cleanErrors: undefined,
      });
    });
  };

  verifyEach(firstName, setFirstNameErrors, false);
  verifyEach(lastName, setLastNameErrors, false);
  verifyEach(email, setEmailErrors, true);
  verifyEach(role, setRoleErrors, false);

  if (selectedProjects.length === 0) {
    setProjectError(true);
    pass = false;
  }

  return pass;
};
