interface handleSetProjectsParams {
  value: string | undefined;
  setSelectedProjects: React.Dispatch<React.SetStateAction<Array<string>>>;
  selectedProjects: Array<string>;
  setProjectError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const handleSetProjects = ({
  value,
  setSelectedProjects,
  selectedProjects,
  setProjectError,
}: handleSetProjectsParams) => {
  setProjectError(false);
  switch (true) {
    case selectedProjects.includes(value):
      setSelectedProjects([...selectedProjects.filter((f) => f !== value)]);
      return;

    default:
      setSelectedProjects((previous: Array<string>) => [...previous, value]);
      return;
  }
};
