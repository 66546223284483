import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PinIcon } from 'Atoms/Icons';
import { Wrapper, Option, OptionsWrapped } from './MapLegend.styles';

interface MapLegendProps {
  legends: {
    label: string;
    colors: {
      primary: string;
    };
  }[];
}

export const MapLegend: React.FC<MapLegendProps> = ({ legends }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2>{t('Legend')}</h2>
      <OptionsWrapped>
        {legends.map((legend) => (
          <Option key={legend.label}>
            <PinIcon width={30} height={30} color={legend.colors.primary} />{' '}
            {legend.label}
          </Option>
        ))}
      </OptionsWrapped>
    </Wrapper>
  );
};
