import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin: 0;
  padding: 0;
  color: black;
  opacity: 0.5;
  text-transform: Uppercase;
`;

export const Text = styled.p`
  font-weight: 700;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin: 0;
  padding: 0;
`;
