import * as React from 'react';
import { OpeningHoursFilter } from './components/OpeningHoursFilter/OpeningHoursFilter';
import { SvgFilter } from './components/SvgFilter';
import { CustomPinProps } from './types';

export const CustomPinFilter = ({
  proposal,
  setPivotFilter,
  pivotQuestion,
}: CustomPinProps) => {
  const categories = {
    openingHours: (
      <OpeningHoursFilter proposal={proposal} setPivotFilter={setPivotFilter} />
    ),
  };

  if (categories[pivotQuestion.category])
    return categories[pivotQuestion.category];

  return <SvgFilter proposal={proposal} setPivotFilter={setPivotFilter} />;
};
