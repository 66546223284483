import * as React from 'react';
import { captureException } from '@sentry/node';
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from 'react-beautiful-dnd';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { EditorPagesTemplate as Template } from 'Templates';
import { cpBrandName } from 'Client/constants/brand';
import { EditModeLayoutProvider } from 'Context/editModeLayoutContext';
import {
  useProject,
  useUser,
  MixpanelEventTypes,
  useAnalytics,
  usePermissions,
  useEditModeContext,
} from 'Client/utils/hooks';
import { postChannelMessage } from 'Client/services/slack';
import { projectEditNotification } from 'Client/utils/slack/formatters';
import { EditModeTools } from 'Organisms';
import { Channel, ProjectEditNotificationPayload } from 'Shared/types/slack';
import { PageTypes } from 'Shared/types/page';
import { LoadingButtonStates } from 'Client/components/atoms/LoadingButton';
import {
  initialise,
  update,
} from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { initialise as initialisePreferences } from 'Client/utils/reduxReducers/editMode/preferencesBlocksReducer';
import { updateView as updatePreferencesView } from 'Client/utils/reduxReducers/editMode/preferencesViewReducer';
import { updateDemographicsView } from 'Client/utils/reduxReducers/editMode/demographicsViewReducer';
import { RootState } from 'Client/redux-store';
import { updateView } from 'Client/utils/reduxReducers/editMode/proposalViewReducer';
import { fetchProposalContentForOtherLanguages } from 'Client/services/proposals';
import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { DemographicsQuestion } from 'Shared/types/demographics';
import { Permissions } from 'Client/constants/permissions';
import { setLocale } from 'Client/utils/reduxReducers/locale/localeReducer';
import { MobilePreview, RightPanel, HideContent } from './components';
import { EditPageProps, SelectedViewOption, SelectedEditItem } from './types';
import { TileData } from './components/SectionPanel/tiles/types';
import {
  DROPPABLE_IDS,
  EDITABLE_PAGE_TYPES,
  EDIT_MODE_MIN_WIDTH,
  HUB_PAGE_ACTIONS,
  METADATA_ACTIONS,
  MILESTONES_BLOCKS_ACTIONS,
  MILESTONES_PAGE_ACTIONS,
  PROPOSAL_BLOCKS_ACTIONS,
  PROPOSAL_QUESTIONS_ACTIONS,
  PROJECT_TEAM_PAGE_ACTIONS,
  EDITABLE_CONTENT_BLOCK_TYPES,
  PROPOSALS_PAGE_ACTIONS,
  NAVBAR_ACTIONS,
} from './constants';
import {
  getSlugValue,
  mapMilestonesToContentBlocks,
  mapQuestionsToProposal,
  rebuildProposalView,
  updateStateBlocks,
  createNewProposalSectionBlock,
  buildProposalForDb,
  getProgrammeContentBlock,
  getDynamicProposalsContentBlock,
  getDescriptionContentBlock,
  getContactTeamContentBlock,
  createNewProjectTeamSectionBlock,
  createNewPreferencesSectionBlock,
  rebuildPreferencesView,
  filterCustom,
  filterDefault,
  createNewDemographicsSectionBlock,
  getImageMapProContentBlock,
  mapProposalToContentBlocks,
  getEmbeddedMapContentBlock,
} from './utils';
import { createNewMilestonesSectionBlock } from './utils/createNewMilestonesSectionBlock'; // needs to be its own import for reasons I don't understand
import {
  AddSectionBlockTypes,
  QuestionBlockTypes,
  getAddTiles,
  DraggableAddItem,
} from './components/SectionPanel';
import {
  Content,
  SideSection,
  MiddleSection,
  Processing,
} from './EditPage.styles';
import { EDIT_PAGE } from './EditPage.gql';
import {
  getTwoColumnsContent,
  getAccordionContentBlock,
  getImageComparisonContent,
} from './utils/createNewProposalSectionBlock';
import { createNewProposalsSectionBlock } from './utils/createNewProposalsSectionBlock';

const NoSSRSectionPanel = dynamic(
  () => import('./components/SectionPanel/SectionPanel'),
  { ssr: false }
);

export const EditPage: React.FC<EditPageProps> = (props: EditPageProps) => {
  const {
    proposals,
    proposalViews,
    proposalsPage,
    newsPosts,
    milestone,
    milestones,
    stakeholders,
    hubContent,
    revisions,
    navbar,
    preferences,
    projectTeamContent,
    demographics,
    editablePages,
    benchmarkQuestions,
  } = props;
  const [
    {
      hubPage,
      proposalQuestions,
      milestonesPage,
      milestonesBlocks,
      projectTeamPage,
      editModeLayout,
      proposalsPageState,
      navbarState,
    },
    {
      dispatchHub,
      dispatchProposalBlocks,
      dispatchMetadata,
      dispatchMilestonesPage,
      dispatchMilestonesBlocks,
      dispatchQuestions,
      dispatchProjectTeamPage,
      dispatchProposalsPage,
      dispatchNavbar,
    },
  ] = useEditModeContext();
  const { projectLocales } = useSelector((state: RootState) => state.locale);
  const proposalBlocksRdx = useSelector(
    (state: RootState) => state.editModeProposalBlocks
  );
  const proposalViewRdx = useSelector(
    (state: RootState) => state.editModeProposalView
  );
  const preferencesBlocksRdx = useSelector(
    (state: RootState) => state.editModePreferencesBlocks
  );
  const { preferencesViewRdx, demographicsViewRdx } = useSelector(
    (state: RootState) => ({
      preferencesViewRdx: state.editModePreferencesView,
      demographicsViewRdx: state.editModeDemographicsView,
    })
  );
  const dispatchRdx = useDispatch();
  const project = useProject();
  const { user } = useUser();
  const { i18n } = useTranslation();
  const { can } = usePermissions();
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const [editPage] = useMutation(EDIT_PAGE);
  const dispatch = useDispatch();

  const { locale: routerLocale, query, asPath } = router;
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [savePerformed, setSavePerformed] = React.useState(false);
  const [currentView, setCurrentView] = React.useState<SelectedViewOption>({
    value: null,
    type: null,
  });
  const [currentEditItem, setCurrentEditItem] =
    React.useState<SelectedEditItem>({
      type: '',
      index: null,
    });
  const [mobileOrTabletView, setMobileOrTabletView] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [lastScrolledItem, setLastScrolledItem] = React.useState({
    id: null,
    timesScrolled: 0,
  });

  const shouldBlockSaving =
    currentView.type === EDITABLE_PAGE_TYPES.DEMOGRAPHICS &&
    project.stage !== 'testing' &&
    !can(Permissions.EDIT_LIVE_DEMOGRAPHICS_CONTENT);

  React.useEffect(() => {
    if (window) {
      setMobileOrTabletView(window.innerWidth < EDIT_MODE_MIN_WIDTH);
    }
  }, [setMobileOrTabletView]);

  React.useEffect(() => {
    // initialise hub page in the context state
    const { sections } = hubContent.content;
    if (
      project.features.navbarV2 &&
      !sections.find((s) => s.type === EDITABLE_CONTENT_BLOCK_TYPES.NAV)
    ) {
      const newSections = sections.splice(1, 0, {
        content: null,
        type: EDITABLE_CONTENT_BLOCK_TYPES.NAV,
        active: true,
        _id: '977b4ac4-094b-48c8-b52d-7f509ccdbe90',
      });
      if (newSections.length) {
        hubContent.content.sections = newSections;
      }
    }
    dispatchHub({ type: HUB_PAGE_ACTIONS.INIT_HUB_PAGE, hubPage: hubContent });
  }, [hubContent]);

  React.useEffect(() => {
    if (!project?.features?.i18n) {
      if (routerLocale) {
        i18n.changeLanguage(routerLocale);
        dispatch(setLocale(routerLocale));
        router.replace(asPath, asPath, {
          scroll: false,
          locale: routerLocale,
        });
      }
    }
  }, [routerLocale]);

  React.useEffect(() => {
    if (
      currentView.type === EDITABLE_PAGE_TYPES.TEAMS &&
      projectTeamContent?.content
    ) {
      // initialise project team page
      dispatchProjectTeamPage({
        type: PROJECT_TEAM_PAGE_ACTIONS.INIT_PROJECT_TEAM_PAGE,
        projectTeamPage: {
          ...projectTeamContent,
          content: {
            ...projectTeamContent.content,
            stakeholders,
          },
        },
      });
    }
  }, [projectTeamContent]);

  React.useEffect(() => {
    const slugValue = getSlugValue(asPath, query?.slug as string | undefined);
    if (editablePages.length > 0) {
      const foundValue = editablePages.find((ep) => ep.value === slugValue);
      if (foundValue) {
        const routeChanged = slugValue !== currentView.value;
        const sameRouteDifferentLang =
          slugValue === currentView.value &&
          foundValue.label !== currentView.label;
        if (
          (routeChanged || sameRouteDifferentLang) &&
          currentView !== foundValue
        ) {
          // if the route has changed
          // or is the same but the label is different (eg same proposal, diff lang)
          // unselect current item, set new page to the state and track the event
          setCurrentView(foundValue);
          setCurrentEditItem({ type: '', index: null });
          trackEvent(MixpanelEventTypes.EDIT_PAGE, {
            path: '/edit',
            page: slugValue,
          });
        }
      }
    }
  }, [asPath, query, editablePages]);

  const refreshData = () => {
    // router.replace(asPath, asPath, { scroll: false });
    // fetch latest v
  };

  React.useEffect(() => {
    // initialise milestones page in the context state
    if (milestone && milestone.content) {
      const {
        content: { title, description, milestones: msContent },
      } = milestone;
      dispatchMilestonesPage({
        type: MILESTONES_PAGE_ACTIONS.INIT_MILESTONES_PAGE,
        milestonesPage: { title, description, milestones: msContent },
      });
      dispatchMilestonesPage({
        type: MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_CONTENT,
        contentBlocks: mapMilestonesToContentBlocks(msContent),
      });
    }
  }, [milestone]);

  React.useEffect(() => {
    if (proposalsPage && proposalsPage.content) {
      const { sections } = proposalsPage.content;
      dispatchProposalsPage({
        type: PROPOSALS_PAGE_ACTIONS.INIT_PROPOSALS_PAGE,
        payload: { blocks: sections, language: router.locale },
      });
    }
  }, [proposalsPage]);

  React.useEffect(() => {
    dispatchNavbar({
      type: NAVBAR_ACTIONS.INIT_NAVBAR,
      payload: { items: navbar.items, language: router.locale },
    });
  }, [navbar]);

  React.useEffect(() => {
    refreshData();
  }, [i18n.language]);

  const handleCurrentEditItem = (
    item: string,
    index: number | null,
    isBenchmark?: boolean,
    locked?: boolean
  ) => {
    setSavePerformed(false);
    const newEditItem = {
      type: item,
      index,
      isBenchmark,
      locked,
    };
    setCurrentEditItem(newEditItem);
    if (tabValue === 1) {
      // switching to content tab after adding a new block
      setTabValue(0);
    }
  };

  const getSectionElementTestId = (type: EDITABLE_PAGE_TYPES) => {
    switch (type) {
      case EDITABLE_PAGE_TYPES.PROPOSAL:
      case EDITABLE_PAGE_TYPES.MAP:
        return 'ProposalSectionRender';
      case EDITABLE_PAGE_TYPES.DEMOGRAPHICS:
        return 'DemographicsSectionRender';
      case EDITABLE_PAGE_TYPES.TIMELINE:
      case EDITABLE_PAGE_TYPES.MILESTONES:
        return 'milestone-item-wrapper';
      case EDITABLE_PAGE_TYPES.PREFERENCES:
        return 'consents-option-container';
      case EDITABLE_PAGE_TYPES.TEAMS:
        return 'teams-block-item';
      case EDITABLE_PAGE_TYPES.HUB:
        return 'hub-block-item';
      case EDITABLE_PAGE_TYPES.PROPOSALS:
        return 'proposals-item-wrapper';
    }
  };

  const getItemIdentifier = (data, pageType) => {
    switch (pageType) {
      case EDITABLE_PAGE_TYPES.TIMELINE:
      case EDITABLE_PAGE_TYPES.MILESTONES:
        return data.payload?.milestone?._id;
      case EDITABLE_PAGE_TYPES.PREFERENCES:
        return data.content?._id;
      case EDITABLE_PAGE_TYPES.TEAMS:
        if (data?.content?.title) {
          return 'teams-header';
        }
        return data.content?._id;
      case EDITABLE_PAGE_TYPES.HUB:
        return data?.content?._id;
      case EDITABLE_PAGE_TYPES.PROPOSALS:
        return data?._id;
      default:
        return data?.questionId;
    }
  };
  const scrollToSection = (data) => {
    const itemIdentifier =
      data?.blockId || getItemIdentifier(data, currentView.type);
    const elementTestId = getSectionElementTestId(currentView.type);
    const sectionElement = document.querySelector(
      `[data-testid="${elementTestId}"][data-scroll-id="${itemIdentifier}"]`
    );

    const scrollTimes =
      lastScrolledItem.id === itemIdentifier
        ? lastScrolledItem.timesScrolled
        : 0;

    if (
      sectionElement &&
      scrollTimes < 3 // in order to prevent scroll at every keydown
    ) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });

      setLastScrolledItem({
        id: itemIdentifier,
        timesScrolled: scrollTimes + 1,
      });
    }
  };

  const handleContentUpdate = (action) => {
    setSavePerformed(false);
    scrollToSection(action);
    switch (currentView.type) {
      case EDITABLE_PAGE_TYPES.HUB: {
        if (currentEditItem.type === EDITABLE_CONTENT_BLOCK_TYPES.NAV) {
          break;
        }
        const newAction = {
          ...action,
          index: currentEditItem.index,
        };
        dispatchHub(newAction);
        break;
      }
      case EDITABLE_PAGE_TYPES.MILESTONES: {
        dispatchMilestonesPage(action);
        const newBlock = {
          component: EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE,
          data: {
            content: action.payload.milestone,
            title: action.payload.milestone.name,
            type: EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE,
          },
        };
        const newMilestoneBlocks = milestonesBlocks.map((block, index) =>
          index === action.payload.index ? newBlock : block
        );
        dispatchMilestonesBlocks({
          type: MILESTONES_BLOCKS_ACTIONS.UPDATE_CONTENT_BLOCKS,
          contentBlocks: newMilestoneBlocks,
        });
        break;
      }
      case EDITABLE_PAGE_TYPES.PROPOSAL: {
        // For Questions, Input, Image Comparison the proposal block dispatch is
        // handled in their editors respectively (to avoid proposal view being
        // one step behind), but we still need to dispatch here for Description (same as hub + milestones pages)
        let localUpdateBlocks = proposalBlocksRdx[router.locale];
        if (action.type === PROPOSAL_BLOCKS_ACTIONS.UPDATE_DESCRIPTION) {
          dispatchProposalBlocks(action);
          dispatchRdx(update({ ...action, lang: router.locale }));
          // and we need to locally do the same update in the blocks too,
          // because the store change is also not yet through
          localUpdateBlocks = updateStateBlocks({
            stateBlocks: proposalBlocksRdx[router.locale],
            index: action.index,
            content: action.content,
          });
        }
        const proposalSteps = buildProposalForDb(localUpdateBlocks);
        const proposalView = mapQuestionsToProposal(
          proposalViewRdx[router.locale],
          proposalSteps,
          proposalQuestions
        );

        dispatchRdx(updateView({ page: proposalView, lang: router.locale }));
        const updatedBlocks = mapProposalToContentBlocks(proposalView);
        dispatchRdx(initialise({ blocks: updatedBlocks, lang: router.locale }));
        dispatchProposalBlocks({
          type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
          contentBlocks: updatedBlocks,
        });
        break;
      }
      case EDITABLE_PAGE_TYPES.TEAMS: {
        dispatchProjectTeamPage(action);
        break;
      }
      case EDITABLE_PAGE_TYPES.PREFERENCES: {
        const customConsents =
          preferencesBlocksRdx[router.locale].filter(filterCustom);
        const defaultConsents =
          preferencesBlocksRdx[router.locale].filter(filterDefault);
        const newCustomConsents = updateStateBlocks({
          stateBlocks: customConsents,
          index: action.index,
          content: action.content,
        });

        const newBlocks = [...defaultConsents, ...newCustomConsents];
        dispatchRdx(
          initialisePreferences({
            blocks: newBlocks,
            lang: router.locale,
          })
        );
        const newView = rebuildPreferencesView(
          newBlocks,
          preferencesViewRdx[router.locale]
        );
        dispatchRdx(
          updatePreferencesView({
            page: newView,
            lang: router.locale,
          })
        );
        break;
      }
      default: {
        captureException(
          `Error on handleContentUpdate() in EditPage.tsx, currentView.type: ${currentView?.type}`
        );
        break;
      }
    }
  };
  const handlePageSave = async (callback?: React.MouseEvent | (() => void)) => {
    try {
      const languageParam = i18n.language;
      let content: {
        _id?: string;
        content?: {
          title?: string;
          description?: string;
          milestones?: unknown[];
        };
      } = {};
      let pageId;
      let pageType = 'page';
      let multiLanguage = false;
      let questions = proposalQuestions;
      setIsProcessing(true);
      switch (currentView.type) {
        case EDITABLE_PAGE_TYPES.PROPOSAL: {
          multiLanguage = true; // we always edit by locale in proposals (COM-492)
          projectLocales.forEach((locale) => {
            if (proposalBlocksRdx[locale] && proposalViewRdx[locale]._id) {
              const proposalSteps = proposalBlocksRdx[locale]
                ? buildProposalForDb(proposalBlocksRdx[locale])
                : [];
              const proposalContent = {
                ...proposalViewRdx[locale].content,
                steps: proposalSteps,
              };
              pageId = proposalViewRdx[locale]._id;
              content[locale] = { content: proposalContent, userId: user._id };
            } else {
              content[locale] = null; // editPageMultipleLocales.ts handles this
            }
          });
          break;
        }
        case EDITABLE_PAGE_TYPES.HUB: {
          pageId = hubPage._id;
          content = { ...hubPage, userId: user._id };
          pageType = EDITABLE_PAGE_TYPES.HUB;
          delete content._id;
          break;
        }
        case EDITABLE_PAGE_TYPES.MILESTONES: {
          pageId = milestone._id;
          pageType = EDITABLE_PAGE_TYPES.MILESTONES;
          const { title, description } = milestone.content;
          content = {
            ...milestone,
            content: {
              title,
              description,
              milestones: milestonesPage.milestones,
            },
          };
          delete content._id;
          break;
        }
        case EDITABLE_PAGE_TYPES.TEAMS: {
          pageId = projectTeamPage._id;
          pageType = EDITABLE_PAGE_TYPES.TEAMS;
          content = {
            ...projectTeamPage,
          };
          delete content._id;
          break;
        }
        case EDITABLE_PAGE_TYPES.PREFERENCES: {
          multiLanguage = true;
          pageId = preferences._id;
          projectLocales.forEach((locale) => {
            if (preferencesBlocksRdx[locale] && preferencesViewRdx[locale]) {
              content[locale] = {
                content: preferencesViewRdx[locale].content,
                userId: user._id,
              };
            } else {
              content[locale] = null; // editPageMultipleLocales.ts handles this
            }
          });
          break;
        }
        case EDITABLE_PAGE_TYPES.DEMOGRAPHICS: {
          multiLanguage = true;
          pageId = demographics._id;
          pageType = EDITABLE_PAGE_TYPES.DEMOGRAPHICS;
          questions = demographicsViewRdx[
            router.locale
          ].content?.questions?.reduce((obj, question) => {
            return {
              ...obj,
              [question.questionId]: JSON.stringify(question.content),
            };
          }, {});

          projectLocales.forEach((locale) => {
            if (demographicsViewRdx[locale] && demographicsViewRdx[locale]) {
              content[locale] = {
                content: {
                  ...demographicsViewRdx[locale].content,
                  questions: demographicsViewRdx[
                    locale
                  ].content?.questions?.map((question) => ({
                    questionId: question.questionId,
                    order: question.order,
                  })),
                },
                userId: user._id,
              };
            } else {
              content[locale] = null; // editPageMultipleLocales.ts handles this
            }
          });
          break;
        }
        case EDITABLE_PAGE_TYPES.PROPOSALS: {
          pageType = EDITABLE_PAGE_TYPES.PROPOSALS;
          multiLanguage = true; // we always edit by locale in proposals (COM-492)
          projectLocales.forEach((locale) => {
            if (
              proposalsPageState[locale]?.blocks &&
              proposalsPageState[locale]?.blocks?.length > 0
            ) {
              const proposalContent = {
                ...proposalsPage.content,
                sections: proposalsPageState[locale].blocks,
              };
              pageId = proposalsPage._id;
              content[locale] = { content: proposalContent, userId: user._id };
            } else {
              content[locale] = null; // editPageMultipleLocales.ts handles this
            }
          });
          break;
        }
        default: {
          break;
        }
      }

      if (shouldBlockSaving) {
        dispatchMetadata({
          type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
          payload: LoadingButtonStates.INITIAL,
        });
        setIsProcessing(false);
        return;
      }
      const editBody = JSON.stringify({
        pageId,
        type: pageType,
        language: languageParam,
        content,
        questions,
        proposalStage: proposalViewRdx[router.locale]?.stage,
        slug: proposalViewRdx[router.locale]?.slug,
        projectLocales,
        multiLanguage,
        navbar: navbarState[router.locale],
      });

      const editModeRequest = async () => {
        const gqlData = await editPage({
          variables: {
            editPageInput: {
              project,
              user,
              body: editBody,
            },
          },
        });
        const result = gqlData.data;

        if (!result.editPage.success) {
          throw new Error('Failure at saving page via GraphQL');
        }

        return result.editPage.newPage;
      };

      const newPage = await editModeRequest();
      currentView.versionId = newPage.content[languageParam][0];

      const otherLocales = projectLocales.filter((l) => l !== languageParam);
      if (newPage.type === PageTypes.PROPOSAL && otherLocales.length > 0) {
        // init redux now with newPage content if page is proposal.
        // This is mainly to update the redux store with the latest content
        // especially after a question is added (issue was: the rest locales still had "new:1234556" as question id)
        const res = await fetchProposalContentForOtherLanguages({
          slug: newPage.slug,
          projectId: project._id,
          forLocales: otherLocales, // for all locales besides the current one, since that one is already okay
          callback: dispatchRdx,
        });
        if (res.done) {
          setIsProcessing(false);
        }
      } else {
        setIsProcessing(false);
      }

      dispatchMetadata({
        type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
        payload: LoadingButtonStates.FINISHED,
      });
      const messageData: ProjectEditNotificationPayload = {
        language: languageParam,
        pageId,
        project: project.name,
        timeStamp: Date.now(),
        user: {
          id: user.id,
          displayName: user.displayName || user.firstName + user.lastName || '',
          email: user.email,
        },
        userAgent: navigator.userAgent,
        url: location.href,
        versionId: newPage.content[languageParam][0],
        view: currentView.label,
      };
      await postChannelMessage({
        channel: Channel.PROJECT_EDITS,
        message: projectEditNotification(messageData),
        project,
      });
      setSavePerformed(true);
      if (callback && typeof callback === 'function') {
        callback();
      } else {
        if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
          router.push(`/edit/proposals/${newPage.slug}`);
        } else {
          refreshData();
        }
      }
    } catch (error) {
      console.error('Error in handlePageSave() at EditPage.tsx: ', error);
      dispatchMetadata({
        type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
        payload: LoadingButtonStates.FINISHED,
      });
      setIsProcessing(false);
      captureException(`Error in handlePageSave() at EditPage.tsx: ${error}`);
    }
  };

  const handleLanguageChange = () => {
    // unselect edit item when changing language
    setCurrentEditItem({ type: '', index: null });
  };

  const handleDropNewSectionBlock = (result: DropResult) => {
    setSavePerformed(false);
    const { source, destination, draggableId } = result;
    if (!destination) {
      // dropped new item outside the list
      return;
    }

    const addTiles = getAddTiles(currentView.type, user, project);

    switch (source.droppableId) {
      case DROPPABLE_IDS.PROPOSAL: {
        const pageFeatures =
          proposalViewRdx[router.locale]?.content?.featureFlags;

        const proposalTiles = getAddTiles(
          currentView.type,
          user,
          project,
          undefined, // tiles
          pageFeatures
        );

        handleDropNewProposalSection(source, destination, proposalTiles);
        break;
      }
      case DROPPABLE_IDS.HUB: {
        const tiles = hubContent.content.sections as TileData[];

        const hubTiles = getAddTiles(currentView.type, user, project, tiles);

        handleDropNewHubSection(source, destination, hubTiles);
        break;
      }
      case DROPPABLE_IDS.MILESTONES:
        handleDropNewMilestonesSection(source, destination, addTiles);
        break;
      case DROPPABLE_IDS.TEAMS:
        handleDropNewProjectTeamSection(destination);
        break;
      case DROPPABLE_IDS.PREFERENCES:
        handleDropNewPreferencesSection(destination);
        break;
      case DROPPABLE_IDS.DEMOGRAPHICS:
        handleDropNewDemographicsSection(
          source,
          destination,
          addTiles,
          draggableId
        );
        break;
      case DROPPABLE_IDS.PROPOSALS:
        handleDropNewProposalsSection(source, destination, addTiles);
        break;
      default:
        break;
    }
  };

  const handleDropNewProposalsSection = (
    source: DraggableLocation,
    destination: DraggableLocation,
    droppableSections: Array<DraggableAddItem>
  ) => {
    const droppingSection = droppableSections[source.index];
    const droppingSectionType = droppingSection.key as AddSectionBlockTypes;
    const droppingIndex = destination.index <= 2 ? 2 : destination.index;
    const newBlock = createNewProposalsSectionBlock(droppingSectionType);
    dispatchProposalsPage({
      type: PROPOSALS_PAGE_ACTIONS.ADD_BLOCK,
      payload: {
        newBlock,
        index: droppingIndex,
        language: router.locale,
      },
    });
  };

  const handleDropNewMilestonesSection = (
    source: DraggableLocation,
    destination: DraggableLocation,
    droppableSections: Array<DraggableAddItem>
  ) => {
    const droppingSection = droppableSections[source.index]; // always a 'milestone' section for now
    const droppingSectionType = droppingSection.key as AddSectionBlockTypes;
    const droppingIndex = destination.index;
    // Make the new content block
    const newContentBlock =
      createNewMilestonesSectionBlock(droppingSectionType);
    // Add it to the milestones page (state dispatchers)
    // update the blocks
    const newMilestoneBlocks = [
      ...milestonesBlocks.slice(0, droppingIndex),
      newContentBlock,
      ...milestonesBlocks.slice(droppingIndex),
    ];
    dispatchMilestonesBlocks({
      type: MILESTONES_BLOCKS_ACTIONS.UPDATE_CONTENT_BLOCKS,
      contentBlocks: newMilestoneBlocks,
    });
    // update the page in the view
    const newMilestonesView = [
      ...milestonesPage.milestones.slice(0, droppingIndex),
      newContentBlock.data.content,
      ...milestonesPage.milestones.slice(droppingIndex),
    ];
    dispatchMilestonesPage({
      type: MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_CONTENT,
      contentBlocks: mapMilestonesToContentBlocks(newMilestonesView),
    });
  };

  const handleDropNewProjectTeamSection = (destination: DraggableLocation) => {
    const droppingIndex = destination.index;
    const newContentBlock = createNewProjectTeamSectionBlock();
    const currentStakeholders = projectTeamPage?.content?.stakeholders || [];
    const newBlocks = [
      ...currentStakeholders.slice(0, droppingIndex),
      {
        ...newContentBlock.data.content,
      },
      ...currentStakeholders.slice(droppingIndex),
    ];
    dispatchProjectTeamPage({
      type: PROJECT_TEAM_PAGE_ACTIONS.UPDATE_BLOCKS,
      contentBlocks: newBlocks,
    });
  };

  const handleDropNewHubSection = (
    source: DraggableLocation,
    destination: DraggableLocation,
    droppableSections: Array<DraggableAddItem>
  ) => {
    const droppingSection = droppableSections[source.index];
    const droppingSectionType = droppingSection.key as AddSectionBlockTypes;
    const droppingIndex = destination.index || 1; // do not allow dropping above the hero, which would be index = 0
    // Make the new content block
    const newContentBlock = (() => {
      if (droppingSectionType === AddSectionBlockTypes.PROGRAMME)
        return getProgrammeContentBlock(project.customer);
      if (droppingSectionType === AddSectionBlockTypes.DYNAMIC_PROPOSALS)
        return getDynamicProposalsContentBlock();
      if (droppingSectionType === AddSectionBlockTypes.CONTACT_TEAM)
        return getContactTeamContentBlock();
      if (droppingSectionType === AddSectionBlockTypes.IMAGE_MAP_PRO)
        return getImageMapProContentBlock();
      if (droppingSectionType === AddSectionBlockTypes.NAV_MAP)
        return getEmbeddedMapContentBlock();
      if (droppingSectionType === AddSectionBlockTypes.TWO_COLUMNS) {
        const data = getTwoColumnsContent();
        const parsedData = {
          ...data,
          ...data.data,
          active: true,
        };
        delete parsedData.data;
        return parsedData;
      }
      if (
        droppingSectionType === AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER
      ) {
        const data = getImageComparisonContent();
        const parsedData = {
          ...data,
          ...data.data,
          active: true,
        };
        delete parsedData.data;
        return parsedData;
      }
      if (droppingSectionType === AddSectionBlockTypes.ACCORDION) {
        const data = getAccordionContentBlock();
        const parsedData = {
          ...data,
          ...data.data,
          active: true,
        };
        delete parsedData.data;
        return parsedData;
      }
      return getDescriptionContentBlock();
    })();
    // Add it to the hub page (state dispatchers)
    const {
      content: { sections },
    } = hubPage;
    const newSections = [
      ...sections.slice(0, droppingIndex),
      newContentBlock,
      ...sections.slice(droppingIndex),
    ];
    dispatchHub({
      type: HUB_PAGE_ACTIONS.UPDATE_SECTIONS,
      sections: newSections,
    });
  };

  const handleQuestionsDrop = ({ qObj, contentId }): string => {
    // no extra handling needed for sentiment -> no new question dropped is feeling by default
    dispatchQuestions({
      questionId: contentId,
      questionJson: JSON.stringify(qObj, null, 2),
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
    return JSON.stringify(qObj, null, 2);
  };

  const handleDropNewProposalSection = (
    source: DraggableLocation,
    destination: DraggableLocation,
    droppableSections: Array<DraggableAddItem>
  ) => {
    const droppingSection = droppableSections[source.index];
    const droppingSectionType = droppingSection.key as AddSectionBlockTypes;
    const droppingIndex = destination.index;
    // Make the new content block
    const { contentId, newContentBlock, newStateQuestionJson } =
      createNewProposalSectionBlock(droppingSectionType);
    let updatedStateQuestionJson = newStateQuestionJson;
    // Add it to the proposal (state dispatchers)
    if (
      QuestionBlockTypes.includes(droppingSectionType) &&
      newStateQuestionJson
    ) {
      // if it's a question, handle the extra dispatch
      updatedStateQuestionJson = handleQuestionsDrop({
        qObj: JSON.parse(newStateQuestionJson),
        contentId,
      });
    }
    const newProposalBlocks = [
      ...proposalBlocksRdx[router.locale].slice(0, droppingIndex),
      newContentBlock,
      ...proposalBlocksRdx[router.locale].slice(droppingIndex),
    ];
    dispatchProposalBlocks({
      type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
      contentBlocks: newProposalBlocks,
    });
    // locally update questions because context state is not updated yet
    let upToDateQuestions = { ...proposalQuestions };
    if (updatedStateQuestionJson) {
      upToDateQuestions = {
        ...proposalQuestions,
        [contentId]: updatedStateQuestionJson,
      };
    }
    dispatchRdx(initialise({ blocks: newProposalBlocks, lang: router.locale }));
    const proposalView = rebuildProposalView(
      newProposalBlocks,
      proposalViewRdx[router.locale],
      upToDateQuestions
    );
    dispatchRdx(updateView({ page: proposalView, lang: router.locale }));
    const restLocales = projectLocales.filter((l) => l !== router.locale);
    // Update with same new content for each other lang
    try {
      restLocales.forEach((lang) => {
        const newProposalBlocks = [
          ...proposalBlocksRdx[lang].slice(0, droppingIndex),
          newContentBlock,
          ...proposalBlocksRdx[lang].slice(droppingIndex),
        ];
        dispatchRdx(initialise({ blocks: newProposalBlocks, lang }));
        const proposalView = rebuildProposalView(
          newProposalBlocks,
          proposalViewRdx[lang],
          upToDateQuestions
        );
        dispatchRdx(updateView({ page: proposalView, lang }));
      });
    } catch (error) {
      // Silently fail if content in other locales could not be updated
      // (usually due to existing faulty data)
      captureException(
        `Error in handleDropNewProposalSection() in EditPage.tsx:
        Failed to update section blocks in proposal: ${proposalView?.slug} for other locales. Error: ${error.toString()}`
      );
    }
  };

  const handleDropNewPreferencesSection = (destination: DraggableLocation) => {
    const updatePreferences = (lang: string) => {
      const defaultSections = preferencesBlocksRdx[lang].filter(filterDefault);
      const customSections = preferencesBlocksRdx[lang].filter(filterCustom);

      const droppingIndex = destination.index + defaultSections?.length;
      const newContentBlock = createNewPreferencesSectionBlock();

      const newSections = [
        ...customSections.slice(0, droppingIndex),
        newContentBlock,
        ...customSections.slice(droppingIndex),
      ];
      const newBlocks = [...defaultSections, ...newSections];
      dispatchRdx(
        initialisePreferences({
          blocks: newBlocks,
          lang,
        })
      );
      const newPreferencesPage = rebuildPreferencesView(
        newBlocks,
        preferencesViewRdx[lang],
        lang
      );
      dispatchRdx(
        updatePreferencesView({
          page: newPreferencesPage,
          lang,
        })
      );
    };

    updatePreferences(router.locale);
    const restLocales = projectLocales.filter((l) => l !== router.locale);
    try {
      restLocales.forEach((lang) => {
        updatePreferences(lang);
      });
    } catch (error) {
      captureException(
        `Error in handleDropNewPreferencesSection() in EditPage.tsx:
        Failed to update section blocks in preferences: ${
          project._id
        } for other locales. Error: ${error.toString()}`
      );
    }
  };

  const handleDropNewDemographicsSection = (
    source: DraggableLocation,
    destination: DraggableLocation,
    droppableSections: Array<DraggableAddItem>,
    questionId?: string
  ) => {
    try {
      const benchmarkQuestion =
        questionId &&
        benchmarkQuestions?.find((q) => q?.questionId === questionId);
      const isSpecialCategoryData =
        destination.droppableId === SPECIAL_CATEGORY_DATA;
      const droppingSection = droppableSections[source.index];
      const droppingSectionType = droppingSection?.key as AddSectionBlockTypes;
      const droppingIndex = destination.index;
      const newQuestion =
        benchmarkQuestion ||
        createNewDemographicsSectionBlock(
          droppingSectionType,
          droppingIndex,
          isSpecialCategoryData
        );

      const updateView = (lang = router.locale) => {
        const allQuestions =
          demographicsViewRdx[lang]?.content?.questions || [];
        const newQuestions = [
          ...allQuestions.slice(0, droppingIndex),
          newQuestion,
          ...allQuestions.slice(droppingIndex),
        ];
        const questions = newQuestions.map(
          (q: DemographicsQuestion, index) => ({
            ...q,
            order: index,
          })
        );
        dispatchRdx(
          updateDemographicsView({
            lang,
            page: {
              ...demographicsViewRdx[lang],
              content: {
                ...demographicsViewRdx[lang].content,
                questions,
              },
            },
          })
        );
      };

      updateView();

      const restLocales = projectLocales.filter((l) => l !== router.locale);

      restLocales.forEach((lang) => {
        updateView(lang);
      });
    } catch (error) {
      captureException(error);
    }
  };
  return (
    <EditModeLayoutProvider>
      <Template {...props}>
        {isProcessing && <Processing />}
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        {mobileOrTabletView ? (
          <HideContent
            text={`Please edit your ${cpBrandName} on a laptop or desktop computer.`}
            link={{
              text: 'Return to homepage',
              url: '/',
            }}
          />
        ) : (
          <>
            <EditModeTools
              currentView={currentView}
              handleSave={handlePageSave}
              editablePages={editablePages}
              proposalViewsInitial={proposalViews}
              milestonesPageInitial={milestone}
              hubContentInitial={hubContent}
              onLanguageChange={handleLanguageChange}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              shouldBlockSaving={shouldBlockSaving}
              savePerformed={savePerformed}
              data-testid="edit-page-toolbar"
            />
            <DragDropContext onDragEnd={handleDropNewSectionBlock}>
              <Content container spacing={2}>
                <SideSection>
                  <NoSSRSectionPanel
                    currentEditItem={currentEditItem}
                    onCurrentEditItem={handleCurrentEditItem}
                    handleOrderChange={dispatchHub}
                    toggleHideShow={dispatchHub}
                    currentView={currentView}
                    hubContentState={hubPage}
                    milestones={milestones}
                    tabValue={tabValue}
                    preferences={preferences}
                    setTabValue={setTabValue}
                    setIsProcessing={setIsProcessing}
                    demographics={demographics}
                    benchmarkQuestions={benchmarkQuestions}
                    {...props}
                  />
                </SideSection>
                <MiddleSection>
                  <MobilePreview
                    currentView={currentView}
                    proposals={proposals}
                    newsPosts={newsPosts}
                    milestones={milestonesPage.milestones}
                    stakeholders={stakeholders}
                    onCurrentEditItem={handleCurrentEditItem}
                    benchmarkQuestions={benchmarkQuestions}
                    editModeDevice={editModeLayout.previewMode}
                  />
                </MiddleSection>
                <SideSection>
                  <RightPanel
                    currentEditItem={currentEditItem}
                    editablePages={editablePages}
                    hubPageState={hubPage}
                    handleContentUpdate={handleContentUpdate}
                    revisions={revisions}
                    currentView={currentView}
                    onCurrentEditItem={handleCurrentEditItem}
                    proposals={proposals}
                    proposalsPage={proposalsPage}
                  />
                </SideSection>
              </Content>
            </DragDropContext>
          </>
        )}
      </Template>
    </EditModeLayoutProvider>
  );
};

export default EditPage;
