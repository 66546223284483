import styled from 'styled-components';
import { readableColor, lighten } from 'polished';

export const Wrapper = styled.div`
  padding: 0.6rem 0;
`;

export const InputLabel = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 1.2rem;
  margin: 0;
`;
export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0.5rem;
`;

export const InlineOption = styled.div<{
  rounded: boolean;
  selected: boolean;
}>`
  ${({ rounded, selected, theme }) => `
  font-size: 0.77rem;
  text-align: center;
  ${rounded ? 'border-radius: 25px;' : ''}
  background-color: ${
    selected
      ? theme.colorMappings.brand
      : lighten(0.4, theme.colorMappings.brand)
  };
  color: ${
    selected
      ? readableColor(theme.colorMappings.brand)
      : readableColor(lighten(0.4, theme.colorMappings.brand))
  };
  padding: 0.7rem 0.5rem;
  cursor: pointer;
`}
`;
