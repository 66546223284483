import * as React from 'react';
import styled from 'styled-components';
import { opacify } from 'polished';
import { Button, Modal as ModalBase } from 'Molecules';

export const Modal = styled(({ ...props }) => (
  <ModalBase width="auto" height="auto" {...props} />
))``;

export const Wrapper = styled.div`
  overflow: auto;
  min-height: 34.5rem;
  max-height: 36rem;
  min-width: 58.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

export const Delete = styled(Button).attrs({
  colorMapping: 'CommonplaceBrand',
})`
  border-radius: 0.1875rem;
`;

export const TextMain = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0.6875rem 0 0.6875rem 0;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const TextDiv = styled.div`
  background-image: url('/static/illustrations/team_management_remove_member_background.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1rem;
  padding: 1rem;
  height: 12.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > img {
    height: 50%;
  }

  ::before {
    content: '';
    width: 100%;
    border: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
    position: relative;
    top: 100%;
  }
`;

export const FormDivAdmin = styled.div`
  overflow: auto;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem 1rem;
  padding: 0 1rem 1rem;
  border-bottom: 0.125rem solid ${({ theme }) => theme.colors.grey[200]};
`;

export const FormDiv = styled.div`
  margin: 0 1rem 1rem;
  padding: 0 1rem 1rem;
  border-bottom: 0.125rem solid ${({ theme }) => theme.colors.grey[200]};
`;

export const SearchInput = styled.input`
  border: 0.125rem solid ${({ theme }) => theme.colors.grey[200]};
  border-radius: 0.3125rem;
  margin: 1rem 0;
  min-height: 3rem;
  font-size: 1rem;
  padding-left: 1rem;
  width: 100%;
`;

export const SearchResultsDiv = styled.div`
  width: 100%;
`;

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
  &:last-child {
    border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
  }
`;

export const UserRollCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  border-right: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
  width: 25%;
  min-width: 10.625rem;
  height: 2.25rem;
  padding: 0.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    border-left: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
  }
`;

export const ReplaceButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  align-self: center;
  padding: 0 1rem;
  margin: -0.0625rem 1rem;
  background: ${({ theme, selected }) =>
    selected && theme.colorMappings.CommonplaceBrand};
  border: 0.0625rem solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.white[500]
        : theme.colorMappings.CommonplaceBrand};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white[500] : theme.colorMappings.CommonplaceBrand};
`;

export const ProjectsDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
`;

export const ProjectsRow = styled.div<{ programmeStyle: boolean }>`
  height: 2.25rem;
  width: ${({ programmeStyle }) => (programmeStyle ? '100%' : '97%')};
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 0.5rem;
  margin: 0.3125rem;

  font-weight: ${({ programmeStyle }) => (programmeStyle ? '700' : '400')};
  cursor: pointer;

  input {
    cursor: pointer;
  }

  &:hover {
    color: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
    background-color: ${({ theme }) =>
      opacify('-0.8', theme.colorMappings.CommonplaceBrand)};

    & > div {
      background-color: ${({ theme }) =>
        opacify('-0.5', theme.colors.white[500])};
    }
  }
`;
export const ProjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white[500]};
  height: 100%;
  flex: 1;
  padding: 0 0 0 1rem;
  border-radius: 0 0.3125rem 0.3125rem 0;
`;
