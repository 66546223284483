import { MapPageProps } from 'Client/pages';

export enum PanelTabIndex {
  INFO_TAB,
  LAYERS_TAB,
  FILTERS_TAB,
}

export interface MapInfoPanelProps {
  isWelcome: boolean;
  proposal?: MapPageProps;
}
