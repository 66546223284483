export const switchText = (header) => {
  switch (header) {
    // Column headers
    case 'Aware':
      return 'A visitor who viewed more than one page but less than four and did not contribute.';
    case 'Bounced':
      return 'A visitor who viewed one page only and left without interacting with the Commonplace.';
    case 'Informed':
      return 'A visitor who viewed four or more pages but did not contributed.';
    case 'Contributed':
      return 'A person who contributed by commenting or agreeing. This excludes people who were surveyed';
    // Row headers
    case 'Direct traffic':
      return 'A visitor arrives directly on a website, without having clicked on a link on another site.';
    case 'Referral traffic':
      return 'A visitor arrives arrives on your website through another source, like through a link on another domain.';
    case 'Email traffic':
      return 'A visitor arrives on your website after clicking on a link in an email.';
    case 'Organic traffic':
      return 'A visitor lands on your website as a result of unpaid  search results.';
    default:
      break;
  }
};
