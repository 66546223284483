import * as React from 'react';
import { useRouter } from 'next/router';
import { addDays } from 'date-fns';
import { useQuery } from '@apollo/client';
import { DashboardPagesTemplate as Template } from 'Templates';
import { ContributionsFilters } from 'Shared/types/dashboard';
import { useProject } from 'Client/utils/hooks';
import { getTopFiltersFromUrl } from 'Pages/dashboard/utils';
import { Header, Card, ChartWrapper } from './components';
import { Wrapper, CardsList } from './OverviewPage.styles';
import { GET_PROJECT_OVERVIEW_QUERY } from './OverviewPage.gql';
import { defaultProjectOverviewCount, getCardsData } from './constants';

export const OverviewPage: React.FC = () => {
  const router = useRouter();
  const { _id } = useProject();

  const { query } = router;
  const topFilters = getTopFiltersFromUrl({ router });
  const dateFilter = topFilters.date;

  const [projectOverviewCount, setProjectOverviewCount] = React.useState(
    defaultProjectOverviewCount
  );
  const [appliedFilters, setAppliedFilters] =
    React.useState<ContributionsFilters>(null);

  const { data } = useQuery(GET_PROJECT_OVERVIEW_QUERY, {
    variables: {
      getProjectsOverviewInput: {
        projectId: _id,
        filters: appliedFilters,
      },
    },
  });

  const applyFilters = () => {
    setAppliedFilters({
      topFilters: {
        date: {
          from: dateFilter.from,
          to: (() => {
            if (dateFilter.to && !Number.isNaN(new Date(dateFilter.to))) {
              return addDays(new Date(dateFilter.to), 1);
            }
            return dateFilter.to;
          })(),
        },
      },
    });
  };

  const cardsData = getCardsData(projectOverviewCount);

  React.useEffect(() => {
    if (query.topFilters) {
      applyFilters();
    }
  }, [query.topFilters]);

  React.useEffect(() => {
    if (data) {
      const projectOverview = data.getProjectOverview;
      setProjectOverviewCount(projectOverview);
    }
  }, [data]);

  return (
    <Template>
      <Wrapper data-testid="OverviewPage-Wrapper">
        <Header appliedFilters={appliedFilters} />
        <CardsList>
          {cardsData.map((card) => (
            <Card {...card} key={card.label} />
          ))}
        </CardsList>
        {console.log(appliedFilters, 'applied render')}
        <ChartWrapper appliedFilters={appliedFilters} />
      </Wrapper>
    </Template>
  );
};
