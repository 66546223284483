import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { TabProps, WrapperProps } from './types';

export const TabBarWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: ${({ navBorderWidth }) => navBorderWidth || '0.07rem'};
  border-bottom-color: ${({ theme }) => theme.colors.grey[600]};

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;

      display: flex;
      align-items: flex-start;
      justify-content: unset;
      border-bottom-color: ${({ theme }) => theme.colors.grey[600]};
      border-bottom-style: solid;

      button {
        margin-right: 0.625rem;
      }
    `}
`;

export const Results = styled.h3`
  padding: 0 25px;
  text-align: center;
  & > span {
    color: ${({ theme }) => theme.colorMappings.brand};
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tab = styled.button<TabProps>`
  ${({
    isSelected,
    borderBottomHeight,
    theme,
    fontWeight = 700,
    color,
    borderSpacing = 1,
    isGla,
  }) => css`
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: ${fontWeight};
    padding-bottom: ${borderSpacing}rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${color || theme.colors.black[500]};

    ${isSelected &&
    !isGla &&
    css`
      border-bottom: ${borderBottomHeight
        ? `${borderBottomHeight}rem solid`
        : '0.063rem solid'};
      border-bottom-color: ${theme.colorMappings.primaryButtonBackground};
      color: ${theme.colorMappings.primaryButtonBackground};
      font-weight: 700;
    `};

    ${isGla &&
    css`
      background-color: #e7e7e7;
      border-bottom: 0.25rem solid #a4a4a4;

      ${isSelected &&
      css`
        border-bottom: 0.25rem solid ${theme.colorMappings.brand};
        background-color: ${lighten(0.45, theme.colorMappings.brand)};
        color: ${theme.colorMappings.brand};
      `}
    `}

    &:not(:last-child) {
      margin-right: 2rem;
    }

    svg {
      padding: 0;
      margin: 0 0.5rem 0 0;
    }
  `}
`;
