import * as React from 'react';
import { useRouter } from 'next/router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/node';
import {
  MixpanelEventTypes,
  useAnalytics,
  useProject,
  useUser,
  useMap,
  useProposalContext,
  useDemographicsContext,
} from 'Client/utils/hooks';
import {
  getNumberSpecialCatAnswered,
  saveDemographicsAnswers,
} from 'Client/services/demographics';
import { getProposalSlugFromContributionId } from 'Client/services/proposals';
import {
  CONTRIBUTION_SESSION_ITEM,
  getLocalItem,
} from 'Client/utils/localstorage';
import { changeRouteKeepParams } from 'Client/utils/url';
import { LoadingButtonStates } from 'Atoms';
import LoadingScreen from 'Client/components/molecules/LoadingScreen/LoadingScreen';
import {
  BottomNavigation,
  BackNavigationAction,
  NextNavigationButton,
} from '../../ProposalPages/components/Footer/Footer.styles';
import { FooterTemplateProps } from '../types';

export const Footer: React.FC<FooterTemplateProps> = ({
  nextButtonAction,
}: FooterTemplateProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const project = useProject();
  const { user } = useUser();
  const { trackEvent } = useAnalytics();
  const [{ signupEmail, contributionId }] = useProposalContext();
  const {
    state: { contributionFlow: mapContributionFlow },
  } = useMap();
  const { demographicsAnswers } = useDemographicsContext();
  const [fetching, setFetching] = React.useState<boolean>(false);

  const isEmbed = router.query?.embed === 'true';

  const handleDemographicsAnswers = async () => {
    try {
      const userId =
        user?._id ?? getLocalItem(CONTRIBUTION_SESSION_ITEM) ?? null;
      const userEmail = user?.email ?? signupEmail ?? null;
      await saveDemographicsAnswers({
        demographicsAnswers,
        projectSlug: project.id,
        userId,
        userEmail,
        locale: router.locale,
      });
    } catch (error) {
      captureException(
        `Error in handleDemographicsAnswers() at src/client/templates/EngagementPages/components/Footer.tsx: ${error.toString()}`
      );
    }
  };
  const handleNextClick = async () => {
    if (fetching) {
      return;
    }
    setFetching(true);

    if (router.route === '/demographics') {
      try {
        await handleDemographicsAnswers();
      } catch (error) {
        setFetching(false);
        captureException(
          `Error in handleNextClick() at src/client/templates/EngagementPages/components/Footer.tsx: ${error.toString()}`
        );
      }
      trackEvent(MixpanelEventTypes.ADDED_DEMOGRAPHICS, {
        path: router.asPath,
        email: user?.email ?? signupEmail ?? 'N/A',
        answeredSpecialCategoryQuestions:
          getNumberSpecialCatAnswered(demographicsAnswers),
      });
      const preferencesRoute = changeRouteKeepParams(
        router.asPath,
        '/preferences'
      );
      router.push(preferencesRoute);
    } else if (router.route === '/preferences') {
      if (user) {
        try {
          // user is logged in
          const contrId = mapContributionFlow
            ? mapContributionFlow.contributionId
            : contributionId;
          const proposalSlug = await getProposalSlugFromContributionId(contrId);
          const thanksRoute = changeRouteKeepParams(
            router.asPath,
            mapContributionFlow
              ? `/map/${proposalSlug}/thanks`
              : `/proposals/${proposalSlug}/thanks`
          );
          router.push(thanksRoute);
        } catch (e) {
          captureException(e);
          setFetching(false);
        }
      } else {
        const confirmEmailRoute = changeRouteKeepParams(
          router.asPath,
          '/confirm-email'
        );
        router.push(confirmEmailRoute);
      }
    }

    if (nextButtonAction) {
      nextButtonAction();
    }
  };

  const handleBackClick = () => {
    router.back();
  };

  return (
    <BottomNavigation
      showLabels
      data-testid="EngagementPagesTemplate-Footer"
      smallContainer
    >
      {fetching && <LoadingScreen withLoadingRing={true} />}
      <NextNavigationButton
        resetState={() => {}}
        data-testid="EngagementPagesFooter-next-button"
        onClick={handleNextClick}
        disabled={fetching}
        loaderColor="grey"
        state={
          fetching ? LoadingButtonStates.LOADING : LoadingButtonStates.INITIAL
        }
      >
        {t('Next')}
      </NextNavigationButton>
      {router.route !== '/email' && !isEmbed && (
        <BackNavigationAction
          data-testid="EngagementPagesFooter-back-button"
          label={t('Back')}
          onClick={handleBackClick}
          icon={<ArrowBackIosIcon fontSize="small" />}
          left
        />
      )}
    </BottomNavigation>
  );
};
