import * as React from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUser, useProject } from 'Client/utils/hooks';
import { SendIconBold } from 'Atoms/Icons';
import { Checkbox, TextField } from 'Client/components/molecules';
import { validateEmail } from 'Client/utils/validators';
import { setResponse } from 'Client/utils/reduxReducers/contributionResponses/responsesReducer';
import {
  FirstRow,
  RadioRow,
  SendButton,
  TextAreaRow,
  TextWrapper,
  UserContainer,
  UserImage,
  Wrapper,
  Option,
  TextArea,
  InputWrapper,
  Label,
} from './CommentReplySection.styles';
import { userInitials } from './utils/userInitials';
import { CommentReplySectionProps } from './types';
import { ADD_COMMENT_REPLY_MUTATION } from './CommentReplySection.gql';

export const CommentReplySection: React.FC<CommentReplySectionProps> = ({
  id,
}: CommentReplySectionProps) => {
  const { user } = useUser();
  const { id: projectName } = useProject();
  const { t } = useTranslation();

  const [visibility, setVisibility] = React.useState<string>(null);
  const [comment, setComment] = React.useState<string>(null);
  const [commentError, setCommentError] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(
    user.displayName || user.email
  );
  const [nameError, setNameError] = React.useState<boolean>(false);

  const [showEmail, setShowEmail] = React.useState<boolean>(null);
  const [email, setEmail] = React.useState<string>(null);
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [apiFeedback, setApiFeedback] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [commentResponse, setCommentResponse] = React.useState(null);

  const dispatchRdx = useDispatch();

  const [addCommentReply, { data, loading }] = useMutation(
    ADD_COMMENT_REPLY_MUTATION
  );

  const handleClick = () => {
    setApiFeedback(null);
    if (name.length <= 0) {
      setNameError(true);
      return;
    }

    if (showEmail && !validateEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!comment) {
      setCommentError(true);
      return;
    }

    const response = {
      visibility: visibility || 'private',
      stakeholder: name,
      email: email || '',
      comment,
      date: new Date(),
    };

    setIsLoading(true);
    setCommentResponse(response);
    addCommentReply({
      variables: {
        commentReplyInput: {
          contributionId: id,
          project: projectName,
          response,
        },
      },
    });
    setVisibility(null);
    setComment('');
  };

  React.useEffect(() => {
    if (isLoading && !loading) {
      setIsLoading(false);

      if (data?.addCommentReply?.status == 200) {
        dispatchRdx(
          setResponse({
            id,
            response: commentResponse,
          })
        );
        setApiFeedback({
          show: true,
          type: 'success',
          message: t('Comment added successfully'),
        });
      } else {
        setApiFeedback({
          show: true,
          type: 'error',
          message: t('Something went wrong, please try again'),
        });
      }
    }
  }, [data, loading]);

  return (
    <Wrapper>
      <FirstRow>
        <UserContainer>
          <UserImage>{userInitials(user)}</UserImage>
          <TextWrapper>
            <InputWrapper>
              <Label> Name: </Label>
              <TextField
                handleChange={(e) => {
                  setNameError(false);
                  setName(String(e.target.value ?? ''));
                }}
                label={''}
                placeholder="Type here your name"
                status={
                  nameError && {
                    show: true,
                    type: 'error',
                    message: 'Name cannot be empty',
                  }
                }
                defaultValue={user.displayName || user.email}
              />
            </InputWrapper>
            <Checkbox
              label="Show email"
              onChange={(e) => {
                setShowEmail(e.target.checked);
                setEmail(String(user.email));
              }}
            />
            {showEmail && (
              <InputWrapper>
                <Label> Email: </Label>
                <TextField
                  handleChange={(e) => {
                    setEmailError(false);
                    setEmail(String(e.target.value ?? ''));
                  }}
                  label={''}
                  placeholder="Type here your email"
                  status={
                    emailError && {
                      show: true,
                      type: 'error',
                      message: 'Email cannot be empty',
                    }
                  }
                  defaultValue={user.email}
                />
              </InputWrapper>
            )}
          </TextWrapper>
        </UserContainer>
      </FirstRow>
      <RadioRow>
        <Option>
          <input
            checked={visibility === 'public'}
            type="radio"
            name="option"
            id="public"
            onClick={() => setVisibility('public')}
          />
          Respond to this comment publicly
        </Option>
        <Option>
          <input
            checked={visibility === 'private'}
            type="radio"
            name="option"
            id="private"
            onClick={() => setVisibility('private')}
          />
          Respond to this comment privately
        </Option>
      </RadioRow>
      <TextAreaRow>
        <TextArea
          placeholder="Type here your reply"
          value={comment}
          handleChange={(e) => {
            setComment(e.target.value as string);
          }}
          status={
            commentError
              ? {
                  show: true,
                  type: 'error',
                  message: t('Comment cannot be empty'),
                }
              : apiFeedback
          }
        />
        <SendButton onClick={handleClick} disabled={isLoading}>
          <SendIconBold /> Send
        </SendButton>
      </TextAreaRow>
    </Wrapper>
  );
};
