import * as React from 'react';
import { useRouter } from 'next/router';
import { useProject, useMap } from 'Client/utils/hooks';
import { useBanner } from 'Client/utils/hooks/useBanner';
import {
  FiltersView,
  LayersView,
  OpenCloseTag,
  ProjectInfoView,
  TabsNavigation,
  ToMapButton,
} from './components';
import { MapInfoPanelProps, PanelTabIndex } from './types';
import { Content, Header, Wrapper } from './MapInfoPanel.styles';

type Props = MapInfoPanelProps;

export const MapInfoPanel = ({ isWelcome, proposal }: Props): JSX.Element => {
  const project = useProject();
  const router = useRouter();
  const {
    dispatch,
    state: { infoPanelOpen, activeInfoPanelTab },
  } = useMap();
  const isEmbed = router?.query?.embed === 'true';
  const [revealing, setRevealing] = React.useState<boolean>(null);
  const [activeTab, setActiveTab] =
    React.useState<PanelTabIndex>(activeInfoPanelTab);

  React.useEffect(() => {
    setActiveTab(activeInfoPanelTab);
  }, [activeInfoPanelTab]);
  React.useEffect(() => {
    const canShowLayersTab = !proposal?.featureFlags?.hideMapLayersTab;
    const canShowProjectInfoTab = !proposal?.featureFlags?.hideProjectInfoTab;
    const activeTab = canShowProjectInfoTab
      ? PanelTabIndex.INFO_TAB
      : canShowLayersTab
      ? PanelTabIndex.LAYERS_TAB
      : PanelTabIndex.FILTERS_TAB;
    setActiveTab(activeTab);
    dispatch({
      type: 'OPEN_INFO_PANEL',
      payload: {
        activeInfoPanelTab: activeTab,
      },
    });
  }, [proposal?.featureFlags]);
  const handleNavigation = (index: PanelTabIndex) => {
    dispatch({
      type: 'OPEN_INFO_PANEL',
      payload: {
        activeInfoPanelTab: index,
      },
    });
    setActiveTab(index);
  };

  const handleScroll = () => {
    revealing && setTimeout(() => setRevealing(false), 250);
  };

  React.useEffect(() => {
    setRevealing(isWelcome);
  }, [isWelcome]);

  const tabsMap = {
    [PanelTabIndex.INFO_TAB]: <ProjectInfoView />,
    [PanelTabIndex.LAYERS_TAB]: <LayersView />,
    [PanelTabIndex.FILTERS_TAB]: <FiltersView />,
  };

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return (
    <Wrapper
      isOpen={isWelcome || infoPanelOpen}
      isEmbed={isEmbed}
      errorBannerHeight={errorBannerHeight}
      stageBannerHeight={stageBannerHeight}
    >
      <Header
        background={proposal?.infoPanel?.image || proposal?.card?.image}
        isRevealing={revealing}
      >
        <ToMapButton />
        <OpenCloseTag />
      </Header>
      {isWelcome ||
        (project?.features?.showMapPanelTabs && (
          <TabsNavigation
            activeTab={activeTab}
            onClick={handleNavigation}
            proposalFeatureFlags={proposal?.featureFlags}
          />
        ))}
      <Content onScroll={handleScroll}>{tabsMap[activeTab]}</Content>
    </Wrapper>
  );
};
