import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReplyIcon } from 'Atoms/Icons';

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

export const ReplyButton: React.FC<Props> = ({ setIsOpen, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Wrapper onClick={() => setIsOpen(!isOpen)}>
      <ReplyIcon /> {t('Reply')}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;

  svg {
    margin-top: 0.4375rem;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }
`;
