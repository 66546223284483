import * as React from 'react';
import { MapLayerNames } from 'Shared/types/map';
import {
  Checkbox,
  CheckBoxContainer,
  IconWrapper,
} from '../FiltersView.styles';
import { CustomPinProps } from '../types';

export const SvgFilter = ({
  proposal,
  setPivotFilter,
}: Omit<CustomPinProps, 'pivotQuestion'>) => {
  const { svgs, style } =
    proposal.geolytixWorkspace.locales.UK.layers[MapLayerNames.CONTRIBUTIONS];
  if (!svgs) return null;

  const [selectedValues, setSelectedValues] = React.useState(
    Object.keys(svgs).reduce(
      (values, key) => ({
        ...values,
        [key]: true,
      }),
      {}
    )
  );

  React.useEffect(() => {
    const filteredValues = Object.keys(selectedValues).filter(
      (key) => selectedValues[key]
    );
    setPivotFilter(filteredValues.length > 0 ? filteredValues : ['']);
  }, [selectedValues]);
  return (
    <>
      {Object.keys(svgs).map((key) => (
        <CheckBoxContainer key={key}>
          <IconWrapper optionColor={style?.theme?.cat[key]?.style?.fillColor}>
            <Checkbox
              data-testid="PivotTabCheckbox"
              label={key}
              checked={selectedValues[key]}
              onChange={(e) => {
                const { checked } = e.target;
                setSelectedValues({
                  ...selectedValues,
                  [key]: checked,
                });
              }}
              status={{ show: false }}
            />
            <i
              dangerouslySetInnerHTML={{
                __html: svgs[key],
              }}
            />
          </IconWrapper>
        </CheckBoxContainer>
      ))}
    </>
  );
};
