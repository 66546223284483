import styled from 'styled-components';

export const MainContentContainer = styled.div`
  position: relative;
  min-height: 16rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  min-height: 10rem;
  box-shadow: 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.31);
  display: flex;
  justify-content: space-between;
`;

export const HeaderDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: baseline;
  min-height: inherit;
`;

export const DropDownGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
