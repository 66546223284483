import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../Shared.styles';
import {
  SwitchContainer,
  SwitchText,
  SwitchWrapper,
} from '../Forms/ProjectDetailsForm/ProjectDetailsForm.styles';
import { Switch } from '../Switch/Switch';
import { ProjectPropertiesProps } from './types';

export const ProjectProperties: React.FC<ProjectPropertiesProps> = ({
  isCsSiteBuild,
  isDemo,
  isTraining,
  setIsCsSiteBuild,
  setIsDemo,
  setIsTraining,
}) => {
  const { t } = useTranslation('customer');
  return (
    <div>
      <Label>
        {t('Categorise the project if appropriate (internal use only)')}:
      </Label>
      <SwitchContainer>
        <SwitchWrapper>
          <Switch checked={isDemo} onClick={() => setIsDemo((prev) => !prev)} />
          <SwitchText>{t('Demo')}</SwitchText>
        </SwitchWrapper>
        <SwitchWrapper>
          <Switch
            checked={isTraining}
            onClick={() => setIsTraining((prev) => !prev)}
          />
          <SwitchText>{t('Training')}</SwitchText>
        </SwitchWrapper>
        <SwitchWrapper>
          <Switch
            checked={isCsSiteBuild}
            onClick={() => setIsCsSiteBuild((prev) => !prev)}
          />
          <SwitchText>{t('CS site build')}</SwitchText>
        </SwitchWrapper>
      </SwitchContainer>
    </div>
  );
};
