import { gql } from '@apollo/client';

export const GET_TEMPLATES_QUERY = gql`
  query Query($getTemplatesInput: GetTemplatesInput) {
    getTemplates(GetTemplatesInput: $getTemplatesInput) {
      _id
      createdAt
      content {
        title
        category {
          name
          color
        }
        description
        list {
          title
          items
        }
        image {
          src
          alt
        }
      }
      subdomain
      projectId
      isPrivate
      owners
    }
  }
`;
