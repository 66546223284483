import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'Client/utils/hooks';

interface Props {
  userId: string;
}

export const CommentsByUser: React.FC<Props> = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (user?._id === userId) return <h1>{t('Your responses')}</h1>;
  return <h1>{t('Responses by the same person')}</h1>;
};
