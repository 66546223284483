import styled from 'styled-components';

export const SwitchContainer = styled.div`
  margin-top: 1.875rem;
  margin-left: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Divider = styled.div`
  width: 23.5rem;
  background-color: ${({ theme }) => theme.colors.grey[300]};
  height: 1px;
  margin: 1rem 0 1rem -1.875rem; /* Margin gap from parent. Needs to be full width */
`;
