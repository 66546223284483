import * as React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useUser } from 'Client/utils/hooks';
import { RootState } from 'Client/redux-store';
import { GET_PROJECTS_LIST_QUERY } from 'Client/pages/project-centre/components/ProjectsTable/ProjectsTable.gql';
import { LoadRing } from 'Client/components/molecules';
import { ProjectGaudi } from 'Shared/types';
import { Wrapper } from './TeamProjectTable.styles';
import { TeamProjectRow } from '../TeamProjectRow';

export const TeamProjectTable: React.FC = () => {
  const { user } = useUser();
  const [projects, setProjects] = React.useState<Array<ProjectGaudi>>([]);
  const { selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const { data, loading } = useQuery(GET_PROJECTS_LIST_QUERY, {
    variables: {
      getProjectsFromAdmin: {
        userId: user._id,
        customer: selectedCustomer,
        limit: 1000,
        lastIndex: 0,
        filters: {
          sort: {
            field: 'Alphabetically',
            order: 'ascending',
          },
        },
      },
    },
  });

  const setInitialData = () => {
    const { projects } = data.getProjectsFromAdminV2;
    if (projects && selectedCustomer.customer && !loading) {
      setProjects(projects);
    }
  };

  React.useEffect(() => {
    if (data) {
      setInitialData();
    }
  }, [data]);

  return (
    <Wrapper>
      {selectedCustomer.customer && loading && (
        <LoadRing loadingMessage={'Loading'} />
      )}
      {!loading &&
        selectedCustomer.customer &&
        projects?.map((project) => {
          return project && <TeamProjectRow key={project._id} {...project} />;
        })}
    </Wrapper>
  );
};
