import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15rem;
  padding: 2rem;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const Number = styled.span`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.black[600]};
`;

export const Text = styled.span`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.grey[900]};
`;
