import { captureException } from '@sentry/node';
import axios from 'axios';
import { MapPageProps } from 'Client/pages';
import { MapPageWithQuestions } from 'Client/pages/map';
import {
  FilterOptions,
  FILTERS_VIEW_COMPONENTS_TYPE,
  FILTERS_VIEW_MULTISELECT_VARIANTS,
  TextContentSection,
} from 'Client/pages/map/types';
import { MapLayerNames } from 'Shared/types/map';
import { MultiselectOption } from 'Shared/types/question';

interface GetFilterOptionsProps {
  projectId: string;
  proposal: Partial<MapPageProps>;
  lang?: string;
}

export const getFilterOptions = async ({
  projectId,
  proposal,
  lang = 'en-GB',
}: GetFilterOptionsProps): Promise<(FilterOptions | TextContentSection)[]> => {
  try {
    const { svgs } =
      proposal.geolytixWorkspace.locales.UK.layers[MapLayerNames.CONTRIBUTIONS];
    const proposalWithContent: { data: MapPageWithQuestions[] } = await axios
      .get(
        `/api/pages/map/get-map-page-populated?projectId=${projectId}&pageId=${proposal.pageId}&lang=${lang}`
      )
      .then(async (res) => await res.data);

    const { content } = proposalWithContent.data[0];
    const { filters, steps } = content;
    if (!filters) return [];
    /* TO-DO: if filter has options, return them
     * if (!filters.options.length) {}
     */
    const toFilterQuestions = filters
      .filter(({ type }) => type !== FILTERS_VIEW_COMPONENTS_TYPE.TEXT_CONTENT)
      .map((versionFilterQuestion, idx) => ({
        ...versionFilterQuestion,
        order: idx,
      }));
    const contentItems: TextContentSection[] = filters
      .filter(({ type }) => type === FILTERS_VIEW_COMPONENTS_TYPE.TEXT_CONTENT)
      .map((versionFilterContent, idx) => ({
        type: versionFilterContent.type,
        content: versionFilterContent?.content,
        title: versionFilterContent?.title,
        order: idx,
      }));

    const populatedFilteredQuestions = toFilterQuestions.map((toFilterItem) => {
      const populatedQuestion = steps[0].sections.find(
        ({ question }) =>
          question.id.toString() === toFilterItem.questionId.toString()
      );
      return {
        ...populatedQuestion,
        ...toFilterItem,
      };
    });
    const populatedQuestionItems: FilterOptions[] =
      populatedFilteredQuestions.map((filterItemWithContent) => {
        const { label, placeholder, type, variant, parentQuestionId } =
          filterItemWithContent;
        const { id, name } = filterItemWithContent.question;
        const isMultiselect = filterItemWithContent.question.type === 'label';
        const optionsProp = isMultiselect ? 'values' : 'options';
        if (
          type === FILTERS_VIEW_COMPONENTS_TYPE.MULTISELECT &&
          variant === FILTERS_VIEW_MULTISELECT_VARIANTS.CHECKBOX
        ) {
          /* Adds icons to options */
          filterItemWithContent.question[optionsProp] =
            filterItemWithContent.question[optionsProp].map(
              (option: MultiselectOption) => ({
                ...option,
                icon: svgs[option.label],
              })
            );
        }
        return {
          label,
          type,
          placeholder,
          id,
          variant,
          name,
          options: filterItemWithContent.question[optionsProp],
          parentQuestionId: parentQuestionId,
          queryOrder: filterItemWithContent?.queryOrder,
          order: filterItemWithContent.order,
        };
      });
    const toReturn = [...populatedQuestionItems, ...contentItems].sort(
      (a, b) => a.order - b.order
    );

    return toReturn;
  } catch (err) {
    captureException(err);
  }
};
