import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from 'Client/redux-store';

const FilterList = styled.ul`
  padding: 0;
  font-size: 1.1rem;
  line-height: 150%;

  li {
    list-style: none;
    margin-bottom: 0.5rem;
  }
`;

const clearQuotes = (val: string) =>
  val.match(/^'[^']*'$/) ? val.slice(1, val.length - 1) : val;

const FilterSummary: React.FC = () => {
  const { t } = useTranslation();
  const { filters, queryByArea } = useSelector(
    (state: RootState) => state.filters
  );
  const parseFilterValue = (...filterProperties: string[]) =>
    filterProperties
      .flatMap((filterProperty) =>
        filters
          .filter((f) => f[filterProperty])
          .flatMap((filter) =>
            Object.entries(filter[filterProperty] ?? {}).flatMap(
              ([operation, val]: [string, string]) =>
                val
                  .split(',')
                  .flatMap((val) => val.split('|'))
                  .map((val) =>
                    operation === 'gte'
                      ? `${t('after')} ${clearQuotes(val)}`
                      : operation === 'lte'
                      ? `${t('before')} ${clearQuotes(val)}`
                      : clearQuotes(val.trim())
                  )
            )
          )
      )
      .join('; ') || t('All');

  const getQueryByArea = () => {
    if (queryByArea?.area) {
      /* Ground work for a possible followup ticket (svg):
       * import geojson2svg from 'geojson-to-svg';
       *  return geojson2svg()
       *    .data({
       *      type: 'Feature',
       *      properties: {
       *        // Needs some work due to only 'fill' being applied
       *        stroke: 'black',
       *        strokeWidth: 60,
       *        transform: 'scale(1, -1)',
       *        width: '30px',
       *        fill: 'none',
       *      },
       *      geometry: queryByArea?.area,
       *    })
       *    .render();
       */
      return t('1 area applied');
    }
    return t('Not specified');
  };
  return (
    <>
      <p style={{ fontSize: '1.1rem' }}>
        {t('Receive updates based on the following filters:')}
      </p>
      <FilterList>
        <li>
          <strong>{t('Category')}:</strong> {parseFilterValue('category')}
        </li>
        <li>
          <strong>{t('Status')}:</strong> {parseFilterValue('status')}
        </li>
        <li>
          <strong>{t('Stage')}:</strong> {t('All')}
        </li>
        <li>
          <strong>{t('Date')}:</strong>{' '}
          {parseFilterValue('validatedDate', 'decisionDate')}
        </li>
        <li>
          <strong>{t('Location')}:</strong> {parseFilterValue('postcode')}
        </li>
        <li>
          <strong>{t('Area')}:</strong> {getQueryByArea()}
        </li>
      </FilterList>
    </>
  );
};

export default FilterSummary;
