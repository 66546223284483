export const userInitials = (user) => {
  if (user.displayName) {
    const nameArray = user.displayName.split(' ');
    const name = nameArray[0][0] + nameArray[nameArray.length - 1][0];
    return name.toUpperCase();
  }

  if (user.firstName && user.lastName) {
    return user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase();
  }

  if (user.email) {
    return user.email[0].toUpperCase();
  }

  return;
};
