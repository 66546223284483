import * as React from 'react';
import uniqBy from 'lodash.uniqby';
import { useQuery } from '@apollo/client';
import { LoadRing } from 'Molecules';
import { ChartItem } from 'Shared/types/dashboard';
import { ChartsContainer } from 'Pages/dashboard/charts';
import { useProject } from 'Client/utils/hooks';
import { TableHeader } from 'Client/pages/dashboard/components/TableHeader/TableHeader';
import { ChartsWrapperProps } from './types';
import {
  enabledRespondentsQuestionTypes,
  // additionalChartData,
} from './constants';
import { GET_ALL_DEMOGRAPHICS_QUESTIONS_QUERY } from './ChartsWrapper.gql';

export const ChartsWrapper: React.FC<ChartsWrapperProps> = ({
  projectId,
  appliedFilters,
  dataTotal,
  filteredRows,
  dataName,
}: ChartsWrapperProps) => {
  const { features } = useProject();
  const { pseudonymiseData, pseudonymiseDataApproved } = features;
  const allowSCD = pseudonymiseData && pseudonymiseDataApproved;
  const [respondents, setRespondents] = React.useState<Array<ChartItem>>([]);

  const { data: gqlData, loading: isLoading } = useQuery(
    GET_ALL_DEMOGRAPHICS_QUESTIONS_QUERY,
    {
      variables: {
        demographicsQuestionsParams: {
          language: 'en-GB',
          projectId: projectId,
        },
      },
    }
  );

  React.useEffect(() => {
    if (gqlData) {
      const { questions: gqlQuestions } = gqlData.getAllDemographicsQuestions;
      const mappedQuestions = gqlQuestions
        .filter((question) => {
          const type = question?.questionContent?.content?.type;
          const sensitiveType =
            question?.questionContent?.content?.sensitiveType;
          if (!allowSCD && sensitiveType === 'special-category-data') {
            return false;
          }
          return enabledRespondentsQuestionTypes.includes(type) && question._id;
        })
        .map((question) => {
          const isSCD =
            question.questionContent.content.sensitiveType ===
            'special-category-data';
          const chartItem = {
            id: question._id,
            name: question.questionContent.content.name,
            label: question.questionContent.content.label,
            type: question.questionContent.content.type,
            content: question.questionContent.content,
            isRespondentsData: true,
            isSpecialCategory: isSCD,
            section: isSCD
              ? {
                  title: 'Special category demographics',
                  showSectionTitle: true,
                }
              : {
                  title: 'non-special demographics questions',
                  showSectionTitle: false,
                },
          };
          return chartItem;
        });
      const uniqueQuestion = uniqBy([...mappedQuestions], 'id');
      setRespondents(uniqueQuestion);
    }
  }, [allowSCD, gqlData, isLoading]);

  if (isLoading) {
    return <LoadRing loadingMessage="Loading charts" />;
  }

  return (
    <div data-testid="respondents-charts">
      <TableHeader
        isTableLoading={isLoading}
        dataTotal={dataTotal}
        currentlyFilteredRows={filteredRows}
        dataName={dataName}
      />
      <ChartsContainer
        data={respondents}
        pageType="respondents"
        appliedFilters={appliedFilters}
        dataType={'respondents'}
        contributionsIds={[{ contributionId: '', pageId: '' }]}
      />
    </div>
  );
};
