import styled, { css } from 'styled-components';
import { opacify } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: calc(100% - 3.75rem);
    min-width: calc(100% - 15.625rem);
    overflow: auto;
    color: ${theme.colors.black[50]};
  `}
`;

export const Content = styled.div`
  width: 100%;
  min-width: 78rem;
  padding: 0 2.875rem 0 3.9375rem;
  overflow: auto;
`;

export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  min-width: 78rem;
  margin: 0 0 1.1875rem;
  position: sticky;
  top: 0;
  background: ${({ theme }) => opacify(-0.8, theme.colors.grey[600])};
  padding: 1rem 0;
  z-index: 8;
  span {
    width: 40%;
  }
`;

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;

  svg {
    margin-right: 0.3125rem;
  }
`;

export const HeaderText = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.1875rem;
`;
