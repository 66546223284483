import * as React from 'react';
import { IconProps } from '../types';

export const DemographicsLightBulb: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 87,
  height = 83,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 87 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M82.4741 65.9058V66.0486C76.7691 74.9708 64.6668 81.1296 43.521 81.1296C23.8616 81.1296 11.9353 75.806 5.65918 67.887V64.5622H7.92787V66.7292H9.26758V69.2052H11.9646V64.5642H13.6759V60.901H16.635V55.8981L17.1376 55.3975V60.903H18.1585V66.8348H19.3496V73.6429H20.9259V68.7945L23.4684 66.252V61.4193L24.1764 62.1253V68.9999L25.0233 69.8487V72.1956H28.0058V69.5162H26.9302V68.7945H28.0078V67.7619H30.83V64.5211H31.5536V67.7619H32.7975V74.7772H36.1634V72.1995L38.1191 70.2437H39.7014V66.6275H40.6206V70.2379H41.751L43.7205 72.2093V74.7772H46.3119V70.0325H48.6979L51.436 67.2944V63.5198H52.2085V67.5506L54.6787 70.0208H58.0211V72.8117H60.0453V66.9365H62.6132V59.9525H62.136V57.2515H62.6132V55.3975H63.3701V61.6814L64.4556 62.7649V67.1438H67.0861V68.2782H70.0197V63.2773H71.3594V58.1923H71.8034V63.2773H73.6497L75.8245 65.4775V67.3492H77.6668V64.3588H80.6552V65.9058H82.4741Z"
      fill={color}
    />
    <path d="M15.655 61.531H14.4365V62.7494H15.655V61.531Z" fill="white" />
    <path d="M17.1901 61.531H15.9717V62.7494H17.1901V61.531Z" fill="white" />
    <path d="M15.655 63.0876H14.4365V64.3061H15.655V63.0876Z" fill="white" />
    <path d="M17.1901 63.0876H15.9717V64.3061H17.1901V63.0876Z" fill="white" />
    <path d="M15.655 64.6423H14.4365V65.8608H15.655V64.6423Z" fill="white" />
    <path d="M17.1901 64.6423H15.9717V65.8608H17.1901V64.6423Z" fill="white" />
    <path d="M15.655 66.1992H14.4365V67.4177H15.655V66.1992Z" fill="white" />
    <path d="M17.1901 66.1992H15.9717V67.4177H17.1901V66.1992Z" fill="white" />
    <path d="M15.655 67.7559H14.4365V68.9743H15.655V67.7559Z" fill="white" />
    <path d="M17.1901 67.7559H15.9717V68.9743H17.1901V67.7559Z" fill="white" />
    <path d="M15.655 69.3127H14.4365V70.5312H15.655V69.3127Z" fill="white" />
    <path d="M15.655 70.8696H14.4365V72.0881H15.655V70.8696Z" fill="white" />
    <path d="M17.1901 70.8696H15.9717V72.0881H17.1901V70.8696Z" fill="white" />
    <path d="M15.655 72.4263H14.4365V73.6447H15.655V72.4263Z" fill="white" />
    <path d="M17.1901 72.4263H15.9717V73.6447H17.1901V72.4263Z" fill="white" />
    <path d="M15.655 73.9832H14.4365V75.2016H15.655V73.9832Z" fill="white" />
    <path d="M17.1901 73.9832H15.9717V75.2016H17.1901V73.9832Z" fill="white" />
    <path d="M18.7233 67.7559H17.5049V68.9743H18.7233V67.7559Z" fill="white" />
    <path d="M18.7233 69.3127H17.5049V70.5312H18.7233V69.3127Z" fill="white" />
    <path d="M18.7233 70.8696H17.5049V72.0881H18.7233V70.8696Z" fill="white" />
    <path d="M18.7233 73.9832H17.5049V75.2016H18.7233V73.9832Z" fill="white" />
    <path d="M14.1198 66.1992H12.9014V67.4177H14.1198V66.1992Z" fill="white" />
    <path d="M14.1198 67.7559H12.9014V68.9743H14.1198V67.7559Z" fill="white" />
    <path d="M14.1198 69.3127H12.9014V70.5312H14.1198V69.3127Z" fill="white" />
    <path d="M14.1198 70.8696H12.9014V72.0881H14.1198V70.8696Z" fill="white" />
    <path d="M23.7621 67.3806H22.7861V68.5991H23.7621V67.3806Z" fill="white" />
    <path d="M23.7621 68.9199H22.7861V70.1384H23.7621V68.9199Z" fill="white" />
    <path d="M23.7621 70.4568H22.7861V71.6752H23.7621V70.4568Z" fill="white" />
    <path d="M23.7621 71.9941H22.7861V73.2126H23.7621V71.9941Z" fill="white" />
    <path d="M23.7621 73.5312H22.7861V74.7497H23.7621V73.5312Z" fill="white" />
    <path d="M23.7621 75.0686H22.7861V76.287H23.7621V75.0686Z" fill="white" />
    <path d="M22.4828 68.9275H21.5068V70.1459H22.4828V68.9275Z" fill="white" />
    <path d="M22.4828 70.4646H21.5068V71.683H22.4828V70.4646Z" fill="white" />
    <path d="M22.4828 72.0002H21.5068V73.2187H22.4828V72.0002Z" fill="white" />
    <path d="M22.4828 73.5374H21.5068V74.7558H22.4828V73.5374Z" fill="white" />
    <path d="M22.4828 75.0725H21.5068V76.291H22.4828V75.0725Z" fill="white" />
    <path d="M22.4828 76.6099H21.5068V77.8283H22.4828V76.6099Z" fill="white" />
    <path d="M8.54624 67.5505H7.57031V68.769H8.54624V67.5505Z" fill="white" />
    <path d="M8.54624 69.1077H7.57031V70.3261H8.54624V69.1077Z" fill="white" />
    <path d="M8.54624 70.6643H7.57031V71.8828H8.54624V70.6643Z" fill="white" />
    <path d="M8.54624 73.7759H7.57031V74.9943H8.54624V73.7759Z" fill="white" />
    <path d="M8.54624 75.3325H7.57031V76.551H8.54624V75.3325Z" fill="white" />
    <path d="M7.26695 67.5505H6.29102V68.769H7.26695V67.5505Z" fill="white" />
    <path d="M7.26695 69.1077H6.29102V70.3261H7.26695V69.1077Z" fill="white" />
    <path d="M7.26695 70.6643H6.29102V71.8828H7.26695V70.6643Z" fill="white" />
    <path d="M7.26695 72.2192H6.29102V73.4377H7.26695V72.2192Z" fill="white" />
    <path d="M7.26695 73.7759H6.29102V74.9943H7.26695V73.7759Z" fill="white" />
    <path d="M30.9096 68.3506H29.8457V69.569H30.9096V68.3506Z" fill="white" />
    <path d="M32.2495 68.3506H31.1855V69.569H32.2495V68.3506Z" fill="white" />
    <path d="M30.9096 69.8857H29.8457V71.1042H30.9096V69.8857Z" fill="white" />
    <path d="M32.2495 69.8857H31.1855V71.1042H32.2495V69.8857Z" fill="white" />
    <path d="M30.9096 71.4211H29.8457V72.6396H30.9096V71.4211Z" fill="white" />
    <path d="M32.2495 71.4211H31.1855V72.6396H32.2495V71.4211Z" fill="white" />
    <path d="M30.9096 72.9563H29.8457V74.1747H30.9096V72.9563Z" fill="white" />
    <path d="M32.2495 72.9563H31.1855V74.1747H32.2495V72.9563Z" fill="white" />
    <path d="M30.9096 74.4915H29.8457V75.7099H30.9096V74.4915Z" fill="white" />
    <path d="M32.2495 74.4915H31.1855V75.7099H32.2495V74.4915Z" fill="white" />
    <path d="M30.9096 76.0271H29.8457V77.2455H30.9096V76.0271Z" fill="white" />
    <path d="M32.2495 76.0271H31.1855V77.2455H32.2495V76.0271Z" fill="white" />
    <path d="M29.5718 68.3506H28.5078V69.569H29.5718V68.3506Z" fill="white" />
    <path d="M29.5718 69.8857H28.5078V71.1042H29.5718V69.8857Z" fill="white" />
    <path d="M29.5718 71.4211H28.5078V72.6396H29.5718V71.4211Z" fill="white" />
    <path d="M29.5718 72.9563H28.5078V74.1747H29.5718V72.9563Z" fill="white" />
    <path d="M39.8425 72.2151H38.624V73.4335H39.8425V72.2151Z" fill="white" />
    <path d="M41.3776 72.2151H40.1592V73.4335H41.3776V72.2151Z" fill="white" />
    <path d="M39.8425 73.7427H38.624V74.9611H39.8425V73.7427Z" fill="white" />
    <path d="M41.3776 73.7427H40.1592V74.9611H41.3776V73.7427Z" fill="white" />
    <path d="M39.8425 75.27H38.624V76.4885H39.8425V75.27Z" fill="white" />
    <path d="M41.3776 75.27H40.1592V76.4885H41.3776V75.27Z" fill="white" />
    <path d="M39.8425 76.7957H38.624V78.0141H39.8425V76.7957Z" fill="white" />
    <path d="M41.3776 76.7957H40.1592V78.0141H41.3776V76.7957Z" fill="white" />
    <path d="M39.8425 78.323H38.624V79.5414H39.8425V78.323Z" fill="white" />
    <path d="M41.3776 78.323H40.1592V79.5414H41.3776V78.323Z" fill="white" />
    <path d="M42.9099 72.2151H41.6914V73.4335H42.9099V72.2151Z" fill="white" />
    <path d="M42.9099 73.7427H41.6914V74.9611H42.9099V73.7427Z" fill="white" />
    <path d="M42.9099 76.7957H41.6914V78.0141H42.9099V76.7957Z" fill="white" />
    <path d="M42.9099 78.323H41.6914V79.5414H42.9099V78.323Z" fill="white" />
    <path d="M38.3083 72.2151H37.0898V73.4335H38.3083V72.2151Z" fill="white" />
    <path d="M38.3083 73.7427H37.0898V74.9611H38.3083V73.7427Z" fill="white" />
    <path d="M38.3083 75.27H37.0898V76.4885H38.3083V75.27Z" fill="white" />
    <path d="M63.4665 67.7559H62.248V68.9743H63.4665V67.7559Z" fill="white" />
    <path d="M65.0007 67.7559H63.7822V68.9743H65.0007V67.7559Z" fill="white" />
    <path d="M63.4665 69.3167H62.248V70.5351H63.4665V69.3167Z" fill="white" />
    <path d="M65.0007 69.3167H63.7822V70.5351H65.0007V69.3167Z" fill="white" />
    <path d="M63.4665 70.8755H62.248V72.0939H63.4665V70.8755Z" fill="white" />
    <path d="M65.0007 70.8755H63.7822V72.0939H65.0007V70.8755Z" fill="white" />
    <path d="M63.4665 72.4363H62.248V73.6547H63.4665V72.4363Z" fill="white" />
    <path d="M65.0007 72.4363H63.7822V73.6547H65.0007V72.4363Z" fill="white" />
    <path d="M63.4665 73.9968H62.248V75.2153H63.4665V73.9968Z" fill="white" />
    <path d="M65.0007 73.9968H63.7822V75.2153H65.0007V73.9968Z" fill="white" />
    <path d="M66.5368 67.7559H65.3184V68.9743H66.5368V67.7559Z" fill="white" />
    <path d="M66.5368 69.3167H65.3184V70.5351H66.5368V69.3167Z" fill="white" />
    <path d="M66.5368 73.9968H65.3184V75.2153H66.5368V73.9968Z" fill="white" />
    <path d="M71.9772 65.6514H70.7588V66.8698H71.9772V65.6514Z" fill="white" />
    <path d="M73.5124 65.6514H72.2939V66.8698H73.5124V65.6514Z" fill="white" />
    <path d="M71.9772 67.2122H70.7588V68.4306H71.9772V67.2122Z" fill="white" />
    <path d="M73.5124 67.2122H72.2939V68.4306H73.5124V67.2122Z" fill="white" />
    <path d="M71.9772 68.771H70.7588V69.9894H71.9772V68.771Z" fill="white" />
    <path d="M73.5124 68.771H72.2939V69.9894H73.5124V68.771Z" fill="white" />
    <path d="M71.9772 70.3315H70.7588V71.55H71.9772V70.3315Z" fill="white" />
    <path d="M73.5124 70.3315H72.2939V71.55H73.5124V70.3315Z" fill="white" />
    <path d="M71.9772 71.8923H70.7588V73.1108H71.9772V71.8923Z" fill="white" />
    <path d="M73.5124 71.8923H72.2939V73.1108H73.5124V71.8923Z" fill="white" />
    <path d="M75.0456 65.6514H73.8271V66.8698H75.0456V65.6514Z" fill="white" />
    <path d="M75.0456 67.2122H73.8271V68.4306H75.0456V67.2122Z" fill="white" />
    <path d="M75.0456 68.771H73.8271V69.9894H75.0456V68.771Z" fill="white" />
    <path d="M75.0456 70.3315H73.8271V71.55H75.0456V70.3315Z" fill="white" />
    <path d="M75.0456 71.8923H73.8271V73.1108H75.0456V71.8923Z" fill="white" />
    <path d="M79.2055 66.4556H78.1826V67.674H79.2055V66.4556Z" fill="white" />
    <path d="M80.5463 66.4556H79.5234V67.674H80.5463V66.4556Z" fill="white" />
    <path d="M79.2055 68.0122H78.1826V69.2307H79.2055V68.0122Z" fill="white" />
    <path d="M80.5463 68.0122H79.5234V69.2307H80.5463V68.0122Z" fill="white" />
    <path d="M79.2055 69.5688H78.1826V70.7873H79.2055V69.5688Z" fill="white" />
    <path d="M80.5463 69.5688H79.5234V70.7873H80.5463V69.5688Z" fill="white" />
    <path d="M80.5463 71.126H79.5234V72.3444H80.5463V71.126Z" fill="white" />
    <path d="M79.2055 72.6826H78.1826V73.9011H79.2055V72.6826Z" fill="white" />
    <path d="M81.8852 66.4556H80.8623V67.674H81.8852V66.4556Z" fill="white" />
    <path d="M81.8852 71.126H80.8623V72.3444H81.8852V71.126Z" fill="white" />
    <path d="M61.9323 67.7559H60.7139V68.9743H61.9323V67.7559Z" fill="white" />
    <path d="M61.9323 69.3167H60.7139V70.5351H61.9323V69.3167Z" fill="white" />
    <path d="M61.9323 70.8755H60.7139V72.0939H61.9323V70.8755Z" fill="white" />
    <path d="M61.9323 72.4363H60.7139V73.6547H61.9323V72.4363Z" fill="white" />
    <path d="M54.2975 72.4148H53.0791V73.6332H54.2975V72.4148Z" fill="white" />
    <path d="M52.8024 72.4148H51.584V73.6332H52.8024V72.4148Z" fill="white" />
    <path d="M51.3102 72.4148H50.0918V73.6332H51.3102V72.4148Z" fill="white" />
    <path d="M49.8161 72.4148H48.5977V73.6332H49.8161V72.4148Z" fill="white" />
    <path d="M48.3229 72.4148H47.1045V73.6332H48.3229V72.4148Z" fill="white" />
    <path d="M55.7907 72.4148H54.5723V73.6332H55.7907V72.4148Z" fill="white" />
    <path d="M57.2858 72.4148H56.0674V73.6332H57.2858V72.4148Z" fill="white" />
    <path d="M54.2975 73.9675H53.0791V75.186H54.2975V73.9675Z" fill="white" />
    <path d="M52.8024 73.9675H51.584V75.186H52.8024V73.9675Z" fill="white" />
    <path d="M51.3102 73.9675H50.0918V75.186H51.3102V73.9675Z" fill="white" />
    <path d="M49.8161 73.9675H48.5977V75.186H49.8161V73.9675Z" fill="white" />
    <path d="M48.3229 73.9675H47.1045V75.186H48.3229V73.9675Z" fill="white" />
    <path d="M55.7907 73.9675H54.5723V75.186H55.7907V73.9675Z" fill="white" />
    <path d="M57.2858 73.9675H56.0674V75.186H57.2858V73.9675Z" fill="white" />
    <path d="M54.2975 75.5225H53.0791V76.7409H54.2975V75.5225Z" fill="white" />
    <path d="M52.8024 75.5225H51.584V76.7409H52.8024V75.5225Z" fill="white" />
    <path d="M51.3102 75.5225H50.0918V76.7409H51.3102V75.5225Z" fill="white" />
    <path d="M49.8161 75.5225H48.5977V76.7409H49.8161V75.5225Z" fill="white" />
    <path d="M55.7907 75.5225H54.5723V76.7409H55.7907V75.5225Z" fill="white" />
    <path d="M54.2975 77.0752H53.0791V78.2936H54.2975V77.0752Z" fill="white" />
    <path d="M52.8024 77.0752H51.584V78.2936H52.8024V77.0752Z" fill="white" />
    <path d="M49.8161 77.0752H48.5977V78.2936H49.8161V77.0752Z" fill="white" />
    <path d="M55.7907 77.0752H54.5723V78.2936H55.7907V77.0752Z" fill="white" />
    <path d="M54.2975 78.6282H53.0791V79.8466H54.2975V78.6282Z" fill="white" />
    <path d="M52.8024 78.6282H51.584V79.8466H52.8024V78.6282Z" fill="white" />
    <path d="M51.3102 78.6282H50.0918V79.8466H51.3102V78.6282Z" fill="white" />
    <path d="M49.8161 78.6282H48.5977V79.8466H49.8161V78.6282Z" fill="white" />
    <path d="M48.3229 78.6282H47.1045V79.8466H48.3229V78.6282Z" fill="white" />
    <path d="M55.7907 78.6282H54.5723V79.8466H55.7907V78.6282Z" fill="white" />
    <path d="M57.2858 78.6282H56.0674V79.8466H57.2858V78.6282Z" fill="white" />
    <path d="M54.2975 80.1809H53.0791V81.3994H54.2975V80.1809Z" fill="white" />
    <path d="M51.3102 80.1809H50.0918V81.3994H51.3102V80.1809Z" fill="white" />
    <path d="M49.8161 80.1809H48.5977V81.3994H49.8161V80.1809Z" fill="white" />
    <path d="M48.3229 80.1809H47.1045V81.3994H48.3229V80.1809Z" fill="white" />
    <path d="M54.2975 81.7358H53.0791V82.9543H54.2975V81.7358Z" fill="white" />
    <path d="M49.8161 81.7358H48.5977V82.9543H49.8161V81.7358Z" fill="white" />
    <path d="M57.2858 81.7358H56.0674V82.9543H57.2858V81.7358Z" fill="white" />
    <path d="M54.2975 70.8618H53.0791V72.0803H54.2975V70.8618Z" fill="white" />
    <path d="M52.8024 70.8618H51.584V72.0803H52.8024V70.8618Z" fill="white" />
    <path d="M51.3102 70.8618H50.0918V72.0803H51.3102V70.8618Z" fill="white" />
    <path d="M49.8161 70.8618H48.5977V72.0803H49.8161V70.8618Z" fill="white" />
    <path d="M48.3229 70.8618H47.1045V72.0803H48.3229V70.8618Z" fill="white" />
    <path d="M55.7907 70.8618H54.5723V72.0803H55.7907V70.8618Z" fill="white" />
    <path d="M57.2858 70.8618H56.0674V72.0803H57.2858V70.8618Z" fill="white" />
    <path d="M73.5124 73.4514H72.2939V74.6699H73.5124V73.4514Z" fill="white" />
    <path d="M73.5124 75.0117H72.2939V76.2302H73.5124V75.0117Z" fill="white" />
    <path d="M71.9772 76.5706H70.7588V77.789H71.9772V76.5706Z" fill="white" />
    <path d="M75.0456 73.4514H73.8271V74.6699H75.0456V73.4514Z" fill="white" />
    <path d="M75.0456 75.0117H73.8271V76.2302H75.0456V75.0117Z" fill="white" />
    <path d="M65.0007 75.5557H63.7822V76.7741H65.0007V75.5557Z" fill="white" />
    <path d="M63.4665 77.1162H62.248V78.3347H63.4665V77.1162Z" fill="white" />
    <path d="M65.0007 77.1162H63.7822V78.3347H65.0007V77.1162Z" fill="white" />
    <path d="M63.4665 78.677H62.248V79.8955H63.4665V78.677Z" fill="white" />
    <path d="M65.0007 78.677H63.7822V79.8955H65.0007V78.677Z" fill="white" />
    <path d="M66.5368 75.5557H65.3184V76.7741H66.5368V75.5557Z" fill="white" />
    <path d="M66.5368 77.1162H65.3184V78.3347H66.5368V77.1162Z" fill="white" />
    <path d="M61.9323 75.5557H60.7139V76.7741H61.9323V75.5557Z" fill="white" />
    <path d="M39.8425 79.8484H38.624V81.0668H39.8425V79.8484Z" fill="white" />
    <path d="M41.3776 79.8484H40.1592V81.0668H41.3776V79.8484Z" fill="white" />
    <path d="M39.8425 81.376H38.624V82.5944H39.8425V81.376Z" fill="white" />
    <path d="M38.3083 79.8484H37.0898V81.0668H38.3083V79.8484Z" fill="white" />
    <path d="M30.9096 77.5623H29.8457V78.7807H30.9096V77.5623Z" fill="white" />
    <path d="M32.2495 77.5623H31.1855V78.7807H32.2495V77.5623Z" fill="white" />
    <path d="M32.2495 79.0974H31.1855V80.3159H32.2495V79.0974Z" fill="white" />
    <path d="M29.5718 77.5623H28.5078V78.7807H29.5718V77.5623Z" fill="white" />
    <path d="M29.5718 80.6328H28.5078V81.8513H29.5718V80.6328Z" fill="white" />
    <path d="M23.7621 79.6804H22.7861V80.8989H23.7621V79.6804Z" fill="white" />
    <path d="M23.7621 81.2195H22.7861V82.4379H23.7621V81.2195Z" fill="white" />
    <path d="M22.4828 78.147H21.5068V79.3654H22.4828V78.147Z" fill="white" />
    <path d="M22.4828 79.6824H21.5068V80.9008H22.4828V79.6824Z" fill="white" />
    <path d="M17.1901 75.5557H15.9717V76.7741H17.1901V75.5557Z" fill="white" />
    <path d="M15.655 77.1123H14.4365V78.3308H15.655V77.1123Z" fill="white" />
    <path
      d="M43.5235 8.33545C43.5235 8.09489 43.5235 7.85433 43.5137 7.61377"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5137 15.5289C43.5358 14.0517 43.545 12.5744 43.5411 11.0972"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5137 19.456V18.2043"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.5859 10.2913C53.7815 9.85907 53.9771 9.4288 54.1903 9.00439"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.7695 16.9837C51.5734 15.2431 52.2501 13.4497 52.9874 11.6816"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.1797 20.6942C49.5252 19.8858 49.8707 19.0794 50.2162 18.2749"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.749 19.4561L49.8378 12.0339"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.7539 13.4734C36.4658 15.6951 37.0701 17.958 37.7899 20.1445"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3916 9.00439C34.711 10.0475 35.0292 11.0906 35.346 12.1336"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0654 20.0427L36.3588 20.694"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0322 15.5288L35.5147 18.8204"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6875 23.4751C26.4424 24.3474 27.1915 25.2255 27.921 26.1213"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3154 19.4873C23.0977 20.4789 23.9329 21.4431 24.7621 22.4053"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7627 17.6528L21.3494 18.355"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7002 23.3384L26.6958 27.1952"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.125 36.0078L24.3415 36.3188"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4238 33.7842L21.8929 35.7008"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2041 33.2168L13.0118 33.4241"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 33.4829L24.3417 34.7679"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2734 32.0259L20.2292 32.9099"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.8379 18.5152L68.3249 18.1123"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.5693 20.3968L66.7037 19.4561"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.9014 24.2654L64.4413 21.3318"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.0068 26.6632L59.8296 25.1533"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.4253 17.6528L54.4883 23.0801"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.0303 30.1366L74.8849 29.8784"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.0742 32.5459C68.3018 31.8947 70.5373 31.2688 72.7375 30.5295"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.1221 33.7547C62.9748 33.4751 63.8334 33.211 64.6939 32.9529"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.5986 35.9022L69.8741 34.9517"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.0879 48.5308L74.1714 48.8789"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.3604 45.0884C65.562 45.9939 68.5758 47.1987 71.7813 48.1081"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.3359 44.502L71.6054 45.0887"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.5986 43.8367L65.8902 44.3061"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.6689 61.8123L66.2185 62.4987"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.0068 52.231C60.2853 55.0414 62.5462 57.9027 64.852 60.7836"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.375 53.7583C62.0361 54.4024 62.6965 55.0465 63.3562 55.6906"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.9023 51.3469L60.5491 52.9526"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.4342 61.9177L23 62.5162"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.1139 58.2488C25.4998 59.1113 24.8778 59.9699 24.2383 60.8148"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8653 53.0935C28.8503 54.398 27.8919 55.7475 26.9316 57.097"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.3604 54.1438L24.1328 56.1465"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6639 52.0725L27.3477 53.2558"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2271 49.436L14.6514 50.721"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9756 45.5774C22.9064 46.6003 20.6455 47.6544 18.4629 48.824"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3416 42.4463L18.7051 43.43"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.0312 29.8782L63.3561 27.8501"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6191 19.8589L31.6473 23.08"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2178 57.1868L57.4714 59.8544"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.1445 54.9004C55.3186 55.272 55.4946 55.6455 55.6687 56.0191"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.8447 50.5745L67.5488 50.9891"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.6934 47.5332L65.644 49.8645"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.3379 40.7017H73.2154"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.166 40.7017C65.4112 40.7212 67.6643 40.7212 69.9975 40.7017"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3923 40.7859L11.9678 40.8426"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0659 40.686C20.2569 40.7232 18.4478 40.731 16.6367 40.7545"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1328 40.6313L23.4014 40.6548"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.869 26.7275L18.0547 24.9224"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.7833 29.8784L22.0928 27.5139"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.8571 22.3154C33.6865 22.4445 26.233 29.9645 26.1665 39.1351C26.1469 41.6842 26.7043 44.2047 27.7969 46.5078C28.8896 48.811 30.4891 50.837 32.4758 52.4343C33.169 53.0023 33.6826 53.7591 33.9544 54.6131L34.7367 57.0323H51.4547L52.2546 54.5642C52.5211 53.7352 53.0225 53.0015 53.6979 52.4519C56.4475 50.2438 58.439 47.2323 59.3944 43.8377C60.3498 40.4431 60.2215 36.8349 59.0272 33.5168C57.8329 30.1987 55.6324 27.3364 52.7328 25.3293C49.8332 23.3222 46.3793 22.2706 42.8532 22.3213L42.8571 22.3154Z"
      fill="white"
    />
    <mask
      id="mask0_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="26"
      y="22"
      width="35"
      height="36"
    >
      <path
        d="M42.8571 22.3154C33.6865 22.4445 26.233 29.9645 26.1665 39.1351C26.1469 41.6842 26.7043 44.2047 27.7969 46.5078C28.8896 48.811 30.4891 50.837 32.4758 52.4343C33.169 53.0023 33.6826 53.7591 33.9544 54.6131L34.7367 57.0323H51.4547L52.2546 54.5642C52.5211 53.7352 53.0225 53.0015 53.6979 52.4519C56.4475 50.2438 58.439 47.2323 59.3944 43.8377C60.3498 40.4431 60.2215 36.8349 59.0272 33.5168C57.8329 30.1987 55.6324 27.3364 52.7328 25.3293C49.8332 23.3222 46.3793 22.2706 42.8532 22.3213L42.8571 22.3154Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_93_1521)">
      <path
        d="M38.9795 55.6321C38.9893 56.0565 38.9795 56.297 38.9795 56.297"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5684 50.4512C38.7776 51.9884 38.8832 53.3946 38.936 54.4429"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0602 41.8555H35.8711C37.1013 43.7193 37.8445 46.2872 38.2943 48.7437"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4275 41.3687L39.2886 41.8556H37.4619"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7331 42.7513L40.5747 43.2872L39.6613 40.5432L39.5166 41.0498"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.6205 42.1039L42.2587 43.2872L41.3805 40.5432L40.9092 42.1509"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1848 41.793L44.8191 40.5432L43.9409 43.2872L43.0999 40.5432L42.8535 41.347"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.4511 41.8555H46.9094L46.5378 40.5432L45.6225 43.2872L45.335 42.3034"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.1613 47.4764C48.6229 45.4287 49.3074 43.3986 50.3264 41.8555H48.0186"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.6621 50.1968C47.756 49.5377 47.8714 48.8552 48.0083 48.1707"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.3242 53.424C47.3731 52.7356 47.4435 51.9591 47.5433 51.1299"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.2422 54.9005C47.2422 54.7205 47.2598 54.5249 47.2696 54.3137"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.2129 56.2968C47.2129 56.2968 47.2129 55.9761 47.2246 55.4226"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M42.8571 22.3154C33.6865 22.4445 26.233 29.9645 26.1665 39.1351C26.1469 41.6842 26.7043 44.2047 27.7969 46.5078C28.8896 48.811 30.4891 50.837 32.4758 52.4343C33.169 53.0023 33.6826 53.7591 33.9544 54.6131L34.7367 57.0323H51.4547L52.2546 54.5642C52.5211 53.7352 53.0225 53.0015 53.6979 52.4519C56.4475 50.2438 58.439 47.2323 59.3944 43.8377C60.3498 40.4431 60.2215 36.8349 59.0272 33.5168C57.8329 30.1987 55.6324 27.3364 52.7328 25.3293C49.8332 23.3222 46.3793 22.2706 42.8532 22.3213L42.8571 22.3154ZM43.0996 50.0287C40.5355 50.0291 38.0289 49.2691 35.8969 47.8448C33.7648 46.4205 32.103 44.3958 31.1217 42.027C30.1404 39.6581 29.8836 37.0515 30.3839 34.5367C30.8842 32.0219 32.119 29.712 33.9322 27.899C35.7454 26.0861 38.0555 24.8516 40.5704 24.3518C43.0853 23.8519 45.6919 24.109 48.0606 25.0907C50.4293 26.0724 52.4536 27.7344 53.8776 29.8667C55.3016 31.999 56.0613 34.5057 56.0605 37.0698C56.06 40.5069 54.6942 43.803 52.2636 46.2333C49.8331 48.6635 46.5367 50.0287 43.0996 50.0287Z"
        fill="#92E3A9"
      />
    </g>
    <path
      d="M42.8571 22.3154C33.6865 22.4445 26.233 29.9645 26.1665 39.1351C26.1469 41.6842 26.7043 44.2047 27.7969 46.5078C28.8896 48.811 30.4891 50.837 32.4758 52.4343C33.169 53.0023 33.6826 53.7591 33.9544 54.6131L34.7367 57.0323H51.4547L52.2546 54.5642C52.5211 53.7352 53.0225 53.0015 53.6979 52.4519C56.4475 50.2438 58.439 47.2323 59.3944 43.8377C60.3498 40.4431 60.2214 36.8349 59.0272 33.5168C57.8329 30.1987 55.6324 27.3364 52.7328 25.3293C49.8332 23.3222 46.3793 22.2706 42.8532 22.3213L42.8571 22.3154Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0576 73.3043H41.1461C41.0491 73.3045 40.9538 73.2788 40.8699 73.23C40.7861 73.1812 40.7167 73.111 40.6689 73.0266L40.0822 71.994L37.8193 70.6816H48.4059L46.1451 71.994L45.5583 73.0266C45.5081 73.1142 45.4349 73.1863 45.3467 73.2352C45.2584 73.2842 45.1585 73.3081 45.0576 73.3043Z"
      fill="#263238"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.4341 69.9033H36.7646C36.989 70.3714 37.341 70.7666 37.7801 71.0434C38.2191 71.3202 38.7275 71.4674 39.2465 71.4679H46.9503C47.4696 71.4673 47.9782 71.3201 48.4175 71.0433C48.8569 70.7665 49.2093 70.3714 49.4341 69.9033Z"
      fill="#757575"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.4342 69.9032H36.7647L35.2568 59.166H50.9421L49.4342 69.9032Z"
      fill="#999999"
    />
    <mask
      id="mask1_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="35"
      y="59"
      width="16"
      height="11"
    >
      <path
        d="M49.4342 69.9035H36.7647L35.2568 59.1663H50.9421L49.4342 69.9035Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_93_1521)">
      <path
        opacity="0.4"
        d="M37.4131 66.3714L37.5519 67.6015L48.7409 66.7351L48.8837 65.46L37.4131 66.3714Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M41.6279 69.5356H47.4659C47.654 69.5352 47.8388 69.4853 48.0015 69.3909C48.1642 69.2965 48.2992 69.1609 48.3929 68.9978L41.6279 69.5356Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M37.6113 68.1238L37.6622 68.5834C37.6949 68.8615 37.8353 69.1156 38.0533 69.2913L48.55 68.4777L48.6888 67.2437L37.6113 68.1238Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M49.1385 63.2049L49.1815 62.8176H42.9993H42.7451H37.0166L37.1633 64.1339L49.1385 63.2049Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M37.2217 64.6561L37.3566 65.8471L48.9426 64.9494L49.0795 63.7153L37.2217 64.6561Z"
        fill="white"
      />
    </g>
    <path
      d="M49.4342 69.9032H36.7647L35.2568 59.166H50.9421L49.4342 69.9032Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.8662 64.4917C35.7464 64.4961 35.6297 64.4527 35.5418 64.3711C35.454 64.2895 35.4022 64.1763 35.3978 64.0565C35.3934 63.9367 35.4367 63.82 35.5183 63.7322C35.5999 63.6444 35.7131 63.5925 35.8329 63.5881L50.3056 62.5183C50.4252 62.5095 50.5434 62.5485 50.6341 62.6268C50.7249 62.7051 50.7809 62.8163 50.7897 62.9359C50.7985 63.0554 50.7595 63.1736 50.6812 63.2644C50.6029 63.3552 50.4917 63.4111 50.3721 63.4199L35.8994 64.4897L35.8662 64.4917Z"
      fill="#BFBFBF"
    />
    <mask
      id="mask2_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="35"
      y="62"
      width="16"
      height="3"
    >
      <path
        d="M35.8652 64.4917C35.7454 64.4961 35.6287 64.4527 35.5409 64.3711C35.453 64.2895 35.4012 64.1763 35.3968 64.0565C35.3924 63.9367 35.4357 63.82 35.5174 63.7322C35.599 63.6444 35.7121 63.5925 35.8319 63.5881L50.3047 62.5183C50.4242 62.5095 50.5424 62.5485 50.6332 62.6268C50.7239 62.7051 50.7799 62.8163 50.7887 62.9359C50.7975 63.0554 50.7585 63.1736 50.6802 63.2644C50.6019 63.3552 50.4907 63.4111 50.3712 63.4199L35.8984 64.4897L35.8652 64.4917Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_93_1521)">
      <g opacity="0.4">
        <path
          d="M50.7303 62.7607C50.6953 62.829 50.6432 62.887 50.5791 62.9292C50.515 62.9714 50.4411 62.9962 50.3646 63.0013L35.8918 64.0711H35.8586C35.8189 64.0937 35.7741 64.1059 35.7284 64.1065C35.6828 64.107 35.6377 64.096 35.5974 64.0744C35.5572 64.0529 35.523 64.0214 35.4983 63.983C35.4735 63.9447 35.4588 63.9006 35.4557 63.855C35.4526 63.8095 35.4611 63.7638 35.4804 63.7224C35.4997 63.681 35.5292 63.6452 35.5662 63.6184C35.6031 63.5915 35.6463 63.5744 35.6916 63.5688C35.7369 63.5631 35.7829 63.5691 35.8254 63.586L50.2981 62.5162C50.3862 62.5101 50.4741 62.53 50.551 62.5734C50.6278 62.6169 50.6902 62.682 50.7303 62.7607Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M35.8662 64.4917C35.7464 64.4961 35.6297 64.4527 35.5418 64.3711C35.454 64.2895 35.4022 64.1763 35.3978 64.0565C35.3934 63.9367 35.4367 63.82 35.5183 63.7322C35.5999 63.6444 35.7131 63.5925 35.8329 63.5881L50.3056 62.5183C50.4252 62.5095 50.5434 62.5485 50.6341 62.6268C50.7249 62.7051 50.7809 62.8163 50.7897 62.9359C50.7985 63.0554 50.7595 63.1736 50.6812 63.2644C50.6029 63.3552 50.4917 63.4111 50.3721 63.4199L35.8994 64.4897L35.8662 64.4917Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.123 66.2363C36.0032 66.2407 35.8865 66.1973 35.7987 66.1157C35.7108 66.0341 35.659 65.9209 35.6546 65.8011C35.6502 65.6813 35.6936 65.5646 35.7752 65.4768C35.8568 65.3889 35.9699 65.3371 36.0898 65.3327L50.0618 64.2981C50.1814 64.2893 50.2995 64.3283 50.3903 64.4066C50.4811 64.4849 50.537 64.5961 50.5459 64.7157C50.5547 64.8352 50.5156 64.9534 50.4373 65.0442C50.359 65.1349 50.2479 65.1909 50.1283 65.1997L36.1563 66.2343L36.123 66.2363Z"
      fill="#BFBFBF"
    />
    <mask
      id="mask3_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="35"
      y="64"
      width="16"
      height="3"
    >
      <path
        d="M36.124 66.2363C36.0042 66.2407 35.8875 66.1973 35.7997 66.1157C35.7118 66.0341 35.66 65.9209 35.6556 65.8011C35.6512 65.6813 35.6945 65.5646 35.7762 65.4768C35.8578 65.3889 35.9709 65.3371 36.0907 65.3327L50.0628 64.2981C50.1823 64.2893 50.3005 64.3283 50.3913 64.4066C50.4821 64.4849 50.538 64.5961 50.5468 64.7157C50.5556 64.8352 50.5166 64.9534 50.4383 65.0442C50.36 65.1349 50.2488 65.1909 50.1293 65.1997L36.1572 66.2343L36.124 66.2363Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_93_1521)">
      <g opacity="0.4">
        <path
          d="M50.4845 64.5406C50.4495 64.6089 50.3974 64.6669 50.3333 64.7091C50.2692 64.7512 50.1953 64.7761 50.1188 64.7811L36.1467 65.8197H36.1233C36.0836 65.8423 36.0388 65.8544 35.9931 65.855C35.9474 65.8556 35.9023 65.8446 35.8621 65.823C35.8218 65.8014 35.7877 65.77 35.7629 65.7316C35.7381 65.6932 35.7235 65.6492 35.7204 65.6036C35.7172 65.558 35.7257 65.5124 35.745 65.471C35.7644 65.4296 35.7939 65.3938 35.8308 65.3669C35.8678 65.34 35.9109 65.323 35.9562 65.3173C36.0016 65.3117 36.0476 65.3176 36.09 65.3346L50.062 64.3C50.148 64.2962 50.2333 64.3167 50.308 64.3593C50.3828 64.4018 50.444 64.4647 50.4845 64.5406Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M36.123 66.2363C36.0032 66.2407 35.8865 66.1973 35.7987 66.1157C35.7108 66.0341 35.659 65.9209 35.6546 65.8011C35.6502 65.6813 35.6936 65.5646 35.7752 65.4768C35.8568 65.3889 35.9699 65.3371 36.0898 65.3327L50.0618 64.2981C50.1814 64.2893 50.2995 64.3283 50.3903 64.4066C50.4811 64.4849 50.537 64.5961 50.5459 64.7157C50.5547 64.8352 50.5156 64.9534 50.4373 65.0442C50.359 65.1349 50.2479 65.1909 50.1283 65.1997L36.1563 66.2343L36.123 66.2363Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3593 67.9806C36.2395 67.9847 36.1229 67.9411 36.0353 67.8593C35.9476 67.7776 35.896 67.6643 35.8919 67.5445C35.8877 67.4246 35.9313 67.3081 36.0131 67.2204C36.0949 67.1328 36.2082 67.0812 36.328 67.077L49.8033 66.0815C49.8625 66.0772 49.922 66.0845 49.9783 66.1031C50.0347 66.1218 50.0868 66.1513 50.1318 66.1901C50.1767 66.2288 50.2136 66.2761 50.2403 66.3291C50.267 66.3821 50.283 66.4399 50.2873 66.4991C50.2917 66.5583 50.2844 66.6178 50.2657 66.6742C50.2471 66.7305 50.2176 66.7827 50.1788 66.8276C50.14 66.8726 50.0928 66.9094 50.0398 66.9361C49.9867 66.9628 49.929 66.9788 49.8698 66.9832L36.3945 67.9806H36.3593Z"
      fill="#BFBFBF"
    />
    <mask
      id="mask4_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="35"
      y="66"
      width="16"
      height="2"
    >
      <path
        d="M36.3603 67.9806C36.2405 67.9847 36.1239 67.9411 36.0362 67.8593C35.9486 67.7776 35.897 67.6643 35.8928 67.5445C35.8887 67.4246 35.9323 67.3081 36.0141 67.2204C36.0959 67.1328 36.2092 67.0812 36.329 67.077L49.8043 66.0815C49.8635 66.0772 49.9229 66.0845 49.9793 66.1031C50.0357 66.1218 50.0878 66.1513 50.1328 66.1901C50.1777 66.2288 50.2146 66.2761 50.2413 66.3291C50.268 66.3821 50.2839 66.4399 50.2883 66.4991C50.2927 66.5583 50.2853 66.6178 50.2667 66.6742C50.2481 66.7305 50.2186 66.7827 50.1798 66.8276C50.141 66.8726 50.0938 66.9094 50.0407 66.9361C49.9877 66.9628 49.93 66.9788 49.8708 66.9832L36.3955 67.9806H36.3603Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_93_1521)">
      <g opacity="0.4">
        <path
          d="M50.2377 66.324C50.2031 66.3926 50.1512 66.451 50.087 66.4932C50.0228 66.5354 49.9486 66.56 49.8719 66.5646L36.3966 67.562H36.3614C36.3217 67.5842 36.277 67.596 36.2316 67.5962C36.1861 67.5965 36.1412 67.5853 36.1013 67.5636C36.0613 67.542 36.0274 67.5105 36.0028 67.4723C35.9782 67.434 35.9637 67.3901 35.9606 67.3447C35.9575 67.2993 35.9659 67.2539 35.9851 67.2127C36.0042 67.1714 36.0335 67.1357 36.0702 67.1087C36.1069 67.0818 36.1498 67.0646 36.1949 67.0587C36.24 67.0528 36.2858 67.0584 36.3282 67.075L49.8035 66.0795C49.8918 66.0732 49.9801 66.093 50.0573 66.1365C50.1345 66.18 50.1972 66.2452 50.2377 66.324Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M36.3593 67.9806C36.2395 67.9847 36.1229 67.9411 36.0353 67.8593C35.9476 67.7776 35.896 67.6643 35.8919 67.5445C35.8877 67.4246 35.9313 67.3081 36.0131 67.2204C36.0949 67.1328 36.2082 67.0812 36.328 67.077L49.8033 66.0815C49.8625 66.0772 49.922 66.0845 49.9783 66.1031C50.0347 66.1218 50.0868 66.1513 50.1318 66.1901C50.1767 66.2288 50.2136 66.2761 50.2403 66.3291C50.267 66.3821 50.283 66.4399 50.2873 66.4991C50.2917 66.5583 50.2844 66.6178 50.2657 66.6742C50.2471 66.7305 50.2176 66.7827 50.1788 66.8276C50.14 66.8726 50.0928 66.9094 50.0398 66.9361C49.9867 66.9628 49.929 66.9788 49.8698 66.9832L36.3945 67.9806H36.3593Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.6084 69.7313C36.4885 69.7357 36.3719 69.6923 36.284 69.6107C36.1962 69.5291 36.1444 69.4159 36.14 69.2961C36.1355 69.1763 36.1789 69.0596 36.2605 68.9718C36.3421 68.884 36.4553 68.8321 36.5751 68.8277L49.5556 67.8674C49.6751 67.8586 49.7933 67.8977 49.8841 67.976C49.9749 68.0543 50.0308 68.1654 50.0396 68.285C50.0484 68.4046 50.0094 68.5227 49.9311 68.6135C49.8528 68.7043 49.7416 68.7602 49.6221 68.769L36.6416 69.7313H36.6084Z"
      fill="#BFBFBF"
    />
    <mask
      id="mask5_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="36"
      y="67"
      width="15"
      height="3"
    >
      <path
        d="M36.6084 69.731C36.4885 69.7355 36.3719 69.6921 36.284 69.6105C36.1962 69.5289 36.1444 69.4157 36.14 69.2959C36.1355 69.1761 36.1789 69.0594 36.2605 68.9716C36.3421 68.8837 36.4553 68.8319 36.5751 68.8275L49.5556 67.8672C49.6751 67.8584 49.7933 67.8974 49.8841 67.9757C49.9749 68.054 50.0308 68.1652 50.0396 68.2848C50.0484 68.4043 50.0094 68.5225 49.9311 68.6133C49.8528 68.704 49.7416 68.76 49.6221 68.7688L36.6416 69.731H36.6084Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_93_1521)">
      <g opacity="0.4">
        <path
          d="M49.99 68.1038C49.955 68.1722 49.9029 68.2304 49.8389 68.2729C49.7748 68.3153 49.7009 68.3406 49.6243 68.3463L36.6418 69.3066H36.6086C36.5689 69.3292 36.5241 69.3413 36.4785 69.3419C36.4328 69.3425 36.3877 69.3315 36.3474 69.3099C36.3072 69.2883 36.273 69.2569 36.2483 69.2185C36.2235 69.1801 36.2088 69.1361 36.2057 69.0905C36.2026 69.0449 36.2111 68.9993 36.2304 68.9579C36.2497 68.9165 36.2792 68.8807 36.3162 68.8538C36.3531 68.827 36.3963 68.8099 36.4416 68.8042C36.4869 68.7986 36.5329 68.8045 36.5754 68.8215L49.5558 67.8612C49.6439 67.855 49.732 67.8746 49.8091 67.9177C49.8862 67.9608 49.9491 68.0254 49.99 68.1038Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M36.6084 69.7313C36.4885 69.7357 36.3719 69.6923 36.284 69.6107C36.1962 69.5291 36.1444 69.4159 36.14 69.2961C36.1355 69.1763 36.1789 69.0596 36.2605 68.9718C36.3421 68.884 36.4553 68.8321 36.5751 68.8277L49.5556 67.8674C49.6751 67.8586 49.7933 67.8977 49.8841 67.976C49.9749 68.0543 50.0308 68.1654 50.0396 68.285C50.0484 68.4046 50.0094 68.5227 49.9311 68.6135C49.8528 68.7043 49.7416 68.7602 49.6221 68.769L36.6416 69.7313H36.6084Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.69 55.7785L52.1933 61.3212L51.0315 62.1974H35.1663L34.0026 61.3212L33.5078 55.7785L33.852 54.9004H52.3439L52.69 55.7785Z"
      fill="#92E3A9"
    />
    <mask
      id="mask6_93_1521"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="33"
      y="54"
      width="20"
      height="9"
    >
      <path
        d="M52.692 55.7788L52.1952 61.3214L51.0335 62.1976H35.1683L34.0046 61.3214L33.5098 55.7788L33.854 54.9006H52.3458L52.692 55.7788Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_93_1521)">
      <path
        opacity="0.2"
        d="M52.1945 61.3215L51.0328 62.1977H35.1676L34.0039 61.3215H52.1945Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        opacity="0.4"
        d="M52.692 55.7788H33.5098L33.854 54.9006H52.3458L52.692 55.7788Z"
        fill="white"
      />
    </g>
    <path
      d="M52.69 55.7785L52.1933 61.3212L51.0315 62.1974H35.1663L34.0026 61.3212L33.5078 55.7785L33.852 54.9004H52.3439L52.69 55.7785Z"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.5078 55.7788H40.6112"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.7266 55.7788H43.5141"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.7881 55.7788H45.1851"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.6908 55.7788H50.9424"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.1941 61.3213H45.1846"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.0324 61.3213H41.7266"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.9372 61.3213H39.46"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0029 61.3213H35.9079"
      stroke="#263238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M39.0234 70.1633C39.6825 70.7696 41.2589 71.1979 43.0993 71.1979C44.9396 71.1979 46.514 70.7696 47.1751 70.1633H39.0234Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M43.9473 73.0559H42.2458C42.1868 73.0554 42.1295 73.0361 42.082 73.001C42.0346 72.9659 41.9995 72.9167 41.9818 72.8604L41.7432 72.127H44.4558L44.2172 72.8604C44.1995 72.9178 44.1635 72.9679 44.1149 73.0031C44.0662 73.0384 44.0074 73.0569 43.9473 73.0559Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M41.7416 71.8433H44.4543L44.7574 71.6125H41.4404L41.7416 71.8433Z"
      fill="white"
    />
  </svg>
);
