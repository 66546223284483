import styled, { css } from 'styled-components';
import { darken } from 'polished';
import MuiIconButton from '@material-ui/core/IconButton';
import { Typography } from 'Atoms';

export const Text = styled(Typography)`
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: 700;
`;

export const ArrowIconButton = styled(MuiIconButton)`
  ${({ theme }) => css`
    padding: 0;

    .MuiSvgIcon-root,
    .planningapp-back-button {
      color: ${theme.colorMappings.primaryButtonBackground};
      &:hover {
        color: ${darken(0.1, theme.colorMappings.primaryButtonBackground)};
      }
    }

    ${theme.breakpoints.down('xs')} {
      .MuiSvgIcon-root,
      .planningapp-back-button {
        width: 2rem;
        height: 2rem;
      }
    }
  `};
`;
