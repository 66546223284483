import styled, { css } from 'styled-components';
import { CircleCheckIcon, CircleCancelIcon } from 'Icons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  ${({ theme }) => css`
    font-size: ${theme.texts.heading2.fontSize};
    font-weight: ${theme.texts.heading2.fontWeight};
  `}
`;

export const StyledCircleCheckIcon = styled(CircleCheckIcon).attrs({
  viewBox: '-1 -2 26 27',
})`
  margin: 0 0.3rem 0 0;
  height: 1.6875rem;
  width: 1.6875rem;
  stroke-width: 0.125rem;
  ${({ theme }) => css`
    color: ${theme.colors.green[500]};
    stroke: ${theme.colors.green[500]};
  `}
`;

export const StyledCircleCancelIcon = styled(CircleCancelIcon).attrs(
  ({ theme }) => ({
    viewBox: '-1 -1 22 22',
    color: theme.colors.red[500],
  })
)`
  height: 1.6875rem;
  width: 1.6875rem;
  margin: 0 0.3125rem 0 0;
  stroke-width: 0;
`;

export const Message = styled.div`
  text-align: center;
  margin-top: 2.0625rem;

  ${({ theme }) => css`
    font-size: ${theme.texts.plainText.fontSize};
    font-weight: ${theme.texts.plainText.fontWeight};
  `}

  p {
    margin: 0;
  }
`;

export const Bold = styled.div`
  display: inline;
  font-weight: 700;
`;

export const ImageContainer = styled.div`
  margin-top: 5.6875rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
