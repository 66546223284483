import { gql } from '@apollo/client';

export const RESEND_INVITE_MUTATION = gql`
  mutation Mutation($inviteId: String, $projectId: String) {
    resendInvite(inviteId: $inviteId, projectId: $projectId) {
      _id
    }
  }
`;

export const DELETE_INVITE_MUTATION = gql`
  mutation Mutation($inviteId: String) {
    deleteInvite(inviteId: $inviteId) {
      status
    }
  }
`;
