import * as React from 'react';
import {
  checkLengthAndSetModal,
  MouseEventCustom,
  HTMLElementEvent,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import { CampaignRowTitleProps } from './types';
import { Wrapper, Value, Modal } from './CampaignRowTitle.styles';

const CampaignRowTitle: React.FC<CampaignRowTitleProps> = ({
  value,
}: CampaignRowTitleProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  if (!value) return <span></span>;

  return (
    <Wrapper
      id="wrapper"
      data-testid="CampaignRowTitle"
      onMouseOver={(
        e:
          | React.MouseEvent<HTMLDivElement, MouseEvent>
          | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
      ) => {
        setOpenModal(() =>
          checkLengthAndSetModal(
            e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
          )
        );
      }}
      onMouseOut={() => setOpenModal(false)}
    >
      <Value id="value" data-testid="CampaignRowTitle-Value">
        {value}
      </Value>
      {openModal && <Modal>{value}</Modal>}
    </Wrapper>
  );
};

export { CampaignRowTitle };
