import { ChartItem } from 'Shared/types/dashboard';

export const enabledRespondentsQuestionTypes = [
  'label',
  'select',
  'textpoll',
  'text',
  'textarea',
];

export const additionalChartData: Array<ChartItem> = [
  {
    id: '1',
    name: 'status',
    label: 'Status',
    type: 'status',
    content: {
      label: 'Status',
      name: 'status',
      type: 'status',
    },
    isRespondentsData: true,
    section: {
      title: 'non-special demographics questions',
      showSectionTitle: false,
    },
  },
];
