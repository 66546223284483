import { gql } from '@apollo/client';

export const CHECK_POSTGRES_CONTRIBUTION = gql`
  query CheckContributionOnPostgres($contributionId: String) {
    checkContributionOnPostgres(id: $contributionId) {
      exists
    }
  }
`;

export const CREATE_POSTGRES_CONTRIBUTION = gql`
  mutation Mutation($contribution: MapContributionInput) {
    createContribution(contribution: $contribution) {
      success
    }
  }
`;

export const UPDATE_POSTGRES_CONTRIBUTION = gql`
  mutation UpdateContribution(
    $id: String!
    $contribution: MapContributionInput!
  ) {
    updateContribution(id: $id, contribution: $contribution) {
      success
    }
  }
`;

export const QUERY_BY_AREA = gql`
  mutation Mutation($table: String, $area: JSONObject, $filters: [JSONObject]) {
    queryByArea(table: $table, area: $area, filters: $filters) {
      success
      features
    }
  }
`;
