import * as React from 'react';
import { DateFilter } from 'Pages/dashboard/components';
import { Wrapper, Section } from './Header.styles';
import { Summary } from './components';
import { HeaderProp } from './types';

export const Header: React.FC<HeaderProp> = ({
  totalRespondents,
  appliedFilters,
}: HeaderProp) => {
  return (
    <Wrapper data-onboarding="dashboard-respondents-top-filter-section">
      <Section>
        <Summary totalRespondents={totalRespondents} />
      </Section>
      <Section>
        <DateFilter pageType="respondents" appliedFilters={appliedFilters} />
      </Section>
    </Wrapper>
  );
};
