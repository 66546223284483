import { formatDistance } from 'date-fns';

export const distanceString = (
  lastLogin: string | number | Date,
  currentDate?: string | number | Date
) => {
  const today = currentDate ? new Date(currentDate) : new Date();
  const string = formatDistance(new Date(lastLogin), today, {
    includeSeconds: true,
  });

  return `${string[0].toUpperCase()}${string.substring(1, string.length)} ago`;
};
