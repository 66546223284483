export interface NewsPostLocaleItem {
  // as saved in acorn db
  author: string;
  headline: string;
  content: string;
}

export type NewsPostLocaleContent = {
  [locale: string]: NewsPostLocaleItem;
};

export interface NewsPostContent {
  content: NewsPostLocaleContent;
}

export interface SentEmailCount {
  count: number;
  date: string;
  userId: string;
}

export enum NewsPostStage {
  ACTIVE = 'active',
  DRAFT = 'draft',
  DELETED = 'deleted',
}

interface NewsAcornBaseProps {
  _id?: string;
  projectId: string;
  userId: string;
  date: string;
  slug: string;
  stage: NewsPostStage;
  sentEmailCount?: SentEmailCount;
  newsImage?: string;
  newsImageAlt?: string;
}

export interface NewsPostAcornDb extends NewsAcornBaseProps {
  content: NewsPostLocaleContent; // all locales as they come from the db
}

export interface NewsPostAcorn extends NewsAcornBaseProps {
  content: NewsPostLocaleItem; // only the locale shown
}

export interface NewsPostGaudi {
  _id?: string;
  acorn?: boolean;
  project: string;
  user_id: string;
  slug: string;
  date: string;
  sentUpdateEmailCount?: {
    count: number;
    date: string;
    user_id: string;
  };
  by: string;
  copy: string | Copy;
  headline: string;
  newsImage?: string;
  newsImageAlt?: string;
}

export type Copy = {
  [key: string]: boolean | number | string | Copy;
}[];

export interface NewsItemEmail {
  // the newsItem prop expected by the emailer lambda
  title: string;
  contactEmail: string;
  url: string; // the news post url
  body?: string; // when not passed, a simple default PROJECT_NEWS email is sent
}

export interface AllNewsItemsEmail {
  [locale: string]: NewsItemEmail;
}
