export const setProjectsPlaceholder = (selectedProjects) => {
  if (selectedProjects.length > 0) {
    if (selectedProjects.includes('account level')) {
      return 'Account access';
    }

    return `${selectedProjects.length} projects`;
  }

  return 'Search projects';
};
