import { TrafficCell, TrafficRowTitle } from './components';

const width = '175';

export const currentBasicColumns = [
  {
    Header: '',
    accessor: 'categories',
    Cell: TrafficRowTitle,
    width,
  },
  {
    Header: 'Visitors',
    accessor: 'visitors',
    Cell: TrafficCell,
    width,
  },
  {
    Header: 'Bounced',
    accessor: 'bounced',
    Cell: TrafficCell,
    width,
  },
  {
    Header: 'Aware',
    accessor: 'aware',
    Cell: TrafficCell,
    width,
  },
  {
    Header: 'Informed',
    accessor: 'informed',
    Cell: TrafficCell,
    width,
  },
  {
    Header: 'Contributed',
    accessor: 'contributed',
    Cell: TrafficCell,
    width,
  },
];
