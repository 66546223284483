import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  height: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      justify-content: center;
    }
  `}
`;

export const ImageWrapper = styled.div`
  background: grey;
`;
