import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useProject, useHubContext } from 'Client/utils/hooks';
import { LOADER_ACTION_TYPES } from 'Client/context/loaderReducer';
import { Text, ArrowIconButton } from './BackButton.styles';

export const BackButton: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useHubContext();
  const project = useProject();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ArrowIconButton
      data-testid="PlanningApp-BackButton"
      onClick={() => {
        if (project.features.glaPlanningApps) {
          dispatch({
            type: LOADER_ACTION_TYPES.SET_LOADING,
            loading: true,
            from: 'planningapp',
          });
        }
        router.push('/');
      }}
    >
      <ArrowBackIcon fontSize="small" />
      {!isMobile && (
        <Text className="planningapp-back-button">
          {t('Planning applications')}
        </Text>
      )}
    </ArrowIconButton>
  );
};
