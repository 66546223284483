import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Stroke, Style } from 'ol/style';
import GeoJSON, { GeoJSONPolygon } from 'ol/format/GeoJSON';
import { useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { CheckIcon } from 'Atoms/Icons';
import { useMap } from 'Client/utils/hooks';
import { Xyz, DrawStateValues } from 'Shared/types/map';
import { FeatureDrawGuidance } from 'Client/components/molecules';
import {
  DrawButton,
  IconContainer,
  CancelIcon,
  DrawIcon,
  MobileConfirmButton,
} from './styles';

export const DrawSearchAreaControls: React.FC<{
  onChange: (value: GeoJSONPolygon) => void;
  clearQueryByArea: () => void;
}> = ({ onChange, clearQueryByArea }) => {
  const { t } = useTranslation();
  const { state, version } = useMap();
  const xyz = state.xyz as Xyz;

  const { queryByArea } = useSelector((state: RootState) => state.filters);
  const [drawMode, setDrawMode] = React.useState(false);
  const [geoJson, setGeoJson] = React.useState<GeoJSONPolygon>();
  const [drawState, setDrawState] = React.useState<DrawStateValues>(
    DrawStateValues.DRAWING_STARTED
  );

  const startMapDrawing = () => {
    setDrawMode(true);
    if (geoJson || queryByArea?.area) {
      clearQueryByArea();
    }
    setDrawState(DrawStateValues.DRAWING_STARTED);

    if (version === 'v4') {
      //TODO: v4 implementation
    }

    xyz.mapview.interaction.draw.begin({
      type: 'Polygon',
      id: 'query-by-area-drawing',
      drawStyle: new Style({
        stroke: new Stroke({ color: '#000000', width: 3 }),
      }),
    });

    xyz.mapview.interaction.draw.interaction.on('drawstart', (event) => {
      event.feature.addEventListener('change', (event) => {
        const coordsCount =
          event.target?.getProperties()?.geometry?.St?.length || 0;
        /* means drawing didn't started (or undid all points) */
        if (coordsCount < 1 && drawState !== DrawStateValues.DRAWING_STARTED)
          return setDrawState(DrawStateValues.DRAWING_STARTED);

        /* means less than 3 clicks */
        if (
          coordsCount < 10 &&
          drawState !== DrawStateValues.HAS_LESS_THAN_THREE_POINTS
        )
          return setDrawState(DrawStateValues.HAS_LESS_THAN_THREE_POINTS);

        /* means more than 2 clicks */
        if (
          coordsCount >= 10 &&
          drawState !== DrawStateValues.HAS_MORE_THAN_TWO_POINTS
        )
          return setDrawState(DrawStateValues.HAS_MORE_THAN_TWO_POINTS);
      });
    });

    xyz.mapview.interaction.draw.interaction.on('drawend', (event) => {
      const geodeticPolygon = event.feature.getGeometry();
      const geoJson = new GeoJSON().writeGeometryObject(geodeticPolygon);
      setGeoJson(geoJson as GeoJSONPolygon);
      setDrawState(DrawStateValues.DRAWING_FINISHED);
    });
  };
  const cancelMapDrawing = () => {
    clearQueryByArea();
    setGeoJson(null);
    setDrawMode(false);
    xyz.mapview.interaction.draw.cancel();
    setDrawState(DrawStateValues.DRAWING_STARTED);
  };

  const toggleMapDrawing = () =>
    (drawMode ? cancelMapDrawing : startMapDrawing)();

  const undoLastPoint = () =>
    xyz.mapview.interaction.draw.interaction.removeLastPoint();

  const restartDrawing = () => {
    clearQueryByArea();
    setGeoJson(null);
    xyz.mapview.interaction.draw.cancel();
    startMapDrawing();
  };

  const onUndoClick = () =>
    (drawState === DrawStateValues.DRAWING_FINISHED
      ? restartDrawing
      : undoLastPoint)();

  const confirmShape = () => {
    onChange(geoJson);
    setDrawMode(false);
    xyz.mapview.interaction.draw.finish();
  };

  const helperText = {
    [DrawStateValues.DRAWING_STARTED]: t(
      'Draw an area by dropping a series of points.'
    ), // 0 points
    [DrawStateValues.HAS_LESS_THAN_THREE_POINTS]: t(
      'Create a shape adding more than two points.'
    ), // 2- points
    [DrawStateValues.HAS_MORE_THAN_TWO_POINTS]: t(
      'Complete the area by connecting to a point.'
    ), // 2+ points
    [DrawStateValues.DRAWING_FINISHED]: t(
      'Click on "Confirm shape" to proceed.'
    ), // finished
  }[drawState];

  return (
    <>
      <DrawButton
        type="button"
        onClick={toggleMapDrawing}
        disabled={!xyz}
        variant={drawMode ? 'cancel' : 'start'}
      >
        <IconContainer>
          <CancelIcon show={drawMode} />
          <DrawIcon show={!drawMode} />
        </IconContainer>
        {drawMode ? t('Cancel area search') : t('Draw search area')}
      </DrawButton>
      {drawState === DrawStateValues.DRAWING_FINISHED && drawMode && (
        <MobileConfirmButton
          type="button"
          onClick={confirmShape}
          disabled={!xyz}
          variant={'confirm'}
        >
          <IconContainer>
            <CheckIcon width={18} height={18} />
          </IconContainer>
          {t('Confirm shape')}
        </MobileConfirmButton>
      )}
      {drawMode && (
        <FeatureDrawGuidance
          drawState={drawState}
          helperText={helperText}
          confirmShape={confirmShape}
          onUndoClick={onUndoClick}
        />
      )}
    </>
  );
};
