import * as React from 'react';
import { ContributionStatus } from 'Shared/types/contribution';
import { Permissions } from 'Client/constants/permissions';
import { userHasPermission } from 'Client/utils/user';
import { ContributionProps } from '../types';
import { ContributionListProps } from './types';
import { ContributionCard } from './ContributionCard';

const ContributionList: React.FC<ContributionListProps> = ({
  contributions,
  userAgreements,
  user,
  refetchUserAgreements,
  canReply,
}: ContributionListProps) => (
  <>
    {contributions.map((contribution: ContributionProps) => {
      const {
        _id,
        response,
        map,
        date,
        userId,
        surveySlug,
        answersPopulated,
        pageId,
        status,
        survey,
        moderation,
        voiceAnswers,
      } = contribution;

      const backUrl = `/${
        contribution.map ? 'map' : 'proposals'
      }/${surveySlug}`;

      const userAgreementId = userAgreements?.find(
        (agr) => agr.commentId === _id
      )?._id;

      const isOwnComment = user && userId && user.id === userId;
      const isSurvey = survey || status === ContributionStatus.SURVEYED;
      const canSeeUnderModerationComments = userHasPermission(
        user,
        Permissions.SEE_UNDER_MODERATION_COMMENTS
      );
      const rejectedModeration = moderation?.status === 'REJECTED';
      if (rejectedModeration && !canSeeUnderModerationComments) return null;

      return (
        <ContributionCard
          canSeeUnderModerationComments={canSeeUnderModerationComments}
          moderation={moderation}
          answers={answersPopulated}
          backUrl={backUrl}
          userAgreementId={userAgreementId}
          contributionId={_id}
          contributionDate={date}
          isOwnComment={isOwnComment}
          key={_id}
          isMapComment={map}
          refetchUserAgreements={refetchUserAgreements}
          contrProposalSlug={surveySlug}
          response={response}
          pageId={pageId}
          isSurvey={isSurvey}
          canReply={canReply}
          voiceAnswers={voiceAnswers}
        />
      );
    })}
  </>
);

export const MemoizedContributionList = React.memo(ContributionList);
