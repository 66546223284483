import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/node';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  PlanningAppsPageTabs,
  PlanningAppsTab,
} from 'Shared/types/planningapp';
import { useProject, useMap } from 'Client/utils/hooks';
import { HubHeader } from 'Client/components/organisms';
import { SortByDropdown } from 'Client/components/molecules/SortByDropdown/SortByDropdown';
import { RootState } from 'Client/redux-store';
import { removePropFromFilters } from 'Client/utils/planningApps/filters';
import { QueryByAreaFilters } from 'Client/utils/reduxReducers/filters/FilterState';
import { setQueryByAreaFilter } from 'Client/utils/reduxReducers/filters/filtersReducer';
import { Xyz } from 'Shared/types/map';
import { ViewTabsProps } from './types';
import { MapView } from '../MapsView';
import { ListView } from '../ListView';
import { FiltersSection } from '../FiltersSection/FiltersSection';
import {
  NoResultsContent,
  NoResultsMessage,
  NoResultsTitle,
  StyledBar,
} from './ViewTabs.styles';
import { GET_FILTERED_PLANNING_APPS } from '../../PlanningApps.gql';

export const ViewTabs: React.FC<ViewTabsProps> = ({
  tabs,
  planningApps,
  hubHeader,
  hubTitle,
}) => {
  const { t } = useTranslation();
  const { dispatch, state } = useMap();
  const xyz = state.xyz as Xyz;
  const project = useProject();
  const dispatchFiltersRdx = useDispatch();
  const client = useApolloClient();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [filteredPlanningApps, setFilteredPlanningApps] =
    React.useState(planningApps);
  const [listResult, setListResults] = React.useState<number>(
    filteredPlanningApps?.length || 0
  );
  const [mapResults, setMapResults] = React.useState(0);

  const queryByAreaLayer = React.useRef(null);

  const currentTab = tabs[selectedTab];
  const currentResults = selectedTab === 0 ? listResult : mapResults;
  const isMap = selectedTab === 1;
  const mapPageId = (currentTab as PlanningAppsTab<PlanningAppsPageTabs.MAP>)
    .pageId;
  const initialFilters = [
    {
      project: {
        eq: project._id.toString(),
      },
      page_id: { eq: mapPageId },
    },
  ];

  const {
    filters: reduxFilters = initialFilters,
    queryByArea: reduxQueryByArea,
  } = useSelector((state: RootState) => state.filters);

  const mapFilters = xyz?.layers?.list?.Custom?.filter?.current;
  const clearQueryByArea = async () => {
    try {
      /* Removes area from the map layer*/
      queryByAreaLayer?.current?.getSource().clear();
      queryByAreaLayer.current = new global.ol.layer.Vector({
        map: xyz.map,
        source: new global.ol.source.Vector({
          projection: `EPSG:${xyz.layers.list.Custom.srid}`,
        }),
        style: xyz.mapview.layer.styleFunction(xyz.layers.list.Custom),
      });

      /* Clear map filters */
      const _mapFilters = [
        ...(typeof mapFilters === 'string' ? JSON.parse(mapFilters) : []),
      ];
      const _clearMapFilters = removePropFromFilters(_mapFilters, 'id');

      dispatch({
        type: 'SET_MAP_FILTERS',
        payload: { filters: _clearMapFilters, table: 'Custom' },
      });
      dispatchFiltersRdx(
        setQueryByAreaFilter({
          area: null,
          references: [],
          ids: [],
        })
      );
      /* Clear redux filters */
      await queryListView();

      /* Removes drawing interaction from the map */
      const interactions = xyz?.map?.getInteractions().getArray();
      const qryByAreaInteraction = interactions?.find(
        (i) => i.getProperties().id === 'query-by-area-drawing'
      );
      xyz?.map?.removeInteraction(qryByAreaInteraction);
    } catch (error) {
      captureException(error);
    }
  };
  const queryListView = async (queryByAreaFilter?: QueryByAreaFilters) => {
    const _reduxFilters = [...(reduxFilters || [])];
    const references = reduxQueryByArea?.references.length
      ? reduxQueryByArea?.references
      : queryByAreaFilter.references;
    const listViewFilters = [
      ..._reduxFilters,
      ...(references
        ? [
            {
              reference: {
                in: `${references.map((ref) => `'${ref}'`).join(',')}`,
              },
            },
          ]
        : []),
    ];

    /* Sends list view query to api */
    const { data } = await client.query({
      query: GET_FILTERED_PLANNING_APPS,
      variables: {
        filters: listViewFilters,
        limit: 5,
        offset: 0,
      },
    });
    const {
      getFilteredPlanningApps: { planningAppContent, count },
    } = data;
    setListResults(count);
    const planningAppsData = planningAppContent.map((item) => {
      return {
        content: item,
        slug: item.reference?.split('/').join('-'),
      };
    });
    setFilteredPlanningApps(planningAppsData);
  };

  const components = {
    [PlanningAppsPageTabs.MAP]: (
      <MapView
        aria-labelledby={`tab-bar-tab-${PlanningAppsPageTabs.MAP}`}
        role="tabpanel"
        mapPageId={mapPageId}
        initialFilters={initialFilters}
        clearQueryByArea={clearQueryByArea}
        queryByAreaLayer={queryByAreaLayer}
        queryListView={queryListView}
      />
    ),
    [PlanningAppsPageTabs.LIST]: (
      <ListView
        aria-labelledby={`tab-bar-tab-${PlanningAppsPageTabs.LIST}`}
        role="tabpanel"
        hideHubHeader
        planningApps={filteredPlanningApps}
        setFilteredPlanningApps={setFilteredPlanningApps}
      />
    ),
  };

  return (
    <div style={{ paddingTop: '2rem' }}>
      {hubHeader && <HubHeader title={hubTitle || t('Have your say')} />}
      <FiltersSection
        initialFilters={initialFilters}
        planningApps={filteredPlanningApps}
        setFilteredPlanningApps={setFilteredPlanningApps}
        setMapResults={setMapResults}
        setListResults={setListResults}
        project={project}
        clearQueryByArea={clearQueryByArea}
      />
      <StyledBar
        selectedTab={selectedTab}
        isFullWidth
        isGla
        results={currentResults}
        showResults
        onTabChange={(tab) => setSelectedTab(tab)}
        tabs={tabs?.map((tab, index) => ({
          ...tab,
          value: index,
        }))}
      />
      {currentTab?.type === PlanningAppsPageTabs.LIST && (
        <SortByDropdown
          tabs={[
            {
              label: 'Validated date',
              value: 'validatedDate',
              options: [
                { label: 'Latest to oldest', value: 'DESC' },
                { label: 'Oldest to latest', value: 'ASC' },
              ],
            },
            {
              label: 'Decided date',
              value: 'decisionDate',
              options: [
                { label: 'Latest to oldest', value: 'DESC' },
                { label: 'Oldest to latest', value: 'ASC' },
              ],
            },
          ]}
          name="sortBy"
        />
      )}

      {currentResults === 0 && (
        <NoResultsMessage isMap={isMap}>
          <NoResultsTitle>
            {t('No applications match your search')}
          </NoResultsTitle>
          <NoResultsContent>
            {t(
              'Try expanding your criteria or set up an email alert to get notified when new planning applications are added.'
            )}
          </NoResultsContent>
        </NoResultsMessage>
      )}
      {components[currentTab.type]}
    </div>
  );
};
