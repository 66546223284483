import * as React from 'react';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import styled, { css } from 'styled-components';
import { Checkbox as CheckboxBase, Button } from 'Molecules';
import { ContributionSentimentCheck } from 'Shared/types/contribution';
import { Typography } from 'Atoms';

export const Loader = styled(MuiCircularProgress)`
  margin: 2.1875rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  width: 100%;
`;

export const FilterItem = styled.div`
  p {
    margin-top: 0px;
  }
`;
export const ClearFilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
`;
export const ClearFiltersButton = styled.button<{ hasFilters?: boolean }>`
  border: 0px;
  background: transparent;
  color: grey;
  border-bottom: 2px solid grey;
  cursor: pointer;
  width: 6rem;
  font-weight: 600;
  padding-bottom: 5px;
  ${({ hasFilters }) =>
    !hasFilters &&
    css`
      display: none;
    `}
`;
export const CheckBoxContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TextContentWrapper = styled.div<{ isFirstItem?: boolean }>`
  ${({ isFirstItem }) => isFirstItem && 'margin-top: 2rem;'}
  padding: 1rem 0.5rem;
  background-color: #dee3f1;
  border-left: 0.25rem solid #7985a8;
  padding-left: 1.25rem;
  text-align: left;
  white-space: break-spaces;
`;

export const TextTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
`;
export const TextContent = styled(Typography)`
  font-size: 0.9rem;
`;

export const Checkbox = styled(CheckboxBase)<{
  sentiment?: ContributionSentimentCheck;
}>`
  padding-top: 1rem;

  .MuiTypography-root {
    margin-left: 0.625rem;
  }

  ${({ theme, sentiment }) =>
    sentiment &&
    css`
      .MuiSvgIcon-root {
        fill: ${theme.colorMappings[sentiment]} !important;
      }
    `};
`;

export const IconWrapper = styled.div<{ optionColor?: string }>`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;

  label {
    padding: 0;
  }

  i {
    padding-top: 0.3125rem;
    margin-left: 0.3125rem;
  }

  ${({ optionColor }) =>
    optionColor &&
    css`
      .MuiIconButton-label {
        path {
          fill: ${optionColor};
        }
      }
    `}
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ViewMapButton = styled((props) => <Button {...props} />)`
  ${({ theme }) => css`
    background: ${theme.colors.green['A700']};
    border: unset;
    border-radius: 0.3125rem;
    width: 100%;
    color: ${theme.colors.white[500]};
    margin-top: 2rem;
    &:hover {
      border: 0px;
    }
    span {
      display: flex;
      justify-content: center;
      svg {
        position: absolute;
        right: 10%;
      }
      min-width: 5rem;
      font-size: 0.875rem;
    }

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`;
