interface setUserValueParams {
  setFunction: React.Dispatch<React.SetStateAction<unknown>>;
  index: number;
  value: boolean | string;
  cleanErrors: () => void;
}
export const setUserValue = ({
  setFunction,
  index,
  value,
  cleanErrors,
}: setUserValueParams) => {
  setFunction((previous) => {
    const newArray = [...previous];
    newArray[index] = value;
    return [...newArray];
  });

  if (cleanErrors) {
    cleanErrors();
  }
};
