import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { MapCancelButton } from 'Molecules';
import { CTAPanel, CTADescription, CTAHeader } from 'Atoms/MapCTA';
import { AppTheme } from 'Client/types';
import { PinIcon } from 'Atoms/Icons/Pin/Pin';
import { LineIcon } from 'Atoms/Icons/LineIcon';

const CTA: React.FC<{
  header: string;
  content: string;
  onCancel: () => void;
  answerType?: string;
  changeColour?: boolean;
}> = ({ header, content, onCancel, answerType, changeColour }) => {
  const { t } = useTranslation();
  const theme: AppTheme = useTheme();

  return (
    <CTAPanel>
      <CTADescription changeColour={changeColour}>
        {answerType === 'line' ? (
          <LineIcon stroke={changeColour ? 'white' : '#7A3995'} />
        ) : (
          <PinIcon color={theme.colorMappings.mapPinIcon} />
        )}
        <CTAHeader variant="h4">{header}</CTAHeader>
        <Typography
          variantMapping={{ body1: 'div' }}
          fontSize={'0.875rem'}
          bold={changeColour}
        >
          {content}
        </Typography>
      </CTADescription>
      <MapCancelButton label={t('Cancel')} onClick={onCancel} />
    </CTAPanel>
  );
};

export default CTA;
