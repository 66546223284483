import React from 'react';
import styled, { css } from 'styled-components';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import { TextField as TextFieldBase } from 'Client/components/molecules';

export interface DotProps {
  status: 'granted' | 'refused';
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.colors.green[500]};
  }

  img {
    width: 8.4194rem;
    height: 6.4375rem;
  }
`;

export const Container = styled.div`
  max-width: 37.5rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.25rem 0 3.4375rem;
  width: 66vw;
  max-width: 37.5rem;
  min-width: 20rem;

  div {
    margin-left: 0;
  }
`;

export const VerificationStepContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const StyledInput = styled(({ ...props }) => (
  <TextFieldBase {...props} />
))`
  ${({ theme }) => css`
    width: 100%;
    min-width: 0 !important;
    margin: 0.625rem 0 0.5rem;
    margin-bottom: 1.875rem;

    :focus {
      box-shadow: 0rem 0rem 0rem 0.25rem rgba(121, 133, 168, 0.2);
      border-radius: 0.3125rem;
    }
    :focus-within {
      box-shadow: 0rem 0rem 0rem 0.25rem rgba(121, 133, 168, 0.2);
      border-radius: 0.3125rem;
    }

    .MuiOutlinedInput-root {
      border: 0.0625rem solid ${theme.colorMappings.loginInputBorderColor};
      height: 2.5625rem;
    }

    .MuiOutlinedInput-root fieldset {
      border-color: ${theme.colorMappings.loginInputBorderColor};
      top: 0;
    }

    .Mui-focused {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border: red;
          border-radius: 0.3125rem;
        }
      }
    }

    .MuiInputBase-input {
      &:focus {
        padding: 0.625rem 0.9rem;
      }
    }
    .MuiOutlinedInput-root {
      &.Mui-error {
        &.MuiOutlinedInput-notchedOutline {
          border: 0.0625rem solid rgb(121, 133, 168);
          box-shadow: 0rem 0rem 0rem 0.25rem ${theme.colors.red[800]};
          border-radius: 0.3125rem;
        }
      }
    }

    .MuiOutlinedInput-root {
      &.Mui-disabled {
        background: ${theme.colorMappings.inputBorder};
        opacity: 0.5;
      }
    }
    span {
      position: absolute;
      top: 2.375rem;
      width: 100%;
    }

    .MuiIconButton-label {
      position: initial;
    }
  `}
  ${({ status }) =>
    status?.type === 'error' &&
    status?.show &&
    css`
      margin-bottom: 4.0625rem;
    `}
`;

export const ResetPasswordButton = styled.button`
  ${({ theme, disabled }) => css`
    height: 2.9375rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${disabled ? 'default' : theme.colors.green[500]};
    border: none;
    border-radius: 0.125rem;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: ${disabled ? 'default' : theme.colors.white[500]};
    cursor: ${disabled ? 'default' : 'pointer'};

    ${theme.breakpoints.down('sm')} {
      font-size: 1rem;
    }

    :hover {
      background: ${disabled ? 'default' : theme.colors.green[600]};
    }
  `}
`;

export const Dot = styled(MuiTimelineDot)<DotProps>`
  ${({ theme, status }) => {
    const glaStatus = {
      granted: 'planningAppDecisionGranted',
      refused: 'planningAppDecisionRefused',
    };

    const widthValue = 0.75;
    const heightValue = 0.75;

    return css`
      width: ${widthValue}rem;
      height: ${heightValue}rem;
      border-radius: 100%;
      margin-left: 15px;
      position: relative;
      display: inline-block;
      justify-content: center;
      align-items: center;
      background-color: ${theme.colorMappings[glaStatus[status]]};
    `;
  }};
`;

export const DotDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const DotLabel = styled.span`
  margin-left: 10px;
`;
