import { useLazyQuery } from '@apollo/client';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { MicrosoftSSOIcon } from 'Atoms/Icons/MicrosoftSSO/MicrosoftSSO';
import { validateEmail } from 'Client/utils/validators';
import { setUserLoginInfo } from 'Client/utils/reduxReducers/login/loginPageInfoReducer';
import { MixpanelEventTypes, useAnalytics } from 'Client/utils/hooks';
import {
  Wrapper,
  Title,
  Form,
  Buttons,
  Description,
  Header,
  Label,
  SignInButton,
  TextField,
  SSOButton,
} from '../Shared.styles';
import { GET_USER_TYPE } from './MainLogin.gql';

export const MainLogin: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<boolean>(false);

  const [getUserType, { data }] = useLazyQuery(GET_USER_TYPE);

  const checkUserType = () => {
    trackEvent(MixpanelEventTypes.CLICKED_MAIN_LOGIN, { email });
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    getUserType({
      variables: {
        email: email,
      },
    });
  };

  React.useEffect(() => {
    if (data) {
      dispatch(
        setUserLoginInfo({
          email,
          userName: data?.getUserTypeFromEmail?.userName,
        })
      );

      router.push(`/project-centre/login/${data?.getUserTypeFromEmail?.type}`);
    }
  }, [data, dispatch, email, router]);

  return (
    <Wrapper data-testid="MainLogin-Wrapper">
      <Header page="main-login">
        <Title>{t('Login')}</Title>
      </Header>
      <Description>
        {t('Login to manage your account, edit your profile and settings.')}
      </Description>
      <Form>
        <Label>{t('Email')}</Label>
        <TextField
          placeholder={t('Email')}
          label={'Email'}
          value={email}
          handleChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value);
          }}
          type="email"
          status={
            emailError && {
              show: true,
              type: 'error',
              message: t('This does not appear to be a valid email address.'),
            }
          }
        ></TextField>
      </Form>
      <Buttons>
        <SignInButton
          onClick={() => checkUserType()}
          data-ghostinspectorid="MainLogin-Button"
        >
          {t('Login / Sign up')}
        </SignInButton>
      </Buttons>
      <Buttons>
        <Description>or</Description>
        <SSOButton
          data-ghostinspectorid="ProjectTeamMicrosoftLogin-Button"
          onClick={() => ''}
        >
          <MicrosoftSSOIcon />
          {t('Sign in with {{microsoft}}', { microsoft: 'Microsoft' })}
        </SSOButton>
      </Buttons>
    </Wrapper>
  );
};
