import * as React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TimelineStatusDot } from 'Atoms/TimelineStatusDot';
import { getStatus } from 'Client/utils/timeline';
import { TimeLineProps } from './types';
import {
  Wrapper,
  Content,
  Hr,
  StatusWrapper,
  Title,
  Text,
  TimelineWrapper,
  StyledLayoutScroll,
} from './TimeLine.styles';

export const TimeLine: React.FC<TimeLineProps> = ({ milestones }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content>
        <Title>{t('TIMELINE')}</Title>
        <StyledLayoutScroll>
          <TimelineWrapper>
            <Hr />

            {milestones.map((milestone, i) => (
              <StatusWrapper key={i}>
                <TimelineStatusDot
                  status={
                    milestone?.date
                      ? getStatus(
                          milestone.date,
                          milestones[i - 1]?.date || milestone.date
                        )
                      : 'planned'
                  }
                />
                <Text bold>{milestone.description}</Text>
                <Text>
                  {milestone.date
                    ? format(new Date(milestone.date), 'dd.MM.yyyy')
                    : 'TBC'}
                </Text>
              </StatusWrapper>
            ))}
          </TimelineWrapper>
        </StyledLayoutScroll>
      </Content>
    </Wrapper>
  );
};
