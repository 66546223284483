import { useTranslation } from 'react-i18next';
import { syntaxValidationRequest } from 'Client/services/validation';
import { parseSyntaxValidationResult } from '../validators';
import useTrackEmailValidation from './useAnalytics/useTrackEmailValidation';

const useValidateEmail = (additionalMixpanelData = {}) => {
  const { t } = useTranslation();
  const trackEmailValidation = useTrackEmailValidation();

  const validateEmail = async (email: string) => {
    const validatorResult = await syntaxValidationRequest({
      data: email,
    }).then(parseSyntaxValidationResult(t));
    const { type: validation, message } = validatorResult ?? {
      validation: null,
      message: null,
    };

    trackEmailValidation(email, validatorResult, additionalMixpanelData);

    return { validation, message };
  };

  return validateEmail;
};

export default useValidateEmail;
