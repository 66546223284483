import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Value = styled.div`
  display: inline;
  margin-right: 0.5rem;
  font-weight: bold;
`;

export const Percentage = styled.div`
  display: inline;
  font-weight: 400;
  color: rgb(0 0 0 / 58%);
`;

export const NoData = styled.div`
  text-align: center;
`;
