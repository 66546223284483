import * as React from 'react';
import dynamic from 'next/dynamic';
import { TextField, TextArea } from 'Molecules';
import { useProject } from 'Client/utils/hooks';
import { Wrapper } from './EmailForm.styles';
import { UseMessages } from '../../hooks';
import { EmailFormProps } from '../../types';

const FroalaEditor = dynamic(() => import('../FroalaEditor/FroalaEditor'), {
  ssr: false,
});

export const EmailForm: React.FC<EmailFormProps> = ({
  setSubjectText,
  setHeaderText,
  setEmailMessage,
  setButtonText,
  setButtonLink,
}: EmailFormProps) => {
  const { SUBJECT, HEADER_TEXT, EMAIL_MESSAGE, BUTTON_TEXT } = UseMessages();
  const { features } = useProject();

  return (
    <Wrapper data-testid="EmailForm-Wrapper">
      <TextField
        handleChange={(event) => {
          const { value } = event.target;
          setSubjectText(value as string);
        }}
        label="Subject"
        placeholder={`e.g. ${SUBJECT}`}
        showLabel={true}
      />
      <TextField
        handleChange={(event) => {
          const { value } = event.target;
          setHeaderText(value as string);
        }}
        label="Header text"
        placeholder={`e.g. ${HEADER_TEXT}`}
        showLabel={true}
      />
      {features.advancedCommunicationEditor ? (
        <FroalaEditor
          label="Email message"
          placeholder={`e.g. ${EMAIL_MESSAGE}`}
          onChange={(text) => setEmailMessage(text)}
        />
      ) : (
        <TextArea
          handleChange={(event) => {
            const { value } = event.target;
            setEmailMessage(value as string);
          }}
          label="Email message"
          placeholder={`e.g. ${EMAIL_MESSAGE}`}
          rowsMax={20}
        />
      )}
      <TextField
        handleChange={(event) => {
          const { value } = event.target;
          setButtonText(value as string);
        }}
        label="Button text"
        placeholder={`e.g. ${BUTTON_TEXT}`}
        showLabel={true}
      />
      <TextField
        handleChange={(event) => {
          const { value } = event.target;
          setButtonLink(value as string);
        }}
        label="Button link"
        placeholder="e.g. https://www.commonplace.is/"
        showLabel={true}
      />
    </Wrapper>
  );
};
