import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LogoControlCenter } from 'Atoms/Icons';
import { OnboardingPagesTemplate as PageTemplate } from 'Templates';
import { cpBrandName } from 'Client/constants/brand';
import { setCurrentTab } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { decodeCustomerNameFromURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { Wrapper } from '../ProjectListPage.styles';
import {
  Bold,
  Content,
  Button,
  TextDiv,
  TextWrapper,
  Text,
  ImageWrapper,
} from './WaitingPage.styles';

export const WaitingPage: React.FC = () => {
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const { t } = useTranslation('customer');

  React.useEffect(() => {
    dispatch(setCurrentTab('Projects'));
  }, []);

  return (
    <PageTemplate>
      <Wrapper data-testid="WaitingPage-Wrapper">
        <Content>
          <TextWrapper>
            <div>
              <LogoControlCenter />
            </div>
            <TextDiv>
              <Bold>
                {t('Your {{cpBrandName}} is being generated!', { cpBrandName })}
              </Bold>
              <Text>{t('This might take a few minutes.')}</Text>
            </TextDiv>
          </TextWrapper>
          <ImageWrapper>
            <img
              src={
                'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1646927878/Project%20creation%20form/Loading_Project.png'
              }
            />
          </ImageWrapper>
          <Button
            onClick={() =>
              router.replace({
                pathname: '/project-centre',
                query: { customer: decodeCustomerNameFromURL(query.customer) },
              })
            }
          >
            {t('Back to projects')}
          </Button>
        </Content>
      </Wrapper>
    </PageTemplate>
  );
};
