import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem 0 0.875rem;
`;

export const Value = styled.div`
  display: inline;
  margin-right: 0.5rem;
  color: rgb(0 0 0 / 58%);
  font-weight: 500;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]} !important;
  font-weight: 400 !important;
  width: 15rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  left: 11%;
  bottom: 20%;
  text-align: center;
  white-space: normal;
`;
