import { AppTheme } from 'Client/types';

export function getTeamMemberRowBGColor(
  invite: boolean,
  status: string,
  theme: AppTheme
) {
  if (invite && status === 'expired') {
    return theme.colors.red[50];
  }

  if (invite && status === 'pending') {
    return theme.colors.grey[200];
  }

  return theme.colors.white[500];
}
