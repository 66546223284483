import styled, { css } from 'styled-components';

const Button = styled.button`
  background-color: transparent;
`;

export const Wrapper = styled.div`
  margin: 2.25rem 0 2.1875rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  border-bottom: ${({ theme }) => `thin solid ${theme.colors.grey[200]}`};

  div {
    display: flex;
    width: 100%;
    align-items: baseline;
  }
`;

export const TabItem = styled(Button)<{ isSelected: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  padding: 0 0.5rem 1rem 0;
  margin-right: 1.625rem;
  border: none;
  cursor: pointer;
  ${({ isSelected, theme }) => css`
    border-bottom: ${isSelected ? '0.25rem solid' : 'none'};
    border-bottom-color: ${theme.colors.green['A400']};
  `}
`;

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-radius: 0.3125rem;
  ${({ theme }) => css`
    background: ${theme.colors.green[500]};
    color: ${theme.colors.white[500]};
    font-weight: ${theme.fontWeights.bold};
  `}
`;

export const CTAWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  width: min-content !important;
`;
