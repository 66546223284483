import { CampaignRowTitle } from '../Table/components';

const width = '200';

export const currentBasicColumns = [
  {
    Header: 'Referral website',
    accessor: 'referral',
    Cell: CampaignRowTitle,
    width,
  },
  {
    Header: 'Visitors',
    accessor: 'visitors',
    width,
  },
  {
    Header: 'Responded',
    accessor: 'responded',
    width,
  },
  {
    Header: 'Subscribed',
    accessor: 'subscribed',
    width,
  },
];
