import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 3.75rem 3rem 3.75rem;
`;

export const CardsList = styled.dl`
  display: flex;
  margin: 3rem 0;
  justify-content: center;
  gap: 3.25rem;
`;
