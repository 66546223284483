import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin: 0.5rem 0;
`;

export const Header = styled.p`
  display: flex;
  align-items: center;
  font-size: 1rem;

  span {
    margin-left: 0.1875rem;
    font-size: 1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorMappings.brand};
  }

  svg {
    fill: ${({ theme }) => theme.colorMappings.brand};
  }
`;

export const OpenModal = styled.button<{
  width?: string;
  rounded?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-left: 10px;
  border: 1px solid #979797;
  padding: 0.3rem 1.563rem;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  color: inherit;
  border-radius: 1.3125rem;

  ${({ theme, 'aria-expanded': ariaExpanded }) =>
    `background-color: ${
      ariaExpanded ? lighten(0.45, theme.colorMappings.brand) : 'white'
    };
  `};

  & > span {
    font-size: 15px;
  }

  & > svg {
    width: 20px;
  }
`;

export const Options = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 60px;

  width: fit-content;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border-radius: 0.3125rem;
  padding: 1rem 2rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
  border: 0.0625rem solid rgba(0, 0, 0, 0.05);

  z-index: 100;

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colorMappings.brand};
    margin-top: 0.5rem;
  }

  & > div {
    margin-top: 0.75rem;

    & > div {
      margin-top: 0.3125rem;
    }
  }
`;

export const Option = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;

  & > label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    ${({ theme }) => css`
      &:focus {
        outline-color: ${theme.colorMappings.focusHighlight};
        outline-style: solid;
        outline-offset: 0.2rem;
        outline-width: 0.1875rem;
      }
    `}
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 700;
    `}
`;
