import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'Client/components/molecules/Switch';
import { MapLayerNames } from 'Shared/types/map';
import { useMap } from 'Client/utils/hooks';
import { Divider, SwitchContainer } from './LayersView.styles';

const LayersView = (): JSX.Element => {
  const { t } = useTranslation();

  const [showContributions, setShowContributions] = React.useState(true);
  const [showPostalDistrict, setShowPostalDistrict] = React.useState(false);
  const {
    state: { showFilters, xyz },
    version,
  } = useMap();

  React.useEffect(() => {
    if (!xyz) return;
    const filter = xyz?.layers?.list?.Contributions?.filter?.current;
    const state = typeof filter === 'string' ? JSON.parse(filter) : filter;

    const sentimentsState = state?.length ? state : [];

    // First/Default state shows contributions, but sentiment filters are not defined
    // Second+ definitions must have sentiment.eq defined, or it means no filters are applied
    const isSentimentDefined = sentimentsState.some((el) => el.sentiment);

    isSentimentDefined
      ? setShowContributions(sentimentsState.some((el) => el.sentiment))
      : setShowContributions(showFilters);
  }, [xyz, setShowContributions]);

  React.useEffect(() => {
    setShowPostalDistrict(
      xyz?.layers?.list?.[MapLayerNames.POSTAL_DISTRICT]?.display
    );

    setShowContributions(
      xyz?.layers?.list?.[MapLayerNames.CONTRIBUTIONS]?.display
    );
  }, [xyz, xyz?.layers?.list]);

  const handleToggleSwitch = (layer: 'Postal district' | 'Contributions') => {
    const isV4 = version === 'v4';
    const isPostalDistrict = layer === 'Postal district';

    if (xyz.layers.list[layer].display) {
      if (isV4 && isPostalDistrict) return xyz.layers.list[layer].hide();

      xyz.layers.list[layer].remove();
    } else {
      xyz.layers.list[layer].show();
    }
  };

  const handlePostalDistrictVisibility = () => {
    setShowPostalDistrict(!showPostalDistrict);
    handleToggleSwitch(MapLayerNames.POSTAL_DISTRICT);
  };

  const handleContributionVisibility = () => {
    setShowContributions(!showContributions);
    handleToggleSwitch(MapLayerNames.CONTRIBUTIONS);
  };

  return (
    <SwitchContainer data-testid="LayersContent">
      {/* Todo: get labels from database layers collection */}

      <Switch
        checked={showPostalDistrict}
        onChange={handlePostalDistrictVisibility}
        name="Postal district"
        label={t('Postal district')}
      />

      <Divider />

      <Switch
        checked={showContributions}
        onChange={handleContributionVisibility}
        name="Contributions"
        label={t('Contributions')}
      />
    </SwitchContainer>
  );
};

export { LayersView };
