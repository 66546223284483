import { mapSentiments, mapStatus } from 'Pages/dashboard/utils';

export const handleDotColor = (label: string, value: string): string => {
  if (!label || !value) return '';
  if (label === 'status') {
    const status = mapStatus(value);
    if (!status) return '';
    return status.color;
  }
  if (label === 'sentiment' || label === 'average sentiment') {
    const sentiment = mapSentiments(value as string);
    if (!sentiment) return '';
    return sentiment.color;
  }
};
