import styled, { css } from 'styled-components';

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
`;

export const Wrapper = styled.footer`
  height: 5.2rem;
  margin-top: 4.5rem;
  background: ${({ theme }) => theme.colors.white[500]};
  padding: 1.25rem 7rem 1.25rem 4.35rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
`;
export const LeftButton = styled(ButtonBase)`
  font-size: 1rem;
  ${({ theme }) => css`
    color: ${theme.colors.green[500]};
    background: ${theme.colors.white[500]};
    font-weight: ${theme.fontWeights.semi};
  `}
`;
export const RightButton = styled(ButtonBase)`
  font-size: 1.15rem;
  padding: 0.4375rem 1.6rem;
  border-radius: 0.35rem;
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.bold};
    background: ${theme.colors.green[500]};
    color: ${theme.colors.white[500]};
  `}
`;
