import { MapQuestionAnswerType } from 'Shared/types/question';

/**
 * it returns the correct map feature
 * based on the answerType prop of a question (type: 'map')
 */
export const getFeatureTypeByAnswerType = (
  answerType: MapQuestionAnswerType
) => {
  const mapping = {
    point: 'Point',
    polygon: 'Polygon',
    line: 'LineString',
    circle: 'Circle',
  };
  return mapping[answerType] ?? answerType;
};
