import * as React from 'react';
import { useRouter } from 'next/router';
import { addDays } from 'date-fns';
import { DashboardPagesTemplate as Template } from 'Templates';
import {
  handleTabUrls,
  redirectToTab,
  getTopFiltersFromUrl,
} from 'Pages/dashboard/utils';
import { ChartWrapper } from './components/charts/ChartWrapper';
import {
  BottomTabNavigation,
  CampaignsTab,
  EmailsTab,
  ReferralTab,
  TrafficTable,
} from './components';
import {
  BottomTabsSection,
  Count,
  Header,
  MainTitle,
  Section,
  Title,
  TitleWrapper,
} from './VisitorsPage.styles';
import { DateFilter } from '../components';
import { AppliedFilters } from '../respondents/types';

export const VisitorsPage: React.FC = () => {
  const router = useRouter();
  const { query } = router;
  const topFilters = getTopFiltersFromUrl({ router });
  const dateFilter = topFilters.date;

  const [bottomTab, setBottomTab] = React.useState<number>(1);
  const [visitorsCount, setVisitorsCount] = React.useState<number>(0);
  const [appliedFilters, setAppliedFilters] =
    React.useState<AppliedFilters>(null);

  const tabOptions = [
    { label: 'Referring domains', id: 'referrals', value: 1 },
    { label: 'Campaigns', id: 'campaigns', value: 2 },
    { label: 'Emails', id: 'emails', value: 3 },
  ];

  const applyFilters = () => {
    setAppliedFilters({
      topFilters: {
        date: {
          from: dateFilter.from,
          to: (() => {
            if (dateFilter.to && !Number.isNaN(new Date(dateFilter.to))) {
              return addDays(new Date(dateFilter.to), 1);
            }
            return dateFilter.to;
          })(),
        },
      },
    });
  };

  const renderTable = (bottomTab: number) => {
    switch (bottomTab) {
      case 1:
        return <ReferralTab appliedFilters={appliedFilters} />;
      case 2:
        return <CampaignsTab appliedFilters={appliedFilters} />;
      case 3:
        return <EmailsTab appliedFilters={appliedFilters} />;
      default:
        return <p>loading</p>;
    }
  };

  React.useEffect(() => {
    const { tab } = query;
    handleTabUrls({
      page: 'visitors',
      tabInQuery: tab as string,
      router,
      tabOptions,
      setTab: setBottomTab,
    });
  }, [query.tab]);

  React.useEffect(() => {
    applyFilters();
  }, [query.topFilters]);

  return (
    <Template currentTab={'Visitors'}>
      <Section>
        <Header>
          <TitleWrapper data-onboarding="dashboard-visitors-count">
            <Count>{visitorsCount || 0}</Count>
            <MainTitle data-testid="VisitorsPage-title">Visitors</MainTitle>
          </TitleWrapper>
          <DateFilter appliedFilters={appliedFilters} pageType={'visitors'} />
        </Header>

        <ChartWrapper appliedFilters={appliedFilters} />

        <TrafficTable
          setVisitorsCount={setVisitorsCount}
          appliedFilters={appliedFilters}
        />
      </Section>

      <BottomTabsSection>
        <Title>Campaigns</Title>

        <BottomTabNavigation
          selectedTab={bottomTab}
          tabOptions={tabOptions}
          onTabChange={(tabId) =>
            redirectToTab({ newTab: tabId, page: 'visitors', router })
          }
        />

        {renderTable(bottomTab)}
      </BottomTabsSection>
    </Template>
  );
};
